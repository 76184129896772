<app-medical-treating-report-header [data]="headerInfo"></app-medical-treating-report-header>

<div class="report-wrapper" #element>

	<div class="report-mts">
		<div class="report-mts__item"
			 [class.is-doctor]="item.type === 'DOCTOR'"
			 [class.is-hospital]="item.type === 'HOSPITAL'"
			 [class.is-other]="item.type === 'OTHER'"
			 *ngFor="let item of tableData">

<!--START HEADER -->
			<div class="report-mts__header"
				 [class.is-doctor]="item.type === 'DOCTOR'"
				 [class.is-hospital]="item.type === 'HOSPITAL'"
				 [class.is-other]="item.type === 'OTHER'">
				<div *ngIf="item.type === 'DOCTOR'">{{item.item.name}}</div>
				<div *ngIf="item.type === 'HOSPITAL'">General Hospital</div>
				<div *ngIf="item.type === 'OTHER'">OTHER treating Sources</div>

				<div class="report-mts__header-date" *ngIf="item.type === 'DOCTOR'">
                    <ng-container *ngIf="item.item.firstSeen">
                        FIRST SEEN:
                    </ng-container>
					<span>{{item.item.firstSeen | date:'MM/dd/yyyy'}}</span>
				</div>
				<div class="report-mts__header-date" *ngIf="item.type === 'DOCTOR'">
                    <ng-container *ngIf="item.item.lastSeen">
                        LAST SEEN:
                    </ng-container>
					<span>{{item.item.lastSeen | date:'MM/dd/yyyy'}}</span>
				</div>
				<div class="report-mts__header-date" *ngIf="item.type === 'DOCTOR'">
                    <ng-container *ngIf="item.item.nextVisit">
					NEXT VISIT:
                    </ng-container>
					<span>{{item.item.nextVisit | date:'MM/dd/yyyy'}}</span>
				</div>
			</div>
<!--END HEADER -->

<!--START INFO -->
			<div class="report-mts__info">
				<div *ngIf="item.type === 'DOCTOR'">{{item.item.practice}}</div>
				<div *ngIf="item.type !== 'DOCTOR'">{{item.item.name}}</div>

				<div>{{item.item.address}}</div>
				<div>{{item.item.city}}, {{item.item.state}}, {{item.item.zip}}</div>
				<div>{{item.item.phoneFormatted}}</div>
			</div>
<!--END INFO -->

<!--START DOCTOR -->
			<div class="reports-mts__block-info block-info" *ngIf="item.type === 'DOCTOR'">
				<div class="block-info">
					<div class="block-info__item">
					<div class="block-info__title">Treats For</div>
					<div class="block-info__list">
						<div class="block-info__list-item" *ngFor="let condition of getConditions(item.item.conditions)">
							{{condition}}
						</div>
					</div>
				</div>

					<div class="block-info__item">
						<div class="block-info__title">Treatment Received <span>{{item.item.medicalTest}}</span></div>
<!--						<div class="block-info__text"></div>-->
					</div>
				</div>
			</div>
<!--END DOCTOR -->

<!--START HOSPITAL -->
			<div class="reports-mts__block-info block-info" *ngIf="item.type === 'HOSPITAL'">
				<div class="block-info block-info--right" *ngIf="item.item.visits && item.item.visits.length">
					<div class="block-info__visits" *ngIf="getInpatientVisits(item.item.visits).length">
						<div class="block-info__item  block-info__visits-item" *ngFor="let visit of getInpatientVisits(item.item.visits)">
							<div class="block-info__title">
								INPATIENT:
								Adm <span>{{visit.inPatientAdmission | date:'MM/dd/yyyy'}}</span>
								Dis <span>{{visit.inPatientDischarge | date:'MM/dd/yyyy'}}</span>
							</div>
							<div class="block-info__title">Reason <span>{{visit.hospitalizationReason}}</span></div>
							<div class="block-info__item">
								<div class="block-info__title">Medical Conditions Diagnoses <span>{{visit.conditions}}</span></div>

							</div>
							<div class="block-info__title">Treatment Received <span>{{visit.treatmentDetails}}</span></div>
							<div class="block-info__title">Doctor's Treated <span>{{visit.treatedDoctor}}</span></div>
						</div>
					</div>

					<div class="block-info__visits" *ngIf="getOutpatientVisits(item.item.visits).length">
						<div class="block-info__item  block-info__visits-item" *ngFor="let visit of getOutpatientVisits(item.item.visits)">
							<div class="block-info__title">
								OUTPATIENT:
								<span>{{visit.outPatientDate | date:'MM/dd/yyyy'}}</span>
							</div>
							<div class="block-info__title">Reason <span>{{visit.hospitalizationReason}}</span></div>
							<div class="block-info__item">
								<div class="block-info__title">Medical Conditions Diagnoses <span>{{visit.conditions}}</span></div>
							</div>
							<div class="block-info__title">Treatment Received <span>{{visit.treatmentDetails}}</span></div>
							<div class="block-info__title">Doctor's Treated <span>{{visit.treatedDoctor}}</span></div>
						</div>
					</div>

					<div class="block-info__visits" *ngIf="getERVisits(item.item.visits).length">
						<div class="block-info__item block-info__visits-item" *ngFor="let visit of getERVisits(item.item.visits)">
							<div class="block-info__title">
								ER VISIT:
								<span>{{visit.eRVisitDate | date:'MM/dd/yyyy'}}</span>
							</div>
							<div class="block-info__title">Reason <span>{{visit.hospitalizationReason}}</span></div>
							<div class="block-info__item">
								<div class="block-info__title">Medical Conditions Diagnoses <span>{{visit.conditions}}</span></div>
							</div>
							<div class="block-info__title">Treatment Received <span>{{visit.treatmentDetails}}</span></div>
							<div class="block-info__title">Doctor's Treated <span>{{visit.treatedDoctor}}</span></div>
						</div>
					</div>

				</div>
				<div class="block-info__item" *ngIf="item.item.conditions">
					<div class="block-info__title">Treats For</div>
					<div class="block-info__list">
						<div class="block-info__list-item" *ngFor="let condition of getConditions(item.item.conditions)">
							{{condition}}
						</div>
					</div>
				</div>
			</div>
<!--END HOSPITAL -->

<!--START OTHER -->
			<div class="reports-mts__block-info block-info" *ngIf="item.type === 'OTHER'">
				<div class="block-info">
					<div class="block-info__item">
						<div class="block-info__title">
							Date of Treatment:
							<span>{{item.item.otherTreatmentStart | date:'MM/dd/yyyy'}} </span>
							<span *ngIf="!item.item.otherTreatmentOngoin">{{item.item.otherTreatmentEnd | date:'MM/dd/yyyy'}} </span>
							<span *ngIf="item.item.otherTreatmentOngoin">Ongoing</span>
						</div>
						<div class="block-info__text">{{item.item.otherTreatmentDetails}}</div>
					</div>
					<div class="block-info__item">
						<div class="block-info__title">Reason <span>{{item.item.otherTreatmentReason}}</span></div>
					</div>
					<div class="block-info__item">
						<div class="block-info__title">Treatment or Service Provided <span>{{item.item.otherTreatmentDetails}}</span></div>
					</div>
				</div>
			</div>
<!--END OTHER -->

<!--START COMMON -->
			<div class="report-mts__info">
				<div class="block-info">
					<div class="block-info__item">
						<div class="block-info__title" *ngIf="!(item.item.medicalTestsList && item.item.medicalTestsList.length)">
							Medical Tests Performed: <span>NONE</span>
						</div>
						<div class="block-info__title" *ngIf="item.item.medicalTestsList && item.item.medicalTestsList.length">
							Medical Tests Ordered
						</div>
						<div class="block-info__list" *ngIf="item.item.medicalTestsList && item.item.medicalTestsList.length">
							<table class="block-info__table">
                                <thead>
                                    <tr class="block-info__list-item">
                                        <th>Test</th>
                                        <th>Date</th>
                                        <th>Body Part</th>
                                    </tr>
                                </thead>
								<tr class="block-info__list-item" *ngFor="let test of item.item.medicalTestsList">
									<td><span>{{getTestName(test.shortName)}} </span></td>
									<td><span>{{getTestDate(test.date)}} </span></td>
                                    <td><span *ngIf="test.description">{{test.description}} </span></td>
								</tr>
							</table>
						</div>
					</div>
					<div class="block-info__item" style="margin-top: 1em;" *ngIf="headerInfo.type === MEDICAL_TREATING_REPORT_TYPE.both">
						<div class="block-info__title block-info__title--orange" *ngIf="(item.discontinuedMedList.length || item.activeMedList.length)">Medications</div>
						<div class="block-info__title block-info__title--orange" *ngIf="!(item.discontinuedMedList.length || item.activeMedList.length)">Medications: <span>NONE</span></div>
						<div class="report-medications__list" *ngIf="(item.discontinuedMedList.length || item.activeMedList.length)">
							<div class="report-medications__col">
								<table class="report-medications-table report-medications-table--two-cols">
									<thead>
									<tr>
										<td>Discontinued Medications</td>
										<td>Date Discontinued</td>
									</tr>
									</thead>
									<tbody>
									<tr *ngFor="let med of item.discontinuedMedList">
										<td>{{med.medicationName}}</td>
										<td>{{item.date| date:'MM/dd/yyyy'}}</td>
									</tr>
									</tbody>
								</table>
							</div>
							<div class="report-medications__col">
								<table class="report-medications-table report-medications-table--three-cols">
									<thead>
									<tr>
										<td style="color: rgb(85, 0, 212)">Active Medications</td>
										<td>Reason</td>
										<td>Side Effects</td>
									</tr>
									</thead>
									<tbody>
									<tr *ngFor="let med of item.activeMedList">
										<td>{{med.medicationName}}</td>
										<td>{{med.reason}}</td>
										<td>{{med.sideEffects}}</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
<!--END COMMON -->

		</div>
<!--END ITEM -->
	</div>
<!--END report-mts-->
</div>
