<app-medical-treating-report-header [data]="headerInfo"></app-medical-treating-report-header>

<div class="report-wrapper" #element>

	<div class="report-medications">
		<div class="report-medications__item" *ngFor="let item of tableData">
			<div class="report-medications__name"
				 [class.is-doctor]="item.type === 'DOCTOR'"
				 [class.is-hospital]="item.type === 'HOSPITAL'"
				 [class.is-other]="item.type === 'OTHER'">
				{{item.name}}
			</div>
			<div class="report-medications__list">
				<div class="report-medications__col">
					<table class="report-medications-table report-medications-table--two-cols">
						<thead>
							<tr>
								<td>Discontinued Medications</td>
								<td>Date Discontinued</td>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let med of item.discontinuedMedList">
								<td>{{med.medicationName}}</td>
								<td>{{med.date| date:'MM/dd/yyyy'}}</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="report-medications__col">
					<table class="report-medications-table report-medications-table--three-cols">
						<thead>
						<tr>
							<td style="color: rgb(85, 0, 212)">Active Medications</td>
							<td>Reason</td>
							<td>Side Effects</td>
						</tr>
						</thead>
						<tbody>
							<tr *ngFor="let med of item.activeMedList">
								<td>{{med.medicationName}}</td>
								<td>{{med.reason}}</td>
								<td>{{med.sideEffects}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>

<!--	<table class="report-table">-->
<!--		<tr>-->
<!--			<td>VISIT DATE:</td>-->
<!--			<td>{{tableData.visitDate ? (tableData.visitDate | date:'MM/dd/yyyy') : '&#45;&#45;/&#45;&#45;/&#45;&#45;&#45;&#45;'}}</td>-->
<!--		</tr>-->
<!--		<tr>-->
<!--			<td>SOURCE NAME</td>-->
<!--			<td>{{tableData.source}}</td>-->
<!--		</tr>-->
<!--		<tr>-->
<!--			<td>MET WITH:</td>-->
<!--			<td> {{ tableData.metWithDoctor ? "DOCTOR" : "OTHER" }} {{tableData.metWithName ? (": " + tableData.metWithName) : '' }}</td>-->
<!--		</tr>-->
<!--		<tr>-->
<!--			<td>NEXT VISIT:</td>-->
<!--			<td>-->
<!--				{{tableData.nextVisit ? (tableData.nextVisit | date:'MM/dd/yyyy') : '&#45;&#45;/&#45;&#45;/&#45;&#45;&#45;&#45;' }}-->
<!--			</td>-->
<!--		</tr>-->
<!--		<tr>-->
<!--			<td>REASON FOR VISIT:</td>-->
<!--			<td class="checks-container">-->
<!--				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visitReason.regular">Regular Visit</mat-checkbox>-->
<!--				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visitReason.bloodWork">Blood Work</mat-checkbox>-->
<!--				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visitReason.medicationUpdate">Medication Update</mat-checkbox>-->
<!--				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visitReason.medicationChanges">Medication Changes</mat-checkbox>-->
<!--				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visitReason.surgeryConsult">Surgery Consult</mat-checkbox>-->

<!--				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visitReason.emergency">Emergency</mat-checkbox>-->
<!--				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visitReason.fUVisit">F/U Visit</mat-checkbox>-->
<!--				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visitReason.initialConsult">Initial Consult</mat-checkbox>-->
<!--				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visitReason.chemoOrRadiation">Chemo/Radiation</mat-checkbox>-->
<!--				<div  class="mat-check-visits" >-->
<!--					<mat-checkbox [disabled]="true" [checked]="tableData.visitReason.otherReason">Other</mat-checkbox>-->

<!--					<div style="padding-top: 5px">-->
<!--						{{tableData.visitReason.otherReasonDescr}}-->
<!--					</div>-->
<!--				</div>-->


<!--			</td>-->
<!--		</tr>-->
<!--		<tr>-->
<!--			<td>MEDICAL CONDITIONS TREATED:</td>-->
<!--			<td>-->
<!--				<div *ngFor="let i of tableData.medicalConditions">{{i}}</div>-->
<!--			</td>-->
<!--		</tr>-->

<!--		<tr>-->
<!--			<td>TREATMENTS RECEIVED:</td>-->
<!--			<td>-->
<!--				{{tableData.treatments}}-->
<!--			</td>-->
<!--		</tr>-->
<!--		<tr>-->
<!--			<td>ITEMS DISCUSSED WITH DOCTOR:</td>-->
<!--			<td>-->
<!--				{{tableData.discussion}}-->
<!--			</td>-->
<!--		</tr>-->
<!--		<tr>-->
<!--			<td>DOCTOR’S RESPONSE:</td>-->
<!--			<td>-->
<!--				{{tableData.docResponse}}-->
<!--			</td>-->
<!--		</tr>-->
<!--		<tr>-->
<!--			<td>CLAIM DISCUSSED WITH DOCTOR:-->
<!--			</td>-->
<!--			<td>-->
<!--				{{tableData.discusSSDI}}-->
<!--			</td>-->
<!--		</tr>-->

<!--	</table>-->
</div>
