import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {IrsTableService} from "./irs-table.service";
import {CdkTableModule} from "@angular/cdk/table";
import {CellId} from "./cellId";
import {IrsDynamicTableComponent} from "./irs-dynamic-table/irs-dynamic-table.component";
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';

import {NgxMaskModule} from 'ngx-mask';
import { IrisTableTabsComponent } from './iris-table-tabs/iris-table-tabs.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCheckboxModule, MatPaginatorModule, MatRadioModule} from "@angular/material";
import {NgDatepickerModule} from "../new-datepicker/module/ng-datepicker.module";
import { CanAccessModule } from "src/app/auth/permission-guard/can-access.module";
import { CustomIrisTableModule } from "../custom-iris-table/custom-iris-table.module";
import { SharedModule } from "src/app/shared/shared.module";
import { DiariesComponent } from "src/app/@view/pages/system-managment/operating-reports/overdue-tickle-report/diaries/diaries.component";

@NgModule({
	declarations: [
		DiariesComponent,
		IrsDynamicTableComponent,
		CellId,
		IrisTableTabsComponent
	],
	imports: [
		CommonModule,
		CdkTableModule,
		NgxMaskModule.forRoot(),
		MatFormFieldModule,
		MatSelectModule,
		FormsModule,
		ReactiveFormsModule,
		MatRadioModule,
		NgDatepickerModule,
        CanAccessModule,
		CustomIrisTableModule,
		MatPaginatorModule,
		MatCheckboxModule,
		SharedModule
	],
	exports: [
		IrsDynamicTableComponent,
		CellId
	],
	entryComponents: [
		IrsDynamicTableComponent
	],
	providers: [IrsTableService]
})
export class IrsTableModule {

}
