import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import Cropper from 'cropperjs';
import {DialogService} from "../../dialog/dialog.service";
import {EditorOptions, NGX_DEFAULT_RATIOS, NgxAspectRatio, RatioType} from "./editor-options";

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImageEditorComponent implements AfterViewInit, OnInit, OnDestroy {

  public state: EditorOptions;
  public cropper: any;
  public croppedImage: string;
  public imageWidth: number;
  public imageHeight: number;
  public canvasWidth: number;
  public canvasHeight: number;
  public cropBoxWidth: number;
  public cropBoxHeight: number;
  public canvasFillColor: string;
  public blob: Blob;
  public loading: boolean;
  public zoomIn: number;
  public sliderValue: number;
  public ratios: NgxAspectRatio[];
  public previewImageURL: any;

  @ViewChild('previewimg', { static: false })
  public previewImage: any;

  @ViewChild('croppedImg', { static: false })
  public croppedImg: any;

  @Input()
  public set config(config: EditorOptions) {
    this.state = config;
  }

  @Output()
  public file: EventEmitter<File> = new EventEmitter<File>();

  public constructor(private dialog: DialogService) {
    this.zoomIn = 0;
    this.sliderValue = 0;
    this.loading = true;
    this.canvasFillColor = '#fff';
    this.state = new EditorOptions();
  }

  public ngOnInit() {
    this.handleStateConfig();
  }

  public ngOnDestroy() {
    this.cropper.destroy();
  }

  public ngAfterViewInit(): void {

    // NOTE if we don't have a file meaning that loading the image will happen synchronously we can safely
    // call initializeCropper in ngAfterViewInit. otherwise if we are using the FileReader to load a base64 image
    // we need to call onloadend asynchronously..
    if (!this.state.File && this.state.ImageUrl) {
      this.initializeCropper();
    }
  }

  private handleStateConfig() {
    this.state.ImageType = this.state.ImageType ? this.state.ImageType : 'image/jpeg';

    if (this.state.ImageUrl) {
      this.state.File = null;
      this.previewImageURL = this.state.ImageUrl;
    }

    if (this.state.File) {
      this.state.ImageUrl = null;
      this.convertFileToBase64(this.state.File);
    }

    if (this.state.AspectRatios) {
      this.addRatios(this.state.AspectRatios);
    } else {
      this.ratios = NGX_DEFAULT_RATIOS;
    }


    if (!this.state.ImageUrl && !this.state.File) {
      console.error('Property ImageUrl or File is missing, Please provide an url or file in the config options.');
    }

    if (!this.state.ImageName) {
      console.error('Property ImageName is missing, Please provide a name for the image.');
    }
  }

  private convertFileToBase64(file: File) {
    const reader = new FileReader();
    reader.addEventListener('load', (e: any) => {
      this.previewImageURL = e.target['result'];
    }, false);
    reader.readAsDataURL(file);
    reader.onloadend = (() => {
      // NOTE since getting the base64 image url happens asynchronously we need to initializeCropper after
      // the image has been loaded.
      this.initializeCropper();
      setTimeout(() => {
        // ////console.log(this.cropper.getContainerData())
        const cropBoxLeft = (this.cropper.getContainerData().width - this.cropper.getCropBoxData().width) / 2;
        const cropBoxTop = (this.cropper.getContainerData().height - this.cropper.getCropBoxData().height) / 2;
        const canvasLeft = (this.cropper.getContainerData().width - this.cropper.getCanvasData().width) / 2;
        const canvasTop = (this.cropper.getContainerData().height - this.cropper.getCanvasData().height) / 2;

        this.cropper.setCropBoxData({
          left: cropBoxLeft,
          top: cropBoxTop,
          width: this.cropper.getCropBoxData().width,
          height: this.cropper.getCropBoxData().height
        });
        this.cropper.setCanvasData({
          left: canvasLeft,
          top: canvasTop,
          width: this.cropper.getCanvasData().width,
          height: this.cropper.getCanvasData().height
        });
        this.cropper.setCropBoxData({
          left: canvasLeft,
          top: canvasTop,
          width: this.cropper.getCanvasData().width,
          height: this.cropper.getCanvasData().height
        });
        // ////console.log(this.cropper.setCropBoxData());
        // ////console.log(this.cropper.setCanvasData());
        this.centerCanvas();
        this.handleCrop();
      });

    });
  }

  private addRatios(ratios: RatioType[]) {
    this.ratios = [];
    ratios.forEach((ratioType: RatioType) => {
      const addedRation = NGX_DEFAULT_RATIOS.find((ratio: NgxAspectRatio) => {
        return ratio.text === ratioType;
      });
      this.ratios.push(addedRation);
    });
  }

  public handleCrop() {

    this.loading = true;
    setTimeout(() => {
      this.croppedImage = this.cropper.getCroppedCanvas({fillColor: this.canvasFillColor})
        .toDataURL(this.state.ImageType);

      setTimeout(() => {
        this.imageWidth = this.croppedImg.nativeElement.width;
        this.imageHeight = this.croppedImg.nativeElement.height;
        if (this.imageWidth < this.state.minWidth) {
          this.imageWidth = this.state.minWidth;
        }

        if (this.imageHeight < this.state.minHeight) {
          this.imageWidth = this.state.minHeight;
        }

        // if (this.imageWidth < this.state.minWidth || this.imageHeight < this.state.minHeight) {
        //   this.dialog
        //     .show(`Minimum Width And Height 100x100, Now Image Size ${this.imageWidth}x ${this.imageHeight}`, ['OK']);
        //   this.undoCrop();
        // }

      });
      this.cropper.getCroppedCanvas({fillColor: this.canvasFillColor}).toBlob((blob: Blob) => {
        this.blob = blob;
      });
      this.zoomIn = 1;
      this.loading = false;
    });
  }

  public undoCrop() {
    // this.croppedImage = null;
    // this.blob = null;
    // setTimeout(() => {
    //   this.initializeCropper();
    // });
    this.file.next(null);
  }

  public saveImage() {
    this.file.emit(new File([this.blob], this.state.ImageName, {type: this.state.ImageType}));
  }

  private initializeCropper() {
    this.cropper = new Cropper(this.previewImage.nativeElement, {
      zoomOnWheel: false,
      zoomable: false,
      zoomOnTouch: false,
      viewMode: 1,

      center: true,
      scalable: false,
      minCropBoxHeight: 100,
      minCropBoxWidth: 100,
      ready: () => this.loading = false,
      dragMode: <any>'move',
      crop: (e: CustomEvent) => {
        // if (!(Math.round(e.detail.height) < this.state.minHeight || Math.round(e.detail.width) < this.state.minWidth)) {

        this.imageHeight = Math.round(e.detail.height);
        this.imageWidth = Math.round(e.detail.width);
        this.cropBoxWidth = Math.round(this.cropper.getCropBoxData().width);
        this.cropBoxHeight = Math.round(this.cropper.getCropBoxData().height);
        this.canvasWidth = Math.round(this.cropper.getCanvasData().width);
        this.canvasHeight = Math.round(this.cropper.getCanvasData().height);
        // }
      }
    });
    this.setRatio(this.ratios[0].value);

  }

  public setRatio(value: any) {
    this.cropper.setAspectRatio(value);
  }

  public zoomChange(input: any, zoom?: string) {
    if (this.croppedImage) {
      if (zoom) {
        zoom === 'zoomIn' ? this.zoomIn += 0.1 : this.zoomIn -= 0.1;
      } else {
        if (input < this.sliderValue) {
          this.zoomIn = -Math.abs(input / 100);
        } else {
          this.zoomIn = Math.abs(input / 100);
        }
      }
      if (this.zoomIn <= 0.1) {
        this.zoomIn = 0.1;
      }
    } else {
      if (zoom) {
        this.cropper.zoom(input);
        this.zoomIn = input;
      } else {
        if (input < this.sliderValue) {
          this.cropper.zoom(-Math.abs(input / 100));
        } else {
          this.cropper.zoom(Math.abs(input / 100));
        }
        if (input === 0) {
          this.cropper.zoom(-1);
        }
      }
    }

    if (!zoom) {
      this.sliderValue = input;
    } else {
      input > 0 ? this.sliderValue += Math.abs(input * 100) : this.sliderValue -= Math.abs(input * 100);
    }

    if (this.sliderValue < 0) {
      this.sliderValue = 0;
    }
  }

  public setImageWidth(canvasWidth: number) {
    if (canvasWidth) {
      this.cropper.setCanvasData({
        left: this.cropper.getCanvasData().left,
        top: this.cropper.getCanvasData().top,
        width: Math.round(canvasWidth),
        height: this.cropper.getCanvasData().height
      });
    }
  }

  public setImageHeight(canvasHeight: number) {
    if (canvasHeight) {
      this.cropper.setCanvasData({
        left: this.cropper.getCanvasData().left,
        top: this.cropper.getCanvasData().top,
        width: this.cropper.getCanvasData().width,
        height: Math.round(canvasHeight)
      });
    }
  }

  public setCropBoxWidth(cropBoxWidth: number) {
    if (cropBoxWidth && this.cropper.getCropBoxData().width >= cropBoxWidth) {
      this.cropper.setCropBoxData({
        left: this.cropper.getCropBoxData().left,
        top: this.cropper.getCropBoxData().top,
        width: Math.round(cropBoxWidth),
        height: this.cropper.getCropBoxData().height
      });
    } else {
      this.setCropBoxWidth(cropBoxWidth - 1);
    }
  }

  public setCropBoxHeight(cropBoxHeight: number) {
    if (cropBoxHeight && this.cropper.getCropBoxData().height >= cropBoxHeight) {
      this.cropper.setCropBoxData({
        left: this.cropper.getCropBoxData().left,
        top: this.cropper.getCropBoxData().top,
        width: this.cropper.getCropBoxData().width,
        height: Math.round(cropBoxHeight)
      });
    } else {
      this.setCropBoxHeight(cropBoxHeight - 1);
    }
  }

  public centerCanvas() {
    const cropBoxLeft = (this.cropper.getContainerData().width - this.cropper.getCropBoxData().width) / 2;
    const cropBoxTop = (this.cropper.getContainerData().height - this.cropper.getCropBoxData().height) / 2;
    const canvasLeft = (this.cropper.getContainerData().width - this.cropper.getCanvasData().width) / 2;
    const canvasTop = (this.cropper.getContainerData().height - this.cropper.getCanvasData().height) / 2;

    this.cropper.setCropBoxData({
      left: cropBoxLeft,
      top: cropBoxTop,
      width: this.cropper.getCropBoxData().width,
      height: this.cropper.getCropBoxData().height
    });
    this.cropper.setCanvasData({
      left: canvasLeft,
      top: canvasTop,
      width: this.cropper.getCanvasData().width,
      height: this.cropper.getCanvasData().height
    });
  }

}
