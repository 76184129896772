import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
	DynamicFormControlLayout,
	DynamicFormControlModel,
	DynamicFormLayout, DynamicFormService
} from "@ng-dynamic-forms/core";
import {DATE_PICKER} from "../../../../../../../@util/dynamic-form/dynamic-controlls";
import {FormValueBuilder} from "../../../../../partial/forms/form-tamplate";
import {FormGroup} from "@angular/forms";
import {MedicalService} from "../../../../../../@core/medical.service";
import {DynamicForm} from "../../../../../../../@util/dynamic-form/dynamic-form";
import {DynamicFormGroupModel, DynamicTextAreaModel} from "../../../../../../../@util/ui-material/form-controls";
import {GlobalSaveService} from "../../../../../../@core/global-save.service";
import {UserInfoService} from "../../../../../../@core/user-info.service";
import { PERMISSIONS } from 'src/app/@config/permissions';

@Component({
	selector: 'app-other-treatment',
	templateUrl: './other-treatment.component.html',
	styleUrls: ['./other-treatment.component.scss']
})
export class OtherTreatmentComponent extends DynamicForm implements OnInit {
	frameTitle = 'Visit Notes';

	@Input()
	sslgId: any;

	@Output()
	backForm: EventEmitter<any> = new EventEmitter();
	@Output()
	nextForm: EventEmitter<any> = new EventEmitter();


	formGroup: FormGroup;
	formModel: DynamicFormControlModel[];
	public PERMISSIONS = PERMISSIONS;

	formLayout: DynamicFormLayout = {
		other: <DynamicFormControlLayout>{
			element: {
				host: 'col-12',
				control: 'row'
			}
		},

		treatments: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},
		discussion: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},
		docResponse: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},

		hospitalizationReason: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},
		medicalConditions: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},
		treatmentDetails: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},
		treatedDoctor: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},

		visitReason3: <DynamicFormControlLayout>{
			element: {
				host: 'col-12',
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},
		treatmentDetails1: <DynamicFormControlLayout>{
			element: {
				host: 'col-12',
				label: 'form-label form-label_full-width',
				container: 'row-mb column-items',
			}
		},
		nextVisit1: <DynamicFormControlLayout>{
			element: {
				label: 'form-label',
				host: 'col-12 col-lg-4'
			}
		},

	};

	formOther = {
    treatmentReason: DynamicTextAreaModel({id: 'visitReason3', label: 'Reason for this Visit or these services', maxLength: 512,editableFor: PERMISSIONS.LOWER_EXEC,}),
		treatmentDetails: DynamicTextAreaModel({
			id: 'treatmentDetails1',
			label: 'Details of Treatment or services provided',
			maxLength: 512,editableFor: PERMISSIONS.LOWER_EXEC,
		}),
		nextVisit: DATE_PICKER({id: 'nextVisit1', label: 'Next Visit',editableFor: PERMISSIONS.LOWER_EXEC,}),
	};

	form = {
		other: DynamicFormGroupModel({
			id: 'other',
			group: FormValueBuilder.buildFormModel(this.formOther),editableFor: PERMISSIONS.LOWER_EXEC,
		}),
	};

	constructor(private medicalService: MedicalService,
							public globalSaveService: GlobalSaveService,
							private userInfoService: UserInfoService,
							private formService: DynamicFormService) {
		super();
		this.formModel = FormValueBuilder.buildFormModel(this.form);
		this.formGroup = formService.createFormGroup(this.formModel);
	}

	ngOnInit() {
		FormValueBuilder.resetToDefault(this.form);
		this.formGroup.valueChanges.subscribe(j => {
			this.formStateChanged.emit({key: this.formKey, value: FormValueBuilder.isDirty(this.form)});
		})
	}

	saveVisitNotes() {
		const rez = FormValueBuilder.getData(this.formOther);
		// await this.medicalService.saveStepTwo(this.sslgId, this._visitId, rez).toPromise();

		console.log(rez);
		return rez;
	}

	ngOnDestroy(): void {
		this.formStateChanged.next({value: false, key: this.formKey});
	}

	isClaimant() {
		if(this.userInfoService.getCurrentUserRole() == 'CLIENT'){
			return true;
		}

		return false;
	}

	nextHospitalsVisitNotes() {
		this.nextForm.emit(this.saveVisitNotes());
	}

	backHospitalsVisitNotes() {
		this.backForm.emit(this.saveVisitNotes());
	}

}
