import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FIELD_OFFICES_CONFIG} from "../@config/api";
import {SSAOffice} from "../@models/s-s-a-office";

@Injectable({
	providedIn: 'root'
})
export class FieldOfficeService {

	constructor(private http: HttpClient) {
	}
	getOffices() {
		return this.http.get<SSAOffice[]>(FIELD_OFFICES_CONFIG.getAll);
	}
	getOffice(officeId) {
		return this.http.get<SSAOffice>(FIELD_OFFICES_CONFIG.get(officeId));
	}

	saveOffice(office: any) {
		return this.http.post(FIELD_OFFICES_CONFIG.saveOffice, office)
	}

	getFieldData(claimantId){
		return this.http.post(FIELD_OFFICES_CONFIG.getFieldData(claimantId), {claimantId:claimantId})
	}
}
