<mat-form-field [appearance]="model.getAdditional('appearance', 'legacy')"
                [color]="model.getAdditional('color', 'primary')"
                [floatLabel]="model.getAdditional('floatLabel', LABEL_OPTIONS && LABEL_OPTIONS['float'] || 'auto')"
                [formGroup]="group"
                [hideRequiredMarker]="model.getAdditional('hideRequiredMarker', false)"
                [ngClass]="getClass('grid', 'control')">

    <mat-chip-list #matChipList
                   [formControlName]="model.id"
                   [id]="elementId"
                   [multiple]="true">

        <mat-chip *ngFor="let chip of chips; let index = index"
                  [selectable]="false"
                  (removed)="onChipRemoved(chip, index)">

            <span>{{ chip }}</span>

            <mat-icon matChipRemove>cancel</mat-icon>

        </mat-chip>

        <input matInput
               [placeholder]="model.placeholder"
               [matChipInputFor]="matChipList"
               [matChipInputAddOnBlur]="model.getAdditional('matChipInputAddOnBlur', true)"
               [matChipInputSeparatorKeyCodes]="model.getAdditional('matChipInputSeparatorKeyCodes', CHIPS_OPTIONS['separatorKeyCodes'])"
               (matChipInputTokenEnd)="onChipInputTokenEnd($event)"/>

    </mat-chip-list>

</mat-form-field>
