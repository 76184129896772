<ng-container [formGroup]="group">
    <input [formControlName]="model.id"
           [id]="elementId"
           [name]="model.name"
           (blur)="onBlur($event)"
           (change)="onChange($event)"
           style="border: none;
    width: 0;
    height: 0;"
           [matDatepicker]="picker"
           (focus)="onFocus($event)">


</ng-container>
<mat-checkbox *ngIf="checkGsStatus()" #matCheckbox (change)="changed($event)"
			  disabled="{{checkboxDisabled}}"
              [color]="model.getAdditional('color', 'accent')"
              [disableRipple]="model.getAdditional('disableRipple', RIPPLE_OPTIONS && RIPPLE_OPTIONS['disabled'] || false)"
              [labelPosition]="'after'"
              [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]">
    <span [ngClass]="getClass('element', 'label')" [innerHTML]="model.label"></span>
</mat-checkbox>

<mat-datepicker (closed)="onClosed($event)" #picker></mat-datepicker>


