import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SourceVariationComponent} from './source-variation/source-variation.component';
import {ExportModule} from "../../../../export/export.module";
import {PartialModule} from "../../../partial/partial.module";
import {DoctorFormComponent} from "./new-source/doctor-form/doctor-form.component";
import {HospitalFormComponent} from "./new-source/hospital-form/hospital-form.component";
import {OtheFormComponent} from "./new-source/othe-form/othe-form.component";
import { MedicalConditionsComponent } from './new-source/medical-conditions/medical-conditions.component';
import { VisitsFormComponent } from './visits-form/visits-form.component';
import { NewSourceStepperComponent } from './new-source-stepper/new-source-stepper.component';
import { DoctorVisitComponent } from './visits-form/doctor-visit/doctor-visit.component';
import { HospitalVisitComponent } from './visits-form/hospital-visit/hospital-visit.component';
import { OtherVisitComponent } from './visits-form/other-visit/other-visit.component';
import { VisitAdditionalComponent } from './visits-form/visit-adiditional/visit-additional.component';
import { VisitMedicationsComponent } from './visits-form/visit-adiditional/visit-medications/visit-medications.component';
import { VisitTestsComponent } from './visits-form/visit-adiditional/visit-tests/visit-tests.component';
import { VisitNotesComponent } from './visits-form/visit-adiditional/visit-notes/visit-notes.component';
import {VisitDialogComponent} from "./visit-dialog/visit-dialog.component";

@NgModule({
	declarations: [
		DoctorFormComponent,
		HospitalFormComponent,
		OtheFormComponent,
		SourceVariationComponent,
		MedicalConditionsComponent,
		VisitsFormComponent,
		NewSourceStepperComponent,
		DoctorVisitComponent,
		HospitalVisitComponent,
		OtherVisitComponent,
		VisitAdditionalComponent,
		VisitMedicationsComponent,
		VisitTestsComponent,
		VisitNotesComponent,
		VisitDialogComponent
	],
	imports: [
		CommonModule,
		ExportModule,
		PartialModule,
	],
	exports: [
		NewSourceStepperComponent,
		MedicalConditionsComponent,
		SourceVariationComponent
	],
	entryComponents: [SourceVariationComponent, MedicalConditionsComponent, VisitDialogComponent]
})
export class MedicalSourceModule {
}
