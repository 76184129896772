/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./iris-table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./iris-table.component";
var styles_IrisTableComponent = [i0.styles];
var RenderType_IrisTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IrisTableComponent, data: {} });
export { RenderType_IrisTableComponent as RenderType_IrisTableComponent };
export function View_IrisTableComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], [[8, "className", 0]], [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.scroll($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n\n\t"])), i1.ɵncd(null, 1), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getClassList(); _ck(_v, 0, 0, currVal_0); }); }
export function View_IrisTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-iris-table", [], null, null, null, View_IrisTableComponent_0, RenderType_IrisTableComponent)), i1.ɵdid(1, 114688, null, 0, i2.IrisTableComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IrisTableComponentNgFactory = i1.ɵccf("app-iris-table", i2.IrisTableComponent, View_IrisTableComponent_Host_0, { inOneLine: "inOneLine", scrollX: "scrollX" }, { tableScrolled: "tableScrolled" }, [".i-thead", ".i-tbody"]);
export { IrisTableComponentNgFactory as IrisTableComponentNgFactory };
