import {Component,Input, OnInit, ViewChild} from '@angular/core';
import {ReportItem} from "../report-item";
import {Items} from "../../../@models/pdf-report/items";

@Component({
	selector: 'app-pdf-view-blocks-hospitals',
	templateUrl: './pdf-view-blocks-hospitals.component.html',
	styleUrls: ['./pdf-view-blocks-hospitals.component.scss']
})
export class PdfViewBlocksHospitalsComponent implements OnInit, ReportItem {

	@Input()
	tableData: Items;

	constructor() {
	}
	@ViewChild('element', { static: true })
	public element;

	ngOnInit() {
		console.log(this.tableData);
	}
}
