<div class="form-actions form-actions--mb form-actions--no-mt form-actions--left" *ngIf="pageType">
	<button class="form-action form-action_dark" type="button" (click)="back()">Back</button>
</div>
<form [formGroup]="requestsForm">
	<div formArrayName="requests" *ngFor="let data of requestsFields.controls; let i = index;">
		<div [formGroupName]="i">
<!--			<h1 class="form-label form-label_full-width form-label_mb">{{i+1}}st Request</h1>-->
			<div class="form-row">
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label>{{pageType}}</mat-label>
									<div class="mat-form-field-infix mat-form-field-infix--no-label">
										<mat-form-field>
											<input [value]="getContactName(contacts)"
												   readonly
												   matInput>
										</mat-form-field>
									</div>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label>Fax Number</mat-label>
									<div class="mat-form-field-infix mat-form-field-infix--no-label">
										<mat-form-field>
											<input [value]="getContactFax(contacts)"
												   readonly
												   matInput>
										</mat-form-field>
									</div>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
			</div>
			<h1 class="form-label form-label_full-width form-label_mb">Who to Call For Medical Records</h1>
			<div class="form-row form-row--center">
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<mat-form-field>
								<mat-label>Name</mat-label>
								<div class="mat-form-field-infix mat-form-field-infix--no-label">
									<mat-form-field>
										<input formControlName="name"
											   [value]=""
											   [readonly]="isViewing()"
											   matInput>
									</mat-form-field>
								</div>
							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<mat-form-field>
								<mat-label>Phone</mat-label>
								<div class="mat-form-field-infix mat-form-field-infix--no-label">
									<mat-form-field>
										<input formControlName="phone"
											   [value]=""
											   mask='(000) 000-0000'
											   [readonly]="isViewing()"
											   matInput>
									</mat-form-field>
								</div>
							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<mat-form-field>
								<mat-label>Press</mat-label>
								<div class="mat-form-field-infix mat-form-field-infix--no-label">
									<mat-form-field>
										<input formControlName="press"
											   [value]=""
											   [readonly]="isViewing()"
											   matInput>
									</mat-form-field>
								</div>
							</mat-form-field>
						</div>
					</div>
				</div>
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<mat-form-field>
								<mat-label>Ext</mat-label>
								<div class="mat-form-field-infix mat-form-field-infix--no-label">
									<mat-form-field>
										<input formControlName="ext"
											   [value]=""
											   [readonly]="isViewing()"
											   matInput>
									</mat-form-field>
								</div>
							</mat-form-field>
						</div>
					</div>
				</div>
			</div>
			<div class="form-row form-row--md">
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label style="color: red;">SSA/ERE File</mat-label>
									<div class="ngx-datepicker-container mat-calendar">
										<div class="ngx-datepicker-input-container" #ngxInputContainer>
											<div class="input-container">
												<input formControlName="ssaEreFile"
													   matInput
													   [matDatepicker]="picker11"
													   [value]=""
													   (dateChange)="onChange($event)"
													   readonly>
											</div>

											<span (click)="clearDate('request','ssa_ere_file',i)"
												  class="icon-clear material-icon material-icons">highlight_off</span>
										</div>
									</div>
									<mat-datepicker-toggle matSuffix [for]="picker11"></mat-datepicker-toggle>
									<mat-datepicker [disabled]="isViewing()" #picker11></mat-datepicker>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form-row form-row--long">
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label>From</mat-label>
									<div class="ngx-datepicker-container mat-calendar">
										<div class="ngx-datepicker-input-container" #ngxInputContainer>
											<div class="input-container">
												<input formControlName="from"
													   matInput
													   [matDatepicker]="picker1"
													   [value]=""
													   (dateChange)="onChange($event)"
													   readonly>
											</div>
											<span (click)="clearDate('request','from',i)"
												  class="icon-clear material-icon material-icons">highlight_off</span>
										</div>
									</div>
									<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
									<mat-datepicker [disabled]="isViewing()" #picker1></mat-datepicker>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label>To</mat-label>
									<div class="ngx-datepicker-container mat-calendar">
										<div class="ngx-datepicker-input-container" #ngxInputContainer>
											<div class="input-container">
												<input formControlName="to"
													   matInput
													   [matDatepicker]="picker2"
													   [value]=""
													   (dateChange)="onChange($event)"
													   readonly>
											</div>
											<span (click)="clearDate('request','to',i)"
												  class="icon-clear material-icon material-icons">highlight_off</span>
										</div>
									</div>
									<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
									<mat-datepicker [disabled]="isViewing()" #picker2></mat-datepicker>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label>Date Requested</mat-label>
									<div class="ngx-datepicker-container mat-calendar">
										<div class="ngx-datepicker-input-container" #ngxInputContainer>
											<div class="input-container">
												<input formControlName="dateRequested"
													   matInput
													   [matDatepicker]="picker3"
													   [value]=""
													   (dateChange)="onChange($event)"
													   readonly>
											</div>
											<span (click)="clearDate('request','dateRequested',i)"
												  class="icon-clear material-icon material-icons">highlight_off</span>
										</div>
									</div>
									<mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
									<mat-datepicker [disabled]="isViewing()" #picker3></mat-datepicker>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field appearance="fill">
									<mat-label>SSA Level</mat-label>
									<mat-select [disabled]="isViewing()" formControlName="ssaLavel">
										<mat-option *ngFor="let level of ssaLevels"
													[value]="level.id">
											{{level.name}}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label>1st F/U</mat-label>
									<div class="ngx-datepicker-container mat-calendar">
										<div class="ngx-datepicker-input-container" #ngxInputContainer>
											<div class="input-container">
												<input formControlName="fu"
													   matInput
													   [matDatepicker]="picker4"
													   [value]=""
													   (dateChange)="onChange($event)"
													   readonly>
											</div>
											<span (click)="clearDate('request','fu',i)"
												  class="icon-clear material-icon material-icons">highlight_off</span>
										</div>
									</div>
									<mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
									<mat-datepicker [disabled]="isViewing()" #picker4></mat-datepicker>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
			</div>
			<h1 class="form-label form-label_full-width form-label_mb">Q's and RFC's Requested</h1>
			<div class="form-row form-row--center">
<!--				<mat-label>Q's and RFC's Requested</mat-label>-->
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix mat-form-field-infix--no-label">
								<mat-form-field>
									<input formControlName="qsRfc1"
										   [readonly]="isViewing()"
										   matInput>
								</mat-form-field>
							</div>
						</div>
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix mat-form-field-infix--no-label">
								<mat-form-field>
									<input formControlName="qsRfc2"
										   [readonly]="isViewing()"
										   matInput>
								</mat-form-field>
							</div>
						</div>
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix mat-form-field-infix--no-label">
								<mat-form-field>
									<input formControlName="qsRfc3"
										   [readonly]="isViewing()"
										   matInput>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div class="form-element form-element--no-grow  form-element--col-md-2">
					<div class="mat-form-field-wrapper mat-form-field-wrapper--col">
						<mat-form-field>
							<mat-label>2nd F/U</mat-label>
							<div class="ngx-datepicker-container mat-calendar">
								<div class="ngx-datepicker-input-container" #ngxInputContainer>
									<div class="input-container">
										<input formControlName="fu2"
											   matInput
											   [matDatepicker]="picker5"
											   [value]=""
											   (dateChange)="onChange($event)"
											   readonly>
									</div>
									<span (click)="clearDate('request','fu2',i)"
										  class="icon-clear material-icon material-icons">highlight_off</span>
								</div>
							</div>
							<mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
							<mat-datepicker [disabled]="isViewing()" #picker5></mat-datepicker>
						</mat-form-field>

						<mat-form-field>
							<mat-label>Next F/U</mat-label>
							<div class="ngx-datepicker-container mat-calendar">
								<div class="ngx-datepicker-input-container" #ngxInputContainer>
									<div class="input-container">
										<input formControlName="nextFu"
											   matInput
											   [matDatepicker]="picker6"
											   [value]=""
											   (dateChange)="onChange($event)"
											   readonly>
									</div>
									<span (click)="clearDate('request','nextFu',i)"
										  class="icon-clear material-icon material-icons">highlight_off</span>
								</div>
							</div>
							<mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
							<mat-datepicker [disabled]="isViewing()" #picker6></mat-datepicker>
						</mat-form-field>
					</div>
				</div>
				<div class="form-element form-element--no-grow form-element--col-md-2 form-element--col-md-2_right">
					<div class="mat-form-field-wrapper mat-form-field-wrapper--col">
						<mat-form-field>
							<mat-label>3rd F/U</mat-label>
							<div class="ngx-datepicker-container mat-calendar">
								<div class="ngx-datepicker-input-container" #ngxInputContainer>
									<div class="input-container">
										<input formControlName="fu3"
											   matInput
											   [matDatepicker]="picker7"
											   [value]=""
											   (dateChange)="onChange($event)"
											   readonly>
									</div>
									<span (click)="clearDate('request','fu3',i)"
										  class="icon-clear material-icon material-icons">highlight_off</span>
								</div>
							</div>
							<mat-datepicker-toggle matSuffix [for]="picker7"></mat-datepicker-toggle>
							<mat-datepicker [disabled]="isViewing()" #picker7></mat-datepicker>
						</mat-form-field>

						<mat-form-field>
							<mat-label>Date Rcvd</mat-label>
							<div class="ngx-datepicker-container mat-calendar">
								<div class="ngx-datepicker-input-container" #ngxInputContainer>
									<div class="input-container">
										<input formControlName="nextRcvd"
											   matInput
											   [matDatepicker]="picker8"
											   [value]=""
											   (dateChange)="onChange($event)"
											   readonly>
									</div>
									<span (click)="clearDate('request','nextRcvd',i)"
										  class="icon-clear material-icon material-icons">highlight_off</span>
								</div>
							</div>
							<mat-datepicker-toggle matSuffix [for]="picker8"></mat-datepicker-toggle>
							<mat-datepicker [disabled]="isViewing()" #picker8></mat-datepicker>
						</mat-form-field>
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-element form-element--col-md-2 form-element--long-label">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label>Submitted SSA to</mat-label>
									<div class="ngx-datepicker-container mat-calendar">
										<div class="ngx-datepicker-input-container" #ngxInputContainer>
											<div class="input-container">
												<input formControlName="submittedSsa"
													   matInput
													   [matDatepicker]="picker9"
													   [value]=""
													   (dateChange)="onChange($event)"
													   readonly>
											</div>
											<span (click)="clearDate('request','submittedSsa',i)"
												  class="icon-clear material-icon material-icons">highlight_off</span>
										</div>
									</div>
									<mat-datepicker-toggle matSuffix [for]="picker9"></mat-datepicker-toggle>
									<mat-datepicker [disabled]="isViewing()" #picker9></mat-datepicker>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div class="form-element form-element--long-label form-element--col-md-2 form-element--col-md-2_right">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label>Date Confirmed by SSA</mat-label>
									<div class="ngx-datepicker-container mat-calendar">
										<div class="ngx-datepicker-input-container" #ngxInputContainer>
											<div class="input-container">
												<input formControlName="confirmedSsa"
													   matInput
													   [matDatepicker]="picker10"
													   [value]=""
													   (dateChange)="onChange($event)"
													   readonly>
											</div>
											<span (click)="clearDate('request','confirmedSsa',i)"
												  class="icon-clear material-icon material-icons">highlight_off</span>
										</div>
									</div>
									<mat-datepicker-toggle matSuffix [for]="picker10"></mat-datepicker-toggle>
									<mat-datepicker [disabled]="isViewing()" #picker10></mat-datepicker>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
			</div>

<!--			<div *ngIf="requestsShowDeleteBtn()" class="form-actions">-->
<!--				<button class="form-action form-action_warn" *ngIf="requestsShowDeleteBtn()" type="button" (click)="deleteRequest(i)">Delete Request</button>-->
<!--			</div>-->
		</div>
	</div>

<!--	<div class="form-actions">-->
<!--		<button class="form-action" type="button" (click)="addRequest()">Add Request</button>-->
<!--	</div>-->

	<div class="form-actions" *ngIf="!isViewing()">
		<button class="form-action" type="button" (click)="save()">{{getBtnName()}}</button>
	</div>
</form>
