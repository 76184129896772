import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})
export class AddressService {

	constructor(private http: HttpClient) {
	}

	findAddress(zipCode: String) {
		return this.http.get<{ city: string, state: string, zip: string, location: string }>("api/address/find/" + zipCode);
	}

	findPlace(query, type) {
		var param = new HttpParams().append("query", query);
		return this.http.get<any[]>("api/address/find/place/"+type, {params: param});
	}

	getPlaceInfo(placeId) {
		// var param = new HttpParams().append("placeId", placeId);
		return this.http.get<{ city: string, state: string, zip: string, locations: string, streetNumber: string, phone: string }>("api/address/place-info/" + placeId);
	}
}
