import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
	selector: 'form-button',
	templateUrl: './form-button.component.html',
	styleUrls: ['./form-button.component.scss']
})
export class FormButtonComponent implements OnInit, OnChanges {

	constructor() {
	}

	@Output() clickOnButton = new EventEmitter<any>();

	@Input() orientation: 'horizontal' | 'vertical';

	button: {
		buttonClass: string;
		showIcon: boolean;
		iconId: string;
	};
	@Input() buttonType: 'save' | 'edit' | 'warning' | 'disabled' | 'add' | 'finish' | 'green' | 'delete' | '';
	@Input() disabled: boolean = false;

	ngOnInit() {
		this.button = {
			buttonClass: '',
			iconId: '',
			showIcon: this.buttonType === 'save' || this.buttonType === 'edit' || this.buttonType === 'finish' || this.buttonType === 'delete'
		};

		switch (this.buttonType) {
			case 'save':
				this.button.iconId = 'save';
				this.button.buttonClass = '';
				break;
			case 'edit':
				this.button.buttonClass = 'form-action_accent form-action_vertical';
				this.button.iconId = 'edit';
				break;
			case 'warning':
				this.button.buttonClass = 'form-action_warn';
				break;
			case 'disabled':
				this.button.buttonClass = 'form-action_disabled';
				break;
			case 'add':
				this.button.buttonClass = 'form-action_add';
				break;
			case 'finish':
				this.button.iconId = 'finish';
				this.button.buttonClass = 'form-action_finish';
				break;
			case 'delete':
				this.button.iconId = 'delete';
				this.button.buttonClass = 'form-action_finish';
				break;
			case 'green':
				this.button.buttonClass = 'form-action_green';
				break;
			default:
				this.button.buttonClass = 'default';
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.ngOnInit();
	}

}
