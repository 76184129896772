import {Injectable} from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from "../_services/authentication.service";
import {Subscription} from "rxjs";
@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
	  //console.log('AuthGuard');
    let storage = sessionStorage;
    if (storage.getItem('currentUser')) {
      const currentUser = JSON.parse(storage.getItem('currentUser'));
      // console.log(currentUser);
      return true;
    }

    // not logged in so redirect to login page with the return url

    this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});

    return false;
  }
}
