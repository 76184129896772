import {Injectable,ViewChild} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class DateService {
	constructor(

		) {
	}



	convertDateWithTimezone(date){
		var res = new Date(date);

		//console.log(today.toISOString());

		return res.toISOString();
	}
}

