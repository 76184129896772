import { HttpClient, HttpParams } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AddressService {
    constructor(http) {
        this.http = http;
    }
    findAddress(zipCode) {
        return this.http.get("api/address/find/" + zipCode);
    }
    findPlace(query, type) {
        var param = new HttpParams().append("query", query);
        return this.http.get("api/address/find/place/" + type, { params: param });
    }
    getPlaceInfo(placeId) {
        // var param = new HttpParams().append("placeId", placeId);
        return this.http.get("api/address/place-info/" + placeId);
    }
}
AddressService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AddressService_Factory() { return new AddressService(i0.ɵɵinject(i1.HttpClient)); }, token: AddressService, providedIn: "root" });
