import { HttpClient } from "@angular/common/http";
import { VERSION_CONFIG } from "../@config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class VersionService {
    constructor(http) {
        this.http = http;
    }
    getVersions() {
        return this.http.get(VERSION_CONFIG.getVersions);
    }
    getLastVersion() {
        return this.http.get(VERSION_CONFIG.getLastVersion);
    }
    getVersionById(versionId) {
        return this.http.get(VERSION_CONFIG.getVersionById(versionId));
    }
    saveEditedVersion(versionId, data) {
        return this.http.post(VERSION_CONFIG.saveVersionById(versionId), data);
    }
    createVersion(data) {
        return this.http.post(VERSION_CONFIG.createVersion, data);
    }
}
VersionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VersionService_Factory() { return new VersionService(i0.ɵɵinject(i1.HttpClient)); }, token: VersionService, providedIn: "root" });
