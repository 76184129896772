<app-frame [overlay]="false" [title]="title" (neadClose)="closeVisitForm()" autocomplete="off">
	<div>
		You are about to discard all your changes. Please confirm before proceeding
	</div>
	<app-form-actions-content>
		<form-button (clickOnButton)="confirm()">Confirm</form-button>
		<form-button (clickOnButton)="closeVisitForm()" buttonType="warning">Cancel</form-button>

	</app-form-actions-content>
</app-frame>
