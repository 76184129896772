import {Functions} from "../../../@util/functions";

export class Social {
  twitter;
  facebook;
  instagram;

  static checkLinck(val: Social) {
    Object.getOwnPropertyNames(val).forEach(j => {
      if (val[j] && val[j].indexOf('http') === -1)
        val[j] = Functions.checkLinck(val[j]);
    });
  }
}
