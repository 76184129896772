import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Social } from '../@models/system-managment/social';
import * as conf from '../@config/api';
import { Functions } from "../../@util/functions";
import { FileUploadingService } from "../../@util/file-uploading-progress/file-uploading.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../@util/file-uploading-progress/file-uploading.service";
export class ClientsService {
    constructor(http, fileUploadingService) {
        this.http = http;
        this.fileUploadingService = fileUploadingService;
        this.config = conf.CLIENT_USER_CONFIG;
        this.clientInfoUpdated = new EventEmitter();
    }
    forgotPassword(username) {
        return this.http.post(this.config.forgotPassword, { username: username });
    }
    addUser(data) {
        return this.http.post(this.config.add, data);
    }
    getUsersList() {
        return this.http.get(this.config.getAll);
    }
    findBasicData(userId) {
        return this.http.get(this.config.findBasicData(userId));
    }
    findSocialData(userId) {
        return this.http.get(this.config.findSocialData(userId));
    }
    saveClientRemarks(sslgId, data) {
        return this.http.post(this.config.setClientRemarks(sslgId), data);
    }
    getClientRemarks(sslgId) {
        return this.http.get(this.config.getClientRemarks(sslgId));
    }
    saveClientCompBenefits(sslgId, data) {
        return this.http.post(this.config.setClientCompBenefits(sslgId), data);
    }
    getClientCompBenefits(sslgId) {
        return this.http.get(this.config.getClientCompBenefits(sslgId));
    }
    getCurrentUser(userId) {
        return this.http.get(this.config.getById(userId));
    }
    updatePasswordAndActive(value) {
        return this.http.post(this.config.updateLoginInfo(value.username), value);
    }
    setSocial(userName, value) {
        Social.checkLinck(value);
        return this.http.post(this.config.setSocial(userName), value);
    }
    setClientBasic(username, value) {
        return this.http.post(this.config.setClientBasic(username), value);
    }
    setClientContact(username, value) {
        value.cell = Functions.checkPhone(value.cell);
        value.fax = Functions.checkPhone(value.fax);
        value.phone = Functions.checkPhone(value.phone);
        return this.http.post(this.config.setClientContact(username), value);
    }
    setClientAddress(username, value) {
        return this.http.post(this.config.setClientAddress(username), value);
    }
    uploadAvatar(username, avatar) {
        return this.fileUploadingService.uploadFile('/api/user/avatar/' + username, { file: avatar, fileInfo: null });
    }
    getAvatarName(username) {
        return this.http.get('/api/user/avatar/' + username + '/name');
    }
    deleteUserAvatar(username) {
        return this.http.post('/api/user/avatar/' + username + '/remove', {});
    }
    delete(sslgId) {
        return this.http.post('/api/users/delete', { sslgId: sslgId });
    }
}
ClientsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClientsService_Factory() { return new ClientsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FileUploadingService)); }, token: ClientsService, providedIn: "root" });
