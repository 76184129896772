import { HttpClient } from "@angular/common/http";
import { POST_AWARD_CONFIG } from "../@config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CoraAuthorizationService {
    constructor(http) {
        this.http = http;
        this.config = POST_AWARD_CONFIG.auth;
    }
    getAuth(sslgId) {
        return this.http.get(this.config.auth(sslgId));
    }
    getSent(sslgId, url) {
        return this.http.post(this.config.sent(sslgId), { url: url });
    }
    getReceive(sslgId) {
        return this.http.post(this.config.receive(sslgId), {});
    }
    clearAuth(sslgId) {
        return this.http.post(this.config.clear(sslgId), {});
    }
    setCoraAuthText(sslgId, text) {
        return this.http.post(this.config.setText(sslgId), text);
    }
}
CoraAuthorizationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CoraAuthorizationService_Factory() { return new CoraAuthorizationService(i0.ɵɵinject(i1.HttpClient)); }, token: CoraAuthorizationService, providedIn: "root" });
