import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {VERSION_CONFIG} from "../@config/api";
import {Versions} from "../@models/versions/versions";

@Injectable({
	providedIn: 'root'
})
export class VersionService {

	constructor(private http: HttpClient) {
	}

	getVersions() {
		return this.http.get<Versions[]>(VERSION_CONFIG.getVersions)
	}


	getLastVersion() {
		return this.http.get<Versions>(VERSION_CONFIG.getLastVersion)
	}

	getVersionById(versionId) {
		return this.http.get<Versions>(VERSION_CONFIG.getVersionById(versionId))
	}

	saveEditedVersion(versionId: any, data: Versions) {
		return this.http.post<Versions>(VERSION_CONFIG.saveVersionById(versionId), data)
	}
	createVersion(data) {
		return this.http.post<Versions>(VERSION_CONFIG.createVersion, data)
	}
}
