import {
	Component, EventEmitter,
	forwardRef, Input,
	OnInit, Output
} from '@angular/core';
import {QuillEditorComponent} from 'ngx-quill';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel} from '@angular/forms';
import {Functions} from "../../../../@util/functions";


@Component({
	selector: 'app-note',
	templateUrl: './note.component.html',
	styleUrls: ['./note.component.scss'],
	providers: [{
		multi: true,
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => NoteComponent)
	}]
})
export class NoteComponent implements ControlValueAccessor, OnInit {
	onChange: Function;

	onTouched: Function;
	private _value: number;
	disabled: boolean;
	oldNoteValue;
	isValueChanged: boolean = false;
	@Output()
	changeEditorEvent: EventEmitter<any> = new EventEmitter();
	@Input()
	set _isValueChanged(val) {
		console.log(val);
		if (this.isValueChanged != val) {
			this.isValueChanged = val;
			this.oldNoteValue = this.value;
		}
	}

	get value() {
		return this._value;
	}

	set value(value: number) {
		this._value = value;
		if (this.onChange) {
			this.onChange(value);
		}
	}


	getHeight(editor: QuillEditorComponent) {
		if (editor.editorElem) {
			// editor.editorElem.style.height = '100%'
			editor.editorElem.style.height = editor.editorElem.clientHeight + 'px';
		}
	}

	writeValue(obj: any): void {
		this.value = obj;
	}

	// сохраняем обратный вызов для изменений
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	// сохраняем обратный вызов для "касаний"
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	ngOnInit(): void {
		setTimeout(()=>{
			this.oldNoteValue = this.value;
			console.log(this.oldNoteValue);
		});
	}

	onStateChanges(): void {
		let newVal = this.value == null ? '' : this.value;
		console.log(this.value);
		if (this.oldNoteValue != null) {
			if (this.oldNoteValue == newVal) {
				this.isValueChanged = false;
				this.changeEditorEvent.emit(false);
			} else {
				this.isValueChanged = true;
				this.changeEditorEvent.emit(true);
			}
		}
	}
}
