<app-frame [overlay]="false" [title]="title" (neadClose)="closeVisitForm()" autocomplete="off">
	<form class="form-horizontal conditions-dialog" [formGroup]="formGroup" novalidate>
		<dynamic-material-form [group]="formGroup"
							   [model]="formModel">
			<ng-template modelId="medicalCondition" let-context="context" let-index="index" align="END">
				<app-form-actions-content class="custom-btn col-1 auto-f-basis">

					<button *ngIf="showRemoveBtn(context, index)" (click)="remove(context, index)" class="close-btn">
						<span></span>
						<span></span>
					</button>
				</app-form-actions-content>
				<app-form-actions-content class=" col-12">
					<form-button *ngIf="showAddBtn(context, index)" (clickOnButton)="insertMedicalCondition()">Add
					</form-button>
				</app-form-actions-content>
			</ng-template>
		</dynamic-material-form>
	</form>
	<app-form-actions-content>
		<form-button (clickOnButton)="saveMedicalConditions()" buttonType="save">Save</form-button>
	</app-form-actions-content>
</app-frame>
