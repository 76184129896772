<div [class]="getClassList()" (scroll)="scroll($event)">
	<!--	class="i-thead"-->
	<ng-content select=".i-thead">

	</ng-content>

	<ng-content select=".i-tbody">

	</ng-content>

</div>


