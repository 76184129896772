import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { UserIdleService } from '../user-idle/user-idle.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthConfig } from './auth-config';
import { Subscription } from 'rxjs';
import { UserIdleConfig } from '../user-idle/user-idle-config';
import { AppStorage } from "../../storage";
import { GlobalSaveService } from "../../@core/global-save.service";
import { UserTempService } from "../../@core/userTemp.service";
import * as i0 from "@angular/core";
import * as i1 from "./auth-config";
import * as i2 from "@angular/common/http";
import * as i3 from "../user-idle/user-idle.service";
import * as i4 from "@angular/router";
import * as i5 from "../../@core/userTemp.service";
import * as i6 from "../../@core/global-save.service";
export class AuthenticationService {
    constructor(authConfig, http, userIdle, router, route, userTempService, globalSaveService) {
        this.authConfig = authConfig;
        this.http = http;
        this.userIdle = userIdle;
        this.router = router;
        this.route = route;
        this.userTempService = userTempService;
        this.globalSaveService = globalSaveService;
        this.storage = sessionStorage;
        this.rolesObservable = new EventEmitter();
    }
    // get userName() {
    //   return AppStorage.getStorage().getItem('user');
    // }
    sendUserName(username) {
        return this.http.post(this.authConfig.authUserNameUrl, { username: username });
    }
    login(username, password, pin, ignore) {
        return this.http.post(this.authConfig.authUrl, { username: username, password: password, pin: pin, ignore: ignore })
            .pipe(map((res) => {
            // login successful if there's a jwt token in the response
            if (res && res.token) {
                this.onStartWatching();
                this.storage = sessionStorage;
                // store username and jwt token in local storage to keep user logged in between page refreshes
                this.storage.setItem('currentUser', JSON.stringify({ token: res.token }));
                this.storage.setItem('currentUserName', username);
                this.storage.setItem('currentUserRole', res.role);
                this.userTempService.setUserTempState(res);
                //this.storage.setItem('currentUserName', username);
                this.storage.setItem('audioNotifiication', "0");
                this.roles = [res.role];
                this.rolesObservable.next(this.roles);
                this.globalSaveService.setLastClaimantData(res.globalSave, res.globalSaveLastClaimant, res.globalSaveLastClaimentFullName);
                if (UserIdleConfig.REMEMBER_ME) {
                    AppStorage.getStorage().setItem('user', username);
                }
                else {
                    AppStorage.getStorage().removeItem('user');
                }
            }
        }));
    }
    checkUser(email, hash) {
        return this.http.post(this.authConfig.checkUser, { email: email, hash: hash });
    }
    restore(email, hash, password) {
        return this.http.post(this.authConfig.restoreUrl, { email: email, hash: hash, password: password });
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.router.url.indexOf(this.authConfig.loginRoute) === -1) {
                yield this.router.navigate([this.authConfig.loginRoute]);
                location.reload();
                return;
            }
            this.storage.removeItem('currentUser');
            if (this.timerStartSubscription && !this.timerStartSubscription.closed) {
                this.onStopWatching();
                // window.location.reload();
            }
        });
    }
    refreshToken() {
        if (!this.storage.getItem('currentUser')) {
            return;
        }
        return this.http.post(this.authConfig.refreshTokenUrl, {}).subscribe(res => {
            this.storage.removeItem('currentUser');
            if (res && res.token) {
                this.storage.setItem('currentUser', JSON.stringify({ token: res.token }));
            }
        }, error => {
            this.storage.removeItem('currentUser');
        });
    }
    checkSession() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.storage = sessionStorage;
            if (this.storage.getItem('currentUser')) {
                try {
                    this.onStopWatching();
                }
                catch (e) {
                    ////console.log(e);
                }
                this.refreshToken();
                this.onStartWatching();
            }
            else {
                let pathname = location.pathname;
                let dir = pathname.split('/');
                if (this.authConfig.restoreRoute !== '/' + dir[1]) {
                    this.router.navigate([this.authConfig.loginRoute], {
                        queryParams: this.authConfig.loginRoute !== location.pathname &&
                            this.authConfig.restoreRoute !== location.pathname ?
                            {
                                returnUrl: location.pathname
                            } : null
                    });
                }
            }
        });
    }
    onStartWatching() {
        if (this.userIdle.getConfigValue().idle === 0) {
            return;
        }
        // Start watching for user inactivity.
        this.userIdle.startWatching();
        // Start watching when user idle is starting.
        this.timerStartSubscription = this.userIdle.onTimerStart()
            .pipe(tap(() => this.isTimer = true))
            .subscribe(count => {
            if (count == null && !this.timeIsUp) {
                this.isTimer = false;
            }
            else if (count == null) {
                this.isTimer = false;
            }
        });
        // Start watch when time is followup.
        this.timeoutSubscription = this.userIdle.onTimeout()
            .subscribe(j => {
            this.timeIsUp = true;
            this.logout();
            ////console.log('time is followup.... LOGOUT', j);
        });
        this.pingSubscription = this.userIdle.ping$
            .subscribe(value => {
            if (!this.isTimer && !this.timeIsUp) {
                ////console.log('ping');
                this.refreshToken();
            }
        });
    }
    onStopWatching() {
        if (this.userIdle.getConfigValue().idle === 0) {
            return;
        }
        this.userIdle.resetTimer();
        this.userIdle.stopWatching();
        this.isTimer = false;
        this.timeIsUp = false;
        this.lastPing = null;
        if (!this.timerStartSubscription) {
            return;
        }
        this.timerStartSubscription.unsubscribe();
        this.timeoutSubscription.unsubscribe();
        this.pingSubscription.unsubscribe();
    }
    isAuthorized() {
        if (this.storage.getItem('currentUser')) {
            return true;
        }
        return false;
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.AuthConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.UserIdleService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i4.ActivatedRoute), i0.ɵɵinject(i5.UserTempService), i0.ɵɵinject(i6.GlobalSaveService)); }, token: AuthenticationService, providedIn: "root" });
