import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DynamicFormControlModel, DynamicFormService, DynamicTextAreaModel} from '@ng-dynamic-forms/core';
import {Note} from '../../../@models/system-managment/note';
import {FormTamplate, FormValueBuilder} from '../forms/form-tamplate';
import {FormMode} from '../../../@models/system-managment/form-mode';
import {FACEBOOK, INSTAGRAM, TWITTER} from '../../../../@util/dynamic-form/dynamic-controlls';
import {Social} from '../../../@models/system-managment/social';
import {DynamicForm} from "../../../../@util/dynamic-form/dynamic-form";
import {FormClose} from "../forms/form-close";


@Component({
	selector: 'app-form-social',
	templateUrl: './form-social.component.html'
})
export class FormSocialComponent extends DynamicForm implements FormTamplate, FormClose {
	@Output()
	closeForm: EventEmitter<any> = new EventEmitter<any>();
	@Output()
	saveForm: EventEmitter<Note> = new EventEmitter<Note>();

	@Input()
	mode: FormMode;
	@Input()
	social: Social;
	formGroup: FormGroup;
	formModel: DynamicFormControlModel[];
	layout;
	title = 'Social';
	form = {
		facebook: FACEBOOK(),
		instagram: INSTAGRAM(),
		twitter: TWITTER()
	};

	constructor(private formService: DynamicFormService) {
		super();
		this.formModel = FormValueBuilder.buildFormModel(this.form);
		this.formGroup = this.formService.createFormGroup(this.formModel);
	}

	onBlur($event) {
	}

	onChange($event) {

	}

	onFocus($event) {

	}

	ngOnInit() {
		setTimeout(() => this.initForm(), 0);
	}

	saveNote() {
		FormValueBuilder.setControlsToched(this.formGroup);
		if (this.formGroup.valid) {
			this.saveForm.emit(FormValueBuilder.getData(this.form));
			// TODO State before
			FormValueBuilder.resetToDefault(this.form);
			this.formStateChanged.emit({key: this.formKey, value: FormValueBuilder.isDirty(this.form)});
		}
	}

	initForm(): void {
		if (this.mode !== FormMode.CreateMode && this.social) {
			FormValueBuilder.setData(this.social, this.form);
		}
		setTimeout(()=>{
			// TODO State reset to default
			FormValueBuilder.resetToDefault(this.form);
			// TODO Form State if Value Changed
			this.formGroup.valueChanges.subscribe(j => {
				this.formStateChanged.emit({key: this.formKey, value: FormValueBuilder.isDirty(this.form)});
			})
		})
	}

	ngOnDestroy(): void {
		this.formStateChanged.next({value: false, key: this.formKey});
	}
}
