import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MedicalService } from '../../../../../@core/medical.service';
import { ActivatedRoute, Router } from "@angular/router";
import { NavigationService } from "../../../../../@core/navigation.service";
import { iTableClassesOptions } from "../../../../../../@util/irs-table/dynamic-table.model";
import { GlobalSaveService } from "../../../../../@core/global-save.service";
import { UserInfoService } from "../../../../../@core/user-info.service";
import { NotificationConfirmationsComponent } from "../../../../notification/confirmations/notification-confirmations.component";
import { MedicalSourcesService } from "../../../../../@core/medical-sources.service";
import { HospitalFormNewComponent } from "./hospital-form-new/hospital-form-new.component";
import { DATE_FORMAT, DEFAULT_DATE } from "../../../../../@config/dateFormat";
import { MDEDICAL_TREATING_SOURCES_STATUSES } from "../../../../../@config/status/medical-treating-sources";
import { LoadingService } from "../../../../../../@util/loading/loading.service";
import { DialogService } from "../../../../../../@util/dialog/dialog.service";
import { DELETE_SOURCE_CONFIRM_MESSAGE } from "../medical-treating-sources.config";
import { PERMISSIONS } from 'src/app/@config/permissions';
export class HospitalsComponent {
    constructor(medicalService, dialog, route, globalSaveService, router, medicalSourcesService, userInfoService, dialogService, loadingService, navService) {
        this.medicalService = medicalService;
        this.dialog = dialog;
        this.route = route;
        this.globalSaveService = globalSaveService;
        this.router = router;
        this.medicalSourcesService = medicalSourcesService;
        this.userInfoService = userInfoService;
        this.dialogService = dialogService;
        this.loadingService = loadingService;
        this.navService = navService;
        this.DATE_FORMAT = DATE_FORMAT;
        this.DEFAULT_DATE = DEFAULT_DATE;
        this.title = 'Hospitals';
        this.closeForm = new EventEmitter();
        this.PERMISSIONS = PERMISSIONS;
        this.dynamicTable = [
            {
                column_name: 'Name',
                column_key: 'name',
                column_type: '',
                column_classes: [iTableClassesOptions.width40]
            },
            {
                column_name: 'Phone',
                column_key: 'phoneFormatted',
                column_type: '',
                column_classes: []
            },
            {
                column_name: '',
                column_key: 'button',
                column_type: 'button',
                column_classes: [iTableClassesOptions.width5]
            }
        ]; // for NEW dynamic table
        this.canScrollLeft = false;
        this.canScrollRight = true;
        this.medicalTreatingSourcesStatuses = MDEDICAL_TREATING_SOURCES_STATUSES;
        this.deleteHospitalsArray = [];
        this.dataSource = new MatTableDataSource([]);
        this.sub = navService.back().subscribe(j => this.goBack());
    }
    ngOnDestroy() {
        this.sub.unsubscribe();
    }
    goBack() {
        this.navService.navigate(['/claimants'], [`${this.route.snapshot.paramMap.get('sslgId')}`]);
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.sslgId = this.route.snapshot.parent.paramMap.get('sslgId');
            this.loadHospitals();
        });
    }
    loadHospitals() {
        this.dataSource.paginator = this.paginator;
        this.medicalService.getAllHospitals(this.sslgId).subscribe((data) => {
            this.hospitals = data;
            this.hospitals.sort((a, b) => {
                const aNext = a.lastInp;
                const bNext = b.lastInp;
                if (aNext < bNext) {
                    return 1;
                }
                if (aNext > bNext) {
                    return -1;
                }
                return 0;
            });
            this.loadData();
        });
    }
    loadData() {
        this.dataSource.data = this.hospitals;
        this.data = this.dataSource.connect();
        if (this.route.snapshot.queryParamMap.get('page')) {
            this.paginator.pageIndex = +this.route.snapshot.queryParamMap.get('page');
            this.data.next(this.dataSource._pageData(this.hospitals));
        }
        const popupNew = this.route.snapshot.queryParamMap.get('popup');
        if (popupNew) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { popup: null },
                queryParamsHandling: 'merge',
            });
            setTimeout(() => {
                this.addDoctor();
            });
        }
    }
    hospitalInfo(visit) {
        const url = this.getUrl();
        this.router.navigate([url + '/' + visit.id + '/info'], {
            queryParams: {
                returnUrl: url,
                page: this.paginator.pageIndex
            }
        });
    }
    getUrl() {
        return this.router.url.indexOf('?') !== -1 ?
            this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
    }
    addDoctor() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            document.querySelectorAll('.mat-drawer-content').forEach(el => {
                el.style.overflow = 'hidden';
            });
            const dialogRef = this.dialog.open(HospitalFormNewComponent, {
                data: {
                    sslgId: this.sslgId
                },
                panelClass: ['mobile-popup', 'with-shadow', 'popup-scrollable'],
                // panelClass: ['mobile-popup', 'with-shadow'],
                backdropClass: 'mobile-popup__overlay',
                disableClose: true
            });
            dialogRef.componentInstance.updateHospitalsList.subscribe(result => {
                this.loadHospitals();
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    if (result.cmd) {
                        if (result.cmd == 'addVisit') {
                            this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'], {
                                queryParams: {
                                    returnUrl: this.route.snapshot.queryParams['returnUrl'],
                                    catalogItemId: result.data.id,
                                    type: 'HOSPITAL',
                                }
                            });
                        }
                        else {
                            this.loadHospitals();
                        }
                    }
                    else if (result == 'new') {
                        this.loadHospitals();
                    }
                }
            });
            //const url = this.getUrl();
            //this.router.navigate([url + '/new'], {queryParams: {returnUrl: url, page: this.paginator.pageIndex}});
            // const dialogRef = this.dialog.open(HospitalFormComponent, {
            //   data: {sslgId: this.sslgId, mode: FormMode.CreateMode},
            //   position: {
            //     top: '7%',
            //     left: '45%'
            //   },
            //   minWidth: '35%',
            //   minHeight: '25%'
            // });
            // const dialogRez = await dialogRef.afterClosed().toPromise();
            // ////console.log('The dialog was closed');
            // if (dialogRez) {
            //   dialogRez.sslgId = this.sslgId;
            //   this.saveDoctor(dialogRez);
            // }
        });
    }
    isClaimant() {
        return this.userInfoService.isClaimant();
    }
    isSystem() {
        return this.userInfoService.getCurrentUserNameIsSystem();
    }
    delete(data, index) {
        let dialog = this.dialog.open(NotificationConfirmationsComponent, {
            data: {
                message: "Are you sure you want to delete the source?"
            }
        });
        dialog.afterClosed().subscribe(j => {
            try {
                if (j.status) {
                    //alert(data.id)
                    this.medicalSourcesService.deleteSource(this.sslgId, data.id).subscribe(() => {
                        let itemIndex = index + this.paginator.pageSize * this.paginator.pageIndex;
                        this.hospitals.splice(itemIndex, 1);
                        this.loadData();
                    });
                }
            }
            catch (e) {
            }
        });
    }
    tableScrolled($event) {
        const scrollLeft = $event.target.scrollLeft;
        const scrollWidth = $event.target.scrollWidth;
        const offsetWidth = $event.target.offsetWidth;
        if (scrollLeft > 0) {
            this.canScrollLeft = true;
        }
        else {
            this.canScrollLeft = false;
        }
        if (scrollWidth - scrollLeft > offsetWidth + 5) {
            this.canScrollRight = true;
        }
        else {
            this.canScrollRight = false;
        }
    }
    getTableHeadClass() {
        // if (this.isSystem()) {
        // 	return 'i-btn-2';
        // }
        return 'i-btn-1';
        // if (this.globalSaveService.getStatusTrue()) {
        // 	if (this.isSystem()) {
        // 		return 'i-btn-2';
        // 	}
        // 	return 'i-btn-1';
        // } else {
        // 	if (this.isSystem()) {
        // 		return 'i-btn-2';
        // 	}
        // 	return 'i-btn-0';
        // }
    }
    isExecutive() {
        if (PERMISSIONS.HIGHER_EXEC_LEVEL_4.includes(this.userInfoService.getCurrentUserRole())) {
            return true;
        }
        return false;
    }
    onMedicalTreatingSourcesStatusChange($event, doctor, select) {
        const newValue = $event.value;
        this.medicalSourcesService.changeStatusSource(this.sslgId, doctor.id, newValue).subscribe((data) => {
            if (data[status] === false) {
                this.loadingService.showError([data['message']]);
                select.value = doctor.catalogStatus;
            }
            else {
                this.loadData();
            }
        }, () => {
            select.value = doctor.catalogStatus;
        });
    }
    getMedicalTreatingSourcesStatus(doctor) {
        // if (doctor.catalogStatus) {
        return doctor.catalogStatus;
        // }
        // return 0;
    }
    getDate(date) {
        return date !== this.DEFAULT_DATE ? date : '';
    }
    toggleDeleteHospitalsArray(e, id) {
        if (e.checked) {
            let elExist;
            this.deleteHospitalsArray.forEach(el => {
                if (el.id === id) {
                    elExist = el;
                }
            });
            if (!elExist) {
                this.deleteHospitalsArray.push({
                    id: id,
                    sslgId: this.sslgId
                });
            }
        }
        else {
            this.deleteHospitalsArray.forEach((el, index) => {
                if (el.id === id) {
                    this.deleteHospitalsArray.splice(index, 1);
                }
            });
        }
    }
    deleteHospitals() {
        if (!this.deleteHospitalsArray.length) {
            this.dialogService.show('Please, select at least one Hospital to delete', ['OK']);
            return;
        }
        let dialog = this.dialog.open(NotificationConfirmationsComponent, {
            data: {
                messageHTML: DELETE_SOURCE_CONFIRM_MESSAGE
            }
        });
        dialog.afterClosed().subscribe(j => {
            try {
                if (j.status) {
                    this.medicalSourcesService.deleteSources(this.deleteHospitalsArray).subscribe(() => {
                        this.deleteHospitalsArray = [];
                        this.loadHospitals();
                    });
                }
            }
            catch (e) {
            }
        });
    }
}
