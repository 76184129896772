import {AfterContentInit, ChangeDetectionStrategy, Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CustomIrisNotificationService} from "../../custom-iris-notification.service";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-inner-container',
    templateUrl: './inner-container.component.html',
    styleUrls: ['./inner-container.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({opacity: '0', transform: 'translateY(110%)'}),
                animate('.5s ease-out', style({opacity: '1', transform: 'translateY(0)'})),
            ]),
            transition(':leave', [
                style({opacity: '1', transform: 'translateY(0)'}),
                animate('.5s ease-out', style({opacity: '0', transform: 'translateX(110%)'})),
            ]),
        ]),
    ]
})
export class InnerContainerComponent implements OnInit, AfterContentInit {
    public allNotificationsConf = [];

    constructor() {
        //console.log('foo')

    }

    ngOnInit() {
    }

    trackByFn(index, item) {
        return item.id; // or item.id
    }

    closeNotif(id) {
        ////console.log(id);
        CustomIrisNotificationService.close(id);
    }

    getClass() {
        return this.allNotificationsConf && this.allNotificationsConf.length ? 'notifications-wrapper position-' + this.allNotificationsConf[0].position : 'notifications-wrapper';
    }

    ngAfterContentInit(): void {
        this.allNotificationsConf = CustomIrisNotificationService.allNotifications;
    }
}
