import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "../../../@util/loading/loading.service";
import { MedicalService } from "../../@core/medical.service";
import { MEDICAL_TREATING_REPORT_TYPE } from "../pages/claimants/medical-treating-sources/medical-treating-reports/medical-treating-reports.config";
export class MedicalTreatingReportPageComponent {
    constructor(route, router, loading, medicalService) {
        this.route = route;
        this.router = router;
        this.loading = loading;
        this.medicalService = medicalService;
        this.scale = 0.95;
        this.showContent = true;
        this.headerInfo = {
            claimant: '',
            sslgNumber: '',
            date: null,
            type: null
        };
        this.MEDICAL_TREATING_REPORT_TYPE = MEDICAL_TREATING_REPORT_TYPE;
        // this.route.queryParams.subscribe(val => {
        // 	this.type = val.type;
        // 	this.sslgId = val.sslgId;
        // });
    }
    ngOnInit() {
        this.type = localStorage.getItem('reportType');
        this.sslgId = localStorage.getItem('reportSslgId');
        // let data = localStorage.getItem('reportData');
        // if (!data) {
        // 	this.router.navigate(['/']);
        // }
        // this.pdfSrc = JSON.parse(data);
        // if(this.pdfSrc.type == "DOWNLOAD"){
        // 	this.showContent = false;
        // }
        // var ids = this.getVisitsIds();
        // this.medicalService.getVisitSummariesById(this.pdfSrc.sslgId, ids).subscribe((data:any) => {
        // 	this.pdfData = data.data;
        // });
        setTimeout(() => {
            this.getReportData();
        });
    }
    getReportData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const result = yield this.medicalService.getMedicalTreatmentSourcesReport(this.sslgId, this.type);
            this.data = result;
            this.setHeaderInfo(this.data);
            if (this.isMedicationsReport() || this.isBothReport()) {
                this.getMedicationsData(this.data.medications);
            }
            if (this.isMTSReport() || this.isBothReport()) {
                this.getMTSData(this.data);
            }
        });
    }
    isMedicationsReport() {
        return this.type === this.MEDICAL_TREATING_REPORT_TYPE.medications;
    }
    isBothReport() {
        return this.type === this.MEDICAL_TREATING_REPORT_TYPE.both;
    }
    isMTSReport() {
        return this.type === this.MEDICAL_TREATING_REPORT_TYPE.mts;
    }
    setHeaderInfo(data) {
        this.headerInfo.claimant = data.name;
        this.headerInfo.sslgNumber = data.sslgNumber;
        this.headerInfo.type = this.type;
        this.headerInfo.date = new Date();
    }
    getMedicationsData(data) {
        const medicationsData = [];
        Object.keys(data).forEach(type => {
            Object.keys(data[type]).forEach((claim) => {
                const discontinuedMedList = [];
                const activeMedList = [];
                data[type][claim].forEach(med => {
                    if (med.status) {
                        activeMedList.push(med);
                    }
                    else {
                        discontinuedMedList.push(med);
                    }
                });
                medicationsData.push({
                    name: data[type][claim][0].catalogItemName,
                    type: type,
                    discontinuedMedList,
                    activeMedList
                });
            });
            this.medicationsData = medicationsData.reverse();
        });
    }
    getMTSData(data) {
        console.log(data);
        const MTSData = [];
        const sources = data.sources;
        const medications = data.medications;
        sources.forEach(source => {
            const sourceItem = source;
            const sourceItemMedications = medications && medications[source.type] && medications[source.type][source.id] ? medications[source.type][source.id] : null;
            const discontinuedMedList = [];
            const activeMedList = [];
            if (sourceItemMedications) {
                sourceItemMedications.forEach(med => {
                    if (med.status) {
                        activeMedList.push(med);
                    }
                    else {
                        discontinuedMedList.push(med);
                    }
                });
            }
            MTSData.push({
                item: sourceItem,
                type: source.type,
                discontinuedMedList,
                activeMedList
            });
            this.MTSData = MTSData.reverse().sort((a, b) => {
                if (a.type < b.type)
                    return -1;
                if (a.type > b.type)
                    return 1;
                return 0;
            });
        });
    }
    getVisitsIds() {
        var ids = [];
        for (let i = 0; i < this.pdfSrc.data.length; i++) {
            ids.push(this.pdfSrc.data[i].id);
        }
        return ids;
    }
    ngAfterViewInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // let loader = this.loading.loading();
            // setTimeout(()=>{
            // 	let doc = PdfDocument.createDocument({
            // 		format: "a4",
            // 		compressPdf: true,
            // 		nativeElements: null,
            // 		orientation: "portrait",
            // 		unit: "mm"
            // 	});
            // 	try {
            // 		if (!this.pdfSrc.isIndividual) {
            // 			doc.build(() => {
            // 				doc.setHeaders(() => {
            //
            // 				}, this.head.nativeElement, this.footer.nativeElement);
            // 			}, [this.report.nativeElement])
            // 		} else {
            // 			let elements = <any[]>(<any>this.body)._results.map(j => j.nativeElement);
            // 			doc.build(() => {
            // 				doc.setHeaders(() => {
            // 					this.saveAndSendEmail(doc, loader);
            // 				}, this.head.nativeElement, this.footer.nativeElement);
            // 			}, elements);
            // 		}
            // 	} catch (e) {
            // 		this.loading.loaded(loader);
            // 		console.error(e)
            // 	}
            // },100)
            //
            // if (this.pdfSrc.type == "EMAIL") {
            // 	this.sendEmail();
            // } else if (this.pdfSrc.type == "DOWNLOAD") {
            // 	this.save();
            // } else {
            // 	//this.save();
            // }
        });
    }
    saveAndSendEmail(doc, loader) {
        // // this.loading.loaded(loader);
        // this.loading.loaded();
        // if (this.pdfSrc.type == "EMAIL") {
        // 	let data: String = doc.output();
        //
        // 	this.medicalService.sentVisitSummaries(this.pdfSrc.sslgId, {
        // 		content: data,
        // 		email: this.pdfSrc.email
        // 	}).subscribe();
        // }
        // doc.save();
    }
    sendEmail() {
        // let {data, report} =  <any>{...this.pdfSrc};
        /*let rez = {...this.pdfSrc};
        rez.data = rez.data.map(j=>j.id);
        (<any>rez).individual = rez.isIndividual;
        this.medicalService.sentVisitSummariesByEmail(rez).subscribe(rez=>{
            if (rez.type == 4)
                this.downloadFile(rez, "report.pdf");
        });*/
        var ids = this.getVisitsIds();
        this.medicalService.sendVisitSummariesByEmail(this.pdfSrc.sslgId, this.pdfSrc.email, this.pdfSrc.isIndividual, ids).subscribe(rez => {
            if (rez.type == 4)
                this.downloadFile(rez, "report.pdf");
        });
    }
    // private download(){
    // 	let rez = {...this.pdfSrc};
    // 	rez.data = rez.data.map(j=>j.id);
    // 	(<any>rez).individual = rez.isIndividual;
    // 	this.medicalService.saveVisitSummariesReport(rez).subscribe(rez=>{
    // 		if (rez.type == 4){
    // 			this.downloadFile(rez, "report.pdf");
    // 			window.close();
    // 		}
    // 	});
    // }
    save() {
        // let {data, report} =  <any>{...this.pdfSrc};
        //console.log(this.pdfSrc);
        let rez = Object.assign({}, this.pdfSrc);
        rez.data = rez.data.map(j => j.id);
        rez.individual = rez.isIndividual;
        this.medicalService.saveVisitSummariesReport(rez).subscribe(rez => {
            if (rez.type == 4) {
                this.downloadFile(rez, "report.pdf");
                window.close();
            }
        });
    }
    downloadFile(data, fileName) {
        const ieEDGE = navigator.userAgent.match(/Edge/g);
        const ie = navigator.userAgent.match(/.NET/g); // IE 11+
        const oldIE = navigator.userAgent.match(/MSIE/g);
        const blob = new Blob([data.body], { type: 'application/pdf' });
        if (ie || oldIE || ieEDGE) {
            window.navigator.msSaveBlob(blob, atob(fileName));
        }
        else {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        }
        return blob;
    }
    print() {
        window.print();
    }
}
