import {
	DYNAMIC_FORM_CONTROL_INPUT_TYPE_NUMBER,
	DYNAMIC_FORM_CONTROL_INPUT_TYPE_PASSWORD, DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEL,
	DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT, DynamicFormControlLayout,
} from '@ng-dynamic-forms/core';

import {STATES} from '../../app/@config/state';
import "../ui-material/extensions"
import {
	DatepickerCustomModel,
	DatepickerCustompropsModel,
	DynamicCheckboxModel, DynamicInputAutocompleteModel,
	DynamicInputModel, DynamicMoneyInputModel,
	DynamicRadioGroupModel,
	DynamicSelectModel
} from "../ui-material/form-controls";
import {UserRole} from "../../app/@models/system-managment/user-role";

export function RADIO({id, label, layout, value, visibleFor, editableFor}: {
	id: any,
	label: any,
	layout?: DynamicFormControlLayout,
	value?: boolean,
	visibleFor?: (keyof UserRole | string)[],
	editableFor?: (keyof UserRole | string)[]
}) {
	return DynamicRadioGroupModel<boolean>((<DynamicRadioGroupModelConfig<boolean>>{
		id: id,
		// legend: "Active Web User",
		editableFor: editableFor,
		visibleFor: visibleFor,
		label: label,
		required: true,
		options: [
			{
				label: 'YES',
				value: true
			},
			{
				label: 'NO',
				value: false
			}
		],
		validators: {
			required: null
		},
		errorMessages: {
			required: 'Required'
		},
		value: value !== undefined ? value : true,
	}), layout);
}

export function USER_NAME_CLAIMANT({id, label, tabIndex, layout, editableFor, visibleFor}:
							  {
								  id: string, label?: string, tabIndex?: number, layout?: DynamicFormControlLayout,
								  editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]
							  }) {


	return DynamicInputModel(<DynamicInputModelConfig>{
		editableFor: editableFor,
		visibleFor: visibleFor,
		id: id,
		label: label ? label : 'Username',
		tabIndex: tabIndex ? tabIndex : 0,
		value: null,
		required: true,
		disabled:true,
		autoComplete: 'off',
		validators: {
			required: null,
			minLength: 3,
			maxLength: 16
		},
		errorMessages: {
			required: 'Required'
		}
	}, layout);
}

export function USER_NAME({id, label, tabIndex, layout, editableFor, visibleFor}:
							  {
								  id: string, label?: string, tabIndex?: number, layout?: DynamicFormControlLayout,
								  editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]
							  }) {

	return DynamicInputModel(<DynamicInputModelConfig>{
		editableFor: editableFor,
		visibleFor: visibleFor,
		id: id,
		label: label ? label : 'Username',
		tabIndex: tabIndex ? tabIndex : 0,
		value: null,
		required: true,
		autoComplete: 'off',
		validators: {
			required: null,
			minLength: 3,
			maxLength: 16
		},
		errorMessages: {
			required: 'Required'
		}
	}, layout);
}

export function PASSWORD({id, label, tabIndex, layout, editableFor, visibleFor}:
							 {
								 id: string, label?: string, tabIndex?: number,
								 layout?: DynamicFormControlLayout,
								 editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]
							 }) {
	return DynamicInputModel(<DynamicInputModelConfig>{
		editableFor: editableFor,
		visibleFor: visibleFor,
		id: id,
		label: label ? label : 'Password hidden',
		tabIndex: tabIndex ? tabIndex : 0,
		inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_PASSWORD,
		value: null,
		required: true,
		autoComplete: 'off',
		validators: {
			required: null,
			minLength: 6,
			maxLength: 16
		},
		errorMessages: {
			required: 'Required',
			minLength: 'Min Length is 6',
			maxLength: 'Max Length is 16',
		},
		placeholder:"******",

	}, layout);
}

export function ROLE({id, roles, label, layout, editableFor, visibleFor}: { id: string, roles: string[], label?: string,
	layout?: DynamicFormControlLayout, editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[] }) {
	return DynamicSelectModel<string>(<DynamicSelectModelConfig<string>>{

		id: id,
		label: label ? label : 'Role',
		visibleFor,
		editableFor,
		options: roles.map(j => {
			return {
				label: j,
				value: j
			};
		}),
		value: null
	}, layout);
}

export function GENDER({editableFor, visibleFor}: { editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[] }) {

	return DynamicSelectModel<string>(<DynamicSelectModelConfig<string>>{
		id: 'gender',
		label: 'Gender',
		editableFor,
		visibleFor,
		options: [
			{
				label: 'Female',
				value: 'female',
			},
			{
				label: 'Male',
				value: 'male',
			}
		],
		value: 'female'
	});
}

export function DATE_PICKER_CUSTOM({id, label, value, required, playsholder, layout,min,max, tabIndex, hiddenClearIco, hiddenDatePicker, hidden, editableFor, visibleFor}:
									   {
										   id: string, label?: string, value?: any, required?: boolean, playsholder?: string,
										   layout?: DynamicFormControlLayout, min?: any, max?: any, tabIndex?: number, hiddenClearIco?: boolean,
										   hiddenDatePicker?: boolean, hidden?: boolean, editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]
									   }) {
	return DatepickerCustomModel({
		id: id,
		editableFor,
		visibleFor,
		inline: false,
		label: label,
		value: value,
		readOnly: true,
		min: min,
		max: max,
		format: 'long',
		//format: 'YYYY-MM-DD',
		tabIndex: tabIndex ? tabIndex : 0,
		required: required,
		placeholder: playsholder,
		validators: required ? {required: null} : undefined,
		hiddenDatePicker: hiddenDatePicker,
		hidden: hidden,
		errorMessages: {
			required: 'Required'
		},
		hiddenClearIco: hiddenClearIco ? hiddenClearIco : false,
	}, layout);
}

export function DATE_PICKER({id, label, value, required, playsholder, layout, tabIndex, hiddenClearIco, hiddenDatePicker, hidden, editableFor, visibleFor,disabled}:
								{
									id: string, label?: string, value?: any, required?: boolean, playsholder?: string,
									layout?: DynamicFormControlLayout, tabIndex?: number, hiddenClearIco?: boolean,
									hiddenDatePicker?: boolean, hidden?: boolean, editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[],
									disabled?: boolean
								}) {


	return DatepickerCustompropsModel({
		id: id,
		editableFor,
		visibleFor,
		inline: false,
		label: label,
		value: value,
		readOnly: true,
		format: 'long',
		//format: 'YYYY-MM-DD',
		tabIndex: tabIndex ? tabIndex : 0,
		required: required,
		placeholder: playsholder,
		validators: required ? {required: null} : undefined,
		hiddenDatePicker: hiddenDatePicker,
		hidden: hidden,
		errorMessages: {
			required: 'Required'
		},
		hiddenClearIco: hiddenClearIco ? hiddenClearIco : false,
		disabled: disabled ? disabled : false,
		//min:new Date(2020, 6, 10)
		// placeholder: "Date of Departure"
	}, layout);
}

export function FIRST_NAME({id, label, tabIndex, layout, editableFor, visibleFor}:
							   {
								   id: string, label?: string, tabIndex?: number, layout?: DynamicFormControlLayout,
								   editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]
							   }) {
	return STRING_FIELD({
		id: 'firstName',
		editableFor,
		visibleFor,
		label: label ? label : 'First Name',
		tabIndex: tabIndex ? tabIndex : 0,
		required: true,
		maxLength: 25,
		inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
		validators: {
			maxLength: 25,
			required: null
		}

	}, layout);
}

export function LAST_NAME(id: string, label?: string, tabIndex?: number, layout?: DynamicFormControlLayout, editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]) {
	let lastName = STRING_FIELD({
		id: id,
		editableFor,
		visibleFor,
		label: label ? label : 'Last Name',
		tabIndex: tabIndex ? tabIndex : 0,
		required: true,
		maxLength: 25,
		inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
		validators: {
			maxLength: 25,
			required: null
		}
	}, layout);
	lastName.valueUpdates.subscribe(j => {
		if ((<string>j).toUpperCase() !== j) {
			setTimeout(() => {
				lastName.valueUpdates.next((<string>j).toUpperCase());
			})
		}
	});
	return lastName;

}

export function MIDDLE_NAME({editableFor, visibleFor}: { editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[] }) {

	return STRING_FIELD({
		id: 'middleName',
		label: 'Middle Name',

		editableFor,
		visibleFor,
		maxLength: 16,
		inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
		validators: {
			maxLength: 16,
		}
	});
}

export function EMAIL(id: string, label?: string, layout?: DynamicFormControlLayout, editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]) {
	return DynamicInputModel(<DynamicInputModelConfig>{

		id: id,
		label: label ? label : 'Email',
		autoComplete: 'off',
		editableFor,
		visibleFor,
		maxLength: 128,
		validators: {
			emailValidator: null
		},
		errorMessages: {
			emailValidator: 'Field has no valid email'
		}
	}, layout);
}

export function EMAIL_REQUIRED(id: string, label?: string, editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]) {
	const input = EMAIL(id, label, null, editableFor, visibleFor);
	input.required = true;

	input.validators = {
		emailValidator: null,
		required: null
	};
	input.errorMessages = {
		required: 'Required',
		emailValidator: 'Field has no valid email'
	};
	return input;
}

export function PHONE({id, label, layout, disabled, visibleFor, editableFor}: {
	id: string, label: string, layout?: DynamicFormControlLayout,
	disabled?: boolean
	editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]
}) {
	let phone = STRING_FIELD({
		id: id,
		editableFor,
		visibleFor,
		label: label,
		inputType: 'tel',
		disabled: disabled,
		mask: '(000)000-0000'
	}, layout);
	return phone;
}

export function DATE_MANUAL({id, label, layout, disabled, visibleFor, editableFor}: {
	id: string, label?: string, layout?: DynamicFormControlLayout,
	disabled?: boolean
	editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]
}) {
	let date = STRING_FIELD({
		id: id,
		editableFor,
		visibleFor,
		label: label,
		inputType: 'tel',
		disabled: disabled,
		placeholder: 'mm/dd/yyyy',
		mask: '00/00/0000',
	}, layout);
	return date;
}

export function PRESS({id, label, layout, disabled, visibleFor, editableFor}: {
	id: string, label: string, layout?: DynamicFormControlLayout,
	disabled?: boolean
	editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]
}) {
	let press = STRING_FIELD({
		id: id,
		editableFor,
		visibleFor,
		label: label,
		inputType: 'tel',
		maxLength: 10,
		disabled: disabled,
		mask: '0000000000'
	}, layout);
	return press;
}

export function MONTH_YEAR({id, label, layout, disabled, visibleFor, editableFor}: {
	id: string, label: string, layout?: DynamicFormControlLayout,
	disabled?: boolean
	editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]
}) {
	let monthYear = STRING_FIELD({
		id: id,
		editableFor,
		visibleFor,
		label: label,
		inputType: 'text',
		maxLength: 7,
		disabled: disabled,
		placeholder: 'mm/yyyy',
		mask: '00/0000'
	}, layout);
	return monthYear;
}

export function EXT({id, label, layout, disabled, visibleFor, editableFor}: {
	id: string, label: string, layout?: DynamicFormControlLayout,
	disabled?: boolean
	editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]
}) {
	let ext = STRING_FIELD({
		id: id,
		editableFor,
		visibleFor,
		label: label,
		inputType: 'tel',
		maxLength: 10,
		disabled: disabled,
		mask: '0000000000'
	}, layout);
	return ext;
}

export function INSTAGRAM(layout?: DynamicFormControlLayout, editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]) {
	return STRING_FIELD({
		id: 'instagram',
		label: 'Instagram',
		editableFor,
		visibleFor,
		maxLength: 254,
		inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
		validators: {
			maxLength: 254
		}
	}, layout);
}

export function TWITTER(layout?: DynamicFormControlLayout, editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]) {
	return STRING_FIELD({
		id: 'twitter',
		label: 'Twitter',
		editableFor,
		visibleFor,
		maxLength: 254,
		inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
		validators: {
			maxLength: 254,

		}
	}, layout);
}

export function FACEBOOK(layout?: DynamicFormControlLayout, editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]) {
	return STRING_FIELD({
		id: 'facebook',
		label: 'Facebook',
		editableFor,
		visibleFor,
		maxLength: 254,
		inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
		validators: {
			maxLength: 254,

		}
	}, layout);
}

export function ADDRESS({id, label, layout, editableFor, visibleFor}: { id: string, label?: string, layout?: DynamicFormControlLayout, editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[] }) {
	return STRING_FIELD({
		id: id,
		label: label ? label : 'Address',
		editableFor,
		visibleFor,
		maxLength: 128,
		inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
	}, layout);
}

export function CYTY({id, label, layout, editableFor, visibleFor}: { id: string, label?: string, layout?: DynamicFormControlLayout, editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[] }) {
	return STRING_FIELD({
		id: id,
		editableFor,
		visibleFor,
		label: label ? label : 'City',
		maxLength: 32,
		inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT
	}, layout);
}

export function ZIP({id, label, layout, editableFor, visibleFor}: { id: string, label?: string, layout?: DynamicFormControlLayout, editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[] }) {
	return STRING_FIELD({
		id: id,
		editableFor,
		visibleFor,
		label: label ? label : 'Zip',
		maxLength: 5,
		inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
		mask: '00000'
	}, layout);
}

export function NUMERIC_FIELD({id, label, maxLength, layout, editableFor, visibleFor}: { id: string, label?: string, maxLength?: number, layout?: DynamicFormControlLayout, editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[] }) {
	let data = STRING_FIELD({
		id: id,
		editableFor,
		visibleFor,
		label: label ? label : '',
		maxLength: maxLength,
		inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
	}, layout);

	data.valueUpdates.subscribe(val => {
		if (val === '')
			return;
		let newValue = checkIsNaN(val, 99999999999999999999999, 0, 0);

		if (newValue == val)
			return;

		setTimeout(() => {
			data.valueUpdates.next(newValue);
		});
	});

	return data;
}

export function STATE_SELECT({id, label, layout, editableFor, visibleFor, disabled}:
								 {
									 id: string, label?: string, layout?: DynamicFormControlLayout,
									 editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[], disabled?: boolean
								 }){
	const states = [{
		disabled: true,
		label: 'Choose State',
		value: null,
	}];
	STATES.forEach(j => {
		states.push({disabled: undefined, label: j.name, value: j.abbreviation});
	});
	return DynamicSelectModel<string>(<DynamicSelectModelConfig<string>>{
		id: id,
		label: label ? label : 'State',
		visibleFor: visibleFor,
		editableFor: editableFor,
		options: states,
		disabled: disabled,
		errorMessages: {
			required: 'Required',
		},
		value: null
	}, layout);
}

export function APT_UNIT(editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]) {
	return STRING_FIELD({
		id: 'aptUnit',
		label: 'Apt/Unit',
		editableFor,
		visibleFor,
		maxLength: 10,
		inputType: DYNAMIC_FORM_CONTROL_INPUT_TYPE_TEXT,
		validators: {
			maxLength: 10,

		}
	});
}

export function CURRENCY_FILD({id, label, layout, maxValue, fractionDigit, showFractionZero, suffix, disabled, editableFor, visibleFor}:
								  {
									  disabled?: boolean, id: string, label?: string, layout?: DynamicFormControlLayout,
									  maxValue?: number, fractionDigit?: number,
									  showFractionZero?: boolean, suffix?: string, editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]
								  }) {

	return DynamicMoneyInputModel(<DynamicInputModelConfig>{
		id: id,
		editableFor,
		visibleFor,
		label: label,
		inputMaxValue: maxValue,
		fractionDigit: fractionDigit,
		showFractionZero: showFractionZero,
		suffix: suffix,
		disabled: disabled
	}, layout);

}

export function NUMERIC_FILD({id, label, layout, maxValue, type, tabIndex, disabled, fractionDigit, showFractionZero, suffix, editableFor, visibleFor}: {
	id: string, label?: string, tabIndex?: number, disabled?: boolean, layout?: DynamicFormControlLayout,
	maxValue?: number, type?: string, fractionDigit?: number, showFractionZero?: boolean, suffix?: string,
	editableFor?: (keyof UserRole | string)[], visibleFor?: (keyof UserRole | string)[]
}) {

	let data = STRING_FIELD({
		id: id, label: label, suffix: suffix, tabIndex: tabIndex, disabled: disabled, editableFor,
		visibleFor
	}, layout);

	data.valueUpdates.subscribe(val => {
		if (val === '')
			return;
		let newValue = checkIsNaN(val, maxValue, fractionDigit, showFractionZero);

		if (type === 'percent') {
			newValue = Number(newValue);

			if (String(newValue) === String(val) || newValue + '.' === String(val)) {
				return;
			}

		} else {
			if (newValue == val) {
				return;
			}
		}

		setTimeout(() => {
			data.valueUpdates.next(newValue);
		});

	});

	return data;

}

function checkIsNaN(val, maxValue, fractionDigit, showFractionZero) {
	let curValue = val;
	if (!isNaN(+val)) {
		if (maxValue && +val > maxValue) {
			curValue = maxValue;
		}
		if (curValue && curValue.indexOf && curValue.indexOf('.') > -1) {
			let dij = curValue.substr(curValue.indexOf('.'));
			if (fractionDigit && dij.length > fractionDigit) {
				dij = dij.substr(0, fractionDigit + 1);
				curValue = curValue.substr(0, curValue.indexOf('.')) + dij;
			}
			////console.log(+dij);
			if (+dij <= 0 && fractionDigit && (dij.length == fractionDigit + 1) && !showFractionZero) {
				curValue = curValue.substr(0, curValue.indexOf('.'));
			}

		}
	} else {
		if (val.length > 1) {
			return checkIsNaN(val.substr(0, val.length - 1), maxValue, fractionDigit, showFractionZero);
		} else if (val.length === 1) {
			curValue = '';
		} else {
			return Error('Value length = 0, not implemented');
		}
	}
	return curValue;
}


export function STRING_FIELD(
	param: DynamicInputModelConfig, layout?: DynamicFormControlLayout
) {

	const input = DynamicInputAutocompleteModel({
		id: param.id,
		label: param.label,
		suffix: param.suffix,
		inputType: param.inputType,
		placeholder: param.placeholder,
		value: null,
		required: param.required,
		validators: param.validators,
		minLength: param.minLength,
		maxLength: param.maxLength,
		hidden: param.hidden,
		mask: param.mask,
		disabled: param.disabled,
		tabIndex: param.tabIndex ? param.tabIndex : 0,
		editableFor: param.editableFor,
		visibleFor: param.visibleFor,
		autoComplete: 'off',
		errorMessages: {
			required: 'Required',
			minLength: param.validators && param.validators.minLength ? 'Min Length is ' + param.validators.minLength : '',
			maxLength: param.validators && param.validators.maxLength ? 'Max Length is ' + param.validators.maxLength : '',
		}
	}, layout);
	return input;
}


export function CHECK_BOX({id, label, visibleFor, editableFor}: { id: string, label: string, visibleFor?: (keyof UserRole | string)[], editableFor?: (keyof UserRole | string)[] }) {
	return DynamicCheckboxModel({
		id: id,
		label: label,
		visibleFor,
		editableFor
	});
}

export function SEARCH_CHECK_BOX({id, label, labelPosition, visibleFor, editableFor}: { id: string, label: string, labelPosition: string, visibleFor?: (keyof UserRole | string)[], editableFor?: (keyof UserRole | string)[] }, layout?: DynamicFormControlLayout) {
	return DynamicCheckboxModel({
		id: id,
		label: label,
		labelPosition: labelPosition,
		visibleFor,
		editableFor
	}, layout);
}

export function RADIO_USER_STATE({id, label, options, layout, value, visibleFor, editableFor}: {
	id: any,
	label?: any,
	options: any,
	layout?: DynamicFormControlLayout,
	value?: string | boolean,
	visibleFor?: (keyof UserRole | string)[],
	editableFor?: (keyof UserRole | string)[]
}) {

	return DynamicRadioGroupModel<string | boolean>((<DynamicRadioGroupModelConfig<string | boolean>>{
		id: id,
		// legend: "Active Web User",
		editableFor: editableFor,
		visibleFor: visibleFor,
		label: label,
		required: true,
		options: options,
		validators: {
			required: null
		},
		errorMessages: {
			required: 'Required'
		},
		value: value,
	}), layout);
}

