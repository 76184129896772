<app-frame (neadClose)="closeMedicalTest()"
           [title]="title"
           [showCloseButton]="false"
           [class.no-frame]="true"
           autocomplete="off">
  <form class="form-horizontal" [class.is-half-page]="isEditingMode()" [formGroup]="formGroup" novalidate>
    <dynamic-material-form [group]="formGroup"
                           [layout]="formLayout"
                           [model]="formModel"
                           (change)="onChange($event)">
        <ng-template modelId="treatment" align="START">
            <label class="form-label form-label_full-width form-label_mb">
                What treatment does this doctor provide?
            </label>
        </ng-template>
        <ng-template modelId="showMedicalTests" align="START">
            <label class="form-label form-label_full-width form-label_mb">
<!--                Please provide the approximate treatment dates below. If you're not sure, just estimate the dates were treated for your disabling conditions.-->
<!--                The SSA will request these records.-->
                <ng-container *ngIf="!isHospital">
                    Did this Doctor order any Medical Tests, or refer you to another Doctor or Medical Facility for the purpose of performing any Medical Tests?
                </ng-container>
                <ng-container *ngIf="isHospital">
                    Were any Medical Tests performed during this Hospital Visit?
                </ng-container>
            </label>
        </ng-template>
        <ng-template *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" modelId="others"  let-context="context" let-index="index"  align="START">
            <ng-container>
                <app-form-actions-content class="custom-btn remove-btn auto-f-basis" *appCanAccess="PERMISSIONS.HIGHER_EXEC_LEVEL_4">
                    <form-button (clickOnButton)="removeOther(index)"
                                 [style.display]="isRemoveHidden() ? 'none' : 'inline-block'"
                                 style="padding-top: 1em"
                                 [buttonType]="getButtonType()"
                                 *appCanAccess="PERMISSIONS.LOWER_EXEC">
                        <span _ngcontent-snf-c45="" class="icon-clear material-icon material-icons ng-star-inserted">highlight_off</span>
                    </form-button>
                </app-form-actions-content>
            </ng-container>
            <ng-template *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" modelId="col1"  let-context="context" let-index="index"  align="END">
                <ng-container>
                    <app-form-actions-content class="add-btn auto-f-basis" *appCanAccess="PERMISSIONS.LOWER_EXEC">
                        <form-button (clickOnButton)="insertOther()">Add</form-button>
                    </app-form-actions-content>
                </ng-container>
            </ng-template>
        </ng-template>
    </dynamic-material-form>
  </form>
  <ng-container *ngIf="isCreationMode()">
	  <app-form-actions-content *appCanAccess="PERMISSIONS.LOWER_EXEC">
          <form-button style="margin-right: auto"
                       class="form-button-back"
                       *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()"
                       (clickOnButton)="backMedicalTest()">Back</form-button>
		  <form-button *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()"
                       [disabled]="formGroup && formGroup.controls.showMedicalTests.value === null"
                       (clickOnButton)="saveMedicalTest()">Save & Exit</form-button>
          <form-button *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()"
                       [disabled]="formGroup && formGroup.controls.showMedicalTests.value === null"
                       (clickOnButton)="nextMedicalTest()">Next</form-button>
	  </app-form-actions-content>
  </ng-container>
    <ng-container *ngIf="isEditingMode() && canEdit">
        <app-form-actions-content *appCanAccess="PERMISSIONS.LOWER_EXEC">
            <form-button *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" (clickOnButton)="saveMedicalTest()">Save</form-button>
        </app-form-actions-content>
    </ng-container>
    <ng-container *ngIf="isCreationVisitMode()">
<!--        <app-form-actions-content *appCanAccess="['EXECUTIVE_STAFF','CLIENT']">-->
<!--            <form-button *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" (clickOnButton)="saveMedicalTest()">Save</form-button>-->
<!--        </app-form-actions-content>-->
        <div class="next-btn">
            <button mat-button matStepperNext (click)="saveMedicalTest()">Save & Next</button>
        </div>
    </ng-container>
</app-frame>
