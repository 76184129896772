<div class="view-wrapper">
	<div  class="view-wrapper view-wrapper--no-mobile-padding" #report>
		<div #body class="visit-card">
			<div class="print-button no-print">
				<!-- <div class="form-buttons">
					<form-button (clickOnButton)="print()">Print</form-button>
				</div> -->
			</div>
		</div>
	</div>
</div>