import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class PhoneService {

	constructor() {

	}

	format(phoneNumberString) {
		var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return '(' + match[1] + ') ' + match[2] + '-' + match[3]
		}
		return null
	};

}
