import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import { GlobalSaveService } from "../../../@core/global-save.service";
import {HttpClient} from "@angular/common/http";
import {UserInfoService} from "../../../@core/user-info.service";
import {ReferralService} from "../../../@core/referral.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService} from "../../../../@util/dialog/dialog.service";
import {NotificationMenuComponent} from "../../notification/notification-menu.component";
import {AuditLogComponent} from "../../menu/menu-desktop/audit-log/audit-log.component";
import {GlobalSaveMissingFollowUpsComponent} from "../../../@view/global-save/global-save-missing-follow-ups/global-save-missing-follow-ups.component";
import {GlobalSaveFollowUpsPastDuesComponent} from "../../../@view/global-save/global-save-follow-ups-past-dues/global-save-follow-ups-past-dues.component";
import {GlobalSaveLoginFormErrorComponent} from "../../../@view/global-save/global-save-login-form-error/global-save-login-form-error.component";
import {MenuService} from "../../../@core/menu.service";
import {GlobalSaveErrorTableComponent} from "../global-save-error-table/global-save-error-table.component";
import {AuthenticationService} from '../../../auth/_services/authentication.service';

@Component({
	selector: 'app-global-save-cancel',
	templateUrl: './global-save.component.html',
	styleUrls: ['../global-save-msg-form.component.scss']
})
export class GlobalSaveComponent implements OnInit {
	title = 'StSr Message';

	//@ViewChild('aside', { static: false }) notificationMenuComponent: NotificationMenuComponent;

	globalSaveService;

	constructor(
		private http: HttpClient,
		private userInfoService: UserInfoService,
		public dialog: MatDialog,
		private dialogRef: MatDialogRef<GlobalSaveComponent>,
		private dialogRef1: MatDialogRef<NotificationMenuComponent>,
		private referralService: ReferralService,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private route: ActivatedRoute,
		private menuService: MenuService,
		private authenticationService:AuthenticationService,
		private dialogService: DialogService) {

	}

	ngOnInit() {
		this.globalSaveService = new GlobalSaveService(this.http,this.userInfoService,this.dialog);
	}

	closeVisitForm() {
		this.dialogRef.close();
	}

	checkTerminated(terminated){
		if(terminated != null){
			if(terminated.closedDate != null || terminated.terminatedDate != null){
				return true;
			}
		}
		return false;
	}
	confirm() {
		try {
			let sslgId = this.referralService.current.sslgId;
			this.referralService.getStatusAndFollowUpsPending(sslgId).subscribe((data:any) => {
				let followUps = data.followUp;
				let terminated = data.terminated;

				let followUpsCount = followUps.length;

				if(this.checkTerminated(terminated)){
					this.confirmSave(sslgId);
				}else if (this.authenticationService.roles.indexOf('EXECUTIVE_STAFF') === -1 &&
					this.authenticationService.roles.indexOf('SSLG_STAFF') === -1) {
					this.confirmSave(sslgId);
				}else if(followUpsCount < 1){
					this.dialogFollowUpsEmpty();
				}else{
					let status = true;
					let statusAnotherMan = true;
					for(let i=0;i<followUpsCount;i++){
						let daysBetweenCurrent = followUps[i].daysBetweenCurrent;
						let assignedToNickName = followUps[i].assignedToNickName;

						if(daysBetweenCurrent < 0 && assignedToNickName == this.userInfoService.getCurrentUserName()){
							status = false;

							break;
						}

						if(statusAnotherMan && daysBetweenCurrent < 0
							&& assignedToNickName != this.userInfoService.getCurrentUserName()){
							statusAnotherMan = false;
						}
					}

					if(status){
						if(statusAnotherMan){
							this.confirmSave(sslgId);
						}else{
							//this.dialogRef.close({status: false});
							this.dialog.open(GlobalSaveMissingFollowUpsComponent,
								{
									data:{
										sslgId:sslgId
									}
								})
								.afterClosed().subscribe(j => {
								try {
									if(j.status){
										this.confirmSave(sslgId);
									}
								} catch (e) {
									this.dialogRef.close({status: false});
								}
							});
						}
					}else{
						this.dialog.open(GlobalSaveFollowUpsPastDuesComponent,
							{
								data:{
									sslgId:sslgId
								}
							})
							.afterClosed().subscribe(j => {
								this.dialogRef.close({status: false});
							// try {
							// 	// if(j.status){
							// 	// 	this.confirmSave(sslgId);
							// 	// }
							// } catch (e) {
							// 	this.dialogRef.close({status: false});
							// }
						});
						//this.dialogRef.close({status: false});
						//this.dialogService.show('You have past dues follow-ups. Please address them before we enable the Save functionality.', ['OK']);
					}
				}
			});
		} catch (e) {
			this.dialogRef.close({status: false});
			this.dialogService.show('Not determined Claimant Id', ['OK']);
		}
	}

	confirmSave(sslgId){
		this.globalSaveService.confirmSave().subscribe(data => {
			if(data.status == "true") {
				this.dialogRef.close({status: true});

				const dialogRef = this.dialog.open(AuditLogComponent, this.menuService.getAuditLogConfig());
				dialogRef.afterClosed().subscribe(result => {
					if (result) {

					}
				});

				this.globalSaveService.setStatus(this.globalSaveService.statusNotActive);

				this.dialogService.show(data.message, ["ok"]);
			}else{
				this.dialogRef.close({status: false});

				try {
					let errorList = data.error_list;
					if(errorList != ''){
						try {
							let result = JSON.parse(errorList);

							this.dialog.open(GlobalSaveErrorTableComponent,
								{
									panelClass:"overlay_table",
									data:{
										errorList:result
									}
								})
								.afterClosed().subscribe(j => {
								//close
							});
						} catch (e) {
							this.dialogService.show(data.message, ["ok"]);
						}
					}else{
						this.dialogService.show(data.message, ["ok"]);
					}
				}catch (e) {
					this.dialogService.show(data.message, ["ok"]);
				}
			}
		});
	}

	dialogFollowUpsEmpty(){
		this.dialogRef.close({status: false});
		this.dialogService.show('Add at least one follow up', ['OK']);
	}
}
