import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {HttpClient} from "@angular/common/http";
import {UserInfoService} from "../../../@core/user-info.service";
import {ReferralService} from "../../../@core/referral.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DialogService} from "../../../../@util/dialog/dialog.service";
import {NotificationMenuComponent} from "../../notification/notification-menu.component";
import {MenuService} from "../../../@core/menu.service";
import {FORMS} from "../../../@config/forms";

@Component({
	selector: 'app-global-save-error-table',
	templateUrl: './global-save-error-table.component.html',
	styleUrls: ['../global-save-msg-form.component.scss']
})
export class GlobalSaveErrorTableComponent implements OnInit {
	title = 'StSr Message';

	FORMS = FORMS;

	constructor(
		private http: HttpClient,
		private userInfoService: UserInfoService,
		public dialog: MatDialog,
		private dialogRef: MatDialogRef<GlobalSaveErrorTableComponent>,
		private dialogRef1: MatDialogRef<NotificationMenuComponent>,
		private referralService: ReferralService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private route: ActivatedRoute,
		private menuService: MenuService,
		private router: Router,
		private dialogService: DialogService) {

	}

	ngOnInit() {

	}

	closeVisitForm() {
		this.dialogRef.close();
	}

	getFolder(formShortName){
		var name = '';
		for(let form in FORMS){
			if(FORMS[form].key == formShortName){
				name = FORMS[form].folder;

				break;
			}
		}

		return name;
	}

	getTab(formShortName){
		var name = '';
		for(let form in FORMS){
			if(FORMS[form].key == formShortName){
				name = FORMS[form].tab;

				break;
			}
		}

		return name;
	}

	getLink(formShortName,claimantUserName){
		var name = '';
		for(let form in FORMS){
			if(FORMS[form].key == formShortName){
				name = FORMS[form].link;
				name = name.replace('{claimantUserName}',claimantUserName);

				break;
			}
		}

		return name;
	}

	link(data){
		let link = this.getLink(data.formShortName,data.claimantUserName);

		this.closeVisitForm();
		this.router.navigate([link]);
	}
}
