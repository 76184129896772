import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DynamicFormService, } from '@ng-dynamic-forms/core';
import { ADDRESS, CYTY, EXT, PHONE, PRESS, STATE_SELECT, STRING_FIELD, ZIP } from '../../../../../../../@util/dynamic-form/dynamic-controlls';
import { MedicalService } from '../../../../../../@core/medical.service';
import { FormValueBuilder } from '../../../../../partial/forms/form-tamplate';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { CatalogItemType } from '../../../../../../@models/medical/catalog-item-view';
import { AddressService } from "../../../../../../@core/address.service";
import { State } from "../../../../../../test-new-stepper-form/test-form/on-changes";
import { Functions } from "../../../../../../../@util/functions";
import { DynamicFormGroupModel } from "../../../../../../../@util/ui-material/form-controls";
import { UserInfoService } from "../../../../../../@core/user-info.service";
import { MedicalRequestsComponent } from "../../medical-requests/medical-requests.component";
import { MedicalPaymentsComponent } from "../../medical-payments/medical-payments.component";
import { MatAutocompleteTrigger, MatDialogRef } from "@angular/material";
import { DialogService } from "../../../../../../../@util/dialog/dialog.service";
import { DateService } from "../../../../../../@core/functions/date.service";
import { MedicalSourcesService } from "../../../../../../@core/medical-sources.service";
import { MEDICAL_TESTS_MODE } from "../../../../../partial/partial-medical-tests/partial-medical-tests.config";
const creationSteps = {
    'initial': 1,
    'visit': 2,
    'visitNotes': 3,
    'medicalTests': 4,
    'medications': 5,
    'finished': 6,
    addAnotherVisitStepOne: 7,
    addAnotherVisitStepTwo: 8,
    addAnotherVisitStepTests: 9,
    addAnotherVisitStepMedications: 10
};
export class HospitalFormNewComponent {
    constructor(medicalService, formService, addressService, userInfoService, dateService, dialog, medicalSourcesService, dialogRef, componentData, route, router) {
        this.medicalService = medicalService;
        this.formService = formService;
        this.addressService = addressService;
        this.userInfoService = userInfoService;
        this.dateService = dateService;
        this.dialog = dialog;
        this.medicalSourcesService = medicalSourcesService;
        this.dialogRef = dialogRef;
        this.componentData = componentData;
        this.route = route;
        this.router = router;
        this.updateHospitalsList = new EventEmitter();
        this.title = 'New Hospital';
        this.MEDICAL_TESTS_MODE = MEDICAL_TESTS_MODE;
        this.formLayout = {
            phones: {
                element: {
                    container: 'row-mb',
                    control: 'form-row',
                }
            },
            contact_phone: {
                element: {
                    host: 'form-element'
                }
            },
            contact_press: {
                element: {
                    host: 'form-element'
                }
            },
            contact_ext: {
                element: {
                    host: 'form-element'
                }
            },
            contact_fax: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address2: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_cyti: {
                element: {
                    container: 'row-mb'
                }
            },
            state_zip: {
                element: {
                    control: 'form-row',
                    container: 'row-mb'
                }
            },
            contact_state: {
                element: {
                    host: 'form-element'
                }
            },
            contact_zip: {
                element: {
                    host: 'form-element label-accent'
                }
            }
        };
        this.formTemplate = {
            name: STRING_FIELD({ id: 'name', label: 'Name', hidden: true }),
            // recipient: {
            // 	recipientName: STRING_FIELD({
            // 		id: 'recipientName',
            // 		label: 'Person To Contact',
            // 		maxLength: 128,
            // 		required: true,
            // 		validators: {required: null}
            // 	}),
            // },
            contact: {
                phone: PHONE({ id: 'contact_phone', label: 'Phone' }),
                press: PRESS({ id: 'contact_press', label: '"Press"' }),
                ext: EXT({ id: 'contact_ext', label: 'Ext.' }),
                fax: PHONE({ id: 'contact_fax', label: 'Business Fax' }),
                street: ADDRESS({ id: 'contact_address' }),
                street2: ADDRESS({ id: 'contact_address2', label: 'Address 2' }),
                city: CYTY({ id: 'contact_cyti' }),
                state: STATE_SELECT({ id: 'contact_state', disabled: true }),
                zip: ZIP({ id: 'contact_zip' }),
            }
        };
        this.form = {
            name: this.formTemplate.name,
            // recipientName: this.formTemplate.recipient.recipientName,
            contact: DynamicFormGroupModel({
                id: 'contact',
                group: [
                    DynamicFormGroupModel({
                        id: 'phones',
                        group: [
                            this.formTemplate.contact.phone,
                            this.formTemplate.contact.press,
                            this.formTemplate.contact.ext
                        ]
                    }),
                    this.formTemplate.contact.fax,
                    DynamicFormGroupModel({
                        id: 'state_zip',
                        group: [
                            this.formTemplate.contact.zip,
                            this.formTemplate.contact.state,
                        ]
                    }),
                    this.formTemplate.contact.city,
                    this.formTemplate.contact.street,
                    this.formTemplate.contact.street2,
                ]
            }),
        };
        this.subscribed = true;
        this.state = State.before;
        this.allFormState = State.before;
        this.tabNavigationActive = 0;
        this.typeAskingStep = creationSteps.initial;
        this.resetVisits = false;
        this.creationSteps = creationSteps;
        this.createdHospitalName = '';
        this.nameCtrl = new FormControl();
        this.nameCtrlId = null;
        this.names = [];
        this.formModel = FormValueBuilder.buildFormModel(this.form);
        this.formGroup = this.formService.createFormGroup(this.formModel);
        this.nameCtrl.setValidators(Validators.required);
        //this.sub = navService.back().subscribe(j => this.closeDoctorForm());
        router.events.subscribe((val) => {
            if (val) {
                document.querySelectorAll('.mat-drawer-content').forEach(el => {
                    el.style.overflow = 'scroll';
                });
                this.dialogRef.close({});
            }
        });
    }
    onMousewheel(event) {
        const input = document.querySelector('.mat-input-element');
        input.blur();
        this.trigger.closePanel();
    }
    ngOnDestroy() {
        //this.sub.unsubscribe();
    }
    _filterNames(value) {
        ////console.log(value);
        const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name ? value.name.toLowerCase() : '';
        ////console.log(filterValue);
        return this.names.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
    }
    displayFn(item) {
        return item ? item.name : undefined;
    }
    ngAfterViewInit() {
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.sslgId = this.getSslgId();
            this.names = yield this.medicalService.getCatalog(CatalogItemType.HOSPITAL).toPromise();
            this.filteredNames = this.nameCtrl.valueChanges
                .pipe(map(value => {
                this.hospital = null;
                this.formTemplate.name.valueUpdates.next(this.nameCtrl.value + '');
                if (value.id) {
                    this.subscription.unsubscribe();
                    if (value.id != -1) {
                        this.formGroup.disable({ onlySelf: true });
                        setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            this.hospital = yield this.medicalService.getCatalogItem(CatalogItemType.HOSPITAL, value.id).toPromise();
                            yield this.setFormData();
                        }));
                    }
                    else {
                        this.addressService.getPlaceInfo(value.placeId).subscribe(j => {
                            this.formTemplate.name.autocompleteSelectedValue = null;
                            if (j.zip)
                                this.formTemplate.contact.zip.valueUpdates.next(j.zip);
                            if (j.state)
                                this.formTemplate.contact.state.valueUpdates.next(j.state);
                            if (j.city)
                                this.formTemplate.contact.city.valueUpdates.next(j.city);
                            if (j.locations)
                                this.formTemplate.contact.street.valueUpdates.next((j.streetNumber ? j.streetNumber + ' ' : '') + (j.locations ? j.locations : ''));
                        });
                    }
                }
                else {
                    if (!this.subscribed) {
                        this.medicalService.setMedicalConditionsTemplate([]);
                        // this.subscribeZipCode();
                    }
                    this.formGroup.enable({ onlySelf: true });
                    this.formTemplate.contact.state.disabledUpdates.next(true);
                    setTimeout(() => {
                        this.formGroup.reset();
                        this.subscribeZipCode();
                    });
                }
                return value;
            }), startWith(''), map((state) => {
                this.onStateChanges();
                if (state) {
                    let names = this._filterNames(state);
                    // if (typeof state === 'string' && state.length > 3 || state.name) {
                    // 	let query = typeof state === 'string' ? state : state.name;
                    // 	this.addressService.findPlace(query, "HOSPITAL").pipe(map((place: any) => {
                    // 		return place.map(j=>{
                    // 			return {
                    // 				id: -1,
                    // 				name: j.title,
                    // 				label: j.description,
                    // 				...j
                    // 			};
                    // 		})
                    // 	})).subscribe(j => {
                    // 		names.push(...j);
                    // 		//console.log(this.filteredNames)
                    // 	});
                    // }
                    return names;
                }
                return this.names.slice();
            }));
            this.subscribeZipCode();
            this.oldFormData = FormValueBuilder.getData(this.form);
            this.oldSourceNameData = this.nameCtrl.value ? this.nameCtrl.value : '';
        });
    }
    getSslgId() {
        return this.componentData.sslgId;
    }
    subscribeZipCode() {
        this.subscription = this.formTemplate.contact.zip.valueUpdates.subscribe((j) => {
            if (j && j.length == 5) {
                this.addressService.findAddress(j).subscribe(addr => {
                    if (addr && addr.state) {
                        this.formTemplate.contact.state.valueUpdates.next(addr.state);
                        this.formTemplate.contact.city.valueUpdates.next(addr.city);
                    }
                });
            }
            this.subscribed = true;
        });
    }
    showName() {
        return true;
    }
    setFormData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            setTimeout(() => {
                if (this.hospital !== null) {
                    try {
                        let street = this.hospital.contact.street;
                        this.hospital.contact.street = street.replace(/,/g, '');
                    }
                    catch (e) {
                    }
                    FormValueBuilder.setData(this.hospital, this.formTemplate);
                }
            });
        });
    }
    onBlur($event) {
    }
    onChange($event) {
    }
    onFocus($event) {
    }
    // closeDoctorForm() {
    // 	this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
    // 		{queryParams: {page: this.route.snapshot.queryParams['page']}});
    // 	// this.dialogRef.close();
    // }
    saveHospital() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            FormValueBuilder.setControlsToched(this.formGroup);
            if (this.nameCtrl.value) {
                const formTemplateCopy = Object.assign({}, this.formTemplate);
                formTemplateCopy.name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
                const data = FormValueBuilder.getData(formTemplateCopy);
                if (this.nameCtrl && this.nameCtrl.value && this.nameCtrl.value.id) {
                    data.id = this.nameCtrl.value.id;
                }
                data.name = formTemplateCopy.name;
                // this.formTemplate.name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
                // const data = FormValueBuilder.getData(this.formTemplate);
                data.sslgId = this.sslgId;
                const catalogItemId = this.hospitalVisitData ? this.hospitalVisitData.catalogItemId : null;
                const eRVisit = this.hospitalVisitData ? this.hospitalVisitData.eRVisit : null;
                const eRVisitDate = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.eRVisitDate) : null;
                const outPatient = this.hospitalVisitData ? this.hospitalVisitData.outPatient : null;
                const outPatientDate = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.outPatientDate) : null;
                const inPatient = this.hospitalVisitData ? this.hospitalVisitData.inPatient : null;
                const inPatientAdmission = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.inPatientAdmission) : null;
                const inPatientDischarge = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.inPatientDischarge) : null;
                const hospitalizationReason = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.hospitalizationReason : null;
                const treatmentDetails = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.treatmentDetails : null;
                const conditions = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.medicalConditions : null;
                const treatedDoctor = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.treatedDoctor : null;
                const treatment = this.medicalTestsData ? this.medicalTestsData.treatment : null;
                const medicalTests = this.medicalTestsData ? this.medicalTestsData.showMedicalTests : null;
                const medicalTestsList = this.medicalTestsData ? this.medicalTestsData.medicalTestsList : null;
                const medications = this.medicationsData ? this.medicationsData.showMedications : null;
                const medicationsList = this.medicationsData ? this.medicationsData.medications : null;
                // this.resetVisits = true;
                // this.hospitalNewVisitId = 70773;
                // this.dialog.show("THANK YOU! You have successfully added this treating source to status star", ["ok"]);
                // this.nameCtrl.setValue({});
                // // this.hospital = null;
                // this.formGroup.reset();
                // setTimeout(() => {
                // 	this.resetVisits = false;
                // });
                // setTimeout(() => {
                // 	this.updateHospitalsList.next(Math.random());
                // 	this.changeTypeAskingStep(this.creationSteps.finished);
                // });
                const hospital = yield this.medicalSourcesService.addTreatingSourcesHospital(this.sslgId, data, eRVisit, eRVisitDate, outPatient, outPatientDate, inPatient, inPatientAdmission, inPatientDischarge, hospitalizationReason, treatmentDetails, conditions, treatedDoctor, medications, medicationsList, treatment, medicalTests, medicalTestsList);
                this.createdHospitalName = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
                this.hospitalNewVisitId = hospital.id;
                this.resetVisits = true;
                this.dialog.show("THANK YOU! You have successfully added this treating source to status star", ["OK"]);
                this.nameCtrl.setValue({});
                this.hospital = null;
                this.formGroup.reset();
                setTimeout(() => {
                    this.resetVisits = false;
                });
                setTimeout(() => {
                    this.updateHospitalsList.next(Math.random());
                    this.changeTypeAskingStep(this.creationSteps.finished);
                });
            }
        });
    }
    onStateChanges() {
        const dataNew = FormValueBuilder.getData(this.form);
        const sourceNew = this.nameCtrl.value ? this.nameCtrl.value : '';
        if (this.oldFormData != '' && this.oldSourceNameData != '') {
            const isChanges = Functions.equals(this.oldFormData, dataNew);
            const isChangesSource = Functions.equals(this.oldSourceNameData, sourceNew);
            if (!isChanges || !isChangesSource) {
                this.state = State.after;
            }
            else {
                this.state = State.before;
            }
        }
    }
    isInputDirty() {
        return this.state == State.after;
    }
    addVisit() {
        // this.saveDoctor('addVisit');
    }
    isClaimant() {
        return this.userInfoService.isClaimant();
    }
    canAccess() {
        if (this.userInfoService.isClaimant() || this.userInfoService.isSslgStaff() || this.userInfoService.isStaff()) {
            return true;
        }
        return false;
    }
    cancel() {
        document.querySelectorAll('.mat-drawer-content').forEach(el => {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close(false);
    }
    saveVisit(e) {
    }
    closeForm(e) {
    }
    onFormStateChanged($event) {
    }
    onAllFormStateChanged($event) {
    }
    changeTypeAskingStep(step) {
        this.typeAskingStep = step;
        let name;
        if (this.nameCtrl && this.nameCtrl.value) {
            name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
        }
        if (this.isInitialStep()) {
            this.title = `New Hospital`;
        }
        if (this.isVisitStep()) {
            this.title = `Hospital Visit <span>${name}</span>`;
        }
        if (this.isVisitNotesStep()) {
            this.title = `Visit Notes <span>${name}</span>`;
        }
        if (this.isMedicalTestsStep()) {
            this.title = `Medical Tests <span>${name}</span>`;
        }
        if (this.isMedicationsStep()) {
            this.title = `Medications <span>${name}</span>`;
        }
        if (this.isFinishedStep()) {
            this.title = `What would you like to do next?`;
        }
        if (this.isAddAnotherVisitStepOne()) {
            this.title = `Hospital Visit <span>${this.createdHospitalName}</span>`;
        }
        if (this.isAddAnotherVisitStepTwo()) {
            this.title = `Visit Notes <span>${this.createdHospitalName}</span>`;
        }
        if (this.isAddAnotherVisitStepTests()) {
            this.title = `Medical Tests <span>${this.createdHospitalName}</span>`;
        }
        if (this.isAddAnotherVisitStepMedications()) {
            this.title = `Medications <span>${this.createdHospitalName}</span>`;
        }
    }
    isInitialStep() {
        return this.typeAskingStep === creationSteps.initial;
    }
    isVisitStep() {
        return this.typeAskingStep === creationSteps.visit;
    }
    isVisitNotesStep() {
        return this.typeAskingStep === creationSteps.visitNotes;
    }
    isMedicalTestsStep() {
        return this.typeAskingStep === creationSteps.medicalTests;
    }
    isMedicationsStep() {
        return this.typeAskingStep === creationSteps.medications;
    }
    isFinishedStep() {
        return this.typeAskingStep === creationSteps.finished;
    }
    isAddAnotherVisitStepOne() {
        return this.typeAskingStep === creationSteps.addAnotherVisitStepOne;
    }
    isAddAnotherVisitStepTwo() {
        return this.typeAskingStep === creationSteps.addAnotherVisitStepTwo;
    }
    isAddAnotherVisitStepTests() {
        return this.typeAskingStep === creationSteps.addAnotherVisitStepTests;
    }
    isAddAnotherVisitStepMedications() {
        return this.typeAskingStep === creationSteps.addAnotherVisitStepMedications;
    }
    nextVisit($event) {
        this.hospitalVisitData = $event;
        if (this.isAddAnotherVisitStepOne()) {
            this.changeTypeAskingStep(creationSteps.addAnotherVisitStepTwo);
        }
        else {
            this.changeTypeAskingStep(creationSteps.visitNotes);
        }
    }
    backVisit() {
        this.changeTypeAskingStep(creationSteps.initial);
    }
    backVisitNotes() {
        if (this.isAddAnotherVisitStepTwo()) {
            this.changeTypeAskingStep(creationSteps.addAnotherVisitStepOne);
        }
        else {
            this.changeTypeAskingStep(creationSteps.visit);
        }
    }
    nextVisitNotes($event) {
        this.hospitalsVisitNotesData = $event;
        if (this.isAddAnotherVisitStepTwo()) {
            this.changeTypeAskingStep(creationSteps.addAnotherVisitStepTests);
        }
        else {
            this.changeTypeAskingStep(creationSteps.medicalTests);
        }
    }
    saveMedication($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.medicationsData = $event;
            if (this.isAddAnotherVisitStepMedications()) {
                yield this.saveHospitalVisit();
            }
            else {
                yield this.saveHospital();
            }
        });
    }
    backMedication($event) {
        this.medicationsData = $event;
        // this.changeTypeAskingStep(creationSteps.medicalTests);
        if (this.isAddAnotherVisitStepMedications()) {
            this.changeTypeAskingStep(creationSteps.addAnotherVisitStepTests);
        }
        else {
            this.changeTypeAskingStep(creationSteps.medicalTests);
        }
    }
    nextMedicalTest($event) {
        this.medicalTestsData = $event;
        if (this.isAddAnotherVisitStepTests()) {
            this.changeTypeAskingStep(creationSteps.addAnotherVisitStepMedications);
        }
        else {
            this.changeTypeAskingStep(creationSteps.medications);
        }
    }
    saveMedicalTest($event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.medicalTestsData = $event;
            if (this.isAddAnotherVisitStepTests()) {
                yield this.saveHospitalVisit();
            }
            else {
                yield this.saveHospital();
            }
        });
    }
    backMedicalTest($event) {
        this.medicalTestsData = $event;
        // this.changeTypeAskingStep(creationSteps.visitNotes);
        if (this.isAddAnotherVisitStepTests()) {
            this.changeTypeAskingStep(creationSteps.addAnotherVisitStepTwo);
        }
        else {
            this.changeTypeAskingStep(creationSteps.visitNotes);
        }
    }
    // saveVisitData($event) {
    // 	this.hospitalVisitData = $event;
    // }
    saveHospitalVisit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const catalogItemId = this.hospitalNewVisitId;
            const eRVisit = this.hospitalVisitData ? this.hospitalVisitData.eRVisit : null;
            const eRVisitDate = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.eRVisitDate) : null;
            const outPatient = this.hospitalVisitData ? this.hospitalVisitData.outPatient : null;
            const outPatientDate = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.outPatientDate) : null;
            const inPatient = this.hospitalVisitData ? this.hospitalVisitData.inPatient : null;
            const inPatientAdmission = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.inPatientAdmission) : null;
            const inPatientDischarge = this.hospitalVisitData ? this.dateService.getDateWithoutHours(this.hospitalVisitData.inPatientDischarge) : null;
            const hospitalizationReason = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.hospitalizationReason : null;
            const treatmentDetails = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.treatmentDetails : null;
            const conditions = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.medicalConditions : null;
            const treatedDoctor = this.hospitalsVisitNotesData ? this.hospitalsVisitNotesData.treatedDoctor : null;
            const treatment = this.medicalTestsData ? this.medicalTestsData.treatment : null;
            const medicalTests = this.medicalTestsData ? this.medicalTestsData.showMedicalTests : null;
            const medicalTestsList = this.medicalTestsData ? this.medicalTestsData.medicalTestsList : null;
            const medications = this.medicationsData ? this.medicationsData.showMedications : null;
            const medicationsList = this.medicationsData ? this.medicationsData.medications : null;
            yield this.medicalSourcesService.addHospitalVisit(this.sslgId, catalogItemId, eRVisit, eRVisitDate, outPatient, outPatientDate, inPatient, inPatientAdmission, inPatientDischarge, hospitalizationReason, treatmentDetails, conditions, treatedDoctor, medications, medicationsList, treatment, medicalTests, medicalTestsList);
            this.resetVisits = true;
            this.dialog.show("THANK YOU! You have successfully added another Visit to the Hospital", ["OK"]);
            setTimeout(() => {
                this.resetVisits = false;
            });
            setTimeout(() => {
                this.changeTypeAskingStep(this.creationSteps.finished);
            });
        });
    }
    addNewHospital() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.names = yield this.medicalService.getCatalog(CatalogItemType.HOSPITAL).toPromise();
            this.formGroup.reset();
            this.nameCtrl.setValue({});
            this.hospital = null;
            this.changeTypeAskingStep(creationSteps.initial);
        });
    }
    addHospitalization() {
        document.querySelectorAll('.mat-drawer-content').forEach(el => {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close({});
        this.addDoctor();
    }
    addDoctor() {
        this.router.navigate(['/claimants/medical-treating-sources/' + this.sslgId + '/doctors'], {
            queryParams: {
                returnUrl: this.route.snapshot.queryParams['returnUrl'],
                popup: 'new',
                from: 'hospitals'
            }
        });
    }
    addAHospitalVisit() {
        this.changeTypeAskingStep(this.creationSteps.addAnotherVisitStepOne);
    }
    addOtherTreatingSource() {
        document.querySelectorAll('.mat-drawer-content').forEach(el => {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close({});
        this.router.navigate(['/claimants/medical-treating-sources/' + this.sslgId + '/others'], {
            queryParams: {
                returnUrl: this.route.snapshot.queryParams['returnUrl'],
                popup: 'new'
            }
        });
    }
    returnToMyProfile() {
        document.querySelectorAll('.mat-drawer-content').forEach(el => {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close({});
    }
}
