import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {
    DynamicFormControlCustomEvent,
    DynamicFormLayout,
    DynamicFormLayoutService, DynamicFormValidationService, DynamicInputModel
} from "@ng-dynamic-forms/core";
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocomplete, MatAutocompleteDefaultOptions } from "@angular/material/autocomplete";
import { ErrorStateMatcher, LabelOptions, MAT_LABEL_GLOBAL_OPTIONS, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from "@angular/material/core";
import { MatInput } from "@angular/material/input";
import {DynamicMaterialFormInputControlComponent} from "../dynamic-material-form-input-control.component";
import {DynamicMoneyInputModel} from "./dynamic-money-input-model";
import {group} from "@angular/animations";

@Component({
    selector: 'app-dynamic-money',
    templateUrl: './dynamic-money.component.html',
    styleUrls: ['./dynamic-money.component.css']
})
export class DynamicMoneyComponent extends DynamicMaterialFormInputControlComponent implements OnInit {

    @Input() group: FormGroup;
    @Input() layout: DynamicFormLayout;
    @Input() model: DynamicMoneyInputModel;

    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
    @Output() focus: EventEmitter<any> = new EventEmitter();

    @ViewChild("matAutocomplete", { static: true }) matAutocomplete: MatAutocomplete;
    @ViewChild(MatInput, { static: true }) matInput: MatInput;

    constructor(protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService,
                @Inject(ErrorStateMatcher) public errorStateMatcher: ErrorStateMatcher,
                @Inject(MAT_AUTOCOMPLETE_DEFAULT_OPTIONS) public AUTOCOMPLETE_OPTIONS: MatAutocompleteDefaultOptions,
                @Inject(MAT_LABEL_GLOBAL_OPTIONS) @Optional() public LABEL_OPTIONS: LabelOptions,
                @Inject(MAT_RIPPLE_GLOBAL_OPTIONS) @Optional() public RIPPLE_OPTIONS: RippleGlobalOptions) {
        super(layoutService, validationService);

    }


    onFocus(value) {
        setTimeout(() => this.matInput.value = this.model.parse(this.matInput.value));

        super.onFocus(value);
    }

    onBlur(value) {
        setTimeout(() => this.matInput.value = this.model.transform(this.matInput.value));

        super.onBlur(value);
    }

    ngOnInit(): void {
        this.model.inputElement = this;
    }
}
