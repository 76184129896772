export class DynamicTableModel<T> {
	column_name: string;
	column_key: keyof T | "button" | string | any;
	column_type?: "button" | "text-row" | 'phone' | string | any;
	column_selectValues?: any;
	displayTitleFn?: Function;
	displayFn?: Function;
	column_btn_title?: string;
	column_classes?: (iTableClassesOptions | string)[];
}

export enum iTableClassesOptions {

	//width in percents
	widthAuto = 'w-size-auto', // it's mean width:auto; flex: inherit
	width1 = 'w-size-1',
	width2 = 'w-size-2',
	width3 = 'w-size-3',
	width4 = 'w-size-4',
	width5 = 'w-size-5',
	width6 = 'w-size-6',
	width7 = 'w-size-7',
	width8 = 'w-size-8',
	width9 = 'w-size-9',
	width10 = 'w-size-10',
	width11 = 'w-size-11',
	width12 = 'w-size-12',
	width13 = 'w-size-13',
	width14 = 'w-size-14',
	width15 = 'w-size-15',
	width16 = 'w-size-16',
	width17 = 'w-size-17',
	width18 = 'w-size-18',
	width19 = 'w-size-19',
	width20 = 'w-size-20',
	width21 = 'w-size-21',
	width22 = 'w-size-22',
	width23 = 'w-size-23',
	width24 = 'w-size-24',
	width25 = 'w-size-25',
	width26 = 'w-size-26',
	width27 = 'w-size-27',
	width28 = 'w-size-28',
	width29 = 'w-size-29',
	width30 = 'w-size-30',
	width31 = 'w-size-31',
	width32 = 'w-size-32',
	width33 = 'w-size-33',
	width34 = 'w-size-34',
	width35 = 'w-size-35',
	width36 = 'w-size-36',
	width37 = 'w-size-37',
	width38 = 'w-size-38',
	width39 = 'w-size-39',
	width40 = 'w-size-40',
	width41 = 'w-size-41',
	width42 = 'w-size-42',
	width43 = 'w-size-43',
	width44 = 'w-size-44',
	width45 = 'w-size-45',
	width46 = 'w-size-46',
	width47 = 'w-size-47',
	width48 = 'w-size-48',
	width49 = 'w-size-49',
	width50 = 'w-size-50',

	width100 = 'w-size-100',
	"c-pointer" = 'c-pointer',
}
