import {Component, Injectable, OnInit} from '@angular/core';
import {PageLoaderService} from "../../../@core/page-loader.service";

@Component({
	selector: 'app-page-loader',
	templateUrl: './page-loader.component.html',
	styleUrls: ['./page-loader.component.scss']
})

export class PageLoaderComponent implements OnInit {
	show = true;

	constructor(private pageLoaderService: PageLoaderService) {
		this.pageLoaderService.loaderShow.subscribe((value: any) => this.loaderChanged(value));
	}

	ngOnInit() {

	}
	loaderChanged(value) {
		this.show = value;
	}

}
