import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/@util/loading/loading.service';

@Component({
  selector: 'app-claimant-print-record-page',
  templateUrl: './claimant-print-record-page.component.html',
  styleUrls: ['./claimant-print-record-page.component.scss']
})
export class ClaimantPrintRecordPageComponent implements OnInit {

  constructor(private loading: LoadingService) { }

  ngOnInit() {
    this.loading.loaded();
  }

}
