import {Component, EventEmitter, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {SearchService} from "../../../@core/search.service";
import {ReferralService} from "../../../@core/referral.service";
import { UserInfoService } from 'src/app/@core/user-info.service';
import { PERMISSIONS } from 'src/app/@config/permissions';

@Component({
	selector: 'app-search-bar',
	templateUrl: './search-bar.component.html',
	styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {
	collapsed: boolean;
	toolbarColor: string;
	@Output() onInput: EventEmitter<any> = new EventEmitter();
	onConfigChanged: Subscription;
	value: string = '';

	includeArchived = false;

	searchTypes = [
		{
			title: 'Claimants',
			value: 'claimants'
		},
		{
			title: 'E-mail',
			value: 'email',
		},
		{
			title: 'Telephone',
			value: 'telephone',
		},
		{
			title: 'ALJ',
			value: 'alj',
		},
		{
			title: 'ZIP Code',
			value: 'zipCode',
		},
		{
			title: 'City/State',
			value: 'city',
		}
	];
	selectedSearchType = this.searchTypes[0].value;

	constructor(private searchService: SearchService,
				private referralService: ReferralService,
				private userInfoService: UserInfoService) {
		this.searchService.clear.subscribe(j => {
			this.value = '';
		});
		const currentRole = this.userInfoService.getCurrentUserRole();
		if(!PERMISSIONS.HIGHER_EXEC_LEVEL_2.includes(currentRole)){
			this.searchTypes.splice(3, 1);
		}
		
		this.collapsed = true;
	}

	collapse() {
		this.collapsed = true;
	}

	expand() {
		this.collapsed = false;
	}

	search(event) {
		const value = event.target.value;
		if (value && value.trim().length) {
			//this.onInput.emit(value);
			this.searchService.searching({search:value,includeArchived:this.includeArchived,type:this.selectedSearchType});
		}
	}

	searchData(){
		if (!this.value) {
			return;
		}
		var $this = this;
		setTimeout(function(){
			$this.searchService.searching({search:$this.value, includeArchived:$this.includeArchived, type:$this.selectedSearchType});
		},0);
	}

}
