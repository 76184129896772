import * as i0 from "@angular/core";
export class DataSortService {
    constructor() {
    }
    sortAlphabeticallyByKey(keyName, additionKey) {
        return function (a, b) {
            if (a[keyName] > b[keyName]) {
                return 1;
            }
            if (b[keyName] > a[keyName]) {
                return -1;
            }
            if (additionKey) {
                if (a[additionKey] > b[additionKey]) {
                    return 1;
                }
                if (b[additionKey] > a[additionKey]) {
                    return -1;
                }
                return 0;
            }
            return 0;
        };
    }
    alphabeticalCompareSelectFunc(a, b) {
        if (a.label.toLowerCase() < b.label.toLowerCase())
            return -1;
        if (a.label.toLowerCase() > b.label.toLowerCase())
            return 1;
        return 0;
    }
}
DataSortService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataSortService_Factory() { return new DataSortService(); }, token: DataSortService, providedIn: "root" });
