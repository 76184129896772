import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {OnStateChanges, State} from "../test-new-stepper-form/test-form/on-changes";
import {FormChangesAttentionComponent} from "../../@util/form-changes-attention/form-changes-attention.component";
import {MatDialog} from "@angular/material";
import {map} from "rxjs/operators";
import {AuditLogComponent} from "../@view/menu/menu-desktop/audit-log/audit-log.component";

@Injectable({providedIn: 'root'})
export class CanDeactivateService<T extends OnStateChanges> implements CanDeactivate<T> {
	constructor(public dialog: MatDialog) {}
	canDeactivate(
		component: T,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		//console.log('canDeactivate');

		if (component.state == State.before) {
			if (component.allFormState == State.after) {
				// const dialogRef = this.dialog.open(AuditLogComponent, {
				// 	panelClass: ['mobile-popup', 'with-shadow'],
				// 	backdropClass: 'mobile-popup__overlay',
				// 	position: {top: '8%', left: '18%'},
				// 	disableClose: true
				// });
				//
				// if (currentRoute.data.changes) {
				// 	return true
				// } else {
				// 	return dialogRef.afterClosed().pipe(map(result => {
				// 		return true;
				// 	}));
				// }

				return  true;
			} else {
				return  true;
			}
		} else if (component.state == State.after) {
			/*const dialogRef = this.dialog.open(FormChangesAttentionComponent, {
				data: {
					closeBtnText: 'Ignore changes',
					infoText: 'You have unsaved data. Please confirm before proceeding.'
				},
				panelClass: 'attention-form-changes',
				disableClose: true
			});
			return dialogRef.afterClosed().pipe(map(result => {
				return result == 'leave';
			}));*/
			return  true;
		}

	}
}
