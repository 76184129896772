import {Inject, Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpResponse, HttpEvent, HttpErrorResponse, HttpInterceptor as Interceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LoadingService} from './loading.service';
import {Router} from '@angular/router';
import {AuthConfig} from '../../app/auth/_services/auth-config';
import {LoadingConfig} from "./loading-config";
import {LoginService} from "../../app/@core/login.service";

@Injectable()
export class HttpInterceptor implements Interceptor {


  constructor(
  	private loadingService: LoadingService,
		private loginService: LoginService,
	@Inject(LoadingConfig) private loadingConfig: LoadingConfig,
    @Inject(AuthConfig) private authConfig: AuthConfig,
	private router: Router) {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let loadingCompRef;
    if (this.authConfig.refreshTokenUrl !== request.url
      && !this.loadingConfig.ignoreUrls.find(j => j.startsWith(request.url))) {

      loadingCompRef = this.loadingService.loading();
    }

    return next.handle(request).pipe(
      tap( // Log the result or error
        (data: { type: number }) => {
          if (data.type !== 0 && this.authConfig.refreshTokenUrl !== request.url) {
            // this.loadingService.loaded(loadingCompRef);
              this.loadingService.loaded();
          }
        },
        error => {
          if (this.authConfig.refreshTokenUrl !== request.url) {
            // this.loadingService.loaded(loadingCompRef);
              this.loadingService.loaded();
          }
          //console.error(`Backend returned code ${error.status}, body was:`);
          //console.error(error);

          if (this.authConfig.refreshTokenUrl === request.url) {
            this.loadingService.showError(['Session Ended.']);
            this.router.navigate([this.authConfig.loginRoute]);
          } else if (error.status == 400 && error.error) {
			  if (error.error.message) {
				  if(error.error.message == "100"){
						this.loadingService.showPasswordChange(['Your password has expired. You must change it.'], ['CHANGE'], (btn) => {
							if (btn.toLowerCase() === 'change') {
								this.loginService.showChangePassword();
							}
						});
				  }else if(error.error.message == "101"){
					  this.loadingService.showError(["Your account is blocked."]);
				  } else if (error.error.message.startsWith('102_')) {
				  	const daysLeft = error.error.message.split('_').pop();
					  if(daysLeft > 0){
						  this.loadingService.showPasswordChange([`Your password is expiring in ${daysLeft} days. Please change it.`], ['CHANGE', 'IGNORE'], (btn) => {
							  if (btn.toLowerCase() === 'change') {
								  this.loginService.showChangePassword();
							  } else if (btn.toLowerCase() === 'ignore') {
								  this.loginService.loginWithIgnorePasswordChanging();
							  }
						  });
					  }else{
						  this.loadingService.showPasswordChange(['Your password has expired. You must change it.'], ['CHANGE'], (btn) => {
							  if (btn.toLowerCase() === 'change') {
								  this.loginService.showChangePassword();
							  }
						  });
					  }
				  }else{
					  this.loadingService.showError(["An error occurred, try again later."]);
				  }
			  }else{
				  this.loadingService.showError(["An error occurred, try again later."]);
			  }
		  } else if (error.status >= 400 && error.status < 500) {
            if (error.status === 401) {
              this.loadingService.showError(['Session Ended.']);
              this.router.navigate(['/login']);
            } else if (error.status === 405) {
              this.loadingService.showError(['You Dont Have Permission For This']);
            } else if (error.status === 409) {
				this.loadingService.showError([error.error.message]);
			} else if (error.status === 404 && error.error) {
				if (error.error.message) {
					if(error.error.message.length > 600){
						this.loadingService.showError(['Data not found']);
					}else{
						this.loadingService.showError([error.error.message]);
					}
				}else if(error.error.length > 600){
					if(error.error.length > 600){
						this.loadingService.showError(['Data not found']);
					}else{
						this.loadingService.showError([error.error]);
					}
				}

			} else if (error.error && error.error.message) {
              // this.loadingService.showError([error.error.message]);
              this.loadingService.showError(["An error occurred, try again later."]);
            } else {
              // this.loadingService.showError([error.error]);
              this.loadingService.showError(["An error occurred, try again later."]);
            }
          } else if (error.status === 500) {
            if (error.error && error.error.message) {
              this.loadingService.showError([error.error.message]);
            } else {
            	try{
            		let err = JSON.parse(error.error)
					this.loadingService.showError([err.message]);
				} catch (e) {
					try{
						var decodedString = String.fromCharCode.apply(null, new Uint8Array(error.error));
						var obj = JSON.parse(decodedString);
						var message = obj['message'];

						message = message.replace("java.lang.RuntimeException:","");

						this.loadingService.showError([message]);
					} catch (e) {
						this.loadingService.showError(["An error occurred, try again later."]);
					}
				}
            }
          } else {
            this.loadingService.showError(['Server update in progress.',' Try to connect later']);
          }
        }
      )
    );
  }
}
