import { HttpClient } from '@angular/common/http';
import { POST_AWARD_CONFIG } from '../@config/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PostAwardService {
    constructor(http) {
        this.http = http;
        this.config = POST_AWARD_CONFIG;
    }
    getAwardDetails(sslgId) {
        return this.http.get(this.config.detail.getAwardDetail(sslgId));
    }
    setAwardDetails(sslgId, details) {
        return this.http.post(this.config.detail.setAwardDetail(sslgId), details);
    }
    setCoraDetails(sslgId, data) {
        return this.http.post(this.config.detail.setCoraDetail(sslgId), data);
    }
    getCoraDetails(sslgId) {
        return this.http.get(this.config.detail.getCoraDetail(sslgId));
    }
}
PostAwardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PostAwardService_Factory() { return new PostAwardService(i0.ɵɵinject(i1.HttpClient)); }, token: PostAwardService, providedIn: "root" });
