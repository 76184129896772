<div class="report-wrapper" #element>
	<div class="report-title doctors">
		Visit Summaries From SSLG
	</div>

	<table class="report-table">
		<tr>
			<td>VISIT DATE:</td>
			<td>{{tableData.visit.visitDate ? (tableData.visit.visitDate | date:'MM/dd/yyyy') : '--/--/----'}}</td>
		</tr>
		<tr>
			<td>SOURCE NAME</td>
			<td>{{tableData.catalogItem.name}}</td>
		</tr>
		<tr>
			<td>MET WITH:</td>
			<td> {{ tableData.visit.metWithDoctor ? "DOCTOR" : "OTHER" }} {{tableData.visit.metWithName ? (": " + tableData.visit.metWithName) : '' }}</td>
		</tr>
		<tr>
			<td>NEXT VISIT:</td>
			<td>
				{{tableData.visit.nextVisit ? (tableData.visit.nextVisit | date:'MM/dd/yyyy') : '--/--/----' }}
			</td>
		</tr>
		<tr>
			<td>REASON FOR VISIT:</td>
			<td class="checks-container">
				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visit.visitReason.regular">Regular Visit</mat-checkbox>
				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visit.visitReason.bloodWork">Blood Work</mat-checkbox>
				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visit.visitReason.medicationUpdate">Medication Update</mat-checkbox>
				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visit.visitReason.medicationChanges">Medication Changes</mat-checkbox>
				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visit.visitReason.surgeryConsult">Surgery Consult</mat-checkbox>

				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visit.visitReason.emergency">Emergency</mat-checkbox>
				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visit.visitReason.fUVisit">F/U Visit</mat-checkbox>
				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visit.visitReason.initialConsult">Initial Consult</mat-checkbox>
				<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.visit.visitReason.chemoOrRadiation">Chemo/Radiation</mat-checkbox>
				<div  class="mat-check-visits" >
					<mat-checkbox [disabled]="true" [checked]="tableData.visit.visitReason.otherReason">Other</mat-checkbox>

					<div style="padding-top: 5px">
						{{tableData.visit.visitReason.otherReasonDescr}}
					</div>
				</div>


			</td>
		</tr>
		<tr>
			<td>MEDICAL CONDITIONS TREATED:</td>
			<td>
				<div *ngFor="let i of tableData.visit.medicalConditions">{{i}}</div>
			</td>
		</tr>

		<tr>
			<td>TREATMENTS RECEIVED:</td>
			<td>
				{{tableData.visit.treatments}}
			</td>
		</tr>
		<tr>
			<td>ITEMS DISCUSSED WITH DOCTOR:</td>
			<td>
				{{tableData.visit.discussion}}
			</td>
		</tr>
		<tr>
			<td>DOCTOR’S RESPONSE:</td>
			<td>
				{{tableData.visit.docResponse}}
			</td>
		</tr>
		<tr>
			<td>CLAIM DISCUSSED WITH DOCTOR:
			</td>
			<td>
				{{tableData.visit.discusSSDI}}
			</td>
		</tr>

		<tr *ngIf="tableData.visit.medicalTest">
			<td rowspan="2">
				<app-pdf-view-blocks-medical-test [tableData]="tableData.visit.medicalTest"></app-pdf-view-blocks-medical-test>
			</td>
		</tr>

		<tr *ngIf="tableData.medications.length > 0">
			<td colspan="2">
				<app-pdf-view-blocks-medications [tableData]="tableData.medications"></app-pdf-view-blocks-medications>
			</td>
		</tr>
	</table>
</div>

