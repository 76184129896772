import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Office} from '../@models/system-managment/office';
import {Contact} from '../@models/contact';
import {Note} from '../@models/system-managment/note';
import {INSURERS_CONFIG} from '../@config/api';
import {Social} from '../@models/system-managment/social';
import {Functions} from '../../@util/functions';
import {WebAndLtd} from '../@models/system-managment/web-and-ltd';

@Injectable({
  providedIn: 'root'
})
export class OfficeService {

  private config = INSURERS_CONFIG.OFFICES;

  constructor(private http: HttpClient) {
  }

  getCurrentOffice(officeId: string) {
    return this.http.get(this.config.byId(officeId));
  }

  getAll() {
    return this.http.get<Office[]>(this.config.getAll);
  }

  addOffice(value: Office) {
    return this.http.post(this.config.addOffice(value.insurenceId), value);
  }

  setOfficeContact(officeId, value: Contact) {
    value.press = Functions.checkPhone(value.press);
    value.fax = Functions.checkPhone(value.fax);
    value.phone = Functions.checkPhone(value.phone);
    return this.http.post(this.config.setContact(officeId), value);
  }

  setOfficeNote(officeId, value: Note) {
    return this.http.post(this.config.setNote(officeId), value);
  }

  getInsuranceOffices(insuranceId) {
    return this.http.get<Office[]>(this.config.getAllByCompanyId(insuranceId));
  }


  setSocial(officeId, value: Social) {
    Social.checkLinck(value);
    return this.http.post(this.config.setSocial(officeId), value);
  }

  setBasic(value: Office) {
    return this.http.post(this.config.setBasic(value.id), value);
  }

  setWebAndLtd(id, value: WebAndLtd) {
    return this.http.post(this.config.setWebAndLtd(id), value);
  }

  getWebAndLtd(id) {
    return this.http.get(this.config.getWebAndLtd(id), {responseType: 'text'});

  }
}
