import { SSA_LEVELS } from '../@config/ssa-levels';
import * as i0 from "@angular/core";
export class SSALevelService {
    constructor() {
        this.config = SSA_LEVELS;
    }
    getLevelName(id) {
        let name;
        this.config.find(el => {
            if (+el.id === +id) {
                name = el.name;
            }
        });
        return name;
    }
}
SSALevelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SSALevelService_Factory() { return new SSALevelService(); }, token: SSALevelService, providedIn: "root" });
