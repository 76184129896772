import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {
    DynamicFormControlModel,
    DynamicFormService,
} from '@ng-dynamic-forms/core';
import {DATE_PICKER} from '../../../../../../../@util/dynamic-form/dynamic-controlls';
import {ReferralService} from '../../../../../../@core/referral.service';
import {FormValueBuilder} from '../../../../../partial/forms/form-tamplate';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {SystemUsersService} from '../../../../../../@core/system-users.service';
import {FollowUp} from '../../../../../../@models/follow-up/follow-up';
import {map} from "rxjs/operators";
import {User} from "../../../../../../@models/system-managment/user";
import {DynamicSelectModel} from "../../../../../../../@util/ui-material/form-controls";
import {UserInfoService} from "../../../../../../@core/user-info.service";
import {GlobalSaveService} from "../../../../../../@core/global-save.service";

@Component({
    selector: 'app-complete-follow-up',
    templateUrl: './complete-follow-up.component.html',
    styleUrls: ['./complete-follow-up.component.css']
})
export class CompleteFollowUpComponent implements OnInit {
    closeForm: EventEmitter<any> = new EventEmitter<any>();
    formGroup: FormGroup;
    formModel: DynamicFormControlModel[];
    title: string = 'Edit';
    form = {
        completedBy: DynamicSelectModel({
            id: 'completedBy',
            label: 'Completed By',
            value: null,
            required: true,
            errorMessages: {
                required: 'Required'
            },
			//disabled:true
        }),
        completeDate: DATE_PICKER({id: 'completeDate', label: 'Complete Date', required: true}),
    };

    constructor(private referralService: ReferralService, private formService: DynamicFormService,
                private dialogRef: MatDialogRef<CompleteFollowUpComponent>,
                @Inject(MAT_DIALOG_DATA) public data: FollowUp,
				private userInfoService: UserInfoService,
				public globalSaveService: GlobalSaveService,
                private systemUsers: SystemUsersService) {
        this.form.completeDate.disabled = true;
		this.form.completedBy.disabled = true;
        this.form.completeDate.hiddenClearIco = true;
        this.formModel = FormValueBuilder.buildFormModel(this.form);
        this.formGroup = this.formService.createFormGroup(this.formModel);


    }

    ngOnInit() {
        setTimeout(async () => {
            await this.fillUsers();
            this.form.completeDate.valueUpdates.next(new Date(Date.now()));
        }, 0);
    }

    closeFormFollowUp() {
        this.dialogRef.close();
    }

    async fillUsers() {
        const users = await this.systemUsers.getUsersList('EXECUTIVE_STAFF')
            .pipe(map((data: User[]) => this.systemUsers.mapUserStaff(data))).toPromise();
        this.form.completedBy.options = users;

        //this.form.completedBy.valueUpdates.next(this.data.assignedTo);
		try {
			this.form.completedBy.valueUpdates.next(this.userInfoService.getCurrentUserName());
		}catch (e) {
			this.form.completedBy.valueUpdates.next(this.data.assignedTo);
		}
    }

    saveFollowUp() {
        FormValueBuilder.setControlsToched(this.formGroup);

        //if (this.formGroup.valid) {
            this.dialogRef.close(this.formGroup.getRawValue());
        //}
    }
}
