import { HttpClient } from "@angular/common/http";
import { FIELD_OFFICES_CONFIG } from "../@config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class FieldOfficeService {
    constructor(http) {
        this.http = http;
    }
    getOffices() {
        return this.http.get(FIELD_OFFICES_CONFIG.getAll);
    }
    getOffice(officeId) {
        return this.http.get(FIELD_OFFICES_CONFIG.get(officeId));
    }
    saveOffice(office) {
        return this.http.post(FIELD_OFFICES_CONFIG.saveOffice, office);
    }
    getFieldData(claimantId) {
        return this.http.post(FIELD_OFFICES_CONFIG.getFieldData(claimantId), { claimantId: claimantId });
    }
}
FieldOfficeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FieldOfficeService_Factory() { return new FieldOfficeService(i0.ɵɵinject(i1.HttpClient)); }, token: FieldOfficeService, providedIn: "root" });
