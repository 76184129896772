<app-frame [overlay]="false"
		   [title]="frameTitle"
		   [class.no-frame]="true"
		   [showCloseButton]="false" class="step-frame">
	<form class="form-horizontal form-fieldset second-step" [formGroup]="formGroup" novalidate>
		<dynamic-material-form [group]="formGroup"
							   [layout]="formLayout"
							   class="row"
							   [model]="formModel">
		</dynamic-material-form>
	</form>

	<ng-container>
		<app-form-actions-content *appCanAccess="PERMISSIONS.LOWER_EXEC">
			<form-button style="margin-right: auto"
						 *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" (clickOnButton)="backHospitalsVisitNotes()">Back</form-button>
			<form-button *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" (clickOnButton)="nextHospitalsVisitNotes()">Finish</form-button>
		</app-form-actions-content>
	</ng-container>
</app-frame>

