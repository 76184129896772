import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {REFERRAL_CONFIG} from '../@config/api';
import {Remanded, StatusModel} from '../@models/status/status';
import {Observable, Subject,BehaviorSubject} from 'rxjs';
import {Intake} from '../@models/status/intake';
import {LevelDetails} from '../@models/status/level-details';
import {HearingDetails} from '../@models/status/hearing-details';
import {HearingSchedule} from "../@models/status/hearing-schedule";
import {map} from "rxjs/operators";
import {TerminatedModel} from "../@models/status/terminated-model";
import {StatusDetails} from "../@models/status/status-details";
import {CeExamDetails} from "../@models/status/ce-exam-details";
import {UserInfoService} from "../@core/user-info.service";


@Injectable({
	providedIn: 'root'
})
export class StatusService {

	private config = REFERRAL_CONFIG.STATUS;
	private updateStatus = new Subject();
	//formsChanged = false;
	public formsChanged = new BehaviorSubject(null);

	public statusChanged = new BehaviorSubject(null);

	constructor(private http: HttpClient,
				private userInfoService: UserInfoService) {
	}

	checkStatus(sslgId){
		this.getClaimantStatusDetails(sslgId).subscribe((j: any) => {
			if (j.status == "true") {
				try {
					let status = JSON.parse(j.statusData);

					this.setStatusChanged(status);
				} catch (e) {}
			}
		});
	}

	setStatusChanged(status){
		this.statusChanged.next({key:Math.round(+new Date()/1000)+Math.random(),status:status});
	}

	setFormsUnchanged(){
		//this.formsChanged = false;
	}

	setFormsChanged(){
		//this.formsChanged = true;
		this.formsChanged.next({key:Math.round(+new Date()/1000)+Math.random()});
	}

	getFormsChanged(){
		return this.formsChanged;
	}

	get(sslgId): Observable<StatusModel> {
		return this.http.get<StatusModel>(this.config.getById(sslgId));
	}

	/*setIntake(sslgId: string, $event: Intake): Observable<StatusModel> {
		return this.http.post<StatusModel>(this.config.setIntake(sslgId), $event)
			.pipe(map(x => {
				this.updateStatus.next();
				return x;
			}));
	}*/

	getClaimantStatusDetails(sslgId: string){
		let userName = this.userInfoService.getCurrentUserName();

		return this.http.post<any>(this.config.getClaimantStatusDetails(sslgId), {userName:userName});
	}

	saveIntake(sslgId: string, $event: Intake): Observable<StatusModel> {
		return this.http.post<StatusModel>(this.config.setIntake(sslgId), $event);
	}

	setLevelDetails(sslgId: string, $event: LevelDetails[]): Observable<StatusModel> {
		return this.http.post<StatusModel>(this.config.setLevelDetails(sslgId), $event)
			.pipe(map(x => {
				this.updateStatus.next();
				return x;
			}));
	}

	setHearingDetails(sslgId: string, $event: HearingDetails): Observable<StatusModel> {
		return this.http.post<StatusModel>(this.config.setHearingDetails(sslgId), $event);
	}

	setHearingSchedule(sslgId: string, $event: HearingSchedule): Observable<StatusModel> {
		return this.http.post<StatusModel>(this.config.setHearingSchedule(sslgId), $event);
	}

	setTerminated(sslgId: string, $event: TerminatedModel) {
		return this.http.post<StatusModel>(this.config.setTerminated(sslgId), $event)
			.pipe(map(x => {
				this.updateStatus.next();
				return x;
			}));
	}

	getStatusDetails(sslgId: string) {
		//console.log(sslgId);
		return this.http.get<StatusDetails>(this.config.getStatusDetails(sslgId));
	}

	changeStatusEvent() {
		return this.updateStatus;
	}

	setRemanded(sslgId: string, value: Remanded) {
		return this.http.post<StatusModel>(this.config.setRemanded(sslgId, value.id), value.levelDetails);
	}

	setCeExam(sslgId: string, value: CeExamDetails[]){
		return this.http.post(this.config.setCeExam(sslgId), value);
	}

	getCeExam(sslgId: string){
		return this.http.get<CeExamDetails[]>(this.config.getCeExam(sslgId));
	}

	getAvailableClosedCodes(sslgId) {
		return this.http.get<CeExamDetails[]>(this.config.getClosedCodes(sslgId));
	}

	isCanTerminate(sslgId: any, rez) {
		return this.http.post(this.config.isCanTerminate(sslgId), rez);
	}
}
