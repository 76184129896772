import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserTempService {
  storage = sessionStorage;
  userTempKey = 'currentUserTemp';

  constructor() {

  }

  isTempUser() {
    // return true;
    return this.storage.getItem(this.userTempKey) === 'true';
  }

  resetUserTempState() {
    this.storage.setItem(this.userTempKey, 'false');
  }

  setUserTempState(res) {
    this.storage.setItem(this.userTempKey, res.currentUserTemp);
    // this.storage.setItem(this.userTempKey, '1');
  }
}
