import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from './auth/_services/authentication.service';
import { UserIdleConfig } from './auth/user-idle/user-idle-config';
import { UserInfoService } from './@core/user-info.service';
import { PhoneService } from './@core/phone.service';
import { PageLoaderService } from "./@core/page-loader.service";
export class AppComponent {
    constructor(router, pageLoaderService, authService, userInfoService, phoneService, route) {
        this.router = router;
        this.pageLoaderService = pageLoaderService;
        this.authService = authService;
        this.userInfoService = userInfoService;
        this.phoneService = phoneService;
        this.route = route;
        this.show = true;
        if (sessionStorage.getItem('currentUser')) {
            UserIdleConfig.REMEMBER_ME = false;
        }
        // this.router.events.subscribe((ev) => {
        // 	if (ev instanceof NavigationEnd) {
        // 		//alert('ssss')
        // 	}
        // });
        this.pageLoaderService.loaderShow.subscribe((value) => this.loaderChanged(value));
        var isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        if (isMac) {
            document.body.classList.add('is-mac-ios');
        }
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const deviceId = urlParams.get('device-id');
            const deviceType = urlParams.get('device-type');
            if (deviceId && deviceType) {
                this.userInfoService.setDeviceData(deviceId, deviceType);
            }
            this.phoneService.init();
            yield this.authService.checkSession();
        });
    }
    loaderChanged(value) {
        this.show = value;
    }
}
