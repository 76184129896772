/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-changes-attention.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../app/@view/partial/forms/form-frame/form-frame.component.ngfactory";
import * as i3 from "../../app/@view/partial/forms/form-frame/form-frame.component";
import * as i4 from "../../app/@view/partial/form-actions-content/form-actions-content.component.ngfactory";
import * as i5 from "../../app/@view/partial/form-actions-content/form-actions-content.component";
import * as i6 from "../../app/@view/partial/form-button/form-button.component.ngfactory";
import * as i7 from "../../app/@view/partial/form-button/form-button.component";
import * as i8 from "./form-changes-attention.component";
import * as i9 from "@angular/material/dialog";
var styles_FormChangesAttentionComponent = [i0.styles];
var RenderType_FormChangesAttentionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormChangesAttentionComponent, data: {} });
export { RenderType_FormChangesAttentionComponent as RenderType_FormChangesAttentionComponent };
export function View_FormChangesAttentionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "app-frame", [["autocomplete", "off"]], null, [[null, "neadClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("neadClose" === en)) {
        var pd_0 = (_co.closeForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormFrameComponent_0, i2.RenderType_FormFrameComponent)), i1.ɵdid(1, 114688, null, 0, i3.FormFrameComponent, [], { autocomplete: [0, "autocomplete"], overlay: [1, "overlay"], showCloseButton: [2, "showCloseButton"], showLegend: [3, "showLegend"] }, { neadClose: "neadClose" }), (_l()(), i1.ɵted(-1, 0, ["\n\t"])), (_l()(), i1.ɵeld(3, 0, null, 0, 7, "div", [["class", "attention-form"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["\n\t\t\t", "\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, 0, ["\n\t"])), (_l()(), i1.ɵeld(12, 0, null, 0, 10, "app-form-actions-content", [], null, null, null, i4.View_FormActionsContentComponent_0, i4.RenderType_FormActionsContentComponent)), i1.ɵdid(13, 114688, null, 0, i5.FormActionsContentComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i1.ɵeld(15, 0, null, 0, 2, "form-button", [], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.leavePage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FormButtonComponent_0, i6.RenderType_FormButtonComponent)), i1.ɵdid(16, 638976, null, 0, i7.FormButtonComponent, [], null, { clickOnButton: "clickOnButton" }), (_l()(), i1.ɵted(17, 0, ["", ""])), (_l()(), i1.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i1.ɵeld(19, 0, null, 0, 2, "form-button", [["buttonType", "warning"]], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.closeForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FormButtonComponent_0, i6.RenderType_FormButtonComponent)), i1.ɵdid(20, 638976, null, 0, i7.FormButtonComponent, [], { buttonType: [0, "buttonType"] }, { clickOnButton: "clickOnButton" }), (_l()(), i1.ɵted(-1, 0, ["Go Back"])), (_l()(), i1.ɵted(-1, 0, ["\n\t"])), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var currVal_0 = "off"; var currVal_1 = false; var currVal_2 = false; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); _ck(_v, 13, 0); _ck(_v, 16, 0); var currVal_6 = "warning"; _ck(_v, 20, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.data.infoText; _ck(_v, 6, 0, currVal_4); var currVal_5 = _co.data.closeBtnText; _ck(_v, 17, 0, currVal_5); }); }
export function View_FormChangesAttentionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-changes-attention", [], null, null, null, View_FormChangesAttentionComponent_0, RenderType_FormChangesAttentionComponent)), i1.ɵdid(1, 114688, null, 0, i8.FormChangesAttentionComponent, [i9.MatDialogRef, i9.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormChangesAttentionComponentNgFactory = i1.ɵccf("app-form-changes-attention", i8.FormChangesAttentionComponent, View_FormChangesAttentionComponent_Host_0, {}, {}, []);
export { FormChangesAttentionComponentNgFactory as FormChangesAttentionComponentNgFactory };
