import { PROMOTIONS_CONFIG } from '../@config/api';
// import {Promotion} from "../@models/promotions/promotion.model";
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { PROMOTIONS_CHAT_TYPES } from "../@view/pages/promotions/promotions-list/pronotions-types.model";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common/http";
export class PromotionsService {
    constructor(router, http) {
        this.router = router;
        this.http = http;
        this.config = PROMOTIONS_CONFIG;
        this.newClaimantInSessionStorage = 'prospectsNewClaimant';
        this.newClaimantPositionInSessionStorage = 'prospectsNewClaimantPosition';
        this.paginationPageActive = 0;
        this.paginationPageSize = 6;
        this.activeUser = null;
    }
    getPromotionsList(page, status, items) {
        let kind = this.router.url.indexOf(PROMOTIONS_CHAT_TYPES.initial) >= 0 ? PROMOTIONS_CHAT_TYPES.application : PROMOTIONS_CHAT_TYPES.appeal;
        let type = 0;
        if (this.router.url.indexOf(PROMOTIONS_CHAT_TYPES.other) >= 0) {
            kind = PROMOTIONS_CHAT_TYPES.other;
            this.router.url.indexOf('something-else') >= 0 ? type = 0 : type = 1;
        }
        return this.http.post(this.config.getList, { page, status, items, kind, type });
    }
    getPromotionsCategories(kind) {
        return this.http.post(this.config.getCategories, { kind });
    }
    getPromotionsCategory(userId, categoryId) {
        return this.http.post(this.config.getCategory, { userId, categoryId });
    }
    changeStatus(id, status) {
        return this.http.post(this.config.changeStatus, { id, status });
    }
    deleteProspects(userId, type) {
        return this.http.post(this.config.deleteProspect, { userId: userId, type: type });
    }
    claimantWasCreated(id) {
        return this.http.post(this.config.claimantCreated, { id });
    }
    updateChatAnswers(userId, categoryId, data) {
        return this.http.post(this.config.updateChatAnswers, { userId, categoryId, answers: JSON.stringify(data) });
    }
    createNewClaimant(info, pagination, page) {
        const newClaimantPosition = {
            pagination: pagination,
            page: page
        };
        sessionStorage.setItem(this.newClaimantPositionInSessionStorage, JSON.stringify(newClaimantPosition));
        sessionStorage.setItem(this.newClaimantInSessionStorage, JSON.stringify(info));
        this.router.navigate(['/claimants/new/claimant'], { queryParams: { from: 'prospects' } });
    }
    getNewClaimantInfo() {
        return sessionStorage.getItem(this.newClaimantInSessionStorage);
    }
    getNewClaimantPosition() {
        return sessionStorage.getItem(this.newClaimantPositionInSessionStorage);
    }
    sendMessage(data) {
        return this.http.post(this.config.sendMessage, data);
    }
}
PromotionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PromotionsService_Factory() { return new PromotionsService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HttpClient)); }, token: PromotionsService, providedIn: "root" });
