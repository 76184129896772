import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AUTH_CONFIG} from '../@config/api';
import {Subject} from 'rxjs';
import {CustomIrisNotificationService} from "../../@util/custom-iris-notification/custom-iris-notification.service";
import {map} from "rxjs/operators";
import {AuthenticationService} from "../auth/_services/authentication.service";
import {DialogService} from "../../@util/dialog/dialog.service";
import { PERMISSIONS } from '../@config/permissions';


@Injectable({
	providedIn: 'root'
})
export class UserInfoService {
	private storage = sessionStorage;
	public current;
	public userInfo: Subject<any> = new Subject<any>();

	constructor(private http: HttpClient,
				private notification: CustomIrisNotificationService,
				private dialog: DialogService) {
	}

	async loadCurent() {
		this.current = (await this.http.get<any>(AUTH_CONFIG.current).pipe(map(j => {
			// if (this.current == null && (+j.passwordChangeDaysLeft) > 80) {
			// 	this.notification.show(<any>{
			// 		autoHide: true,
			// 		timeOut: 15000,
			// 		message: `Your access will be revoked after ${j.passwordChangeDaysLeft} days.`,
			// 		title: "Change your password!",
			// 		type: 'info',
			// 		position: "rb",
			// 		imagePosition: 'right', // left or right
			// 		progressBar: 'linear', //linear or circle
			// 	});
			// }

			return j;
		})).toPromise()).user;
		this.userInfo.next(this.current);
	}

	getCurrentUserName(){
		return this.storage.getItem('currentUserName');
	}

	getCurrentFirebaseToken(){
		return this.storage.getItem('currentFirebaseToken');
	}

	setCurrentFirebaseToken(token:string){
		this.storage.setItem('currentFirebaseToken',token);
	}

	getCurrentUserRole(){
		return this.storage.getItem('currentUserRole');
	}


	getCurrentUserNameIsSystem(){
		if(this.getCurrentUserName() == 'CEO'){
			return true;
		}

		return false;
	}

	isAdjuster() {
		if(this.getCurrentUserRole() == 'ADJUSTER'){
			return true;
		}

		return false;
	}

	isStaff() {
		if(PERMISSIONS.HIGHER_EXEC_LEVEL_4.includes(this.getCurrentUserRole())){
			return true;
		}

		return false;
	}

	someStaff() {
		return !(this.getCurrentUserRole().indexOf('ADJUSTER') != -1 || this.getCurrentUserRole().indexOf('CLIENT') != -1);
	}

	isSslgStaff() {
		if(this.getCurrentUserRole() == 'SSLG_STAFF'){
			return true;
		}

		return false;
	}

	isClaimant() {
		if(this.getCurrentUserRole() == 'CLIENT'){
			return true;
		}

		return false;
	}

	setAudioNotifiicationRunning(){
		this.storage.setItem('audioNotifiication',"1");
	}

	setDeviceData(uuid:string,type:string){
		this.storage.setItem('deviceUuid',uuid);
		this.storage.setItem('deviceType',type);
	}

	getDeviceUuid(){
		return this.storage.getItem('deviceUuid');
	}

	getDeviceType(){
		return this.storage.getItem('deviceType');
	}

	isApp() {
		if (this.getDeviceType() !== undefined && this.getDeviceType() !== null) {
			return true;
		}
		return false;
	}

	getAudioNotifiicationRunning(){
		return this.storage.getItem('audioNotifiication');
	}

	getToken(){
		let currentUser = JSON.parse(this.storage.getItem('currentUser'));

		return currentUser.token;
	}
}
