import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Functions} from '../functions';

export function emailValidator(control: AbstractControl): any {
  ////console.log(control);
  const hasError = control.value ? !Functions.validateEmail(control.value) : false;
  ////console.log(hasError);
  return hasError ? {emailValidator: true} : null;
}
export function ICD_10Validator(control: AbstractControl): any {
  ////console.log(control);
  const hasError = control.value ? !Functions.validateICD_10(control.value) : false;
  ////console.log(hasError);
  return hasError ? {ICD_10Validator: true} : null;
}
