import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {POST_AWARD_CONFIG} from "../@config/api";

@Injectable({
    providedIn: 'root'
})
export class PostAwardDetailsService {
    private config = POST_AWARD_CONFIG.award_details;

    constructor(private http: HttpClient) {
    }


    getOverview(sslgId) {
        return this.http.get(this.config.overview(sslgId))
    }

    setOverview(sslgId, data) {
        return this.http.post(this.config.overview(sslgId), data)
    }

    getDependent(sslgId) {
        return this.http.get(this.config.dependent(sslgId))
    }

    setDependent(sslgId, data) {
        return this.http.post(this.config.dependent(sslgId), data)
    }

    getPrimary(sslgId) {
        return this.http.get(this.config.primary(sslgId))
    }

    setPrimary(sslgId, data) {
        return this.http.post(this.config.primary(sslgId), data)
    }

}
