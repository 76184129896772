import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogComponent} from './dialog.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DialogService} from './dialog.service';
import {PartialModule} from "../../app/@view/partial/partial.module";
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
	imports: [
		CommonModule,
		FormsModule, ReactiveFormsModule, PartialModule,
        DragDropModule
	],
  declarations: [DialogComponent],
  providers: [DialogService],
  exports: [DialogComponent, DragDropModule],
  entryComponents: [DialogComponent]
})
export class DialogModule {
}



