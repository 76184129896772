import {
  Injectable,
  ApplicationRef,
  ComponentFactoryResolver,
  Injector,
  ComponentRef, EmbeddedViewRef, Inject
} from '@angular/core';
import {HttpErrorMessageComponent} from "./http-error-message/http-error-message.component";
// import {LoadingComponent} from "./spinner/loading.component";
import {LoadingConfig} from "./loading-config";
import {PageLoaderService} from "../../app/@core/page-loader.service";
import {HttpMessageComponent} from "./http-message/http-message.component";

@Injectable()
export class LoadingService {

  private load: boolean = false;

  constructor(@Inject(LoadingConfig) private lodingConfig: LoadingConfig,
              private pageLoaderService: PageLoaderService,
              private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef,
              private injector: Injector) {

  }

  // loading(): ComponentRef<LoadingComponent> {
  //   // if (this.lodingConfig.showSpinner && !this.load) {
  //   //   this.load = true;
  //   //   return this.showLoader();
  //   //
  //   // }
  //   this.pageLoaderService.show();
  // }

  loading() {
    this.pageLoaderService.show();
  }
  // loaded(comp: ComponentRef<LoadingComponent>) {
  //   // if (this.lodingConfig.showSpinner && comp) {
  //   //   this.load = false;
  //   //   this.destroy(comp);
  //   // }
  //   this.pageLoaderService.hide();
  // }

  loaded() {
    this.pageLoaderService.hide();
  }

  showError(message: string[], noClip?) {
    if (this.lodingConfig.showErrors) {
      // Create a component reference from the component
      let componentRef: ComponentRef<HttpErrorMessageComponent> | ComponentRef<HttpMessageComponent>;
      if (noClip) {
        componentRef = this.getComponentRef(HttpMessageComponent);
      } else {
        componentRef = this.getComponentRef(HttpErrorMessageComponent);
      }

      // if(message[0].length > 500){
		//   componentRef.instance.title = ["An error occurred, try again later"];
	  // }else{
		//   componentRef.instance.title = message;
	  // }
		  componentRef.instance.title = message;
      componentRef.instance.buttons = ['OK'];
      componentRef.instance.onCloseDialog.subscribe(j => {
        this.destroy(componentRef);
      });

      this.addComponentInDOM(componentRef);
    }
  }

  showPasswordChange(message: string[], buttons: string[], btnClickHandler: any) {
    if (this.lodingConfig.showErrors) {
      // Create a component reference from the component
      const componentRef: ComponentRef<HttpErrorMessageComponent> = this.getComponentRef(HttpErrorMessageComponent);

      // if(message[0].length > 500){
      //   componentRef.instance.title = ["An error occurred, try again later"];
      // }else{
      //   componentRef.instance.title = message;
      // }
      componentRef.instance.title = message;
      componentRef.instance.buttons = buttons;
      componentRef.instance.onCloseDialog.subscribe(j => {
        this.destroy(componentRef);
      });

      componentRef.instance.buttonClicked.subscribe(btn => {
        // if (btn.toLowerCase() === 'change') {
        //   alert('isdgdg');
        // }
        // if (btn.toLowerCase() === 'ignore') {
        //   alert('ignore');
        // }
        return btnClickHandler(btn);
      });

      this.addComponentInDOM(componentRef);
    }
  }

  private addComponentInDOM<T>(componentRef: ComponentRef<T>): void {
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);

  }

  private getComponentRef(component) {
    let compRef: any = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);
    return compRef;
  }

  private destroy(componentRef) {
    if (componentRef) {
      if (componentRef.hostView)
        this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    }
  }

  private showLoader() {
    // const componentRef: ComponentRef<LoadingComponent> = this.getComponentRef(LoadingComponent);
    // this.addComponentInDOM(componentRef);
    // return componentRef;
    this.pageLoaderService.show();
  }
}
