import {Inject, Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthConfig} from '../_services/auth-config';
import {UserIdleConfig} from "../user-idle/user-idle-config";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  hasError = false;
  refreshingToken = false;

  constructor(@Inject(AuthConfig) private authConfig: AuthConfig) {
    ////console.log(authConfig);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // ////console.log(request);
    // ////console.log(next);
    if (this.authConfig.authUrl == request.url) {
      this.hasError = false;
      this.refreshingToken = false;
    }

    while (this.refreshingToken) {

    }
    if (this.hasError && this.authConfig.authUrl != request.url) {
      return null;
    }
    let storage = sessionStorage;
    // add authorization header with jwt token if available
    const currentUser = JSON.parse(storage.getItem('currentUser'));
    // ////console.log(request.url);
    if (currentUser && currentUser.token) {
      // ////console.log("inject: ")
      // ////console.log(currentUser.token)
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.token}`
        }
      });
    }

    if (this.authConfig.refreshTokenUrl == request.url) {
      this.refreshingToken = true;
    }
    return next.handle(request).pipe(
      tap( // Log the result or error
        data => {
          if (this.authConfig.refreshTokenUrl == request.url) {
            this.refreshingToken = false;
          }
        },
        error => {
          if (this.authConfig.refreshTokenUrl == request.url) {
            this.refreshingToken = false;
            this.hasError = true;
          }

        }
      )
    );
  }
}
