import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class LoginService {
	changePassword = new BehaviorSubject(false);
	ignorePasswordChanging = new BehaviorSubject(false);

	constructor() {
	}

	showChangePassword() {
		this.changePassword.next(true);
	}

	loginWithIgnorePasswordChanging() {
		this.ignorePasswordChanging.next(true);
	}
}
