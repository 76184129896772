import { BehaviorSubject } from "rxjs";
import { PromotionsService } from "./promotions.service";
import * as i0 from "@angular/core";
import * as i1 from "./promotions.service";
export class IrisTableTabsService {
    constructor(promotionsService) {
        this.promotionsService = promotionsService;
        this.content = new BehaviorSubject(null);
    }
    updateContent(data) {
        this.content.next(data);
    }
    requestContent(userId, categoryId) {
        this.updateContent('reset');
        this.promotionsService.getPromotionsCategory(userId, categoryId).subscribe(val => {
            this.updateContent(val);
        });
    }
}
IrisTableTabsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IrisTableTabsService_Factory() { return new IrisTableTabsService(i0.ɵɵinject(i1.PromotionsService)); }, token: IrisTableTabsService, providedIn: "root" });
