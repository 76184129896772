/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./medical-conditions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../partial/form-button/form-button.component.ngfactory";
import * as i3 from "../../../../../partial/form-button/form-button.component";
import * as i4 from "../../../../../partial/form-actions-content/form-actions-content.component.ngfactory";
import * as i5 from "../../../../../partial/form-actions-content/form-actions-content.component";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../partial/forms/form-frame/form-frame.component.ngfactory";
import * as i8 from "../../../../../partial/forms/form-frame/form-frame.component";
import * as i9 from "@angular/forms";
import * as i10 from "../../../../../../../@util/ui-material/dynamic-material-form.component.ngfactory";
import * as i11 from "../../../../../../../@util/ui-material/dynamic-material-form.component";
import * as i12 from "@ng-dynamic-forms/core";
import * as i13 from "./medical-conditions.component";
import * as i14 from "../../../../../../@core/medical.service";
import * as i15 from "@angular/material/dialog";
import * as i16 from "@angular/router";
var styles_MedicalConditionsComponent = [i0.styles];
var RenderType_MedicalConditionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MedicalConditionsComponent, data: {} });
export { RenderType_MedicalConditionsComponent as RenderType_MedicalConditionsComponent };
function View_MedicalConditionsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.parent.context.context, _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"]))], null, null); }
function View_MedicalConditionsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "form-button", [], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.insertMedicalCondition() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormButtonComponent_0, i2.RenderType_FormButtonComponent)), i1.ɵdid(1, 638976, null, 0, i3.FormButtonComponent, [], null, { clickOnButton: "clickOnButton" }), (_l()(), i1.ɵted(-1, 0, ["Add\n\t\t\t\t\t"]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_MedicalConditionsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 5, "app-form-actions-content", [["class", "custom-btn col-1 auto-f-basis"]], null, null, null, i4.View_FormActionsContentComponent_0, i4.RenderType_FormActionsContentComponent)), i1.ɵdid(2, 114688, null, 0, i5.FormActionsContentComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\n\n\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MedicalConditionsComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "app-form-actions-content", [["class", " col-12"]], null, null, null, i4.View_FormActionsContentComponent_0, i4.RenderType_FormActionsContentComponent)), i1.ɵdid(9, 114688, null, 0, i5.FormActionsContentComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MedicalConditionsComponent_3)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.showRemoveBtn(_v.context.context, _v.context.index); _ck(_v, 5, 0, currVal_0); _ck(_v, 9, 0); var currVal_1 = _co.showAddBtn(_v.context.context, _v.context.index); _ck(_v, 12, 0, currVal_1); }, null); }
export function View_MedicalConditionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "app-frame", [["autocomplete", "off"]], null, [[null, "neadClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("neadClose" === en)) {
        var pd_0 = (_co.closeVisitForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_FormFrameComponent_0, i7.RenderType_FormFrameComponent)), i1.ɵdid(1, 114688, null, 0, i8.FormFrameComponent, [], { title: [0, "title"], autocomplete: [1, "autocomplete"], overlay: [2, "overlay"] }, { neadClose: "neadClose" }), (_l()(), i1.ɵted(-1, 0, ["\n\t"])), (_l()(), i1.ɵeld(3, 0, null, 0, 13, "form", [["class", "form-horizontal conditions-dialog"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i9.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(5, 540672, null, 0, i9.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i9.ControlContainer, null, [i9.FormGroupDirective]), i1.ɵdid(7, 16384, null, 0, i9.NgControlStatusGroup, [[4, i9.ControlContainer]], null, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(9, 0, null, null, 6, "dynamic-material-form", [], null, null, null, i10.View_DynamicMaterialFormComponent_0, i10.RenderType_DynamicMaterialFormComponent)), i1.ɵdid(10, 49152, null, 1, i11.DynamicMaterialFormComponent, [i12.DynamicFormService, i12.DynamicFormLayoutService], { formGroup: [0, "formGroup"], formModel: [1, "formModel"] }, null), i1.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(0, null, null, 1, null, View_MedicalConditionsComponent_1)), i1.ɵdid(14, 16384, [[1, 4]], 0, i12.DynamicTemplateDirective, [i1.TemplateRef], { align: [0, "align"], modelId: [1, "modelId"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, 0, ["\n\t"])), (_l()(), i1.ɵeld(18, 0, null, 0, 6, "app-form-actions-content", [], null, null, null, i4.View_FormActionsContentComponent_0, i4.RenderType_FormActionsContentComponent)), i1.ɵdid(19, 114688, null, 0, i5.FormActionsContentComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i1.ɵeld(21, 0, null, 0, 2, "form-button", [["buttonType", "save"]], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.saveMedicalConditions() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormButtonComponent_0, i2.RenderType_FormButtonComponent)), i1.ɵdid(22, 638976, null, 0, i3.FormButtonComponent, [], { buttonType: [0, "buttonType"] }, { clickOnButton: "clickOnButton" }), (_l()(), i1.ɵted(-1, 0, ["Save"])), (_l()(), i1.ɵted(-1, 0, ["\n\t"])), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = "off"; var currVal_2 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_10 = _co.formGroup; _ck(_v, 5, 0, currVal_10); var currVal_11 = _co.formGroup; var currVal_12 = _co.formModel; _ck(_v, 10, 0, currVal_11, currVal_12); var currVal_13 = "END"; var currVal_14 = "medicalCondition"; _ck(_v, 14, 0, currVal_13, currVal_14); _ck(_v, 19, 0); var currVal_15 = "save"; _ck(_v, 22, 0, currVal_15); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 7).ngClassValid; var currVal_8 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_MedicalConditionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-medical-conditions", [], null, null, null, View_MedicalConditionsComponent_0, RenderType_MedicalConditionsComponent)), i1.ɵdid(1, 114688, null, 0, i13.MedicalConditionsComponent, [i12.DynamicFormService, i14.MedicalService, i15.MatDialogRef, i15.MAT_DIALOG_DATA, i16.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MedicalConditionsComponentNgFactory = i1.ɵccf("app-medical-conditions", i13.MedicalConditionsComponent, View_MedicalConditionsComponent_Host_0, {}, {}, []);
export { MedicalConditionsComponentNgFactory as MedicalConditionsComponentNgFactory };
