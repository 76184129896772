import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingComponent} from "./spinner/loading.component";
import {LoadingService} from "./loading.service";
import {HttpInterceptor} from "./http-interceptor";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {HttpErrorMessageComponent} from './http-error-message/http-error-message.component';
import {LoadingConfig} from "./loading-config";
import {PartialModule} from "../../app/@view/partial/partial.module";
import {HttpMessageComponent} from "./http-message/http-message.component";

@NgModule({
	imports: [
		CommonModule,
		PartialModule
	],
	declarations: [LoadingComponent, HttpErrorMessageComponent, HttpMessageComponent],
	entryComponents: [LoadingComponent, HttpErrorMessageComponent, HttpMessageComponent]
})
export class LoadingModule {
	static forRoot(loadingConfig: LoadingConfig): ModuleWithProviders {
		return {
			ngModule: LoadingModule,

			providers: [
				{provide: LoadingConfig, useValue: loadingConfig},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: HttpInterceptor,
					multi: true,
				},
				LoadingService
			]
		};
	}
}
