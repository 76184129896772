import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Component, Inject, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {Subject, Subscription} from 'rxjs';
import {MedicalService} from '../../../../../@core/medical.service';
import {CatalogItemView} from '../../../../../@models/medical/catalog-item-view';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationService} from "../../../../../@core/navigation.service";
import {GlobalSaveService} from "../../../../../@core/global-save.service";
import {UserInfoService} from "../../../../../@core/user-info.service";
import {NotificationConfirmationsComponent} from "../../../../notification/confirmations/notification-confirmations.component";
import {MedicalSourcesService} from "../../../../../@core/medical-sources.service";
import {OtheFormNewComponent} from "./othe-form-new/othe-form-new.component";
import {MDEDICAL_TREATING_SOURCES_STATUSES} from "../../../../../@config/status/medical-treating-sources";
import {LoadingService} from "../../../../../../@util/loading/loading.service";
import {DialogService} from "../../../../../../@util/dialog/dialog.service";
import {DELETE_SOURCE_CONFIRM_MESSAGE} from "../medical-treating-sources.config";
import { PERMISSIONS } from 'src/app/@config/permissions';

@Component({
    selector: 'app-hospitals',
    templateUrl: './others.component.html',
    styleUrls: ['./others.component.scss']
})
export class OthersComponent implements OnInit {

  title: string = 'Hospitals';
  @Output()
  closeForm: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  sslgId;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  dataSource: MatTableDataSource<CatalogItemView>;

  data: Subject<CatalogItemView[]>;
  hospitals: CatalogItemView[];
  private sub: Subscription;

  canScrollLeft = false;
  canScrollRight = false;

  medicalTreatingSourcesStatuses = MDEDICAL_TREATING_SOURCES_STATUSES;
  deleteOthersArray= [];
  public PERMISSIONS = PERMISSIONS;

  constructor(private medicalService: MedicalService,
      public dialog: MatDialog,
      private route: ActivatedRoute,
      public globalSaveService: GlobalSaveService,
      private router: Router,
      private dialogService: DialogService,
      private userInfoService: UserInfoService,
      private medicalSourcesService: MedicalSourcesService,
      private loadingService: LoadingService,
      private navService: NavigationService) {
      this.dataSource = new MatTableDataSource([]);
      this.sub = navService.back().subscribe(j => this.goBack());
  }

  ngOnDestroy(): void {
      this.sub.unsubscribe();
  }

  goBack() {
      this.navService.navigate(['/claimants'], [`${this.route.snapshot.paramMap.get('sslgId')}`]);
  }

  async ngOnInit() {
      ////console.log(this.route.snapshot.parent);
      this.sslgId = this.route.snapshot.parent.params['sslgId'];

      this.loadOthers();

  }

	loadOthers(){
		this.dataSource.paginator = this.paginator;
		this.medicalService.getAllOthers(this.sslgId).subscribe((data:any) => {
            this.hospitals = data;

            this.dataSource.data = this.hospitals;
            this.data = this.dataSource.connect();

            this.loadData();
            this.checkTableHasScroll();
        });

    }

	loadData(){
		this.dataSource.data = this.hospitals;
		this.data = this.dataSource.connect();
		if (this.route.snapshot.queryParamMap.get('page')) {
			this.paginator.pageIndex = +this.route.snapshot.queryParamMap.get('page');
			this.data.next(this.dataSource._pageData(this.hospitals));
		}
	}

    otherInfo(visit: CatalogItemView) {
        const url = this.getUrl();
        this.router.navigate([url + '/' + visit.id + '/info'], {
            queryParams: {
                returnUrl: url,
                page: this.paginator.pageIndex
            }
        });
    }

    getUrl() {
        return this.router.url.indexOf('?') !== -1 ?
            this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
    }

    async addDoctor() {
        //const url = this.getUrl();
        //this.router.navigate([url + '/new'], {queryParams: {returnUrl: url, page: this.paginator.pageIndex}});
        document.querySelectorAll('.mat-drawer-content').forEach(el => {
            (<HTMLElement>el).style.overflow = 'hidden';
        });

		const dialogRef = this.dialog.open(OtheFormNewComponent, {
			data: {
				sslgId:this.sslgId
			},
			panelClass: ['mobile-popup', 'with-shadow', 'popup-scrollable'],
			// panelClass: ['mobile-popup', 'with-shadow'],
			backdropClass: 'mobile-popup__overlay',
			disableClose: true
		});

    dialogRef.componentInstance.updateOthersList.subscribe(result => {
      this.loadOthers();
    });

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				if(result.cmd == 'addVisit'){
					this.router.navigate(['/claimants/medical/'+this.sslgId+'/visit-summaries/new'],
						{queryParams: {
								returnUrl: this.route.snapshot.queryParams['returnUrl'],
								catalogItemId: result.data.id,
								type: 'OTHER',
							}});
				}else{
					this.loadOthers();
				}

			}
		});
    }

	isClaimant() {
		return this.userInfoService.isClaimant();
	}

	isSystem(){
		return this.userInfoService.getCurrentUserNameIsSystem();
	}

	delete(data,index){
		let dialog = this.dialog.open(NotificationConfirmationsComponent,{
			data:{
				message: "Are you sure you want to delete the source?"
			}
		});
		dialog.afterClosed().subscribe(j => {
			try {
				if (j.status) {
					//alert(data.id)
					this.medicalSourcesService.deleteSource(this.sslgId, data.id).subscribe(() => {
						let itemIndex = index + this.paginator.pageSize * this.paginator.pageIndex;
						this.hospitals.splice(itemIndex, 1);
						this.loadData();
					});
				}
			} catch (e) {

			}
		});
	}

	checkTableHasScroll() {
        const table = document.querySelector('.i-table');
        if (table) {
            const tableScrollW = document.querySelector('.i-table').scrollWidth;
            const clientW = document.querySelector('.i-table').clientWidth;
            if (tableScrollW !== clientW) {
                this.canScrollRight = true;
            }
        }
    const popupNew = this.route.snapshot.queryParamMap.get('popup');
    if (popupNew) {
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {popup: null},
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        }
      );
      setTimeout(() => {
        this.addDoctor();
      });
    }


    }

    tableScrolled($event) {
        const scrollLeft = $event.target.scrollLeft;
        const scrollWidth = $event.target.scrollWidth;
        const offsetWidth = $event.target.offsetWidth;

        if (scrollLeft > 0) {
            this.canScrollLeft = true;
        } else {
            this.canScrollLeft = false;
        }

        if (scrollWidth - scrollLeft > offsetWidth + 5) {
            this.canScrollRight = true;
        } else {
            this.canScrollRight = false;
        }
    }

    getTableHeadClass() {
      // if (this.isSystem()) {
      //   return 'i-btn-2';
      // }
      return 'i-btn-1';
        // if (this.globalSaveService.getStatusTrue()) {
        //     if (this.isSystem()) {
        //         return 'i-btn-2';
        //     }
        //     return 'i-btn-1';
        // } else {
        //     if (this.isSystem()) {
        //         return 'i-btn-2';
        //     }
        //     return 'i-btn-0';
        // }
    }

    isExecutive() {
        if(PERMISSIONS.HIGHER_EXEC_LEVEL_4.includes(this.userInfoService.getCurrentUserRole())){
            return true;
        }
        return false;
    }

  onMedicalTreatingSourcesStatusChange($event, doctor, select) {
    const newValue = $event.value;

    this.medicalSourcesService.changeStatusSource(this.sslgId, doctor.id, newValue).subscribe((data) => {
      if (data[status] === false) {
        this.loadingService.showError([data['message']]);
        select.value = doctor.catalogStatus;
      } else {
        this.loadData();
      }
    }, () => {
      select.value = doctor.catalogStatus;
    });
  }

  getMedicalTreatingSourcesStatus(doctor) {
    // if (doctor.catalogStatus) {
      return doctor.catalogStatus;
    // }
    // return 0;
  }
  
  toggleDeleteOthersArray(e, id) {
    if (e.checked) {
      let elExist;
      this.deleteOthersArray.forEach(el => {
        if (el.id === id) {
          elExist = el;
        }
      });
      if (!elExist) {
        this.deleteOthersArray.push({
          id: id,
          sslgId: this.sslgId
        });
      }
    } else {
      this.deleteOthersArray.forEach((el, index) => {
        if (el.id === id) {
          this.deleteOthersArray.splice(index, 1);
        }
      });
    }
  }

  deleteOthers() {
    if (!this.deleteOthersArray.length) {
      this.dialogService.show('Please, select at least one Source to delete', ['OK']);
      return;
    }

    let dialog = this.dialog.open(NotificationConfirmationsComponent,{
      data: {
        messageHTML: DELETE_SOURCE_CONFIRM_MESSAGE
      }
    });
    dialog.afterClosed().subscribe(j => {
      try {
        if (j.status) {
          this.medicalSourcesService.deleteSources(this.deleteOthersArray).subscribe(() => {
            this.deleteOthersArray = [];
            this.loadOthers();
          });
        }
      } catch (e) {

      }
    });
  }
}
