import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MedicalService } from '../../../../../@core/medical.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from "../../../../../@core/navigation.service";
import { GlobalSaveService } from "../../../../../@core/global-save.service";
import { UserInfoService } from "../../../../../@core/user-info.service";
import { NotificationConfirmationsComponent } from "../../../../notification/confirmations/notification-confirmations.component";
import { MedicalSourcesService } from "../../../../../@core/medical-sources.service";
import { DoctorFormNewComponent } from "./doctor-form-new/doctor-form-new.component";
import { DATE_FORMAT, DEFAULT_DATE } from "../../../../../@config/dateFormat";
import { MDEDICAL_TREATING_SOURCES_STATUSES } from "../../../../../@config/status/medical-treating-sources";
import { LoadingService } from "../../../../../../@util/loading/loading.service";
import { DialogService } from "../../../../../../@util/dialog/dialog.service";
import { DELETE_SOURCE_CONFIRM_MESSAGE } from "../medical-treating-sources.config";
import { PERMISSIONS } from 'src/app/@config/permissions';
export class DoctorsComponent {
    constructor(medicalService, medicalSourcesService, dialog, route, globalSaveService, router, dialogService, userInfoService, loadingService, navService) {
        this.medicalService = medicalService;
        this.medicalSourcesService = medicalSourcesService;
        this.dialog = dialog;
        this.route = route;
        this.globalSaveService = globalSaveService;
        this.router = router;
        this.dialogService = dialogService;
        this.userInfoService = userInfoService;
        this.loadingService = loadingService;
        this.navService = navService;
        this.DATE_FORMAT = DATE_FORMAT;
        this.DEFAULT_DATE = DEFAULT_DATE;
        this.title = 'Doctors';
        this.closeForm = new EventEmitter();
        this.canScrollLeft = false;
        this.canScrollRight = true;
        this.medicalTreatingSourcesStatuses = MDEDICAL_TREATING_SOURCES_STATUSES;
        this.deleteDoctorsArray = [];
        this.PERMISSIONS = PERMISSIONS;
        this.dataSource = new MatTableDataSource([]);
        this.sub = navService.back().subscribe(j => this.goBack());
    }
    ngOnDestroy() {
        this.sub.unsubscribe();
    }
    goBack() {
        this.navService.navigate(['/claimants'], [`${this.route.snapshot.paramMap.get('sslgId')}`]);
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.sslgId = this.route.snapshot.parent.paramMap.get('sslgId');
            this.loadDoctors();
        });
    }
    loadDoctors() {
        this.dataSource.paginator = this.paginator;
        this.medicalService.getAllDoctors(this.sslgId).subscribe((data) => {
            this.doctors = data;
            this.doctors.sort((a, b) => {
                const aNext = a.visits && a.visits.nextVisit ? a.visits.nextVisit : null;
                const bNext = b.visits && b.visits.nextVisit ? b.visits.nextVisit : null;
                if (aNext < bNext) {
                    return 1;
                }
                if (aNext > bNext) {
                    return -1;
                }
                return 0;
            });
            this.loadData();
        });
    }
    loadData() {
        this.dataSource.data = this.doctors;
        console.log(this.doctors);
        this.data = this.dataSource.connect();
        if (this.route.snapshot.queryParamMap.get('page')) {
            this.paginator.pageIndex = +this.route.snapshot.queryParamMap.get('page');
            this.data.next(this.dataSource._pageData(this.doctors));
        }
        const popupNew = this.route.snapshot.queryParamMap.get('popup');
        const popupNewFrom = this.route.snapshot.queryParamMap.get('from');
        if (popupNew) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { popup: null },
                queryParamsHandling: 'merge',
            });
            setTimeout(() => {
                this.addDoctor(popupNewFrom);
            });
        }
    }
    doctorInfo(visit) {
        const url = this.getUrl();
        this.router.navigate([url + '/' + visit.id + '/info'], {
            queryParams: {
                returnUrl: url,
                page: this.paginator.pageIndex
            }
        });
    }
    getUrl() {
        return this.router.url.indexOf('?') !== -1 ?
            this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
    }
    addDoctor(from) {
        //const url = this.getUrl();
        //this.router.navigate([url + '/new'], {queryParams: {returnUrl: url, page: this.paginator.pageIndex}});
        const dialogRef = this.dialog.open(DoctorFormNewComponent, {
            data: {
                sslgId: this.sslgId,
                from: from
            },
            panelClass: ['mobile-popup', 'with-shadow', 'popup-scrollable'],
            // panelClass: ['mobile-popup', 'with-shadow'],
            backdropClass: 'mobile-popup__overlay',
            disableClose: true
        });
        dialogRef.componentInstance.updateDoctorsList.subscribe(result => {
            this.loadDoctors();
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (result.cmd) {
                    if (result.cmd == 'addVisit') {
                        this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'], {
                            queryParams: {
                                returnUrl: this.route.snapshot.queryParams['returnUrl'],
                                catalogItemId: result.data.id,
                                type: 'DOCTOR',
                            }
                        });
                    }
                    else {
                        this.loadDoctors();
                    }
                }
                else if (result == 'new') {
                    this.loadDoctors();
                }
            }
        });
        document.querySelectorAll('.mat-drawer-content').forEach(el => {
            el.style.overflow = 'hidden';
        });
    }
    isClaimant() {
        if (this.userInfoService.getCurrentUserRole() == 'CLIENT') {
            return true;
        }
        return false;
    }
    isSystem() {
        return this.userInfoService.getCurrentUserNameIsSystem();
    }
    isExecutive() {
        if (PERMISSIONS.HIGHER_EXEC_LEVEL_4.includes(this.userInfoService.getCurrentUserRole())) {
            return true;
        }
        return false;
    }
    getFirstSeen(visits) {
        if (visits) {
            if (new Date(visits.firstSeen).toUTCString() !== new Date('1969-12-31 00:00:00').toUTCString()) {
                return this.getDate(visits.firstSeen);
            }
        }
        return "";
    }
    getLastSeen(visits) {
        if (visits) {
            if (new Date(visits.lastSeen).toUTCString() !== new Date('1969-12-31 00:00:00').toUTCString()) {
                return this.getDate(visits.lastSeen);
            }
        }
        return "";
    }
    getNextVisit(visits) {
        if (visits) {
            if (new Date(visits.nextVisit).toUTCString() !== new Date('1969-12-31 00:00:00').toUTCString()) {
                return this.getDate(visits.nextVisit);
            }
        }
        return "";
    }
    getDate(value) {
        if (value === this.DEFAULT_DATE) {
            return '';
        }
        var datePipe = new DatePipe("en-US");
        if (value) {
            value = datePipe.transform(value.replace(/ /g, "T"), this.DATE_FORMAT.defaultPipe);
        }
        return value;
    }
    // delete(item,index){
    // 	let dialog = this.dialog.open(NotificationConfirmationsComponent,{
    // 		data:{
    // 			message: "Are you sure you want to delete the source?"
    // 		}
    // 	});
    // 	dialog.afterClosed().subscribe(j => {
    // 		try {
    // 			if (j.status) {
    // 				this.medicalSourcesService.deleteSource(this.sslgId, item.id).subscribe(() => {
    // 					let itemIndex = index + this.paginator.pageSize * this.paginator.pageIndex;
    // 					this.doctors.splice(itemIndex, 1);
    // 					this.loadData();
    // 				});
    // 			}
    // 		} catch (e) {
    //
    // 		}
    // 	});
    // }
    tableScrolled($event) {
        const scrollLeft = $event.target.scrollLeft;
        const scrollWidth = $event.target.scrollWidth;
        const offsetWidth = $event.target.offsetWidth;
        if (scrollLeft > 0) {
            this.canScrollLeft = true;
        }
        else {
            this.canScrollLeft = false;
        }
        if (scrollWidth - scrollLeft > offsetWidth) {
            this.canScrollRight = true;
        }
        else {
            this.canScrollRight = false;
        }
    }
    getTableHeadClass() {
        // console.log(this.globalSaveService.getStatusTrue())
        // if (this.isSystem()) {
        // 	return 'i-btn-2';
        // }
        return 'i-btn-1';
        // if (this.globalSaveService.getStatusTrue()) {
        // 	if (this.isSystem()) {
        // 		return 'i-btn-2';
        // 	}
        // 	return 'i-btn-1';
        // } else {
        // 	if (this.isSystem()) {
        // 		return 'i-btn-2';
        // 	}
        // 	return 'i-btn-0';
        // }
    }
    onMedicalTreatingSourcesStatusChange($event, doctor, select) {
        const newValue = $event.value;
        this.medicalSourcesService.changeStatusSource(this.sslgId, doctor.id, newValue).subscribe((data) => {
            if (data[status] === false) {
                this.loadingService.showError([data['message']]);
                select.value = doctor.catalogStatus;
            }
            else {
                this.loadData();
            }
        }, () => {
            select.value = doctor.catalogStatus;
        });
    }
    getMedicalTreatingSourcesCatalogStatus(doctor) {
        // if (doctor.catalogStatus) {
        return doctor.catalogStatus;
        // }
        //return 0;
    }
    toggleDeleteDoctorsArray(e, id) {
        if (e.checked) {
            let elExist;
            this.deleteDoctorsArray.forEach(el => {
                if (el.id === id) {
                    elExist = el;
                }
            });
            if (!elExist) {
                this.deleteDoctorsArray.push({
                    id: id,
                    sslgId: this.sslgId
                });
            }
        }
        else {
            this.deleteDoctorsArray.forEach((el, index) => {
                if (el.id === id) {
                    this.deleteDoctorsArray.splice(index, 1);
                }
            });
        }
    }
    deleteDoctors() {
        if (!this.deleteDoctorsArray.length) {
            this.dialogService.show('Please, select at least one Doctor to delete', ['OK']);
            return;
        }
        let dialog = this.dialog.open(NotificationConfirmationsComponent, {
            data: {
                messageHTML: DELETE_SOURCE_CONFIRM_MESSAGE
            }
        });
        dialog.afterClosed().subscribe(j => {
            try {
                if (j.status) {
                    this.medicalSourcesService.deleteSources(this.deleteDoctorsArray).subscribe(() => {
                        this.deleteDoctorsArray = [];
                        this.loadDoctors();
                    });
                }
            }
            catch (e) {
            }
        });
    }
}
