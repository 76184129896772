<app-frame [overlay]="false" (neadClose)="closeForm()" [showCloseButton]="false" [showLegend]="false" autocomplete="off">
	<div class="attention-form">
		<p>
			{{data.infoText}}
		</p>
<!--		<p>-->
<!--			Are you sure?-->
<!--		</p>-->
	</div>
	<app-form-actions-content>
		<form-button (clickOnButton)="leavePage()">{{data.closeBtnText}}</form-button>
		<form-button (clickOnButton)="closeForm()" buttonType="warning">Go Back</form-button>
	</app-form-actions-content>
</app-frame>

