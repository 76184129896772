import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
	selector: 'app-form-changes-attention',
	templateUrl: './form-changes-attention.component.html',
	styleUrls: ['./form-changes-attention.component.scss']
})
export class FormChangesAttentionComponent implements OnInit {

	constructor(public dialogRef: MatDialogRef<FormChangesAttentionComponent>,
				@Inject(MAT_DIALOG_DATA) public data: any) {
	}

	ngOnInit() {
		// console.log(this.data);
	}

	closeForm() {
		this.dialogRef.close('close');
	}

	leavePage() {
		this.dialogRef.close('leave');
	}
}
