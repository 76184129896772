import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-iris-table',
	templateUrl: './iris-table.component.html',
	styleUrls: ['./iris-table.component.scss']
})
export class IrisTableComponent implements OnInit {

	@Input()
	inOneLine: boolean = false;
	@Input()
	scrollX: boolean = false;
	@Output() tableScrolled: EventEmitter<any> = new EventEmitter();


	constructor() {
	}

	ngOnInit() {
	}


	getClassList() {
		return `i-table ${this.inOneLine ? 'one-line' : ''} ${this.scrollX ? 'scroll-x' : ''}`
	}

	scroll($event) {
		this.tableScrolled.emit($event);
	}
}
