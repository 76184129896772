<fieldset [disabled]="disabled" class="form-fieldset">

  <legend [cdkDragDisabled]="dragAndDropDisabled" cdkDrag
          cdkDragRootElement=".cdk-overlay-pane" class="form-legend"
          *ngIf="showLegend && !titleHTML" >
    {{title}}
    <svg class="form-legend__icon" *ngIf="icon">
      <use [attr.xlink:href]='"../../../../assets/img/sprite.svg#"+icon'></use>
    </svg>
  </legend>

  <legend [cdkDragDisabled]="dragAndDropDisabled" cdkDrag
          cdkDragRootElement=".cdk-overlay-pane"
          class="form-legend" *ngIf="showLegend && titleHTML"
          [innerHTML]="titleHTML">
  </legend>

  <div class="form-content">
    <ng-content></ng-content>
  </div>

  <button *ngIf="showCloseButton" class="close-modal" [class.is-ios]="isIOS" (click)="closeModal()">
    <svg class="close-modal__icon">
      <use xlink:href="../../../../assets/img/sprite.svg#close"></use>
    </svg>
  </button>

</fieldset>
