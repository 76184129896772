import {Injectable,ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DEVICES_CONFIG} from "../@config/api";
import {UserInfoService} from "./user-info.service";
import {DialogService} from "../../@util/dialog/dialog.service";


@Injectable({
	providedIn: 'root'
})
export class DevicesService {
	private config = DEVICES_CONFIG;

	constructor(
		private dialog: DialogService,
		private http: HttpClient,
		private userInfoService: UserInfoService,
		) {
	}

	add(uuid:string,type:string){
		let userName = this.getUserName();

		return this.http.post<any>(this.config.add,{
			userName:userName,
			uuid:uuid,
			type:type,
		});
	}

	setLocalFirebaseToken(){
		let token = this.userInfoService.getCurrentFirebaseToken();

		this.saveFirebaseToken(token);
	}

	setFirebaseToken(token:string){
		this.saveFirebaseToken(token);
	}

	saveFirebaseToken(token:string){
		let userName = this.getUserName();
		let currentFirebaseToken = this.userInfoService.getCurrentFirebaseToken();
		let uuid = this.userInfoService.getDeviceUuid();

		if(currentFirebaseToken != token && userName != '' && uuid != ''){
			this.http.post<any>(this.config.saveFirebaseToken, {
				userName: userName,
				token: token,
				uuid: uuid
			}).subscribe(data => {
				this.userInfoService.setCurrentFirebaseToken(token);
			});
		}
	}

	getUserName(){
		return this.userInfoService.getCurrentUserName();
	}
}

