import {Injectable} from '@angular/core';
import {PERMISSIONS_CONFIG} from '../@config/api';
import {HttpClient} from '@angular/common/http';
import {Permission} from "../@models/permission/permission";
import {User} from "../@models/system-managment/user";
import {Claimant} from "../@models/system-managment/claimant";

@Injectable({
  providedIn: 'root'
})
export class ClaimantsPermissionsService {
  private config = PERMISSIONS_CONFIG;

  constructor(private http: HttpClient) {
  }

  geUsers(type) {
    return this.http.get<User[]>(this.config.users(type));
  }


  getPermissions(sslgId: string) {
    return this.http.get<Permission>(this.config.claimant.permissions(sslgId));
  }

  addPermission(sslgId: string, userName: string) {
    return this.http.post(this.config.claimant.permit(sslgId, userName), {},{responseType: 'text'});
  }

  removePermission(sslgId: string, userName: string) {
    return this.http.post(this.config.claimant.forbid(sslgId, userName), {}, {responseType: 'text'});
  }

    getClaimats() {
        return this.http.get<Claimant[]>(this.config.claimant.claimants);
    }
}
