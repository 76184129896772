import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-medical-treating-sources',
    templateUrl: './medical-treating-sources.component.html',
    styleUrls: ['./medical-treating-sources.component.scss']
})

export class MedicalTreatingSourcesComponent implements OnInit {
    public hideHeader = false;
    sslgId: string;
    board = ['Visits', 'Doctors'];
    left = ['Hospitals'];
    right = [];
    navLinks;

    constructor(private route: ActivatedRoute) {
    }


    async ngOnInit() {
        this.sslgId = this.route.snapshot.paramMap.get('sslgId');
        this.navLinks = [
            {
                path: '/claimants/medical-treating-sources/' + this.sslgId + '/doctors',
                label: 'Doctors',
            },
            {
                path: '/claimants/medical-treating-sources/' + this.sslgId + '/hospitals',
                label: 'Hospitals',
            },
            {
                path: '/claimants/medical-treating-sources/' + this.sslgId + '/others',
                label: 'Others',
            },
            {
                path: '/claimants/medical-treating-sources/' + this.sslgId + '/medications',
                label: 'Medications',
            }
			
        ];
    }
}
