export interface CatalogItemView {
    id;
    name;
    phone;
    type: CatalogItemType;
    city;
    state;
    address;
    phoneFormatted;
	visits;
	medicalConditions;
	faxFormatted;
	fax;
	lastEr;
	lastOp;
	lastInp;
}

export enum CatalogItemType {
    DOCTOR,
    HOSPITAL,
    OTHER
}
