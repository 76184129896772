import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {POST_AWARD_CONFIG} from "../@config/api";
import {CoraAuthRequest} from "../@models/cora-auth-request";

@Injectable({
    providedIn: 'root'
})
export class CoraAuthorizationService {
    private config = POST_AWARD_CONFIG.auth;

    constructor(private http: HttpClient) {
    }

    getAuth(sslgId) {
        return this.http.get<CoraAuthRequest>(this.config.auth(sslgId))
    }

    getSent(sslgId, url) {
        return this.http.post<CoraAuthRequest>(this.config.sent(sslgId),{url: url})
    }

    getReceive(sslgId) {
        return this.http.post<CoraAuthRequest>(this.config.receive(sslgId),{})
    }

    clearAuth(sslgId: string) {
        return this.http.post<CoraAuthRequest>(this.config.clear(sslgId),{})
    }
    setCoraAuthText(sslgId,text: string) {
    	return this.http.post<String>(this.config.setText(sslgId), text);
	}
}
