import {Injectable} from '@angular/core';
import {CatalogItemView} from '../@models/medical/catalog-item-view';
import {DoctorItem} from '../@models/medical/doctor-item';
import {HospitalItem} from '../@models/medical/hospital-item';
import {OtherItem} from '../@models/medical/othe-item';
import {MEDICAL_SOURCE_CONFIG} from '../@config/api';
import {HttpClient} from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class MedicalSourcesService {

	private config = MEDICAL_SOURCE_CONFIG;

	constructor(private http: HttpClient) {

	}

	getAllDoctors() {
		return this.http.get<CatalogItemView[]>(this.config.getAllDoctors);
	}

	getDoctorById(doctorId) {
		return this.http.get<DoctorItem>(this.config.getDoctorById(doctorId));
	}

	setDoctor(result: DoctorItem) {
		return this.http.post<DoctorItem>(this.config.setDoctor, result);
	}

	saveTreatingSourcesRequests(sslgId: String, sourceId: String, requests) {
		return this.http.post<DoctorItem>(this.config.saveTreatingSourcesRequests(sslgId), {sourceId:sourceId,requests:requests});
	}

	saveTreatingSourcesDoctor(sslgId: String, result: DoctorItem) {
		return this.http.post<DoctorItem>(this.config.saveTreatingSourcesDoctor(sslgId), result);
	}

	async addTreatingSourcesDoctor(sslgId, result, typeClaimAppeal, dateIc, dateRecon, dateHrg, treatment, medicalTests, medicalTestsList, medications, medicationsList) {
		const data = {
			...result,
			typeClaimAppeal: typeClaimAppeal,
			dateIc: dateIc,
			dateRecon: dateRecon,
			dateHrg: dateHrg,
			treatment: treatment,
			medicalTests,
			medicalTestsList,
			medications,
			medicationsList
		};
		// console.log(data);

		return await this.http.post<DoctorItem>(this.config.addTreatingSourcesDoctor(sslgId), data).toPromise();
	}

	addTreatingSourcesDoctorDates(sslgId: String) {
		return this.http.post<DoctorItem>(this.config.addTreatingSourcesDoctorDates(sslgId),{});
	}

	async addTreatingSourcesHospital(sslgId: String, result: DoctorItem, eRVisit, eRVisitDate, outPatient, outPatientDate, inPatient, inPatientAdmission, inPatientDischarge, hospitalizationReason, treatmentDetails, conditions, treatedDoctor, medications, medicationsList, treatment, medicalTests, medicalTestsList) {
		const data = {
			...result,
			eRVisit,
			eRVisitDate,
			outPatient,
			outPatientDate,
			inPatient,
			inPatientAdmission,
			inPatientDischarge,
			hospitalizationReason,
			treatmentDetails,
			conditions,
			treatedDoctor,
			medications,
			medicationsList,
			treatment,
			medicalTests,
			medicalTestsList
		};

		return await this.http.post<DoctorItem>(this.config.addTreatingSourcesHospital(sslgId), data).toPromise();
	}

	async addHospitalVisit(sslgId: String, catalogItemId, eRVisit, eRVisitDate, outPatient, outPatientDate, inPatient, inPatientAdmission, inPatientDischarge, hospitalizationReason, treatmentDetails, conditions, treatedDoctor, medications, medicationsList, treatment, medicalTests, medicalTestsList) {
		const data = {
			catalogItemId,
			eRVisit,
			eRVisitDate,
			outPatient,
			outPatientDate,
			inPatient,
			inPatientAdmission,
			inPatientDischarge,
			hospitalizationReason,
			treatmentDetails,
			conditions,
			treatedDoctor,
			medications,
			medicationsList,
			treatment,
			medicalTests,
			medicalTestsList
		};

		return await this.http.post<DoctorItem>(this.config.addHospitalVisit(sslgId), data).toPromise();
	}

	saveTreatingSourcesHospital(sslgId: String, result: DoctorItem) {
		return this.http.post<DoctorItem>(this.config.saveTreatingSourcesHospital(sslgId), result);
	}

	async addTreatingSourcesOther(sslgId: String, result: DoctorItem, treatmentStart, treatmentEnd, treatmentOngoing, treatmentReason, treatmentDetails, nextVisit) {
		const data = {
			...result,
			treatmentStart,
			treatmentEnd,
			treatmentOngoing,
			treatmentReason,
			treatmentDetails,
			nextVisit
		};

		// console.log(data);
		return await this.http.post<DoctorItem>(this.config.addTreatingSourcesOther(sslgId), data).toPromise();
	}

	saveTreatingSourcesOther(sslgId: String, result: OtherItem) {
		return this.http.post<OtherItem>(this.config.saveTreatingSourcesOther(sslgId), result);
	}

	treatingSourcesRequestsList(sslgId: String, sourceId: String) {
		return this.http.post<any>(this.config.treatingSourcesRequestsList(sslgId), {sourceId:sourceId});
	}

	treatingSourcesPaymentsList(sslgId: String, sourceId: String) {
		return this.http.post<any>(this.config.treatingSourcesPaymentsList(sslgId), {sourceId:sourceId});
	}

	saveTreatingSourcesRequest(sslgId: String,id: String,sourceId: String, data) {
		return this.http.post<any>(this.config.saveTreatingSourcesRequest(sslgId,id,sourceId), data);
	}

	saveTreatingSourcesPayment(sslgId: String,id: String,sourceId: String, data) {
		return this.http.post<any>(this.config.saveTreatingSourcesPayment(sslgId,id,sourceId), data);
	}

	treatingSourcesFindPayment(id: String) {
		return this.http.post<any>(this.config.treatingSourcesFindPayment(), {id:id});
	}

	treatingSourcesLastUserToRecords(sourceId: String){
		return this.http.post<any>(this.config.treatingSourcesLastUserToRecords(sourceId), {});
	}

	treatingSourcesFindRequest(id: String) {
		return this.http.post<any>(this.config.treatingSourcesFindRequest(), {id:id});
	}

	deleteSourceRequest(request: String) {
		return this.http.post<any>(this.config.deleteSourceRequest(), {requestId:request});
	}

	deleteSourcePayment(request: String) {
		return this.http.post<any>(this.config.deleteSourcePayment(), {requestId:request});
	}

	getHospital(hospitalId: number) {
		return this.http.get<HospitalItem>(this.config.getHospitalById(hospitalId));
	}

	getClaimantDoctors(sslgId: string) {
		return this.http.get<CatalogItemView[]>(this.config.getClaimantDoctors(sslgId));
	}

	getAllHospitals() {
		return this.http.get<CatalogItemView[]>(this.config.getAllHospitals);
	}

	setHospital(result: HospitalItem) {
		return this.http.post<HospitalItem>(this.config.setHospital, result);
	}


	getOther(id: number) {
		return this.http.get<OtherItem>(this.config.getOtherById(id));
	}

	getAllOthers() {
		return this.http.get<CatalogItemView[]>(this.config.getAllOthers);
	}

	setOther(result: OtherItem) {
		return this.http.post<OtherItem>(this.config.setOther, result);
	}

	deleteSource(sslgId, id) {
		return this.http.post<HospitalItem>(this.config.deleteSource, {sslgId, id});
	}

	deleteSources(sources) {
		return this.http.post<any>(this.config.deleteSources, {sources});
	}

	changeStatusSource(sslgId, id, status) {
		return this.http.post<HospitalItem>(this.config.changeStatusSource, {sslgId, id, status});
	}
}
