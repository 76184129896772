import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';

@Component({
	selector: 'app-http-error-message',
	templateUrl: './http-error-message.component.html',
	styleUrls: ['./http-error-message.component.scss']
})
export class HttpErrorMessageComponent implements OnInit, OnDestroy {

	title: string[];
	buttons: string[];
	onCloseDialog: EventEmitter<any> = new EventEmitter<any>();
	buttonClicked: EventEmitter<string> = new EventEmitter<string>();

	constructor() {
	}

	ngOnInit() {
		//console.log(this.title);
	}

	click(button) {
		this.buttonClicked.emit(button);
		this.onCloseDialog.emit();
	}

	ngOnDestroy(): void {
		this.onCloseDialog.unsubscribe();
	}

	getTitle(item) {
		if (item){
			return item.split(" ").map(j => {
				if (j.length > 10) {
					return j.slice(0, 10) + '...';
				} else {
					return j;
				}
			}).join(' ');
		}
		return '';

	}
}
