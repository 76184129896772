/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./medical-treating-report-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./medical-treating-report-medications/medical-treating-report-medications.component.ngfactory";
import * as i3 from "./medical-treating-report-medications/medical-treating-report-medications.component";
import * as i4 from "../../../@util/loading/loading.service";
import * as i5 from "./medical-treating-report-mts/medical-treating-report-mts.component.ngfactory";
import * as i6 from "./medical-treating-report-mts/medical-treating-report-mts.component";
import * as i7 from "../partial/form-button/form-button.component.ngfactory";
import * as i8 from "../partial/form-button/form-button.component";
import * as i9 from "@angular/common";
import * as i10 from "./medical-treating-report-page.component";
import * as i11 from "@angular/router";
import * as i12 from "../../@core/medical.service";
var styles_MedicalTreatingReportPageComponent = [i0.styles];
var RenderType_MedicalTreatingReportPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MedicalTreatingReportPageComponent, data: {} });
export { RenderType_MedicalTreatingReportPageComponent as RenderType_MedicalTreatingReportPageComponent };
function View_MedicalTreatingReportPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-medical-treating-report-medications", [], null, null, null, i2.View_MedicalTreatingReportMedicationsComponent_0, i2.RenderType_MedicalTreatingReportMedicationsComponent)), i1.ɵdid(1, 114688, null, 0, i3.MedicalTreatingReportMedicationsComponent, [i4.LoadingService], { tableData: [0, "tableData"], headerInfo: [1, "headerInfo"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.medicationsData; var currVal_1 = _co.headerInfo; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MedicalTreatingReportPageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-medical-treating-report-mts", [], null, null, null, i5.View_MedicalTreatingReportMtsComponent_0, i5.RenderType_MedicalTreatingReportMtsComponent)), i1.ɵdid(1, 114688, null, 0, i6.MedicalTreatingReportMtsComponent, [i4.LoadingService], { tableData: [0, "tableData"], headerInfo: [1, "headerInfo"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.MTSData; var currVal_1 = _co.headerInfo; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MedicalTreatingReportPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "view-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n\t"])), (_l()(), i1.ɵeld(2, 0, [[3, 0], ["report", 1]], null, 20, "div", [["class", "view-wrapper view-wrapper--no-mobile-padding"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["body", 1]], null, 17, "div", [["class", "visit-card"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "print-button no-print"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "form-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "form-button", [], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.print() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_FormButtonComponent_0, i7.RenderType_FormButtonComponent)), i1.ɵdid(11, 638976, null, 0, i8.FormButtonComponent, [], null, { clickOnButton: "clickOnButton" }), (_l()(), i1.ɵted(-1, 0, ["Print"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MedicalTreatingReportPageComponent_2)), i1.ɵdid(17, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MedicalTreatingReportPageComponent_3)), i1.ɵdid(20, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\n\n\n"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 11, 0); var currVal_0 = (_co.type == _co.MEDICAL_TREATING_REPORT_TYPE.medications); _ck(_v, 17, 0, currVal_0); var currVal_1 = ((_co.type == _co.MEDICAL_TREATING_REPORT_TYPE.mts) || (_co.type === _co.MEDICAL_TREATING_REPORT_TYPE.both)); _ck(_v, 20, 0, currVal_1); }, null); }
export function View_MedicalTreatingReportPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { body: 1 }), i1.ɵqud(402653184, 2, { head: 0 }), i1.ɵqud(402653184, 3, { report: 0 }), i1.ɵqud(402653184, 4, { footer: 0 }), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MedicalTreatingReportPageComponent_1)), i1.ɵdid(19, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showContent && _co.data); _ck(_v, 19, 0, currVal_0); }, null); }
export function View_MedicalTreatingReportPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-medical-treating-report-page", [], null, null, null, View_MedicalTreatingReportPageComponent_0, RenderType_MedicalTreatingReportPageComponent)), i1.ɵdid(1, 4308992, null, 0, i10.MedicalTreatingReportPageComponent, [i11.ActivatedRoute, i11.Router, i4.LoadingService, i12.MedicalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MedicalTreatingReportPageComponentNgFactory = i1.ɵccf("app-medical-treating-report-page", i10.MedicalTreatingReportPageComponent, View_MedicalTreatingReportPageComponent_Host_0, {}, {}, []);
export { MedicalTreatingReportPageComponentNgFactory as MedicalTreatingReportPageComponentNgFactory };
