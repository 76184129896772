<div class="ngx-image-editor-component" fxLayout="column" fxLayoutAlign="center stretch">
  <div mat-dialog-content
       #dialogCropContainer
       class="dialog-crop-container"
       fxLayout="column"
       fxLayoutAlign="center center"
       fxFlex="grow">
    <ng-template [ngIf]="!croppedImage">
      <!--[style.visibility]="loading ? 'hidden' : 'visible'"-->
      <div style="visibility: hidden"
           [style.background]="canvasFillColor"
           class="img-container">
        <img #previewimg
             [src]="previewImageURL">
      </div>
    </ng-template>
    <ng-template [ngIf]="croppedImage && !loading">
      <div class="cropped-image">
        <img #croppedImg

             [src]="croppedImage">
        <!--[ngStyle]="{'transform': 'scale(' + zoomIn + ')'}"-->
      </div>
    </ng-template>
    <!--<mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>-->
  </div>
  <div class="dialog-button-actions" mat-dialog-actions fxLayout="column" align="start" fxFlex="nogrow" style="padding-top: 10px;">
    <div [style.display]="!croppedImage ? 'none' : 'flex'">
      <form-button (click)="saveImage()">
        <mat-icon>done</mat-icon>
        <span>Save</span>
      </form-button>
      <form-button [buttonType]="'disabled'" (click)="undoCrop()">
        <mat-icon>undo</mat-icon>
        <span>Undo</span>
      </form-button>
    </div>
    <!--<div  [style.display]="croppedImage ? 'none' : 'flex'">-->
    <!--<form-button (click)="handleCrop()">-->
    <!--<mat-icon>crop</mat-icon>-->
    <!--<span>Crop</span>-->
    <!--</form-button>-->
    <!--<form-button [buttonType]="'disabled'" (click)="centerCanvas()" >-->
    <!--<mat-icon>center_focus_strong</mat-icon>-->
    <!--<span>Center</span>-->
    <!--</form-button>-->
    <!--</div>-->
  </div>
</div>
