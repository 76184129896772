import { HttpClient } from "@angular/common/http";
import { DIARIES_CONFIG } from "../@config/api";
import { UserInfoService } from "./user-info.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user-info.service";
export class DiariesService {
    constructor(http, userInfoService) {
        this.http = http;
        this.userInfoService = userInfoService;
        this.config = DIARIES_CONFIG;
    }
    getList(type, user) {
        let userName = user ? user : this.getUserName();
        return this.http.post(this.config.getList, {
            userName: userName,
            type: type
        });
    }
    getUserName() {
        return this.userInfoService.getCurrentUserName();
    }
}
DiariesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DiariesService_Factory() { return new DiariesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserInfoService)); }, token: DiariesService, providedIn: "root" });
