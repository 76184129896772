export class AppStorage {

  public static getStorage() {
    if (window.navigator.userAgent == 'SSLG_Browser') {
        return sessionStorage;
    } else {
      return localStorage;
    }
  }

}
