import { HttpClient, HttpParams } from '@angular/common/http';
import * as conf from '../@config/api';
import { Social } from '../@models/system-managment/social';
import { Functions } from '../../@util/functions';
import { FileUploadingService } from "../../@util/file-uploading-progress/file-uploading.service";
import { FormMode } from "../@models/system-managment/form-mode";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../@util/file-uploading-progress/file-uploading.service";
export class SystemUsersService {
    constructor(http, fileUploadingService) {
        this.http = http;
        this.fileUploadingService = fileUploadingService;
        this.config = conf.SYSTEM_USER_CONFIG;
    }
    addUser(user) {
        return this.http.post(this.config.add, user);
    }
    getUsersList(role) {
        let httpParams = new HttpParams();
        if (role !== null)
            httpParams = httpParams.append('role', role);
        return this.http.get(this.config.getAll, { params: httpParams });
    }
    mapUserStaff(data) {
        return data.map(j => {
            let thisLabel = '';
            if (!(j.personal && j.personal.firstName && j.personal.lastName)) {
                thisLabel = 'Fill Name';
            }
            else {
                thisLabel = j.personal.firstName + ' ' + j.personal.lastName;
            }
            if (j.personal && j.personal.title) {
                thisLabel = thisLabel + ', ' + j.personal.title;
            }
            else {
                thisLabel = thisLabel + ' ';
            }
            if (!j.contact || !j.contact.email) {
                thisLabel = thisLabel + '';
            }
            else {
                thisLabel = thisLabel + ' ~ ' + j.contact.email;
            }
            return {
                value: j.username,
                label: thisLabel
            };
        });
    }
    getCurrentUser(userId) {
        return this.http.get(this.config.getById(userId));
    }
    updatePasswordAndActive(value) {
        return this.http.post(this.config.updateLoginInfo(value.username), value);
    }
    setContact(userName, value, formMode) {
        value.press = Functions.checkPhone(value.press);
        value.fax = Functions.checkPhone(value.fax);
        value.phone = Functions.checkPhone(value.phone);
        const url = formMode !== FormMode.SelfEdit ? this.config.setContact(userName) : this.config.selfEdit.setContact(userName);
        return this.http.post(this.config.setContact(userName), value);
    }
    setPersonal(userName, value, formMode) {
        value.phone = Functions.checkPhone(value.phone);
        ////console.log(formMode);
        const url = formMode !== FormMode.SelfEdit ? this.config.setPersonal(userName) : this.config.selfEdit.setPersonal(userName);
        return this.http.post(url, value);
    }
    setSocial(userName, value, formMode) {
        Social.checkLinck(value);
        const url = formMode !== FormMode.SelfEdit ? this.config.setSocial(userName) : this.config.selfEdit.setSocial(userName);
        return this.http.post(this.config.setSocial(userName), value);
    }
    uploadAvatar(username, avatar) {
        return this.fileUploadingService.uploadFile('/api/user/avatar/' + username, { file: avatar, fileInfo: null });
    }
}
SystemUsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SystemUsersService_Factory() { return new SystemUsersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FileUploadingService)); }, token: SystemUsersService, providedIn: "root" });
