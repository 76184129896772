import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
export class MedicalTreatingSourcesComponent {
    constructor(route) {
        this.route = route;
        this.hideHeader = false;
        this.board = ['Visits', 'Doctors'];
        this.left = ['Hospitals'];
        this.right = [];
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.sslgId = this.route.snapshot.paramMap.get('sslgId');
            this.navLinks = [
                {
                    path: '/claimants/medical-treating-sources/' + this.sslgId + '/doctors',
                    label: 'Doctors',
                },
                {
                    path: '/claimants/medical-treating-sources/' + this.sslgId + '/hospitals',
                    label: 'Hospitals',
                },
                {
                    path: '/claimants/medical-treating-sources/' + this.sslgId + '/others',
                    label: 'Others',
                },
                {
                    path: '/claimants/medical-treating-sources/' + this.sslgId + '/medications',
                    label: 'Medications',
                }
            ];
        });
    }
}
