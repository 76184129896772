import {Component, Input, OnInit} from '@angular/core';
import {UserAdditionalInfo} from "../../../@models/user-additional-info";
import {environment} from "../../../../environments/environment";
import {Claimant} from "../../../@models/system-managment/claimant";
import {ReferralService} from "../../../@core/referral.service";
import {GlobalSaveService} from "../../../@core/global-save.service";

@Component({
	selector: 'app-additional-user-info',
	templateUrl: './additional-user-info.component.html',
	styleUrls: ['./additional-user-info.component.scss']
})
export class AdditionalUserInfoComponent implements OnInit {
	additionalInfo = new UserAdditionalInfo();
	_currentRole;
	_currentUser;
	_currentUserInfo;
	@Input()
	set currentRole(role) {
		if (role) {
			this._currentRole = role;
			this.isSetAdditional();
		}
	};
	@Input()
	set currentUser(user: Claimant) {
		if (user) {
			this._currentUser = user;
			this.isSetAdditional();
		}
	};
	@Input()
	set currentUserInfo(userInfo) {
		if (userInfo) {
			this._currentUserInfo = userInfo;
			this.isSetAdditional();
		}
	};

	constructor(private referallService: ReferralService,
				private globalSaveService:GlobalSaveService) {
	}

	ngOnInit() {
		// this.setAdditionalInfo();
	}

	isSetAdditional() {
		if (this._currentUser && this._currentRole && this._currentUserInfo) {
			let additionalObjName = Object.getOwnPropertyNames(this.additionalInfo);
			for (let i in additionalObjName) {
				this.additionalInfo[additionalObjName[i]].name = null;
				this.additionalInfo[additionalObjName[i]].img = null;
			}
			this.setAdditionalInfo();
		}
	}

	setAdditionalInfo() {
		let levelDetail = this._currentUserInfo.levelDetails;

		if (this._currentRole && this._currentUser && this._currentRole.indexOf('CLIENT') !== -1) {

			this.additionalInfo.left.name = this._currentUserInfo.lastAccessedName;
			this.additionalInfo.left.img = this.getAvatarUrl(this._currentUserInfo.lastAccessedImg);
			this.additionalInfo.left.title = "Last SSLG Staffer who worked on the record";
			this.additionalInfo.left.email = this._currentUserInfo.lastAccessedEmail;
			this.additionalInfo.left.phone = this._currentUserInfo.lastAccessedPhone;
		} else {
			this.additionalInfo.left.name = this._currentUserInfo.claimantName;
			this.additionalInfo.left.img = this.getAvatarUrl(this._currentUserInfo.claimantAvatar);
			this.additionalInfo.left.title = "";
			this.additionalInfo.left.email = this._currentUserInfo.claimantEmail;
			if(this._currentUserInfo.phone){
				this.additionalInfo.left.phone = this._currentUserInfo.phone;
			}else{
				this.additionalInfo.left.phone = this._currentUserInfo.claimantPhone;
			}

		}

		this.additionalInfo.middle.name = this._currentUserInfo.assignedToName;
		this.additionalInfo.middle.img = this.getAvatarUrl(this._currentUserInfo.assignedToAvatar);
		this.additionalInfo.middle.title = "SSLG Staffer Assigned to your case";
		this.additionalInfo.middle.phone = this._currentUserInfo.assignedToPhone;
		this.additionalInfo.middle.email = this._currentUserInfo.assignedToEmail;

		let statusAward = false;
		for(let i=0;i<levelDetail.length;i++){
			if(levelDetail[i].decision == 'AWARDED'){
				statusAward = true;

				break;
			}
		}

		if(statusAward){
			this.additionalInfo.middle.name = this._currentUserInfo.postAwardCSRName;
			this.additionalInfo.middle.img = this.getAvatarUrl(this._currentUserInfo.postAwardCSRAvatar);
			this.additionalInfo.middle.title = "Post Award SSLG Staffer Assigned to your case";
			this.additionalInfo.middle.phone = this._currentUserInfo.postAwardCSRPhone;
			this.additionalInfo.middle.email = this._currentUserInfo.postAwardCSREmail;
		}

		this.additionalInfo.right.name = this._currentUserInfo.claimantManagerName;
		this.additionalInfo.right.img = this.getAvatarUrl(this._currentUserInfo.claimantManagerImg);
		this.additionalInfo.right.title = "LTD adjuster managed by";
		this.additionalInfo.right.directDial = this._currentUserInfo.managetDirectDial;
		this.additionalInfo.right.phone = this._currentUserInfo.managerPhone;
		this.additionalInfo.right.email = this._currentUserInfo.managerEmail;
	}

	getAvatarUrl(id) {
		if (this._currentUser) {
			const url = '/api/user/avatar/' + id;
			if (environment.production) {
				return window.location.origin + url;
			} else {
				return 'https://sslg.dev.iriskeysolutions.com' + url;
			}
		}
	}

	isStaff(){
		return this.globalSaveService.isStaff();
	}
}
