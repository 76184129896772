import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {
    DynamicDatePickerModel, DynamicFormControlComponent,
    DynamicFormControlCustomEvent,
    DynamicFormLayout,
    DynamicFormLayoutService, DynamicFormValidationService
} from "@ng-dynamic-forms/core";
import { LabelOptions, MAT_LABEL_GLOBAL_OPTIONS } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatInput } from "@angular/material/input";
import {DatepickerCustomModel} from "./datepicker-custom-model";


import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment} from 'moment';

const moment = _rollupMoment || _moment;


@Component({
    selector: 'app-datepicker-custom',
    templateUrl: './datepicker-custom.component.html',
    styleUrls: ['./datepicker-custom.component.scss'],
	providers: [
		{provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
	],
})
export class DatepickerCustomComponent extends DynamicFormControlComponent implements OnInit{

	selectedValue;

    @Input() group: FormGroup;
    @Input() layout: DynamicFormLayout;
    @Input() model: DatepickerCustomModel;

    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
    @Output() focus: EventEmitter<any> = new EventEmitter();

    @ViewChild("matDatepicker", { static: true }) matDatePicker: MatDatepicker<any>;
    @ViewChild(MatInput, { static: false }) matInput: MatInput;

	date = null;
	minDate = null;

    constructor(protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService,
                @Inject(MAT_LABEL_GLOBAL_OPTIONS) @Optional() public LABEL_OPTIONS: LabelOptions) {

        super(layoutService, validationService);
    }

    ngOnInit() {

    	this.minDate = this.model.min;

    	this.model.valueUpdates.subscribe((j:any) => {
    		if (j) {
				this.selectedValue = j;

				let dateValue = new Date(j);

				let year = dateValue.getFullYear();
				let month = dateValue.getMonth();
				let day = dateValue.getDate();

				this.date = new FormControl(moment([year, month, day]));
			}
		});

    }

    datePick(event) {
		this.model.valueUpdates.next(event);
	}

    onChange(event) {
        super.onChange(event);
    }

	clearInput() {
		this.matInput.value = '';

		super.onChange({});
	}

	test(r){
    	//alert(r)
	}
}
