import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Subject} from 'rxjs';
import {Alert} from '../@models/notification/alert';
import * as Stomp from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {NOTIFICATIONS_CONFIG, VERSION_CONFIG} from "../@config/api";
import {Versions} from "../@models/versions/versions";

@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	private notifiRecived = new EventEmitter();
	private alertsRecived = new EventEmitter();
	public alertsQtyChanged = new BehaviorSubject(null);
	private connection;

	private config = NOTIFICATIONS_CONFIG;

	constructor(private http: HttpClient) {
	}

	connect() {
		// this.disconnect();
		// this.connection = setInterval(() => {

		// }, 3000);
		const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));



		const url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? environment.production ? `:${window.location.port}` : ':8191' : ''}`;
		const socket = new SockJS(url + '/ws?token=' + currentUser.token);
		const stompClient = Stomp.over(socket);
		// Subscribe the '/notify' channell
		stompClient.connect({}, (frame) => {

			stompClient.subscribe('/user/notifications', (notification) => {
				this.notifiRecived.emit(JSON.parse(notification.body));

			});
			stompClient.subscribe('/user/notifications/alerts', (notification) => {
				// Call the notify function when receive a notification
				this.alertsRecived.emit(JSON.parse(notification.body));
			});
		});
		setTimeout(() => {
			this.http.get('/api/notifications').subscribe(j => {
				this.notifiRecived.emit(j);
			});
			this.http.get('/api/notifications/alerts').subscribe(j => {
				this.alertsRecived.emit(j);
			});
		}, 300);
	}

	disconnect() {
		clearInterval(this.connection);
		this.connection = undefined;
	}

	getNotifications(): Subject<any> {
		return this.notifiRecived;
	}

	getAlerts(): Subject<any> {
		return this.alertsRecived;
	}

	getNotificationsQuantity(sslgId){
		return this.http.get<any>(this.config.quantity(sslgId));
	}

	alertNotificationsWatched(id) {
		return this.http.post<Versions>(this.config.setWatched, {id:id})
	}

	getNotification(sslgId: number, notificationId: number) {
		/*return this.http.get<Alert>('/api/audit-log/' + sslgId + '/read/' + notificationId).pipe(map(j => {
			if (j.content) {
				Object.keys(j.content).forEach(i => {
					if (j.content[i]) {
						j.content[i] = JSON.parse(j.content[i]);
					}
				});
			}
			return j;
		}));*/
		return this.http.get<Alert>('/api/claimant/' + sslgId + '/audit-log/read/' + notificationId).pipe(map(j => {
			if (j.content) {
				Object.keys(j.content).forEach(i => {
					if (j.content[i]) {
						j.content[i] = JSON.parse(j.content[i]);
					}
				});
			}
			return j;
		}));
	}

	setAlertsQtyChanged(){
		this.alertsQtyChanged.next(Math.round(+new Date()/1000)+Math.random());
	}
}
