import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavigationService} from './navigation.service';
import {UserInfoService} from './user-info.service';
import {SystemUsersService} from './system-users.service';
import {AdjustersService} from './adjusters.service';
import {AdvisorsService} from './advisors.service';
import {InsurancesService} from './insurances.service';
import {OfficeService} from './office.service';
import {ClientsService} from './clients.service';
import {AuditLogService} from './audit-log.service';
import {DashboardService} from './dashboard.service';
import {DocumentService} from './document.service';
import {MedicalRecordService} from './medical-record.service';
import {MedicalService} from './medical.service';
import {ReferralService} from './referral.service';
import {StatusService} from './status.service';
import {NotificationService} from './notification.service';
import {MedicalSourcesService} from './medical-sources.service';
import {ClaimantsPermissionsService} from './claimants-permissions.service';
import {LtdInfoService} from './ltd-info.service';
import {CodeDictionaryService} from './code-dictionary.service';
import {PostAwardService} from './post-award.service';
import {CoraAuthorizationService} from "./cora-authorization.service";
import {PostAwardDetailsService} from "./post-award-details.service";
import {CoraDetailsService} from "./cora-details.service";
import {VersionService} from "./version.service";
import {MenuService} from "./menu.service";
import {AddressService} from "./address.service";
import {FieldOfficeService} from "./field-office.service";
import {ClaimantHearingOfficeService} from "./claimant-hearing-office.service";

@NgModule({
    imports: [
        CommonModule
    ]
})
export class CoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CoreModule,
            providers: [
                AdjustersService,
                AdvisorsService,
                AuditLogService,
                ClientsService,
                DashboardService,
                DocumentService,
                InsurancesService,
                MedicalService,
                MedicalRecordService,
                NavigationService,
                OfficeService,
                ReferralService,
                StatusService,
                SystemUsersService,
                UserInfoService,
                NotificationService,
                MedicalSourcesService,
                ClaimantsPermissionsService,
                LtdInfoService,
                CodeDictionaryService,
                PostAwardService,
                CoraAuthorizationService,
                PostAwardDetailsService,
                CoraDetailsService,
                VersionService,
				MenuService,
				AddressService,
				FieldOfficeService,
				ClaimantHearingOfficeService
            ]
        };
    }
}
