<div class="overlay">
    <div class="mask">

		<div class="tnfr-modal">
			<app-frame [showCloseButton]="buttons?.length===1" (neadClose)="this.onCloseDialog.emit()">
				<div class="tnfr-modal__content">
					<div cdkDrag cdkDragRootElement=".tnfr-modal" class="head">
						{{title}}
					</div>
					<button *ngFor="let btn of buttons" class="btn-inp" #enroll (click)="click(btn)">{{btn}}</button>
				</div>
			</app-frame>
		</div>

    </div>
</div>
