import {Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {AuthenticationService} from './auth/_services/authentication.service';
import {UserIdleConfig} from './auth/user-idle/user-idle-config';
import {MatPaginator} from "@angular/material";
import {UserInfoService} from './@core/user-info.service';
import {PhoneService} from './@core/phone.service'
import {PageLoaderService} from "./@core/page-loader.service";

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
	animations: [
		// routerTransition(),
	]
})
export class AppComponent implements OnInit {
	show = true;
	constructor(private router: Router,
				private pageLoaderService: PageLoaderService,
				private authService: AuthenticationService,
				private userInfoService: UserInfoService,
				private phoneService: PhoneService,
				private route: ActivatedRoute,) {
		if (sessionStorage.getItem('currentUser')) {
			UserIdleConfig.REMEMBER_ME = false;
		}


		// this.router.events.subscribe((ev) => {
		// 	if (ev instanceof NavigationEnd) {
		// 		//alert('ssss')
		// 	}
		// });
		this.pageLoaderService.loaderShow.subscribe((value: any) => this.loaderChanged(value));
		var isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
		if (isMac) {
			document.body.classList.add('is-mac-ios');
		}
	}

	async ngOnInit() {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const deviceId = urlParams.get('device-id');
		const deviceType = urlParams.get('device-type');
		if (deviceId && deviceType) {
			this.userInfoService.setDeviceData(deviceId, deviceType);
		}

		this.phoneService.init();

		await this.authService.checkSession();
	}


	loaderChanged(value) {
		this.show = value;
	}



}
