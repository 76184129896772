/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./http-error-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../app/@view/partial/forms/form-frame/form-frame.component.ngfactory";
import * as i4 from "../../../app/@view/partial/forms/form-frame/form-frame.component";
import * as i5 from "./http-error-message.component";
var styles_HttpErrorMessageComponent = [i0.styles];
var RenderType_HttpErrorMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HttpErrorMessageComponent, data: {} });
export { RenderType_HttpErrorMessageComponent as RenderType_HttpErrorMessageComponent };
function View_HttpErrorMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "head"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTitle(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_HttpErrorMessageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["enroll", 1]], null, 4, "button", [["class", "btn-inp"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.click(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "btn-green": 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "btn-inp"; var currVal_1 = _ck(_v, 3, 0, (_v.context.$implicit === "OK")); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 4, 0, currVal_2); }); }
export function View_HttpErrorMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 17, "div", [["class", "mask"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 14, "div", [["class", "tnfr-modal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 11, "app-frame", [], null, [[null, "neadClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("neadClose" === en)) {
        var pd_0 = (_co.onCloseDialog.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FormFrameComponent_0, i3.RenderType_FormFrameComponent)), i1.ɵdid(7, 114688, null, 0, i4.FormFrameComponent, [], { showCloseButton: [0, "showCloseButton"] }, { neadClose: "neadClose" }), (_l()(), i1.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i1.ɵeld(9, 0, null, 0, 7, "div", [["class", "tnfr-modal__content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HttpErrorMessageComponent_1)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HttpErrorMessageComponent_2)), i1.ɵdid(15, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.buttons == null) ? null : _co.buttons.length) === 1); _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.buttons; _ck(_v, 15, 0, currVal_2); }, null); }
export function View_HttpErrorMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-http-error-message", [], null, null, null, View_HttpErrorMessageComponent_0, RenderType_HttpErrorMessageComponent)), i1.ɵdid(1, 245760, null, 0, i5.HttpErrorMessageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HttpErrorMessageComponentNgFactory = i1.ɵccf("app-http-error-message", i5.HttpErrorMessageComponent, View_HttpErrorMessageComponent_Host_0, {}, {}, []);
export { HttpErrorMessageComponentNgFactory as HttpErrorMessageComponentNgFactory };
