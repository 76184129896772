import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { CLAIMANT_HEARING_OFFICE_CONFIG} from "../@config/api";
import {SSAOffice} from "../@models/s-s-a-office";
import {HearingOffice} from "../@models/hearing-office";

@Injectable({
	providedIn: 'root'
})
export class ClaimantHearingOfficeService {

	constructor(private http: HttpClient) {
	}

	getHearingOffice(sslgId) {
		return this.http.get<HearingOffice>(CLAIMANT_HEARING_OFFICE_CONFIG.get(sslgId));
	}

	updateHearingOffice(sslgId, data: SSAOffice) {
		return this.http.post(CLAIMANT_HEARING_OFFICE_CONFIG.update(sslgId), data);
	}

	getHearingOfficeData(sslgId,zip){
		return this.http.post(CLAIMANT_HEARING_OFFICE_CONFIG.getData(sslgId), {zip:zip});
	}
}
