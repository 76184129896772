import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef, EventEmitter,
  Injectable,
  Injector
} from '@angular/core';
import {DialogComponent} from './dialog.component';
import {BaseDialog} from './base-dialog';


@Injectable()
export class DialogService implements BaseDialog {

  onCloseDialog: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
  }

  show(message: string, buttons: string[], autoHide: boolean = false, timout: number = 3000): BaseDialog {

    // Create a component reference from the component
    const componentRef: any = this.getComponentRef(DialogComponent);


    componentRef.instance.title = message;
    componentRef.instance.buttons = buttons;
    componentRef.instance.buttonClicked.subscribe(button => {
      this.result(button);
      componentRef.instance.buttonClicked.unsubscribe();
    });


    this.addComponentInDOM(componentRef);
    // Wait some time and remove it from the component tree and from the DOM
    if (autoHide) {
      setTimeout(() => {
        this.destroy(componentRef);
      }, timout);
    }
    return this;
  }

  private addComponentInDOM<T>(componentRef: ComponentRef<T>): void {
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    document.body.appendChild(domElem);
  }

  private getComponentRef(component) {

    const compRef: any = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(this.injector);
    compRef.instance.onCloseDialog.subscribe(j => {
      ////console.log(j);
      this.destroy(compRef);
      if (j) {
        this.result(j);
      }
    });
    return compRef;
  }

  private destroy(componentRef) {
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }

  showDialog(dialogComponent): BaseDialog {
    const componentRef: any = this.getComponentRef(dialogComponent);
    this.addComponentInDOM(componentRef);
    return this;
  }

  showDialogRef(dialogComponent) {
    const componentRef: any = this.getComponentRef(dialogComponent);
    this.addComponentInDOM(componentRef);
    return componentRef;
  }

  private result(value) {
    ////console.log(value);
    this.onCloseDialog.emit(value);
    this.onCloseDialog.complete();
    this.onCloseDialog = new EventEmitter<any>();
  }

}
