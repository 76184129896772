import {EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {OnStateChanges, State} from "../../app/test-new-stepper-form/test-form/on-changes";
import {FormChangesAttentionComponent} from "../form-changes-attention/form-changes-attention.component";
import {MatDialog} from "@angular/material";

export abstract class DynamicForm implements OnInit, OnDestroy {

	@Output()
	formStateChanged = new EventEmitter<{ key: string, value: boolean }>();
	@Output()
	allFormStateChanged = new EventEmitter<State>();
	@Input()
	formKey: string;

	abstract ngOnDestroy(): void;

	abstract ngOnInit(): void;


}

export abstract class DynamicFormParent extends DynamicForm implements OnStateChanges {

	formsState: Map<string, boolean> = new Map<string, boolean>();
	allFormState: State;

	protected constructor(protected matDialog: MatDialog) {
		super();
	}


	onFormStateChanged(state: { key: string, value: boolean }) {
		this.formsState.set(state.key, state.value);
	}

	onAllFormStateChanged(state: State) {
		this.allFormState = state;
	}

	get state(): State {
		let changed = State.before;
		this.formsState.forEach((value) => {
			if (value) {
				changed = State.after;
			}
		});
		return changed;
	}

	onStateChanges(): void {
		throw Error("Not Implemented");
	}

	canCloseForm(formName): boolean {
		return !this.formsState.has(formName) || !this.formsState.get(formName);
	}

	async showDataChangedDialog() {
		// const dialogRef = this.matDialog.open(FormChangesAttentionComponent, {
		// 	data: {
		// 		closeBtnText: 'Ignore changes',
		// 		infoText: 'You have unsaved data. Please confirm before proceeding.'
		// 	},
		// 	panelClass: 'attention-form-changes',
		// 	disableClose: true
		// });
		// return (await dialogRef.afterClosed().toPromise()) == 'leave';


		return true;
	}


}
