<div class="form-actions form-actions--mb form-actions--no-mt form-actions--left">
	<button class="form-action form-action_dark" type="button" (click)="back()">Back</button>
</div>
<form [formGroup]="requestsForm">
	<div formArrayName="payments" *ngFor="let data of paymentsFields.controls; let i = index;">
		<div [formGroupName]="i">
			<div class="form-row">
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label>Fee Charged($)</mat-label>
									<input formControlName="feeCharged"
										   currencyMask
										   [readonly]="isViewing()"
										   matInput>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label>Date Pd.</mat-label>
									<div class="ngx-datepicker-container mat-calendar">
										<div class="ngx-datepicker-input-container" #ngxInputContainer>
											<div class="input-container">
												<input formControlName="datePd"
													   matInput
													   [matDatepicker]="picker11"
													   [value]=""
													   (dateChange)="onChange($event)"
													   readonly>
											</div>
											<span (click)="clearDate('payment','datePd',i)"
												  class="icon-clear material-icon material-icons">highlight_off</span>
										</div>
									</div>
									<mat-datepicker-toggle matSuffix [for]="picker11"></mat-datepicker-toggle>
									<mat-datepicker [disabled]="isViewing()" #picker11></mat-datepicker>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label>Check No.</mat-label>
									<input formControlName="checkNo"
										   [readonly]="isViewing()"
										   matInput>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form-row">
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label>Date Claimant Billed</mat-label>
									<div class="ngx-datepicker-container mat-calendar">
										<div class="ngx-datepicker-input-container" #ngxInputContainer>
											<div class="input-container">
												<input formControlName="dateBilled"
													   matInput
													   [matDatepicker]="picker12"
													   [value]=""
													   (dateChange)="onChange($event)"
													   readonly>
											</div>
											<span (click)="clearDate('payment','dateBilled',i)"
												  class="icon-clear material-icon material-icons">highlight_off</span>
										</div>
									</div>
									<mat-datepicker-toggle matSuffix [for]="picker12"></mat-datepicker-toggle>
									<mat-datepicker [disabled]="isViewing()" #picker12></mat-datepicker>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label>Amt Billed</mat-label>
									<input formControlName="amountBilled"
										   currencyMask
										   [readonly]="isViewing()"
										   matInput>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
				<div class="form-element">
					<div class="mat-form-field-wrapper">
						<div class="mat-form-field-flex">
							<div class="mat-form-field-infix">
								<mat-form-field>
									<mat-label>Date Paid</mat-label>
									<div class="ngx-datepicker-container mat-calendar">
										<div class="ngx-datepicker-input-container" #ngxInputContainer>
											<div class="input-container">
												<input formControlName="datePaid"
													   matInput
													   [matDatepicker]="picker13"
													   [value]=""
													   (dateChange)="onChange($event)"
													   readonly>
											</div>
											<span (click)="clearDate('payment','datePaid',i)"
												  class="icon-clear material-icon material-icons">highlight_off</span>
										</div>
									</div>
									<mat-datepicker-toggle matSuffix [for]="picker13"></mat-datepicker-toggle>
									<mat-datepicker [disabled]="isViewing()" #picker13></mat-datepicker>
								</mat-form-field>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="form-actions" *ngIf="!isViewing()">
		<button class="form-action" type="button" (click)="save()">{{getBtnName()}}</button>
	</div>

</form>
