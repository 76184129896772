/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./claimant-print-record-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./claimant-print-record-page.component";
import * as i3 from "../../../@util/loading/loading.service";
var styles_ClaimantPrintRecordPageComponent = [i0.styles];
var RenderType_ClaimantPrintRecordPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClaimantPrintRecordPageComponent, data: {} });
export { RenderType_ClaimantPrintRecordPageComponent as RenderType_ClaimantPrintRecordPageComponent };
export function View_ClaimantPrintRecordPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "view-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(2, 0, [["report", 1]], null, 8, "div", [["class", "view-wrapper view-wrapper--no-mobile-padding"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(4, 0, [["body", 1]], null, 5, "div", [["class", "visit-card"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "print-button no-print"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_ClaimantPrintRecordPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-claimant-print-record-page", [], null, null, null, View_ClaimantPrintRecordPageComponent_0, RenderType_ClaimantPrintRecordPageComponent)), i1.ɵdid(1, 114688, null, 0, i2.ClaimantPrintRecordPageComponent, [i3.LoadingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClaimantPrintRecordPageComponentNgFactory = i1.ɵccf("app-claimant-print-record-page", i2.ClaimantPrintRecordPageComponent, View_ClaimantPrintRecordPageComponent_Host_0, {}, {}, []);
export { ClaimantPrintRecordPageComponentNgFactory as ClaimantPrintRecordPageComponentNgFactory };
