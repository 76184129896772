import { FileUploadingService } from '../../@util/file-uploading-progress/file-uploading.service';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { MEDICAL_RECORDS_CONFIG } from "../@config/api";
import * as i0 from "@angular/core";
import * as i1 from "../../@util/file-uploading-progress/file-uploading.service";
import * as i2 from "@angular/common/http";
export class MedicalRecordService {
    constructor(fileUploadin, http) {
        this.fileUploadin = fileUploadin;
        this.http = http;
        this.config = MEDICAL_RECORDS_CONFIG;
    }
    // pushFileToStorage(data: { file: File, fileInfo: { title: string, category: string, description: string, sslgId: string } }) {
    //   this.fileUploadin.uploadFile('/api/claimant/medical-record', data);
    // }
    // uploadEnded(): Observable<any> {
    //   return this.fileUploadin.uploadEnded;
    // }
    //
    // getDocuments(sslgId: any, title: string) {
    //   return this.fileUploadin.getDocuments('/api/claimant/medical-record/' + sslgId, title);
    // }
    //
    // isExist(sslgId: string, name: string) {
    //   return this.fileUploadin.isExist('/api/claimant/medical-record/' + sslgId + '/exist/' + name);
    // }
    //
    //
    // getTitlesForClaim(sslgId: string) {
    //   return this.fileUploadin.getTitlesForClaim('/api/claimant/medical-record/' + sslgId + '/titles');
    // }
    getVisitsInformation(sslgId, catalogItemId) {
        return this.http.get(this.config.getVisitsInformation(sslgId, catalogItemId));
    }
    getRequestsByType(sslgId, requestType) {
        if (requestType === 'SSA') {
            return null;
        }
        if (requestType !== 'ALL') {
            return this.http.get(this.config.getRequestsByTypeOne(sslgId, requestType));
        }
        else {
            return this.http.get(this.config.getRequestsByTypeAll(sslgId));
        }
    }
    createRequest(sslgId, $event) {
        return this.http.post(this.config.createRequest(sslgId), $event);
    }
    downloadDocument(sslgId, documentId) {
        return this.fileUploadin.downloadDocument(this.config.downloadDocument(sslgId, documentId));
    }
    getRequestById(sslgId, requestId) {
        return this.http.get(this.config.getRequestById(sslgId, requestId));
    }
    getUploadedDocument(sslgId, documentId) {
        return this.http.get(this.config.getUploadedDocument(sslgId, documentId));
    }
    sendFile(sslgId, requestId, file) {
        const formData = new FormData();
        if (file === undefined || file === null) {
            return;
        }
        formData.append('file', file.file);
        formData.append('fileName', file.file.name);
        formData.append('type', file.type);
        formData.append('uploadedBy', file.uploadedBy);
        return this.http.post(this.config.sendDocument(sslgId, requestId), formData);
    }
    uploadFile(sslgId, requestId, file) {
        const formData = new FormData();
        if (file === undefined || file === null) {
            return;
        }
        formData.append('file', file.file);
        formData.append('type', file.type);
        formData.append('uploadedBy', file.uploadedBy);
        const req = new HttpRequest('POST', this.config.uploadFile(sslgId, requestId), formData, {
            reportProgress: true,
            responseType: 'text'
        });
        this.fileUploadin.show(0, file.file.name);
        return this.http.request(req);
    }
    getDocTypes(sslgId) {
        return this.http.get(this.config.getDocTypes(sslgId));
    }
    getDocumentById(sslgId, docId) {
        return this.http.get(this.config.getDocumentById(sslgId, docId));
    }
    deleteDocument(sslgId, docId) {
        return this.http.post(this.config.deleteDocument(sslgId, docId), {});
    }
    updateDocument(sslgId, docId, value) {
        return this.http.post(this.config.updateDocument(sslgId, docId), value);
    }
    changeDocumentStatus(sslgId, id, status) {
        return this.http.post(this.config.changeDocumentStatus(sslgId, id), status);
    }
    getSSADocument(sslgId) {
        return this.http.get(this.config.getSSADocument(sslgId));
    }
    changeSSADocumentStatus(sslgId, id, status) {
        return this.http.post(this.config.changeSSADocumentStatus(sslgId, id), status);
    }
    getDocSSATypes(sslgId) {
        return this.http.get(this.config.getDocSSATypes(sslgId));
    }
    downloadSSADocument(sslgId, id) {
        return this.fileUploadin.downloadDocument(this.config.downloadSSADocument(sslgId, id));
    }
    uploadSSAFile(sslgId, file) {
        const formData = new FormData();
        if (file === undefined || file === null) {
            return;
        }
        formData.append('file', file.file);
        formData.append('type', file.type);
        formData.append('uploadedBy', file.uploadedBy);
        const req = new HttpRequest('POST', this.config.uploadSSAFile(sslgId), formData, {
            reportProgress: true,
            responseType: 'text'
        });
        this.fileUploadin.show(0, file.file.name);
        return this.http.request(req);
    }
    updateDocumentSsa(sslgId, docId, value) {
        return this.http.post(this.config.updateDocumentSsa(sslgId, docId), value);
    }
    getDocumentSsaById(sslgId, docId) {
        return this.http.get(this.config.getDocumentSsaById(sslgId, docId));
    }
}
MedicalRecordService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MedicalRecordService_Factory() { return new MedicalRecordService(i0.ɵɵinject(i1.FileUploadingService), i0.ɵɵinject(i2.HttpClient)); }, token: MedicalRecordService, providedIn: "root" });
