import { FileUploadingService } from '../../@util/file-uploading-progress/file-uploading.service';
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import * as i0 from "@angular/core";
import * as i1 from "../../@util/file-uploading-progress/file-uploading.service";
import * as i2 from "@angular/common/http";
export class DocumentService {
    constructor(fileUploadingService, http) {
        this.fileUploadingService = fileUploadingService;
        this.http = http;
        this.utl = (sslgId) => `/api/claimant/${sslgId}/documents`;
    }
    pushFileToStorage(data) {
        this.fileUploadingService.uploadFile(this.utl(data.fileInfo.sslgId), data);
    }
    uploadEnded() {
        return this.fileUploadingService.uploadEnded;
    }
    downloadDocument(sslgId, documentId) {
        return this.fileUploadingService.downloadDocument(`${this.utl(sslgId)}/${documentId}/download`);
    }
    getDocuments(sslgId, title) {
        let params = new HttpParams();
        if (title && title.trim().length > 0 && title !== 'null') {
            params = params.append('title', title);
        }
        return this.http.get(this.utl(sslgId), { params: params });
    }
    getById(sslgId, documentId) {
        return this.http.get(`${this.utl(sslgId)}/${documentId}`);
    }
    isExist(sslgId, name) {
        return this.http.get(`${this.utl(sslgId)}/exist/${name}`);
    }
    getTitlesForClaim(sslgId) {
        return this.http.get(`${this.utl(sslgId)}/titles`);
    }
    getTitles() {
        return this.http.get('/api/claimant/documents/titles');
    }
    updateFileInfo(sslgId, documentId, formData) {
        return this.http.post(`${this.utl(sslgId)}/${documentId}/update`, formData);
    }
}
DocumentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DocumentService_Factory() { return new DocumentService(i0.ɵɵinject(i1.FileUploadingService), i0.ɵɵinject(i2.HttpClient)); }, token: DocumentService, providedIn: "root" });
