import { EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { DynamicFormControlComponent, DynamicFormControlCustomEvent, DynamicFormLayout, DynamicFormLayoutService, DynamicFormValidationService } from "@ng-dynamic-forms/core";
import { LabelOptions } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatInput } from "@angular/material/input";
import { DatepickerCustomModel } from "./datepicker-custom-model";
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment } from 'moment';
const moment = _rollupMoment || _moment;
const ɵ0 = MAT_MOMENT_DATE_FORMATS;
export class DatepickerCustomComponent extends DynamicFormControlComponent {
    constructor(layoutService, validationService, LABEL_OPTIONS) {
        super(layoutService, validationService);
        this.layoutService = layoutService;
        this.validationService = validationService;
        this.LABEL_OPTIONS = LABEL_OPTIONS;
        this.blur = new EventEmitter();
        this.change = new EventEmitter();
        this.customEvent = new EventEmitter();
        this.focus = new EventEmitter();
        this.date = null;
        this.minDate = null;
    }
    ngOnInit() {
        this.minDate = this.model.min;
        this.model.valueUpdates.subscribe((j) => {
            if (j) {
                this.selectedValue = j;
                let dateValue = new Date(j);
                let year = dateValue.getFullYear();
                let month = dateValue.getMonth();
                let day = dateValue.getDate();
                this.date = new FormControl(moment([year, month, day]));
            }
        });
    }
    datePick(event) {
        this.model.valueUpdates.next(event);
    }
    onChange(event) {
        super.onChange(event);
    }
    clearInput() {
        this.matInput.value = '';
        super.onChange({});
    }
    test(r) {
        //alert(r)
    }
}
export { ɵ0 };
