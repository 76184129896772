<app-frame [overlay]="false" [title]="title" (neadClose)="closeVisitForm()"  [showCloseButton]="false" autocomplete="off">
	<div>
		You did not save data for this person {{claimantName}}.
	</div>
	<app-form-actions-content>
		<form-button (clickOnButton)="confirm()">Go to Profile</form-button>
		<form-button (clickOnButton)="clearVisitForm()" buttonType="warning">Ignore & Clear</form-button>

	</app-form-actions-content>
</app-frame>
