<div class="custom-iris-notification"
     [ngClass]="['image-'+imagePosition, type+'-notification']">
    <div class="custom-iris-notification__content">
        <div class="title">
            {{title}}
        </div>
        <div class="message">
            {{message}}
        </div>
    </div>
    <div class="custom-iris-notification__icon" [class.canvas-progress]="progressBar=='circle'">
        <canvas *ngIf="progressBar=='circle'" #progressCircleCanvas></canvas> <!--circle progress bar-->
    </div>
    <div class="custom-iris-notification__progress">
        <span *ngIf="progressBar=='linear'" [style.width]="progressWidth+'%'"></span> <!--linear progress bar-->
    </div>
</div>
