import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild} from "@angular/core";
import {FormGroup} from "@angular/forms";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import {
    DynamicFormControlComponent,
    DynamicFormControlCustomEvent,
    DynamicFormLayout,
    DynamicFormLayoutService,
    DynamicFormValidationService,
} from "@ng-dynamic-forms/core";
import {DatepickerCheckboxModel} from "./datepicker-checkbox-model";
import {GlobalSaveService} from "../../../app/@core/global-save.service";

@Component({
    selector: "dynamic-material-checkbox",
    templateUrl: "./dynamic-material-checkbox.component.html"
})
export class DatepickerCheckboxComponent extends DynamicFormControlComponent implements OnInit {

    @Input() group: FormGroup;
    @Input() layout: DynamicFormLayout;
    @Input() model: DatepickerCheckboxModel;

    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
    @Output() focus: EventEmitter<any> = new EventEmitter();
    //
    @ViewChild("matCheckbox", { static: true }) matCheckbox: MatCheckbox;
    @ViewChild("picker", { static: true }) matDatePicker: MatDatepicker<any>;

	checkboxDisabled = false;

    constructor(protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService,
				public globalSaveService: GlobalSaveService,
                @Inject(MAT_RIPPLE_GLOBAL_OPTIONS) @Optional() public RIPPLE_OPTIONS: RippleGlobalOptions) {

        super(layoutService, validationService);

    }

    ngOnInit(): void {
        this.matDatePicker.closedStream.subscribe(j => {
            if (!(<any>this.matDatePicker)._validSelected) {
                this.matCheckbox.checked = false;
                this.model.valueUpdates.next(null);
            } else {
                this.model.valueUpdates.next((<any>this.matDatePicker)._validSelected);
            }

        });
        this.model.valueUpdates.subscribe(j => {
            this.matCheckbox.checked = <any>(this.model && this.model.value);
        })
    }

    changed($event: MatCheckboxChange) {
		super.onChange($event);

        if ($event.checked)
            this.matDatePicker.open();
        else
            this.model.valueUpdates.next(null);

    }

    isChecked() {
        return this.model.value !== undefined && this.model.value !== null;
    }

	onChange(event) {

	}

	onClosed(event){
		super.onChange(event);
	}

	checkGsStatus(){
		this.checkboxDisabled = this.globalSaveService.getStatusFalse();

		return true;
	}

}
