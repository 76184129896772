import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
	AuditLogNotification,
	NotificationRecepientType,
	NotificationType,
	NotificationTypeChanges,
	TimeSpent
} from '../../../../@models/system-managment/notification';
import {ReferralService} from '../../../../@core/referral.service';
import {AuditLogService} from '../../../../@core/audit-log.service';
import {DialogService} from '../../../../../@util/dialog/dialog.service';
import {AuditInfo} from '../../../../@models/audit-info/audit-info';

@Component({
	selector: 'app-audit-log',
	templateUrl: './audit-log.component.html',
	styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {

	showChanges: boolean = true;
	showDOCSTAR: boolean = true;
	showPostAwards: boolean = false;

	time_spent: TimeSpent = {hours: 0, minutes: 0};
	changes: AuditLogNotification =
		{
			type: [],
			typeChanges: NotificationTypeChanges.CHANGES,
			recipientType: []
		};
	docstar: AuditLogNotification =
		{
			type: [],
			typeChanges: NotificationTypeChanges.DOCSTAR,
			recipientType: []
		};
	post_award: AuditLogNotification =
		{
			type: [],
			typeChanges: NotificationTypeChanges.POST_AWARD,
			recipientType: []
		};

	constructor(private dialogRef: MatDialogRef<AuditLogComponent>,
				private referralService: ReferralService,
				@Inject(MAT_DIALOG_DATA) public data: AuditInfo,
				private audiLog: AuditLogService, private dialog: DialogService) {
	}

	ngOnInit() {
		if (this.data) {
			this.time_spent = this.data.timeSped;
		}

		this.changesRecepient({checked:true}, 'CLAIMANT');
		this.changesNotifType({checked:true}, 'EMAIL');
	}

	checkValid($event) {
		setTimeout(() => {
			(<any>this.time_spent.hours) = ((this.time_spent.hours + '').replace('.', ''));
			(<any>this.time_spent.minutes) = ((this.time_spent.minutes + '').replace('.', ''));
			if (isNaN(this.time_spent.hours) || this.time_spent.hours < 0 ||
				this.time_spent.hours == null || (this.time_spent.hours + '') === '') {
				this.time_spent.hours = 0;
			}
			if (isNaN(this.time_spent.minutes) || this.time_spent.minutes < 0 ||
				this.time_spent.minutes == null || (this.time_spent.minutes + '') === '') {
				this.time_spent.minutes = 0;
			}
			if (this.time_spent.hours > 999) {
				this.time_spent.hours = 999;
			}
			if (this.time_spent.minutes > 60) {
				this.time_spent.minutes = 60;
			}
		}, 0);
	}

	sendLog() {
		this.sendNotifyForm("log");
	}

	sendNotify() {
		this.sendNotifyForm("");
	}

	sendNotifyForm(type) {
		if (this.time_spent.hours == null || this.time_spent.minutes == null) {
			this.dialog.show('Time Spent required', ['OK']);
			return;
		}
		if (!this.data) {
			this.audiLog.sendNotification(this.referralService.current.sslgId, {
				template: location.origin + '/report/notification/',
				timeSpent: this.time_spent,
				recepient: this.getRecepient(),
				type:type
			}).subscribe(j => {
				if (j) {
					this.audiLog.$logSent.next();
					this.dialogRef.close(true);
				}
			});
		} else {
			this.audiLog.reSendNotification(this.referralService.current.sslgId, this.data.id, {
				template: location.origin + '/report/notification/',
				timeSpent: this.time_spent,
				recepient: this.getRecepient(),
				type:type
			}).subscribe(j => {
				if (j) {
					this.audiLog.$logSent.next();
					this.dialogRef.close(true);
				}
			});
		}
	}

	changesRecepient(event, type): void {
		if (event.checked) {
			this.changes.recipientType.push(<NotificationRecepientType>type);
		} else {
			this.changes.recipientType = this.changes.recipientType.filter(j => j !== type);
		}
	}

	changesNotifType(event, type: string): void {
		if (event.checked) {
			this.changes.type.push(<NotificationType>type);
		} else {
			this.changes.type = this.changes.type.filter(j => j !== type);
		}
	}

	docstarRecepient(event, type): void {
		if (event.checked) {
			this.docstar.recipientType.push(<NotificationRecepientType>type);
		} else {
			this.docstar.recipientType = this.docstar.recipientType.filter(j => j !== type);
		}
	}

	docstarNotifType(event, type: string): void {

		if (event.checked) {
			this.docstar.type.push(<NotificationType>type);
		} else {
			this.docstar.type = this.docstar.type.filter(j => j !== type);
		}

	}

	awardRecepient(event, type): void {

		if (event.checked) {
			this.post_award.recipientType.push(<NotificationRecepientType>type);
		} else {
			this.post_award.recipientType = this.post_award.recipientType.filter(j => j !== type);
		}
	}

	awardNotifType(event, type: string): void {

		if (event.checked) {
			this.post_award.type.push(<NotificationType>type);
		} else {
			this.post_award.type = this.post_award.type.filter(j => j !== type);
		}

	}

	private getRecepient() {
		const recepientArr = [];
		this.changes.recipientType.forEach((recepient, index) => {
			this.changes.type.forEach(value => {
				recepientArr.push({
					type: value,
					typeChanges: NotificationTypeChanges.CHANGES,
					recipientType: recepient
				});
			});
		});
		this.docstar.recipientType.forEach((recepient, index) => {
			this.docstar.type.forEach(value => {
				recepientArr.push({
					type: value,
					typeChanges: NotificationTypeChanges.DOCSTAR,
					recipientType: recepient
				});
			});
		});
		this.post_award.recipientType.forEach((recepient, index) => {
			this.post_award.type.forEach(value => {
				recepientArr.push({
					type: value,
					typeChanges: NotificationTypeChanges.POST_AWARD,
					recipientType: recepient
				});
			});
		});

		return recepientArr;
	}

	closeDialog() {
		this.dialogRef.close(true);
	}
}
