import {
    AfterContentInit,
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter, HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition,
} from '@angular/animations';
import {NotificationConfig} from "../notification-config";

@Component({
    selector: 'app-custom-iris-notification',
    templateUrl: './custom-iris-notification.component.html',
    styleUrls: ['./custom-iris-notification.component.scss'],
    // animations: [
    //     trigger('fadeOut', [
    //         transition('*', [
    //             style({opacity: '1', transform: 'translateY(0)'}),
    //             animate('.5s ease-out', style({opacity: '0', transform: 'translateX(110%)'})),
    //         ]),
    //     ]),
    // ],
})
export class CustomIrisNotificationComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentInit {
    @ViewChild('progressCircleCanvas', { static: false })
    progressCircleCanvas: ElementRef;
    progressCircleContext: CanvasRenderingContext2D;

    isOpen = false;
    timer: any;

    @Input() config: NotificationConfig;

    title?: string;
    message?: string;
    autoHide?: boolean;
    timeOut?: number;
    type?: string; // can be - info, alert, done
    imagePosition?: string; // can be - left, right
    progressBar?: string; // can be - linear, circle
    position?: string; //can be rb, lb, tr, tl

    class: { container?: string, title?: string, message?: string };
    @Output()
    onCloseDialog: EventEmitter<any> = new EventEmitter<any>();

    progressWidth = 0; //linear bar

    constructor() {
    }

    @HostListener('click')
    close() {
        setTimeout(() => this.onCloseDialog.next(this.config.id), 0);
    }

    ngOnInit() {
        (<any>this.config).progressWidth = 0;
        Object.getOwnPropertyNames(this.config).forEach((item) => {
            this[item] = this.config[item]
        });
        if (this.config.autoHide) {
            setTimeout(() => {

                this.close();

            }, this.config.timeOut)
        }
    }

    // closeNotif() {
    //     this.onCloseDialog.next();
    // }

    ngAfterViewInit(): void {
        if (this.config.autoHide && this.progressBar == 'circle') {
            this.progressCircleContext = (this.progressCircleCanvas.nativeElement as HTMLCanvasElement).getContext('2d');
            this.progressCircleDraw();
        }
    }


    progressCircleDraw() {
        let posX = (this.progressCircleCanvas.nativeElement as HTMLCanvasElement).width / 2,
            posY = (this.progressCircleCanvas.nativeElement as HTMLCanvasElement).height / 2,
            fps = 1500 / 200,
            procent = 0,
            oneProcent = 360 / 100,
            result = oneProcent * 100;
        this.progressCircleContext.lineCap = 'round';

        let deegres = 0;
        let acrInterval = setInterval(() => {
            deegres += 1;
            this.progressCircleContext.clearRect(0, 0, this.progressCircleContext.canvas.clientWidth, this.progressCircleContext.canvas.clientHeight);

            this.progressCircleContext.beginPath();
            this.progressCircleContext.arc(posX, posY, 70, (Math.PI / 180) * 270, (Math.PI / 180) * (270 + 360));
            this.progressCircleContext.strokeStyle = 'transparent';
            this.progressCircleContext.lineWidth = 10;
            this.progressCircleContext.stroke();

            this.progressCircleContext.beginPath();
            this.progressCircleContext.strokeStyle = '#ffffff';
            // this.progressCircleContext.lineWidth = '10';
            this.progressCircleContext.arc(posX, posY, 70, (Math.PI / 180) * 270, (Math.PI / 180) * (270 + deegres));
            this.progressCircleContext.stroke();
            if (deegres >= result) clearInterval(acrInterval);
        }, fps);


    }

    timerDestroy() {
        clearInterval(this.timer);
    }

    ngOnDestroy(): void {
        this.timerDestroy();
    }

    ngAfterContentInit(): void {
        if (this.config.autoHide) {
            if (this.progressBar == 'linear') {
                ////console.log(this.timeOut);
                this.timer = setInterval(() => {
                    if (this.progressWidth === 100) {
                        this.timerDestroy();
                        return;
                    }
                    this.progressWidth = ++this.progressWidth;
                    ////console.log(this.config.id + ': ' + this.progressWidth);
                }, this.timeOut / 100)
            }
            if (this.progressBar == 'circle') {

            }
        }
    }
}
