export const LEVEL_REF_ITEMS_ID = {
  initial: 1,
  icPending: 2,
  recon: 3,
  rcPending: 4,
  hearing: 5,
  hrgPend: 6,
  hrgSched: 7
}
export const LEVEL_REF_ITEMS = [
  {
    id: LEVEL_REF_ITEMS_ID.initial,
    label: 'Initial',
    checked: false,
  },
  {
    id: LEVEL_REF_ITEMS_ID.icPending,
    label: 'IC Pending',
    checked: false,
  },
  {
    id: LEVEL_REF_ITEMS_ID.recon,
    label: 'Recon',
    checked: false,
  },
  {
    id: LEVEL_REF_ITEMS_ID.rcPending,
    label: 'RC Pending',
    checked: false,
  },
  {
    id: LEVEL_REF_ITEMS_ID.hearing,
    label: 'Hearing',
    checked: false,
  },
  {
    id: LEVEL_REF_ITEMS_ID.hrgPend,
    label: 'HRG Pend',
    checked: false,
  },
  {
    id: LEVEL_REF_ITEMS_ID.hrgSched,
    label: 'HRG Sched',
    checked: false,
  }
];
