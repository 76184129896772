import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageEditorComponent} from './image-editor/image-editor.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../app/material/material.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {PartialModule} from "../../app/@view/partial/partial.module";
export * from './image-editor/image-editor.component';

@NgModule({
  declarations: [ImageEditorComponent],
  exports: [ImageEditorComponent],
  entryComponents: [ImageEditorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule, FlexLayoutModule,
    PartialModule
  ]
})
export class ImageEditorModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ImageEditorModule,
    };
  }
}
