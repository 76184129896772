import {Injectable} from '@angular/core';
import {PROMOTIONS_CONFIG} from '../@config/api';
// import {Promotion} from "../@models/promotions/promotion.model";
import {HttpClient, HttpParams} from '@angular/common/http';
import {Router} from "@angular/router";
import {Observable} from "rxjs";
import {PROMOTIONS_CHAT_TYPES} from "../@view/pages/promotions/promotions-list/pronotions-types.model";

@Injectable({
  providedIn: 'root'
})
export class PromotionsService {
  private config = PROMOTIONS_CONFIG;

  newClaimantInSessionStorage = 'prospectsNewClaimant';
  newClaimantPositionInSessionStorage = 'prospectsNewClaimantPosition';

  paginationPageActive = 0;
  paginationPageSize = 6;
  activeUser = null;

  constructor(
    private router: Router,
    private http: HttpClient) {
  }

  getPromotionsList(page, status, items) {
    let kind = this.router.url.indexOf(PROMOTIONS_CHAT_TYPES.initial) >= 0 ? PROMOTIONS_CHAT_TYPES.application : PROMOTIONS_CHAT_TYPES.appeal;
    let type = 0;
    if(this.router.url.indexOf(PROMOTIONS_CHAT_TYPES.other) >= 0){
      kind = PROMOTIONS_CHAT_TYPES.other;
      this.router.url.indexOf('something-else')>= 0 ? type = 0 : type=1;
    }
    return this.http.post<any>(this.config.getList, {page, status, items, kind, type});
  }

  getPromotionsCategories(kind) {
    return this.http.post<any>(this.config.getCategories, {kind});
  }

  getPromotionsCategory(userId, categoryId) {
    return this.http.post<any>(this.config.getCategory, {userId, categoryId});
  }

  changeStatus(id, status) {
    return this.http.post<any>(this.config.changeStatus, {id, status});
  }

  deleteProspects(userId, type) {
    return this.http.post<any>(this.config.deleteProspect, {userId:userId, type:type});
  }

  claimantWasCreated(id) {
    return this.http.post<any>(this.config.claimantCreated, {id});
  }

  updateChatAnswers(userId, categoryId, data) {
    return this.http.post<any>(this.config.updateChatAnswers, {userId, categoryId,answers:JSON.stringify(data)});
  }

  createNewClaimant(info, pagination, page) {
    const newClaimantPosition = {
      pagination: pagination,
      page: page
    };
    sessionStorage.setItem(this.newClaimantPositionInSessionStorage, JSON.stringify(newClaimantPosition));
    sessionStorage.setItem(this.newClaimantInSessionStorage, JSON.stringify(info));
    this.router.navigate(['/claimants/new/claimant'], { queryParams: { from: 'prospects' } });
  }

  getNewClaimantInfo() {
    return sessionStorage.getItem(this.newClaimantInSessionStorage);
  }

  getNewClaimantPosition() {
    return sessionStorage.getItem(this.newClaimantPositionInSessionStorage);
  }

  sendMessage(data) {
    return this.http.post<any>(this.config.sendMessage, data);
  }
}
