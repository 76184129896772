/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../global-save-msg-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../partial/forms/form-frame/form-frame.component.ngfactory";
import * as i3 from "../../partial/forms/form-frame/form-frame.component";
import * as i4 from "../../partial/form-actions-content/form-actions-content.component.ngfactory";
import * as i5 from "../../partial/form-actions-content/form-actions-content.component";
import * as i6 from "../../partial/form-button/form-button.component.ngfactory";
import * as i7 from "../../partial/form-button/form-button.component";
import * as i8 from "./global-save-login-form-error.component";
import * as i9 from "@angular/common/http";
import * as i10 from "../../../@core/user-info.service";
import * as i11 from "@angular/material/dialog";
import * as i12 from "../../../../@util/dialog/dialog.service";
import * as i13 from "@angular/router";
var styles_GlobalSaveLoginFormErrorComponent = [i0.styles];
var RenderType_GlobalSaveLoginFormErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GlobalSaveLoginFormErrorComponent, data: {} });
export { RenderType_GlobalSaveLoginFormErrorComponent as RenderType_GlobalSaveLoginFormErrorComponent };
export function View_GlobalSaveLoginFormErrorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "app-frame", [["autocomplete", "off"]], null, [[null, "neadClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("neadClose" === en)) {
        var pd_0 = (_co.closeVisitForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormFrameComponent_0, i2.RenderType_FormFrameComponent)), i1.ɵdid(1, 114688, null, 0, i3.FormFrameComponent, [], { title: [0, "title"], autocomplete: [1, "autocomplete"], overlay: [2, "overlay"] }, { neadClose: "neadClose" }), (_l()(), i1.ɵted(-1, 0, ["\n\t"])), (_l()(), i1.ɵeld(3, 0, null, 0, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["\n\t\t", "\n\t"])), (_l()(), i1.ɵted(-1, 0, ["\n\t"])), (_l()(), i1.ɵeld(6, 0, null, 0, 10, "app-form-actions-content", [], null, null, null, i4.View_FormActionsContentComponent_0, i4.RenderType_FormActionsContentComponent)), i1.ɵdid(7, 114688, null, 0, i5.FormActionsContentComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i1.ɵeld(9, 0, null, 0, 2, "form-button", [], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.goToPerson() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FormButtonComponent_0, i6.RenderType_FormButtonComponent)), i1.ɵdid(10, 638976, null, 0, i7.FormButtonComponent, [], null, { clickOnButton: "clickOnButton" }), (_l()(), i1.ɵted(-1, 0, ["Go to Person"])), (_l()(), i1.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i1.ɵeld(13, 0, null, 0, 2, "form-button", [["buttonType", "warning"]], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.closeVisitForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FormButtonComponent_0, i6.RenderType_FormButtonComponent)), i1.ɵdid(14, 638976, null, 0, i7.FormButtonComponent, [], { buttonType: [0, "buttonType"] }, { clickOnButton: "clickOnButton" }), (_l()(), i1.ɵted(-1, 0, ["Close"])), (_l()(), i1.ɵted(-1, 0, ["\n\t"])), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = "off"; var currVal_2 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 7, 0); _ck(_v, 10, 0); var currVal_4 = "warning"; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.errorMessage; _ck(_v, 4, 0, currVal_3); }); }
export function View_GlobalSaveLoginFormErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-global-save-login-form-error", [], null, null, null, View_GlobalSaveLoginFormErrorComponent_0, RenderType_GlobalSaveLoginFormErrorComponent)), i1.ɵdid(1, 114688, null, 0, i8.GlobalSaveLoginFormErrorComponent, [i9.HttpClient, i10.UserInfoService, i11.MatDialog, i11.MatDialogRef, i11.MAT_DIALOG_DATA, i12.DialogService, i13.ActivatedRoute, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GlobalSaveLoginFormErrorComponentNgFactory = i1.ɵccf("app-global-save-login-form-error", i8.GlobalSaveLoginFormErrorComponent, View_GlobalSaveLoginFormErrorComponent_Host_0, {}, {}, []);
export { GlobalSaveLoginFormErrorComponentNgFactory as GlobalSaveLoginFormErrorComponentNgFactory };
