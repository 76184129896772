<mat-form-field [appearance]="model.getAdditional('appearance', 'legacy')"
				[color]="model.getAdditional('color', 'primary')"
				[floatLabel]="model.getAdditional('floatLabel', LABEL_OPTIONS && LABEL_OPTIONS['float'] || 'auto')"
				[formGroup]="group"
				[hideRequiredMarker]="model.getAdditional('hideRequiredMarker', false)"
				[ngClass]="getClass('grid', 'control')">

	<ng-container *ngIf="model.label" ngProjectAs="mat-label">

		<mat-label>{{ model.label }}</mat-label>

	</ng-container>

	<span *ngIf="model.prefix" matPrefix [innerHTML]="model.prefix"></span>

	<app-ng-datepicker
		[disabled]="model.disabled" [startPosition]="'end'" [options]="newDatepickerOptions"
					   [position]="'auto'" [hideClear]="model.hiddenClearIco" [hideTrigger]="model.hiddenDatePicker"
					   (datePick)="datePick($event)" [selectedValue]="selectedValue" (clearDate)="clearDate()" (onChange)="onChange($event)">


		<input matInput #inp
			   type="text"
			   [autofocus]="model.autoFocus"
			   [formControlName]="model.id"
			   [id]="elementId"
			   [name]="model.name"
			   [matDatepicker]="null"
			   [ngClass]="getClass('element', 'control')"
			   [placeholder]="model.placeholder"
			   [readonly]="model.readOnly"
			   [required]="model.required"
			   (blur)="onBlur($event)"
			   [tabIndex]="model.tabIndex"
			   (dateChange)="onChange($event)"
			   (dateInput)="onCustomEvent($event, 'dateInput')"
			   (focus)="onFocus($event)"
			   [disabled]=""
		/>
	</app-ng-datepicker>

	<!--<input matInput #inp-->
	<!--[autofocus]="model.autoFocus"-->
	<!--[formControlName]="model.id"-->
	<!--[id]="elementId"-->
	<!--[max]="model.max"-->
	<!--[matDatepicker]="matDatepicker"-->
	<!--[min]="model.min"-->
	<!--[name]="model.name"-->
	<!--[ngClass]="getClass('element', 'control')"-->
	<!--[placeholder]="model.placeholder"-->
	<!--[readonly]="model.readOnly"-->
	<!--[required]="model.required"-->
	<!--(blur)="onBlur($event)"-->
	<!--[tabIndex]="model.tabIndex"-->
	<!--(dateChange)="onChange($event)"-->
	<!--(dateInput)="onCustomEvent($event, 'dateInput')"-->
	<!--(focus)="onFocus($event)"/>-->

	<!--<span *ngIf="model.suffix" matSuffix [innerHTML]="model.suffix"></span>-->

	<mat-hint *ngIf="model.hint !== null" align="start" [innerHTML]="model.hint"></mat-hint>

	<!--<mat-icon (click)="clearDate()" *ngIf="!model.hiddenClearIco" matSuffix  style="color: rgba(67, 66, 93, 0.6); cursor: pointer" color="#43425D" class="maticon-trash">highlight_off</mat-icon>-->

	<!--<mat-datepicker-toggle *ngIf="!model.hiddenDatePicker" [disabled]="model.disabled" matSuffix [for]="matDatepicker"></mat-datepicker-toggle>-->


	<mat-datepicker #matDatepicker
					[opened]="model.getAdditional('opened', false)"
					[startAt]="model.focusedDate"
					[startView]="model.getAdditional('startView', 'month')"
					[touchUi]="model.getAdditional('touchUi', false)"
					(closed)="onCustomEvent($event, 'closed')"
					(opened)="onCustomEvent($event, 'opened')"></mat-datepicker>


	<ng-container *ngFor="let message of errorMessages" ngProjectAs="mat-error">

		<mat-error *ngIf="showErrorMessages">{{ message }}</mat-error>

	</ng-container>

</mat-form-field>
