import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {NavigationService} from "./@core/navigation.service";
import {ReferralService} from "./@core/referral.service";
import {map} from "rxjs/operators";


@Injectable()
export class TitleRouterResolver implements Resolve<any> {

	constructor(private navService: NavigationService, private referralService: ReferralService) {

	}

	async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		let sslgId = this.getSslgId(route);
		if (route.data.title) {

			if (route.data.title.indexOf("${referral}") > -1) {
				if (!this.referralService.current) {

					if (!sslgId){
						Error("SSLG Id Not Found");

						return false;
					}


					return this.referralService.getClaimantLevelDetails(sslgId).pipe(map(current => {
						this.referralService.setCurrent(current);

						this.navService
							.setTitle(route.data.title.replace('${referral}',
								this.referralService.current.firstName + ' ' + this.referralService.current.lastName));

					})).subscribe();
				} else {
					this.navService
						.setTitle(route.data.title.replace('${referral}',
							this.referralService.current.firstName + ' ' + this.referralService.current.lastName));
				}
			} else {
				this.navService.setTitle(route.data.title);
			}
		} else if (sslgId) {
			return this.referralService.getClaimantLevelDetails(sslgId).pipe(map(current => {
				this.referralService.setCurrent(current);
			})).subscribe();
		}
		return true;
	}

	private getSslgId(route: ActivatedRouteSnapshot) {
		let sslgId = route.paramMap.get('sslgId');
		if (!sslgId && route.parent)
			return this.getSslgId(route.parent);
		return sslgId;
	}
}
