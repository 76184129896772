import * as tslib_1 from "tslib";
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { MEDICAL_CONFIG, REFERRAL_CONFIG, NOTIFICATIONS_CONFIG } from '../@config/api';
import { CatalogItemType } from '../@models/medical/catalog-item-view';
import { MedicalSourcesService } from './medical-sources.service';
import { Subject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./medical-sources.service";
export class MedicalService {
    constructor(http, medicalSourcesService) {
        this.http = http;
        this.medicalSourcesService = medicalSourcesService;
        this.config = MEDICAL_CONFIG;
        this.configNotification = NOTIFICATIONS_CONFIG;
    }
    getClaimantUserName() {
        return this.claimantUserName;
    }
    setClaimantUserName(value) {
        this.claimantUserName = value;
    }
    getVisits(sslgId) {
        return this.http.get(this.config.visit.getVisits(sslgId));
    }
    getVisit(sslgId, id) {
        return this.http.get(this.config.visit.getVisit(sslgId, id));
    }
    saveVisit(sslgId, type, data) {
        let url;
        if (type === 'DOCTOR') {
            url = this.config.visit.saveDoctorVisit(sslgId);
        }
        else if (type === 'HOSPITAL') {
            url = this.config.visit.saveHospitalVisit(sslgId);
        }
        else {
            url = this.config.visit.saveOtherVisit(sslgId);
        }
        //console.log("jjjjj");
        //console.log(data);
        return this.http.post(url, data);
    }
    getMedicalTest(sslgId, visitId) {
        return this.http.get(this.config.visit.getMedicalTests(sslgId, visitId));
    }
    setMedicalTest(sslgId, visitId, $event) {
        return this.http.post(this.config.visit.setMedicalTests(sslgId, visitId), $event);
    }
    getMedications(sslgId, visitId) {
        return this.http.get(this.config.visit.getMedications(sslgId, visitId));
    }
    setMedication(sslgId, visitId, value) {
        return this.http.post(this.config.visit.setMedications(sslgId, visitId), value);
    }
    getAllMedicationForClaim(sslgId) {
        return this.http.get(this.config.getAllMedicationForClaim(sslgId));
    }
    getVisitSummaries(sslgId, startDate, endDate, source) {
        let param = new HttpParams();
        if (startDate) {
            param = param
                .append('startDate', startDate.getTime() + '');
        }
        if (endDate) {
            param = param
                .append('endDate', endDate.getTime() + '');
        }
        return this.http.get(this.config.visit.getVisitsSummaries(sslgId), { params: param });
    }
    getVisitNotifications(sslgId) {
        return this.http.get(this.config.visit.getVisitNotifications(sslgId));
    }
    getAlertNotifications(sslgId) {
        return this.http.get(this.configNotification.getAlerts(sslgId));
    }
    setVisitReaded(sslgId) {
        return this.http.post(this.config.visit.setVisitReaded(sslgId), {});
    }
    getVisitSummariesById(sslgId, ids) {
        return this.http.post('/api/claimant/' + sslgId + '/findByIds', { sslgId: sslgId, ids: ids });
    }
    saveStepTwo(sslgId, _visitId, rez) {
        return this.http.post(this.config.visit.saveStepTwo(sslgId, _visitId), rez);
    }
    getCatalogItemInfo(sslgId, id) {
        return this.http.get(this.config.catalog_items.getSourceInfo(sslgId, id));
    }
    getCatalogVisitItem(sslgId) {
        return this.http.get(REFERRAL_CONFIG.CLAIMANT.getAllCatalogItems(sslgId));
    }
    getAllDoctors(sslgId) {
        return this.http.get(this.config.catalog_items.getAllDoctors(sslgId));
    }
    getDoctorById(sslgId, doctorId) {
        return this.http.get(this.config.catalog_items.getDoctorById(sslgId, doctorId));
    }
    setDoctor(sslgId, result, current) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //if (!current) {
            current = yield this.medicalSourcesService.setDoctor(result).toPromise();
            //}
            var param = new HttpParams().append("name", result.name);
            return yield this.http.post(this.config.catalog_items.setDoctor(sslgId), {
                sslgId: result.sslgId,
                catalogItemId: current.id,
                type: CatalogItemType.DOCTOR,
                phone: current.contact ? current.contact.phone : '',
                fax: current.contact ? current.contact.fax : '',
            }, { params: param }).toPromise();
        });
    }
    saveTreatingSourcesDoctor(sslgId, result, current) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //if (!current) {
            current = yield this.medicalSourcesService.saveTreatingSourcesDoctor(sslgId, result).toPromise();
            //}
            var param = new HttpParams().append("name", result.name);
            return yield this.http.post(this.config.catalog_items.setDoctor(sslgId), {
                sslgId: result.sslgId,
                catalogItemId: current.id,
                type: CatalogItemType.DOCTOR,
                phone: current.contact ? current.contact.phone : '',
                fax: current.contact ? current.contact.fax : '',
            }, { params: param }).toPromise();
        });
    }
    getHospital(sslgId, hospitalId) {
        return this.http.get(this.config.catalog_items.getHospitalById(sslgId, hospitalId));
    }
    getAllHospitals(sslgId) {
        return this.http.get(this.config.catalog_items.getAllHospitals(sslgId));
    }
    setHospital(sslgId, result, current) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!current) {
                current = yield this.medicalSourcesService.setHospital(result).toPromise();
            }
            var param = new HttpParams().append("name", result.name);
            return this.http.post(this.config.catalog_items.setHospital(sslgId), {
                sslgId: result.sslgId,
                catalogItemId: current.id,
                type: CatalogItemType.HOSPITAL
            }, { params: param }).toPromise();
        });
    }
    saveTreatingSourcesHospital(sslgId, result, current) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            current = yield this.medicalSourcesService.saveTreatingSourcesHospital(sslgId, result).toPromise();
            var param = new HttpParams().append("name", result.name);
            return this.http.post(this.config.catalog_items.setHospital(sslgId), {
                sslgId: result.sslgId,
                catalogItemId: current.id,
                type: CatalogItemType.HOSPITAL
            }, { params: param }).toPromise();
        });
    }
    getOther(sslgId, id) {
        return this.http.get(this.config.catalog_items.getOtherById(sslgId, id));
    }
    getAllOthers(sslgId) {
        return this.http.get(this.config.catalog_items.getAllOthers(sslgId));
    }
    setOther(sslgId, result, current) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //console.log(result);
            if (!current) {
                current = yield this.medicalSourcesService.setOther(result).toPromise();
            }
            var param = new HttpParams().append("name", result.name);
            return this.http.post(this.config.catalog_items.setOther(sslgId), {
                sslgId: result.sslgId,
                catalogItemId: current.id,
                type: CatalogItemType.OTHER
            }, { params: param }).toPromise();
        });
    }
    saveTreatingSourcesOther(sslgId, result, current) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //if (!current) {
            current = yield this.medicalSourcesService.saveTreatingSourcesOther(sslgId, result).toPromise();
            //current = await this.medicalSourcesService.setOther(result).toPromise();
            //}
            var param = new HttpParams().append("name", result.name);
            return this.http.post(this.config.catalog_items.setOther(sslgId), {
                sslgId: result.sslgId,
                catalogItemId: current.id,
                type: CatalogItemType.OTHER
            }, { params: param }).toPromise();
        });
    }
    getCatalogItemBySourceIdAndSslgId(catalogItemId, sslgId) {
        return this.http.get(this.config.catalog_items.getCatalogItemBySourceId(catalogItemId, sslgId));
    }
    getCatalogItemById(catalogItemId, sslgId) {
        return this.http.get(this.config.catalog_items.getCatalogItemById(catalogItemId, sslgId));
    }
    getCatalog(type) {
        switch (type) {
            case CatalogItemType.DOCTOR:
                return this.medicalSourcesService.getClaimantDoctors(this.getClaimantUserName());
                //return this.medicalSourcesService.getAllDoctors();
                break;
            case CatalogItemType.HOSPITAL:
                return this.medicalSourcesService.getAllHospitals();
                break;
            case CatalogItemType.OTHER:
                return this.medicalSourcesService.getAllOthers();
                break;
            default:
                throw Error('Not Implemented');
        }
    }
    getCatalogItem(type, id) {
        switch (type) {
            case CatalogItemType.DOCTOR:
                return this.medicalSourcesService.getDoctorById(id);
                break;
            case CatalogItemType.HOSPITAL:
                return this.medicalSourcesService.getHospital(id);
                break;
            case CatalogItemType.OTHER:
                return this.medicalSourcesService.getOther(id);
                break;
            default:
                throw Error("Type needed");
        }
    }
    getLastMedicalConditions(sslgId, id) {
        return this.http.get(this.config.visit.getLastMedicalConditions(sslgId, id));
    }
    //@Deprecated
    // getSourceRecipients(id: any) {
    // 	return this.http.get<any[]>(this.config.catalog_items.getRecipients(id));
    // }
    // saveVisitSummaries(sslgId, data) {
    // 	return this.request(`/api/medical-visits/${sslgId}/visit-summaries/save`, data);
    // }
    sentVisitSummaries(sslgId, data) {
        return this.request(`/api/medical-visits/${sslgId}/visit-summaries/email`, data);
    }
    printVisitSummaries(sslgId, data) {
        return this.request(`/api/medical-visits/${sslgId}/visit-summaries/print`, data);
    }
    request(url, data) {
        const req = new HttpRequest('POST', url, data, { responseType: 'arraybuffer' });
        return this.http.request(req);
    }
    setMedicalConditions(sslgId, _visitId, medicalConditions) {
        return this.http.post(this.config.visit.setMedicalCondition(sslgId, _visitId), medicalConditions);
    }
    saveDoctorNextVisit(sslgId, visitId, data) {
        return this.http.post(this.config.visit.saveDoctorNextVisit(sslgId, visitId), data);
    }
    setMedicalConditionsTemplate(conditions) {
        this.medicalConditions = conditions;
    }
    getMedicationsTemplate() {
        //console.log(this.medicalConditions);
        return this.medicalConditions;
    }
    saveVisits(type, visitSourceId, sslgId, visits, nextVisit) {
        let subscr = new Subject();
        visits.forEach((visit, index) => {
            if (visit.visitParameters && visit.visitParameters.notes) {
                if (type == 'DOCTOR') {
                    visit.visit.treatments = visit.visitParameters.notes.treatments;
                    visit.visit.discussion = visit.visitParameters.notes.discussion;
                    visit.visit.docResponse = visit.visitParameters.notes.docResponse;
                    visit.visit.discusSSDI = visit.visitParameters.notes.discusSSDI;
                }
                else {
                    visit.visit.treatmentDetails = visit.visitParameters.notes.treatmentDetails;
                    if (type == 'HOSPITAL') {
                        visit.visit.hospitalizationReason = visit.visitParameters.notes.hospitalizationReason;
                        visit.visit.medicalConditions = visit.visitParameters.notes.medicalConditions;
                    }
                    if (type == 'OTHER') {
                        visit.visit.visitReason = visit.visitParameters.notes.visitReason;
                    }
                }
            }
            if (nextVisit && index === visits.length - 1)
                visit.nextVisit = nextVisit;
            visit.visit.sslgId = sslgId;
            visit.visit.catalogItemId = visitSourceId;
            if (!visit.visit.visitReason && type == 'DOCTOR')
                visit.visit.visitReason = {};
            this.saveVisit(sslgId, type, visit.visit).subscribe(rez => {
                if (visit.visitParameters) {
                    if (visit.visitParameters.tests)
                        this.setMedicalTest(sslgId, rez.id, visit.visitParameters.tests).subscribe();
                    if (visit.visitParameters.medications)
                        this.setMedication(sslgId, rez.id, visit.visitParameters.medications).subscribe();
                }
                if (this.medicalConditions && this.medicalConditions.length)
                    this.setMedicalConditions(sslgId, rez.id, this.medicalConditions).subscribe();
                if (index === visits.length - 1) {
                    subscr.next(true);
                    this.medicalConditions = [];
                }
            });
        });
        return subscr;
    }
    sentVisitSummariesByEmail(report) {
        return this.request(`/api/claimant/${report.sslgId}/visit-summaries/email-report`, report);
    }
    sendVisitSummariesByEmail(sslgId, email, individual, ids) {
        return this.request('/api/claimant/' + sslgId + '/visit-summaries/email-pdf-report', { sslgId: sslgId, email: email, individual: individual, ids: ids });
    }
    saveVisitSummariesReport(report) {
        return this.request(`/api/claimant/${report.sslgId}/visit-summaries/save-report`, report);
    }
    visitWatched(id, sslgId) {
        return this.request(`/api/claimant/${sslgId}/visit-watched`, { visitId: id });
    }
    saveAllMedications(sslgId, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(this.config.changeMedicationStatus(sslgId), data).toPromise();
        });
    }
    addMedication(sslgId, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.http.post(this.config.addMedication(sslgId), data).toPromise();
        });
    }
    getMedicalTreatmentSourcesReport(sslgId, type) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(this.config.catalog_items.getMedicalTreatmentSourcesReport(sslgId), { type: type }).toPromise();
        });
    }
}
MedicalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MedicalService_Factory() { return new MedicalService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.MedicalSourcesService)); }, token: MedicalService, providedIn: "root" });
