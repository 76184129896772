import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ExportModule} from "../../../../export/export.module";
import {PartialModule} from "../../../partial/partial.module";
import {AuthModule} from "../../../../auth/auth.module";
import {MedicalTreatingSourcesComponent} from "./medical-treating-sources.component";
import {VisitsComponent} from "./visits/visits.component";
import {VisitFormsComponent} from "./visits/visit-forms/visit-forms.component";
import {VisitComponent} from "./visits/visit-forms/visit/visit.component";
import {MedicationsComponent} from "./visits/visit-forms/medications/medications.component";
import {MedicalTestComponent} from "./visits/visit-forms/medical-test/medical-test.component";
import {OthersComponent} from "./othe/others.component";
import {OtheFormComponent} from "./othe/othe-form/othe-form.component";
import {HospitalsComponent} from "./hospitals/hospitals.component";
import {HospitalFormComponent} from "./hospitals/hospital-form/hospital-form.component";
import {DoctorsComponent} from "./doctors/doctors.component";
import {DoctorFormComponent} from "./doctors/doctor-form/doctor-form.component";
import {ClaimantMedicationsComponent} from "./claimant-medications/claimant-medications.component";
import {MedicalTreatingSourcesRoutingModule} from "./medical-treating-sources-routing.module";
import {IrsTableModule} from "../../../../../@util/irs-table/irs-table.module";
import {CanAccessModule} from "../../../../auth/permission-guard/can-access.module";
import {MedicalRequestsPaymentsComponent} from "./medical-requests-payments/medical-requests-payments.component";
import {MedicalRequestsFormComponent} from "./medical-requests/medical-requests-form/medical-requests-form.component";
import {MedicalPaymentsFormComponent} from "./medical-payments/medical-payments-form/medical-payments-form.component";
import {MedicalRequestsComponent} from "./medical-requests/medical-requests.component";
import {MedicalPaymentsComponent} from "./medical-payments/medical-payments.component";

import { NgxCurrencyModule } from "ngx-currency";
import {TextMaskModule} from 'angular2-text-mask/dist/angular2TextMask';
import {NgxMaskModule} from 'ngx-mask';

import {HospitalsVisitComponent} from "./hospitals/hospitals-visit/hospitals-visit.component";
import {MedicalTreatingSourcesMedicalTestComponent} from "./medical-treating-sources-medical-test/medical-treating-sources-medical-test.component";
import {MedicalTreatingSourcesMedicationsComponent} from "./medical-treating-sources-medications/medical-treating-sources-medications.component";
import {HospitalsVisitNotesComponent} from "./hospitals/hospitals-visit-notes/hospitals-visit-notes.component";
import {OtherTreatmentComponent} from "./othe/other-treatment/other-treatment.component";
import {MedicalTreatingReportsComponent} from "./medical-treating-reports/medical-treating-reports.component";

const components = [
	MedicalTreatingSourcesComponent,
	VisitsComponent,
	VisitFormsComponent,
	VisitComponent,
	MedicationsComponent,
	OthersComponent,
	HospitalsComponent,
	DoctorsComponent,
	DoctorFormComponent,
	HospitalFormComponent,
	OtheFormComponent,
	ClaimantMedicationsComponent,
	MedicalTestComponent,
	MedicalRequestsPaymentsComponent,
	MedicalRequestsComponent,
	MedicalPaymentsComponent,
	MedicalRequestsFormComponent,
	MedicalPaymentsFormComponent,
	MedicalTreatingSourcesMedicalTestComponent,
	MedicalTreatingSourcesMedicationsComponent,
	HospitalsVisitComponent,
	HospitalsVisitNotesComponent,
	OtherTreatmentComponent,
	MedicalTreatingReportsComponent,
];

@NgModule({
	declarations: [...components],
	exports: [...components],
	imports: [
		CommonModule,
		ExportModule,
		PartialModule,
		AuthModule,
		MedicalTreatingSourcesRoutingModule,
		IrsTableModule,
		NgxCurrencyModule,
		CanAccessModule,
		TextMaskModule,
		NgxMaskModule.forRoot()
	],
	entryComponents: []
})

export class MedicalTreatingSourcesModule {

}
