export const FORMS = {
	claimantContact:{
		'key':'claimant_contact',
		'folder':'Claimant Record',
		'tab':'',
		'link':'/claimants/record/{claimantUserName}',
		'title':'Contact',
	},
	claimantAddress:{
		'key':'claimant_address',
		'folder':'Claimant Record',
		'tab':'',
		'link':'/claimants/record/{claimantUserName}',
		'title':'Address',
	},
	claimantSocial:{
		'key':'user_social',
		'folder':'Claimant Record',
		'tab':'',
		'link':'/claimants/social/{claimantUserName}',
		'title':'Social Media',
	},
	claimantRemark:{
		'key':'user_remark',
		'folder':'Claimant Remark',
		'tab':'',
		'link':'/claimants/remark/{claimantUserName}',
		'title':'Remarks',
	},
	claimantCompBenefits: {
		'key':'user_comp_benefits',
		'folder':'Comp & Benefits',
		'tab':'',
		'link':'/claimants/comp-benefits/{claimantUserName}',
		'title':'Comp & Benefits',
	},
	referralAcquisition:{
		'key':'referral_acquisition',
		'folder':'SSDI',
		'tab':'',
		'link':'/claimants/{claimantUserName}',
		'title':'Acquisition',
	},
	staff:{
		'key':'staff',
		'folder':'SSDI',
		'tab':'',
		'link':'/claimants/{claimantUserName}',
		'title':'SSLG Staff',
	},
	ageEducationIncome:{
		'key':'age_education_income',
		'folder':'SSDI',
		'tab':'',
		'link':'/claimants/{claimantUserName}',
		'title':'Age, Edu & Income',
	},
	intake:{
		'key':'intake',
		'folder':'Status',
		'tab':'',
		'link':'/claimants/status/{claimantUserName}',
		'title':'Intake',
	},
	closureCerminated:{
		'key':'closure_terminated',
		'folder':'Status',
		'tab':'',
		'link':'/claimants/status/{claimantUserName}',
		'title':'Closure Status',
	},
	hearingSchedule:{
		'key':'hearing_schedule',
		'folder':'Status',
		'tab':'',
		'link':'/claimants/status/{claimantUserName}',
		'title':'Hearing Schedule',
	},
	hearingDetails:{
		'key':'hearing_details',
		'folder':'Status',
		'tab':'',
		'link':'/claimants/status/{claimantUserName}',
		'title':'Hearing Details',
	},
	hearingOfficeDetails:{
		'key':'hearing_office_details',
		'folder':'Status',
		'tab':'',
		'link':'/claimants/status/{claimantUserName}',
		'title':'Hearing Office',
	},
	fieldOfficeDetails:{
		'key':'field_office_details',
		'folder':'Status',
		'tab':'',
		'link':'/claimants/status/{claimantUserName}',
		'title':'Field Office',
	},
	ddsOffice:{
		'key':'dds_office',
		'folder':'Status',
		'tab':'',
		'link':'/claimants/status/{claimantUserName}',
		'title':'DDS Office',
	},
	claimFlags:{
		'key':'claim_flags',
		'folder':'Flags & Other Income',
		'tab':'',
		'link':'/claimants/flags-income/{claimantUserName}',
		'title':'Claim Flags',
	},
	otherIncome:{
		'key':'other_income',
		'folder':'Flags & Other Income',
		'tab':'',
		'link':'/claimants/flags-income/{claimantUserName}',
		'title':'Other Income',
	},
	awardOverview:{
		'key':'award_overview',
		'folder':'Post Award',
		'tab':'Award Details ',
		'link':'/claimants/{claimantUserName}/post-award/award-details',
		'title':'Overview',
	},
	primaryFees:{
		'key':'primary_fees',
		'folder':'Post Award',
		'tab':'Award Details ',
		'link':'/claimants/{claimantUserName}/post-award/award-details',
		'title':'Primary Fees',
	},
	dependent:{
		'key':'dependent',
		'folder':'Post Award',
		'tab':'Award Details ',
		'link':'/claimants/{claimantUserName}/post-award/award-details',
		'title':'Dependent',
	},
	recoverySummary:{
		'key':'recovery_summary',
		'folder':'Post Award',
		'tab':'Award Details',
		'link':'/claimants/{claimantUserName}/post-award/award-details',
		'title':'AWD & Recovery Summary',
	},
	meters:{
		'key':'meters',
		'folder':'Post Award',
		'tab':'Award Details',
		'link':'/claimants/{claimantUserName}/post-award/award-details',
		'title':'Meters',
	},
	coraEnrolment:{
		'key':'cora_enrolment',
		'folder':'Post Award',
		'tab':' CORA Details ',
		'link':'/claimants/{claimantUserName}/post-award/cora-details',
		'title':'CORA Enrolment',
	},
	primaryCora:{
		'key':'primary_cora',
		'folder':'Post Award',
		'tab':' CORA Details ',
		'link':'/claimants/{claimantUserName}/post-award/cora-details',
		'title':'Primary CORA',
	},
	dependentCora:{
		'key':'dependent_cora',
		'folder':'Post Award',
		'tab':' CORA Details ',
		'link':'/claimants/{claimantUserName}/post-award/cora-details',
		'title':'Dependent CORA',
	},
	ceExam:{
		'key':'ce_exam',
		'folder':'Status',
		'tab':'',
		'link':'/claimants/status/{claimantUserName}',
		'title':'CE EXAM Details',
	},
	levelDetails:{
		'key':'level_details',
		'folder':'Status',
		'tab':'',
		'link':'/claimants/status/{claimantUserName}',
		'title':'Level Details',
	},
	disablingConditions:{
		'key':'disabling_conditions',
		'folder':'SSDI',
		'tab':'',
		'link':'/claimants/{claimantUserName}',
		'title':'Disabling Conditions',
	},
	dependents:{
		'key':'dependents',
		'folder':'SSDI',
		'tab':'',
		'link':'/claimants/{claimantUserName}',
		'title':'Dependents',
	},
	login:{
		'key':'login',
		'folder':'Claimant Record',
		'tab':'',
		'link':'/claimants/record/{claimantUserName}',
		'title':'Log-in',
	},
	basic:{
		'key':'basic',
		'folder':'Claimant Record',
		'tab':'',
		'link':'/claimants/record/{claimantUserName}',
		'title':'Basic',
	},
	coraAuthorize:{
		'key':'cora_authorize',
		'folder':'Post Award',
		'tab':'CORA Authorizatio',
		'link':'/claimants/{claimantUserName}/post-award/cora-authorize',
	},
	multiMediaAudio:{
		'key':'audio',
		'folder':'Post Award',
		'tab':'CORA Authorizatio',
		'link':'/claimants/{claimantUserName}/post-award/cora-authorize',
		'title':'Audio',
	},
	multiMediaVideo:{
		'key':'video',
		'folder':'Post Award',
		'tab':'CORA Authorizatio',
		'link':'/claimants/{claimantUserName}/post-award/cora-authorize',
		'title':'Video',
	},
	lastJobDetails: {
		'key': 'job_details',
		'folder': 'Last Job',
		'tab':'',
		'link': '/claimants/record/{claimantUserName}',
		'title': 'Job Details',
	},
	lastJobDescription:{
		'key': 'job_description',
		'folder': 'Last Job',
		'tab':'',
		'link': '/claimants/record/{claimantUserName}',
		'title': 'Job Description',
	},
	lastJobAdditionInfo:{
		'key': 'job_addition_info',
		'folder': 'Additional Info',
		'tab':'',
		'link': '/claimants/record/{claimantUserName}',
		'title': 'Additional Info',
	},
	noteNewInfo: {
		'key': 'notes_add',
		'folder': 'Note New Info',
		'title': 'Note New Info',
	},
	noteEditInfo: {
		'key': 'notes_update',
		'folder': 'Note Edit Info',
		'title': 'Note Edit Info',
	}
};
