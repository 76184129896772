import { HttpClient, HttpRequest } from '@angular/common/http';
import { CODES_DICTIONARY_CONFIG } from '../@config/api';
import { FileUploadingService } from '../../@util/file-uploading-progress/file-uploading.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../@util/file-uploading-progress/file-uploading.service";
export class CodeDictionaryService {
    constructor(http, fileService) {
        this.http = http;
        this.fileService = fileService;
        this.config = CODES_DICTIONARY_CONFIG;
    }
    getFollowUpsCodesByType(claimantLevelType) {
        return this.http.get(this.config.FOLLOW_UPS.getCodesByType(claimantLevelType));
    }
    getFollowUpsCodes() {
        return this.http.get(this.config.FOLLOW_UPS.getCodes);
    }
    addFollowUpsCode(data) {
        return this.http.post(this.config.FOLLOW_UPS.addCode, data);
    }
    getFollowUpsCode(id) {
        return this.http.get(this.config.FOLLOW_UPS.getCode(id));
    }
    getAvailableFollowUpCodes(type) {
        return this.http.get(this.config.FOLLOW_UPS.getAvailable(type));
    }
    getProspectsCode(id) {
        return this.http.get(this.config.PROSPECTS_TYPE.getCode(id));
    }
    getProspectsCodes() {
        return this.http.get(this.config.PROSPECTS_TYPE.getCodes);
    }
    addProspectsCode(data) {
        return this.http.post(this.config.PROSPECTS_TYPE.addCode, data);
    }
    getImpairmentsCodes() {
        return this.http.get(this.config.IMPAIRMENT.getCodes);
    }
    addImpairmentsCode(data) {
        return this.http.post(this.config.IMPAIRMENT.addCode, data);
    }
    getImpairmentsCode(id) {
        return this.http.get(this.config.IMPAIRMENT.getCode(id));
    }
    importImpairmentsCodeFromFile(selectedFile, splitter) {
        const formData = new FormData();
        if (selectedFile === undefined || selectedFile === null) {
            return;
        }
        formData.append('file', selectedFile);
        formData.append('splitter', splitter);
        const req = new HttpRequest('POST', this.config.IMPAIRMENT.fromFile, formData, {
            reportProgress: true,
            responseType: 'text'
        });
        return this.http.request(req);
    }
    getReferralTypeCodes() {
        return this.http.get(this.config.REFERRAL_TYPE.getCodes);
    }
    addReferralTypeCode(data) {
        return this.http.post(this.config.REFERRAL_TYPE.addCode, data);
    }
    getReferralTypeCode(id) {
        return this.http.get(this.config.REFERRAL_TYPE.getCode(id));
    }
    findImairamentCodeLike(value) {
        return this.http.get(this.config.IMPAIRMENT.findLike(value));
    }
    findImairamentCodeByDescription(value) {
        return this.http.get(this.config.IMPAIRMENT.findByDescription(value));
    }
    getPostAwardCodes() {
        return this.http.get(this.config.POST_AWARD.getCodes);
    }
    getPostAwardCode(id) {
        return this.http.get(this.config.POST_AWARD.getCode(id));
    }
    addPostAwardCode(value) {
        return this.http.post(this.config.POST_AWARD.addCode, value);
    }
    getFormsSetCodes() {
        return this.http.get(this.config.FORMS_SET.getCodes);
    }
    getFormsSetCode(id) {
        return this.http.get(this.config.FORMS_SET.getCode(id));
    }
    addFormsSetCode(value) {
        return this.http.post(this.config.FORMS_SET.addCode, value);
    }
    getPolicyholderCodes() {
        return this.http.get(this.config.POLICYHOLDER.getCodes);
    }
    getPolicyholderCode(id) {
        return this.http.get(this.config.POLICYHOLDER.getCode(id));
    }
    addPolicyholderCode(value) {
        return this.http.post(this.config.POLICYHOLDER.addCode, value);
    }
    getClosedCodes() {
        return this.http.get(this.config.CLOSED.getCodes);
    }
    getClosedCode(id) {
        return this.http.get(this.config.CLOSED.getCode(id));
    }
    getAvailableClosedCodes(type) {
        return this.http.get(this.config.CLOSED.getAvalibleCode(type), { responseType: 'text' });
    }
    getClosedCodeTypes() {
        return this.http.get(this.config.CLOSED.getTypes);
    }
    addClosedCode(data) {
        return this.http.post(this.config.CLOSED.addCode, data);
    }
    isClosedCodeExist(newCode) {
        return this.http.get(this.config.CLOSED.isExist(newCode), { responseType: 'text' });
    }
}
CodeDictionaryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CodeDictionaryService_Factory() { return new CodeDictionaryService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FileUploadingService)); }, token: CodeDictionaryService, providedIn: "root" });
