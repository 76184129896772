import * as tslib_1 from "tslib";
import { MEDICAL_SOURCE_CONFIG } from '../@config/api';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class MedicalSourcesService {
    constructor(http) {
        this.http = http;
        this.config = MEDICAL_SOURCE_CONFIG;
    }
    getAllDoctors() {
        return this.http.get(this.config.getAllDoctors);
    }
    getDoctorById(doctorId) {
        return this.http.get(this.config.getDoctorById(doctorId));
    }
    setDoctor(result) {
        return this.http.post(this.config.setDoctor, result);
    }
    saveTreatingSourcesRequests(sslgId, sourceId, requests) {
        return this.http.post(this.config.saveTreatingSourcesRequests(sslgId), { sourceId: sourceId, requests: requests });
    }
    saveTreatingSourcesDoctor(sslgId, result) {
        return this.http.post(this.config.saveTreatingSourcesDoctor(sslgId), result);
    }
    addTreatingSourcesDoctor(sslgId, result, typeClaimAppeal, dateIc, dateRecon, dateHrg, treatment, medicalTests, medicalTestsList, medications, medicationsList) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = Object.assign({}, result, { typeClaimAppeal: typeClaimAppeal, dateIc: dateIc, dateRecon: dateRecon, dateHrg: dateHrg, treatment: treatment, medicalTests,
                medicalTestsList,
                medications,
                medicationsList });
            // console.log(data);
            return yield this.http.post(this.config.addTreatingSourcesDoctor(sslgId), data).toPromise();
        });
    }
    addTreatingSourcesDoctorDates(sslgId) {
        return this.http.post(this.config.addTreatingSourcesDoctorDates(sslgId), {});
    }
    addTreatingSourcesHospital(sslgId, result, eRVisit, eRVisitDate, outPatient, outPatientDate, inPatient, inPatientAdmission, inPatientDischarge, hospitalizationReason, treatmentDetails, conditions, treatedDoctor, medications, medicationsList, treatment, medicalTests, medicalTestsList) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = Object.assign({}, result, { eRVisit,
                eRVisitDate,
                outPatient,
                outPatientDate,
                inPatient,
                inPatientAdmission,
                inPatientDischarge,
                hospitalizationReason,
                treatmentDetails,
                conditions,
                treatedDoctor,
                medications,
                medicationsList,
                treatment,
                medicalTests,
                medicalTestsList });
            return yield this.http.post(this.config.addTreatingSourcesHospital(sslgId), data).toPromise();
        });
    }
    addHospitalVisit(sslgId, catalogItemId, eRVisit, eRVisitDate, outPatient, outPatientDate, inPatient, inPatientAdmission, inPatientDischarge, hospitalizationReason, treatmentDetails, conditions, treatedDoctor, medications, medicationsList, treatment, medicalTests, medicalTestsList) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = {
                catalogItemId,
                eRVisit,
                eRVisitDate,
                outPatient,
                outPatientDate,
                inPatient,
                inPatientAdmission,
                inPatientDischarge,
                hospitalizationReason,
                treatmentDetails,
                conditions,
                treatedDoctor,
                medications,
                medicationsList,
                treatment,
                medicalTests,
                medicalTestsList
            };
            return yield this.http.post(this.config.addHospitalVisit(sslgId), data).toPromise();
        });
    }
    saveTreatingSourcesHospital(sslgId, result) {
        return this.http.post(this.config.saveTreatingSourcesHospital(sslgId), result);
    }
    addTreatingSourcesOther(sslgId, result, treatmentStart, treatmentEnd, treatmentOngoing, treatmentReason, treatmentDetails, nextVisit) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = Object.assign({}, result, { treatmentStart,
                treatmentEnd,
                treatmentOngoing,
                treatmentReason,
                treatmentDetails,
                nextVisit });
            // console.log(data);
            return yield this.http.post(this.config.addTreatingSourcesOther(sslgId), data).toPromise();
        });
    }
    saveTreatingSourcesOther(sslgId, result) {
        return this.http.post(this.config.saveTreatingSourcesOther(sslgId), result);
    }
    treatingSourcesRequestsList(sslgId, sourceId) {
        return this.http.post(this.config.treatingSourcesRequestsList(sslgId), { sourceId: sourceId });
    }
    treatingSourcesPaymentsList(sslgId, sourceId) {
        return this.http.post(this.config.treatingSourcesPaymentsList(sslgId), { sourceId: sourceId });
    }
    saveTreatingSourcesRequest(sslgId, id, sourceId, data) {
        return this.http.post(this.config.saveTreatingSourcesRequest(sslgId, id, sourceId), data);
    }
    saveTreatingSourcesPayment(sslgId, id, sourceId, data) {
        return this.http.post(this.config.saveTreatingSourcesPayment(sslgId, id, sourceId), data);
    }
    treatingSourcesFindPayment(id) {
        return this.http.post(this.config.treatingSourcesFindPayment(), { id: id });
    }
    treatingSourcesLastUserToRecords(sourceId) {
        return this.http.post(this.config.treatingSourcesLastUserToRecords(sourceId), {});
    }
    treatingSourcesFindRequest(id) {
        return this.http.post(this.config.treatingSourcesFindRequest(), { id: id });
    }
    deleteSourceRequest(request) {
        return this.http.post(this.config.deleteSourceRequest(), { requestId: request });
    }
    deleteSourcePayment(request) {
        return this.http.post(this.config.deleteSourcePayment(), { requestId: request });
    }
    getHospital(hospitalId) {
        return this.http.get(this.config.getHospitalById(hospitalId));
    }
    getClaimantDoctors(sslgId) {
        return this.http.get(this.config.getClaimantDoctors(sslgId));
    }
    getAllHospitals() {
        return this.http.get(this.config.getAllHospitals);
    }
    setHospital(result) {
        return this.http.post(this.config.setHospital, result);
    }
    getOther(id) {
        return this.http.get(this.config.getOtherById(id));
    }
    getAllOthers() {
        return this.http.get(this.config.getAllOthers);
    }
    setOther(result) {
        return this.http.post(this.config.setOther, result);
    }
    deleteSource(sslgId, id) {
        return this.http.post(this.config.deleteSource, { sslgId, id });
    }
    deleteSources(sources) {
        return this.http.post(this.config.deleteSources, { sources });
    }
    changeStatusSource(sslgId, id, status) {
        return this.http.post(this.config.changeStatusSource, { sslgId, id, status });
    }
}
MedicalSourcesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MedicalSourcesService_Factory() { return new MedicalSourcesService(i0.ɵɵinject(i1.HttpClient)); }, token: MedicalSourcesService, providedIn: "root" });
