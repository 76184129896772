import {
	DynamicDatePickerModel,
	DynamicDatePickerModelConfig,
	DynamicFormControlLayout
} from "@ng-dynamic-forms/core";

export interface DatepickerCustompropsModelConfig extends DynamicDatePickerModelConfig, PermissionConfig {
	hiddenClearIco: boolean;
	hiddenDatePicker: boolean;
}

export class DatepickerCustompropsModel extends DynamicDatePickerModel {
	readonly type = 'customDatePicker';
	hiddenClearIco = false;
	hiddenDatePicker = false;

	constructor(config: DatepickerCustompropsModelConfig, layout?: DynamicFormControlLayout) {
		super(config, layout);

		//config.min = new Date(2020, 5, 5);
		if (config.hiddenClearIco != undefined)
			this.hiddenClearIco = config.hiddenClearIco;
		if (config.hiddenDatePicker != undefined)
			this.hiddenDatePicker = config.hiddenDatePicker;
	}

}
