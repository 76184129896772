export class UserAdditionalInfo {
	left = {
		name: null,
		img: null,
		title: null,
		phone: null,
		email: null,
		directDial: null,
	};
	middle = {
		name: null,
		img: null,
		title: null,
		phone: null,
		email: null,
		directDial: null,
	};
	right = {
		name: null,
		img: null,
		title: null,
		phone: null,
		email: null,
		directDial: null,
	};
}
