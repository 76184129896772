<!--<div class="content" [@routerAnimation]="getRouteAnimation(router)">-->
  <!--<router-outlet  #router="outlet"></router-outlet>-->
<!--</div>-->


<div class="content">
  <router-outlet #router="outlet"></router-outlet>
  <app-page-loader></app-page-loader>
  <div class="page-loader page-loader--loading" *ngIf="show === null">
    <div class="page-loader__progress"></div>
  </div>
</div>
