import {Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {
    DynamicDatePickerModel, DynamicFormControlComponent,
    DynamicFormControlCustomEvent,
    DynamicFormLayout,
    DynamicFormLayoutService, DynamicFormValidationService
} from "@ng-dynamic-forms/core";
import { LabelOptions, MAT_LABEL_GLOBAL_OPTIONS } from "@angular/material/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MatInput } from "@angular/material/input";
import {DatepickerCustompropsModel} from "./datepicker-customprops-model";

@Component({
    selector: 'app-datepicker-customprops',
    templateUrl: './datepicker-customprops.component.html',
    styleUrls: ['./datepicker-customprops.component.scss'],
})
export class DatepickerCustompropsComponent extends DynamicFormControlComponent implements OnInit{

	selectedValue;

    @Input() group: FormGroup;
    @Input() layout: DynamicFormLayout;
    @Input() model: DatepickerCustompropsModel;

    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
    @Output() focus: EventEmitter<any> = new EventEmitter();

    @ViewChild("matDatepicker", { static: true }) matDatePicker: MatDatepicker<any>;
    @ViewChild(MatInput, { static: false }) matInput: MatInput;

    newDatepickerOptions: {};

    constructor(protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService,
                @Inject(MAT_LABEL_GLOBAL_OPTIONS) @Optional() public LABEL_OPTIONS: LabelOptions) {

        super(layoutService, validationService);
    }

	minDate = new Date(2020, 5, 10);

    ngOnInit() {
    	this.model.valueUpdates.subscribe(j => {
    		if (j) {
				this.selectedValue = j;
			}
		});


        // if (this.model) {
        //     this.newDatepickerOptions = {
        //         autofocus: this.model.autoFocus,
        //         formControlName: this.model.id,
        //         id: this.elementId,
        //         maxDate: this.model.max,
        //         minDate: this.model.min,
        //         name: this.model.name,
        //         ngClass: this.getClass('element', 'control'),
        //         placeholder: this.model.placeholder,
        //         readonly: this.model.readOnly,
        //         required: this.model.required,
        //         tabIndex: this.model.tabIndex,
        //         displayFormat: 'M[/]D[/]YYYY'
        //     };
        // }
    }

    clearDate() {
		if(!this.model.disabled){
			this.model.valueUpdates.next(null);

			super.onChange({});
		}
    }

    datePick(event) {
		this.model.valueUpdates.next(event);
	}

    onChange(event) {
        super.onChange(event);
    }
}
