/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pdf-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./pdf-view-blocks/pdf-view-blocks-doctors/pdf-view-blocks-doctors.component.ngfactory";
import * as i3 from "./pdf-view-blocks/pdf-view-blocks-doctors/pdf-view-blocks-doctors.component";
import * as i4 from "./pdf-view-blocks/pdf-view-blocks-hospitals/pdf-view-blocks-hospitals.component.ngfactory";
import * as i5 from "./pdf-view-blocks/pdf-view-blocks-hospitals/pdf-view-blocks-hospitals.component";
import * as i6 from "./pdf-view-blocks/pdf-view-blocks-other/pdf-view-blocks-other.component.ngfactory";
import * as i7 from "./pdf-view-blocks/pdf-view-blocks-other/pdf-view-blocks-other.component";
import * as i8 from "@angular/common";
import * as i9 from "./visits-report/report-header/report-header.component.ngfactory";
import * as i10 from "./visits-report/report-header/report-header.component";
import * as i11 from "../@core/referral.service";
import * as i12 from "./pdf-view.component";
import * as i13 from "@angular/router";
import * as i14 from "../../@util/loading/loading.service";
import * as i15 from "../@core/medical.service";
var styles_PdfViewComponent = [i0.styles];
var RenderType_PdfViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PdfViewComponent, data: {} });
export { RenderType_PdfViewComponent as RenderType_PdfViewComponent };
function View_PdfViewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pdf-view-blocks-doctors", [], null, null, null, i2.View_PdfViewBlocksDoctorsComponent_0, i2.RenderType_PdfViewBlocksDoctorsComponent)), i1.ɵdid(1, 114688, null, 0, i3.PdfViewBlocksDoctorsComponent, [], { tableData: [0, "tableData"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PdfViewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pdf-view-blocks-hospitals", [], null, null, null, i4.View_PdfViewBlocksHospitalsComponent_0, i4.RenderType_PdfViewBlocksHospitalsComponent)), i1.ɵdid(1, 114688, null, 0, i5.PdfViewBlocksHospitalsComponent, [], { tableData: [0, "tableData"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PdfViewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pdf-view-blocks-other", [], null, null, null, i6.View_PdfViewBlocksOtherComponent_0, i6.RenderType_PdfViewBlocksOtherComponent)), i1.ɵdid(1, 114688, null, 0, i7.PdfViewBlocksOtherComponent, [], { tableData: [0, "tableData"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_PdfViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["body", 1]], null, 10, "div", [["class", "visit-card"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PdfViewComponent_3)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PdfViewComponent_4)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PdfViewComponent_5)), i1.ɵdid(9, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type == "DOCTOR"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == "HOSPITAL"); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type == "OTHER"); _ck(_v, 9, 0, currVal_2); }, null); }
function View_PdfViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "view-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n\t"])), (_l()(), i1.ɵeld(2, 0, [[2, 0], ["head", 1]], null, 4, "div", [["style", "page-break-inside: avoid; page-break-after: avoid;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-report-header", [], null, null, null, i9.View_ReportHeaderComponent_0, i9.RenderType_ReportHeaderComponent)), i1.ɵdid(5, 114688, null, 0, i10.ReportHeaderComponent, [i11.ReferralService], { sslgId: [0, "sslgId"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(8, 0, [[3, 0], ["report", 1]], null, 4, "div", [["class", "view-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PdfViewComponent_2)), i1.ɵdid(11, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.pdfSrc.data[0] == null) ? null : _co.pdfSrc.data[0].sslgId); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.pdfData; _ck(_v, 11, 0, currVal_1); }, null); }
export function View_PdfViewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { body: 1 }), i1.ɵqud(402653184, 2, { head: 0 }), i1.ɵqud(402653184, 3, { report: 0 }), i1.ɵqud(402653184, 4, { footer: 0 }), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PdfViewComponent_1)), i1.ɵdid(19, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showContent; _ck(_v, 19, 0, currVal_0); }, null); }
export function View_PdfViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-pdf-view", [], null, null, null, View_PdfViewComponent_0, RenderType_PdfViewComponent)), i1.ɵdid(1, 4308992, null, 0, i12.PdfViewComponent, [i13.ActivatedRoute, i13.Router, i14.LoadingService, i15.MedicalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PdfViewComponentNgFactory = i1.ɵccf("app-pdf-view", i12.PdfViewComponent, View_PdfViewComponent_Host_0, {}, {}, []);
export { PdfViewComponentNgFactory as PdfViewComponentNgFactory };
