<app-frame [overlay]="false" [title]="title" (neadClose)="closeVisitForm()" autocomplete="off">
	<form class="form-horizontal" [formGroup]="formGroup" novalidate>
		<dynamic-material-form [group]="formGroup"
							   [layout]="formLayout"
							   [model]="formModel">
		</dynamic-material-form>
	</form>
	<app-form-actions-content>
		<form-button (clickOnButton)="saveVisitForm()" buttonType="save">Save & Next</form-button>
	</app-form-actions-content>
</app-frame>
