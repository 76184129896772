<app-frame [overlay]="false" [title]="title" (neadClose)="closeFormFollowUp()" autocomplete="off">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <dynamic-material-form [group]="formGroup"
                           [model]="formModel">
    </dynamic-material-form>
  </form>
  <app-form-actions-content>
    <form-button *ngIf="this.globalSaveService.getStatusTrue()" (clickOnButton)="saveFollowUp()" buttonType="save">Complete</form-button>
  </app-form-actions-content>
</app-frame>
