import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {UserInfoService} from "../../@core/user-info.service";
import {UserTempService} from "../../@core/userTemp.service";

@Injectable()
export class TempUsersGuard implements CanActivate {

	constructor(private router: Router, private userInfoService: UserInfoService, private userTempService: UserTempService) {

	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// this.userInfoService.loadCurent();
		// return this.userInfoService.userInfo.pipe(map(j => {
			if (this.isTempUser()) {
				this.router.navigate([`/claimants/medical-treating-sources/${this.userInfoService.getCurrentUserName()}`]);
				return false;
			}
			return true;
		// }));
	}

	isTempUser() {
		return this.userTempService.isTempUser();
	}

}
