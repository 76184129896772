import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CardModule} from './card/card.module';
import {FormActionsContentComponent} from './form-actions-content/form-actions-content.component';
import {FormButtonComponent} from './form-button/form-button.component';
import {FormSocialComponent} from './form-social/form-social.component';
import {FormFrameComponent} from './forms/form-frame/form-frame.component';
import {PopupContainerComponent} from './popup-container/popup-container.component';
import {NoteComponent} from './note/note.component';
import { QuillModule} from 'ngx-quill';
import {ExportModule} from "../../export/export.module";
import { TableSearchComponent } from './table-search/table-search.component';
import {SearchBarComponent} from "./search-bar/search-bar.component";
import { AddressSelectComponent } from './address-select/address-select.component';
import {AdditionalUserInfoComponent} from "../home/additional-user-info/additional-user-info.component";
import {PageLoaderComponent} from "./page-loader/page-loader.component";
import {PartialMedicalTestsComponent} from "./partial-medical-tests/partial-medical-tests.component";
import {CanAccessModule} from "../../auth/permission-guard/can-access.module";

const components = [
    PopupContainerComponent,
    FormFrameComponent,
    FormSocialComponent,
    FormButtonComponent,
    FormActionsContentComponent,
    TableSearchComponent,
    SearchBarComponent,
    NoteComponent,
    AddressSelectComponent,
    AdditionalUserInfoComponent,
    PageLoaderComponent,
    PartialMedicalTestsComponent
];

const toolBarConfig = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        // ['blockquote', 'code-block'],

        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{'list': 'ordered'}, {'list': 'bullet'}],
        // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
        // [{ 'direction': 'rtl' }],                         // text direction

        [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
        // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{'color': []}, {'background': []}],          // dropdown with defaults from theme
        // [{ 'font': [] }],
        // [{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        // ['link', 'image', 'video']                         // link and image, video
    ]
};

@NgModule({
    declarations: [...components, ],
    imports: [
        CanAccessModule,
        CardModule,
        CommonModule,
        ExportModule,
        QuillModule.forRoot({modules: toolBarConfig}),
    ],
    exports: [
        ...components,
        CardModule,
    ]
})
export class PartialModule {
}
