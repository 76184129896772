import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

import {DialogService} from "../../../../../../../@util/dialog/dialog.service";
import {GlobalSaveService} from "../../../../../../@core/global-save.service";

@Component({
	selector: 'app-claimant-notifications-alert-details',
	templateUrl: './claimant-notifications-alert-details.component.html'
})
export class ClaimantNotificationsAlertDetailsComponent implements OnInit {
	link;

	constructor(private dialogRef: MatDialogRef<ClaimantNotificationsAlertDetailsComponent>,
				private dialog: DialogService,
				private globalSaveService: GlobalSaveService,
				@Inject(MAT_DIALOG_DATA) public data:any) {
	}

	ngOnInit() {
		this.link = '/report/notification/'+this.globalSaveService.getUserName()+'/'+this.data.id+'/local';

		document.getElementById("notificationAlert").setAttribute("src",this.link);
	}

	closeForm() {
		this.dialogRef.close();
	}

}
