import * as i0 from "@angular/core";
export class UserTempService {
    constructor() {
        this.storage = sessionStorage;
        this.userTempKey = 'currentUserTemp';
    }
    isTempUser() {
        // return true;
        return this.storage.getItem(this.userTempKey) === 'true';
    }
    resetUserTempState() {
        this.storage.setItem(this.userTempKey, 'false');
    }
    setUserTempState(res) {
        this.storage.setItem(this.userTempKey, res.currentUserTemp);
        // this.storage.setItem(this.userTempKey, '1');
    }
}
UserTempService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserTempService_Factory() { return new UserTempService(); }, token: UserTempService, providedIn: "root" });
