<app-frame [overlay]="false"
		   [titleHTML]="title"
		   [showCloseButton]="true"
		   [class.no-padding-form-content]="isMedicalTestsStep() || isNextDoctorCreatedStep()"
		   (neadClose)="closeDoctorForm({})"
		   autocomplete="off">
	<div class="adding-info-block" *ngIf="isInitialStep()">
		<div class="adding-info-block__head">
			<div class="adding-info-block__text">
				If your case is pending, please select the correct level below
			</div>
		</div>

		<form class="form-horizontal" [formGroup]="formInitialGroup" novalidate>
			<dynamic-material-form [group]="formInitialGroup"
								   [model]="formInitialModel"
								   (blur)="onBlur($event)"
								   (change)="onChange($event); onStateChanges()"
								   (focus)="onFocus($event)">
				<ng-template modelId="medicalConditions" align="START">
					<label class="form-label form-label_full-width form-label_mb">
						What medical conditions does this doctor treat?
					</label>
				</ng-template>
			</dynamic-material-form>
			<mat-radio-group #matRadioGroup
							 (change)="onRadioChange($event)">

				<mat-radio-button *ngFor="let option of initialFormRadioOptions"
								  [disabled]="isRadioDisabled(option) || (levelRefValue === 'unset')"
								  [value]="option.value"><span [innerHTML]="option.label"></span></mat-radio-button>
			</mat-radio-group>
		</form>

		<app-form-actions-content>
			<form-button (clickOnButton)="goToSecondStep()"
						 [disabled]="formInitialGroup.controls.levelSelection.value === null"
						 buttonType="add"
						 title="Continue">
				Continue
			</form-button>
		</app-form-actions-content>
	</div>

	<div class="adding-info-block adding-info-block--finish" *ngIf="isFinishedStep()">
		<div class="adding-info-block__row adding-info-block__row--wrap">
<!--			<app-form-actions-content>-->
				<form-button (clickOnButton)="addAnotherDoctor()"
							 buttonType="add"
							 class="adding-info-block__btn"
							 title="Continue">
					Add Another Doctor
				</form-button>
				<form-button (clickOnButton)="addHospitalization()"
							 buttonType="add"
							 class="adding-info-block__btn"
							 title="Continue">
					Add a Hospitalization
				</form-button>
				<form-button  (clickOnButton)="addADoctorsVisit()"
							  buttonType="add"
							 class="adding-info-block__btn"
							 title="Continue">
					Add a Doctor's Visit Summary
				</form-button>
				<form-button (clickOnButton)="addOtherTreatingSource()"
							 buttonType="add"
							 class="adding-info-block__btn"
							 title="Continue">
					Add a OTHER Treating Source
				</form-button>
				<form-button (clickOnButton)="returnToMyProfile()"
							 buttonType="add"
							 class="adding-info-block__btn"
							 title="Continue">
					Return to my Profile
				</form-button>
<!--			</app-form-actions-content>-->
		</div>
	</div>

	<div class="adding-info-block"
		 *ngIf="isInitialFilingStep() || isRequestForReconsiderationStep() || isRequestForHearingStep()">
		<div class="adding-info-block__head">
<!--			<div class="adding-info-block__title">-->
<!--				INITIAL CLAIM FILING-->
<!--			</div>-->
			<div class="adding-info-block__text"
				 *ngIf="isInitialFilingStep()">
				According to the Information you gave us, you last worked (DLW) on or about:
			</div>
			<div class="adding-info-block__text"
				 *ngIf="isRequestForReconsiderationStep()">
				According to the Information you gave us, you filed your Initial Claim on or about
			</div>
			<div class="adding-info-block__text"
				 *ngIf="isRequestForHearingStep()">
				According to the Information you gave us, you filed your Reconsideration Claim on or About
			</div>
		</div>
		<form class="form-horizontal" [formGroup]="formDate" novalidate>
			<div class="mat-form-field-wrapper">
				<div class="mat-form-field-flex">
					<div class="mat-form-field-infix">
						<mat-form-field>
							<mat-label *ngIf="isInitialFilingStep()">Date Last Worked</mat-label>
							<mat-label *ngIf="isRequestForReconsiderationStep()">IC Filing Date</mat-label>
							<mat-label *ngIf="isRequestForHearingStep()">Recon Filing Date</mat-label>
							<div class="ngx-datepicker-container mat-calendar">
								<div class="ngx-datepicker-input-container" #ngxInputContainer>
									<div class="input-container">
										<input name="introOut"
											   matInput
											   (dateChange)="onChangeDate($event)"
											   [matDatepicker]="picker"
											   (click)="picker.open()"
											   [value]="formDate.date">
									</div>
									<span class="icon-clear material-icon material-icons">highlight_off</span>
								</div>
							</div>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-datepicker #picker></mat-datepicker>
						</mat-form-field>
					</div>
				</div>
			</div>
		</form>
		<ng-container *ngIf="isInitialFilingStep()">
			<div class="adding-info-block__text">
				As such, we only need Medical Records from your Doctors and Hospitals that
				<b>have treated you for your Disabling Conditions</b>
				as of 6 (six) to 9 (nine) months prior to the above date.
			</div>
			<div class="adding-info-block__text">
				Please do not include any Treating Sources older than 6-9 months from the DLW, and please do not
				provide treating sources information for diagnoses that do not impact your ability to work.
			</div>
		</ng-container>

		<ng-container *ngIf="isRequestForReconsiderationStep()">
			<div class="adding-info-block__text">
				As such, we only need Medical Records from your Doctors and Hospitals that Treated you
				<b>as of this date.</b>
			</div>
			<div class="adding-info-block__text">
				When you've finished listing the treating sources above, we can list other treating sources that SSA never
				contacted, or those that provided medical treatment for your disabling conditions.
			</div>
		</ng-container>

		<ng-container *ngIf="isRequestForHearingStep()">
			<div class="adding-info-block__text">
				As such, we only need Medical Records from your Doctors and Hospitals that treated you
				<b>as of this date.</b>
			</div>
			<div class="adding-info-block__text">
				When you've finished listing the treating sources above, we will contact you to update your Medical Records
				from all relevant treating sources in preparation of your hearing.
			</div>
		</ng-container>

		<div class="adding-info-block__footer">
			<button (click)="changeTypeAskingStep(creationSteps.initial)"
					class="form-action default">
				Back
			</button>
			<button (click)="setPendingDate(); changeTypeAskingStep(5)"
					[disabled]="!formDate.date"
					class="form-action default">
				Continue
			</button>
		</div>
	</div>

	<ng-container *ngIf="isNewDoctorStep()">
		<form class="form-horizontal" [formGroup]="formGroup" novalidate>
			<mat-form-field *ngIf="showName()" class="name_input" [ngClass]="{'dirty': isInputDirty()}">
				<input required
					   matInput
					   placeholder="Name"
					   aria-label="Name"
					   #trigger="matAutocompleteTrigger"
					   [matAutocomplete]="auto"
					   [formControl]="nameCtrl">
				<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
			  <span *ngIf="!isApp()">
					<mat-option [class.grey]="name.id != -1" *ngFor="let name of filteredNames | async" [value]="name"
								[matTooltip]="name.label ? name.label : null">
						<span [class.grey]="!name.label">{{name.name}}</span>
					</mat-option>
			  </span>
					<span *ngIf="isApp()">
			  	<mat-option [class.grey]="name.id != -1" *ngFor="let name of filteredNames | async" [value]="name">
					<span [class.grey]="!name.label">{{name.name}}</span>
				</mat-option>
		 	</span>
				</mat-autocomplete>
			</mat-form-field>

			<dynamic-material-form [group]="formGroup"
								   [layout]="formLayout"
								   [model]="formModel"
								   (blur)="onBlur($event)"
								   (change)="onChange($event); onStateChanges()"
								   (focus)="onFocus($event)">
				<ng-template modelId="medicalConditions" align="START">
					<label class="form-label form-label_full-width form-label_mb">
						What medical conditions does this doctor treat?
					</label>
				</ng-template>
				<ng-template modelId="visits" align="START">
					<label class="form-label form-label_full-width form-label_mb">
						Please provide the approximate treatment dates below. If you're not sure, just estimate the dates that you were treated for your disabling conditions.
						The SSA will request these records.
					</label>
				</ng-template>
<!--				<ng-template modelId="medicalConditions" align="START">-->
<!--					<label class="form-label form-label_full-width form-label_mb">-->
<!--						What medical conditions were treated?-->
<!--					</label>-->
<!--				</ng-template>-->
<!--				<ng-template modelId="callerName" align="START">-->
<!--					<label class="form-label form-label_full-width form-label_mb">-->
<!--						&lt;!&ndash;					Who to call for Medical Record requests&ndash;&gt;-->
<!--						&lt;!&ndash;					Is there someone we can call at this office when we need to get medical records?&ndash;&gt;-->
<!--						Do you know someone we can call to F/U on Medical Record requests?-->
<!--					</label>-->
<!--				</ng-template>-->
				<ng-template modelId="medicalCondition" let-context="context" let-index="index" align="END">
					<app-form-actions-content *ngIf="showRemoveBtn(context, index)" class="custom-btn col-1 auto-f-basis">

						<button (click)="remove(context, index)" class="close-btn">
							<span></span>
							<span></span>
						</button>
					</app-form-actions-content>
					<app-form-actions-content *ngIf="showAddBtn(context, index)" class="col-12">
						<form-button (clickOnButton)="insertMedicalCondition()">Add
						</form-button>
					</app-form-actions-content>
				</ng-template>
			</dynamic-material-form>

		</form>

		<app-form-actions-content *ngIf="canAccess()">
			<form-button
					(clickOnButton)="closeDoctorForm({})"
					buttonType="warning"
					title="Cancel">
				Cancel
			</form-button>

<!--			<form-button *ngIf="showSave() && !isUserTemp()"-->
<!--						 (clickOnButton)="addVisit()"-->
<!--						 buttonType="add"-->
<!--						 title="Add Visit">-->
<!--				Add Visit-->
<!--			</form-button>-->
<!--			<form-button *ngIf="showSave()"-->
<!--						 (clickOnButton)="saveDoctor('new')"-->
<!--						 buttonType="add"-->
<!--						 title="{{getBtnTitle()}}">-->
<!--				{{getBtnName()}}-->
<!--			</form-button>-->
			<form-button (clickOnButton)="changeTypeAskingStep(6)"
						 [disabled]="!nameCtrl.value"
						 buttonType="add"
						 title="Next">
				Next
			</form-button>

		</app-form-actions-content>
	</ng-container>

	<ng-container *ngIf="nameCtrl.value">
		<div [style.display]="isMedicalTestsStep() ? 'block' : 'none'">
<!--			<app-medical-treating-sources-medical-test [medicalTest]="null"-->
<!--							  (backForm)="changeTypeAskingStep(creationSteps.newDoctor)"-->
<!--							  (formStateChanged)="onFormStateChanged($event)"-->
<!--							  (allFormStateChanged)="onAllFormStateChanged($event)"-->
<!--							  [formKey]="'Medical Tests'"-->
<!--							  (nextForm)="nextMedicalTest($event)"-->
<!--							  (saveForm)="saveMedicalTest($event)"></app-medical-treating-sources-medical-test>-->
			<app-partial-medical-tests (backForm)="changeTypeAskingStep(creationSteps.newDoctor)"
									   (formStateChanged)="onFormStateChanged($event)"
									   (allFormStateChanged)="onAllFormStateChanged($event)"
									   [formKey]="'Medical Tests'"
									   [mode]="MEDICAL_TESTS_MODE.create"
									   (nextForm)="nextMedicalTest($event)"
									   (saveForm)="saveMedicalTest($event)"></app-partial-medical-tests>
		</div>
	</ng-container>

	<ng-container *ngIf="nameCtrl.value">
		<div [style.display]="isNextDoctorCreatedStep() ? 'block' : 'none'">
			<app-medical-treating-sources-medications
					(closeForm)="closeForm('Medications')"
					(backForm)="changeTypeAskingStep(creationSteps.medicalTests)"
					[sslgId]="sslgId"
					(formStateChanged)="onFormStateChanged($event)"
					(saveForm)="saveMedication($event)"
					(allFormStateChanged)="onAllFormStateChanged($event)"
					[formKey]="'Medications'">
			</app-medical-treating-sources-medications>
		</div>
	</ng-container>

</app-frame>
