import {Component, EventEmitter, Input, Output, QueryList} from "@angular/core";
import {FormArray, FormGroup} from "@angular/forms";
import {
	DynamicFormArrayComponent, DynamicFormArrayGroupModel,
	DynamicFormArrayModel,
	DynamicFormControlCustomEvent,
	DynamicFormLayout,
	DynamicFormLayoutService,
	DynamicFormValidationService,
	DynamicTemplateDirective
} from "@ng-dynamic-forms/core";

@Component({
	selector: "dynamic-material-form-array",
	templateUrl: "./dynamic-material-form-array.component.html"
})
export class DynamicMaterialFormArrayComponent extends DynamicFormArrayComponent {

	@Input() group: FormGroup;
	@Input() layout: DynamicFormLayout;
	@Input() model: DynamicFormArrayModel;
	@Input() templates: QueryList<DynamicTemplateDirective> | undefined;

	@Output() blur: EventEmitter<any> = new EventEmitter();
	@Output() change: EventEmitter<any> = new EventEmitter();
	@Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
	@Output() focus: EventEmitter<any> = new EventEmitter();

	constructor(protected layoutService: DynamicFormLayoutService,
				protected validationService: DynamicFormValidationService) {

		super(layoutService, validationService);
	}

	getFormControl() {
		return (<any>this.control);
	}

	isDirty(group?) {
		if (!group && (this.model as any).isDirty != undefined)
			return (this.model as any).isDirty();
		if (group) {
			for (let i = 0; i < (<DynamicFormArrayGroupModel>group).group.length; i++) {
				if ((<any>(<DynamicFormArrayGroupModel>group).group[i]).isDirty != undefined
					&& (<any>(<DynamicFormArrayGroupModel>group).group[i]).isDirty()) {
					return true;
				}
			}
		}

	}
}
