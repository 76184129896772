import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {MEDICAL_TREATING_REPORT_TYPE} from "./medical-treating-reports.config";

@Component({
  selector: 'app-medical-treating-reports',
  templateUrl: './medical-treating-reports.component.html',
  styleUrls: ['./medical-treating-reports.component.scss']
})
export class MedicalTreatingReportsComponent implements OnInit {
  @Input() isAbsolute = false;
  @Input() sslgId;
  printTypes = [
    {
      id: MEDICAL_TREATING_REPORT_TYPE.both,
      name: 'Both'
    },
    {
      id: MEDICAL_TREATING_REPORT_TYPE.medications,
      name: 'Medications'
    },
    {
      id: MEDICAL_TREATING_REPORT_TYPE.mts,
      name: 'MTS'
    }
  ];
  printType = this.printTypes[0].id;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute) {
  }

  // @Output() clickOnButton = new EventEmitter<any>();
  // @Input() orientation: 'horizontal' | 'vertical';

  ngOnInit() {
  }

  printReport(value) {
    this.openNewTab(value);
  }

  openNewTab(type: string) {
    // this.router.navigate(['/view-medical-treating-report'], {queryParams: {type: type, sslgId: this.sslgId}});
    localStorage.setItem('reportType', type);
    localStorage.setItem('reportSslgId', this.sslgId);
    const url = this.router.serializeUrl(this.router.createUrlTree(['/view-medical-treating-report']));
    window.open(url, '_blank');
  }

  // ngOnChanges(changes: SimpleChanges): void {
  // 	// this.ngOnInit();
  // }

}
