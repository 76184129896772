import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { MedicalService } from "../../../../../@core/medical.service";
import { DynamicFormService } from "@ng-dynamic-forms/core";
import { FormValueBuilder } from "../../../../partial/forms/form-tamplate";
import { Router } from "@angular/router";
import { DynamicRadioGroupModel } from "../../../../../../@util/ui-material/form-controls";
import { PERMISSIONS } from 'src/app/@config/permissions';
export class SourceVariationComponent {
    constructor(formService, medicalService, sslgId, dialogRef, router) {
        this.formService = formService;
        this.medicalService = medicalService;
        this.sslgId = sslgId;
        this.dialogRef = dialogRef;
        this.router = router;
        this.title = 'New Medical Record';
        this.form = {
            type: DynamicRadioGroupModel({
                id: 'type',
                label: '',
                value: 'DOCTOR',
                options: [
                    { value: 'DOCTOR', label: 'DOCTOR' },
                    { value: 'HOSPITAL', label: 'HOSPITAL' },
                    { value: 'OTHER', label: 'OTHER' },
                ], editableFor: PERMISSIONS.LOWER_EXEC
            })
        };
        this.formModel = FormValueBuilder.buildFormModel(this.form);
        this.formGroup = formService.createFormGroup(this.formModel);
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
        });
    }
    onChange($event) {
    }
    closeVisitForm() {
        this.dialogRef.close();
    }
    saveVisitForm() {
        switch (this.form.type.value) {
            case 'DOCTOR':
                this.router.navigate([`/claimants/${this.sslgId}/visit-medical-source/DOCTOR`]);
                break;
            case 'HOSPITAL':
                this.router.navigate([`/claimants/${this.sslgId}/visit-medical-source/HOSPITAL`]);
                break;
            case 'OTHER':
                this.router.navigate([`/claimants/${this.sslgId}/visit-medical-source/OTHER`]);
                break;
        }
        this.dialogRef.close();
    }
    getUrl() {
        return this.router.url.indexOf('?') !== -1 ?
            this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
    }
}
