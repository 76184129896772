<div class="mat-paginator mat-paginator--print form-fieldset"
     [class.mat-paginator--absolute]="isAbsolute">
  <div class="mat-paginator-outer-container">
    <div class="mat-paginator-container" style="display: flex; justify-content: flex-end; align-items: center">
      <app-form-actions-content>
        <form-button (clickOnButton)="printReport(typeSelect.value)">Print</form-button>
      </app-form-actions-content>

      <div class="form-horizontal">
        <mat-form-field>
          <mat-select
                  [(value)]="printType"
                  #typeSelect
                  name="printType">
            <mat-option *ngFor="let printType of printTypes" [value]="printType.id">
              {{printType.name}}
            </mat-option>
          </mat-select>

        </mat-form-field>
      </div>
    </div>
  </div>
<!--  <app-medical-treating-reports>-->

<!--  </app-medical-treating-reports>-->
</div>




