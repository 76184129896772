import {
	Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef
} from '@angular/core';
import {NgIfContext} from '@angular/common';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../_services/authentication.service';
import {UserRole} from "../../@models/system-managment/user-role";

@Directive({
	selector: '[appCanAccess]'
})
export class CanAccessDirective implements OnInit, OnDestroy {
	private context: NgIfContext = new NgIfContext();
	private roles: string[];

	private principalSubscription: Subscription;

	@Input()
	public set appCanAccess(roles: (keyof UserRole | string)[]) {
		this.setRoles(roles);
	}

	private setRoles(roles: string[]): void {
		this.roles = roles;
		this.updateView(this.principalService.roles);
	}

	constructor(
		private viewContainer: ViewContainerRef,
		private templateRef: TemplateRef<NgIfContext>,
		private principalService: AuthenticationService,
	) {
	}

	ngOnInit(): void {
		this.principalSubscription = this.principalService.rolesObservable
			.subscribe(principal => this.updateView(principal));
	}

	ngOnDestroy(): void {
		if (this.principalSubscription) {
			this.principalSubscription.unsubscribe();
			this.principalSubscription = null;
		}
	}

	private updateView(roles: string[]): void {

		if (!roles) {
			return;
		}

		this.viewContainer.clear();
		this.context.$implicit = this.context.ngIf = this.isAllowed(roles);

		if (this.context.$implicit && this.templateRef) {
			this.viewContainer.createEmbeddedView(this.templateRef, this.context);
		}
	}

	private isAllowed(roles: string[]): boolean {
		return roles.some((role: string) => this.hasRole(role));
	}

	private hasRole(role: string): boolean {
		return this.roles.some(r => r === role);
	}

}
