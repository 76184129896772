<dynamic-material-form-control *ngFor="let model of formModel; trackBy: trackByFn"
                               [group]="formGroup"
                               [hidden]="model.hidden"
                               [layout]="formLayout"
                               [model]="model"
                               [ngClass]="[getClass(model, 'element', 'host'), getClass(model, 'grid', 'host')]"
                               [templates]="templates"
                               (blur)="onEvent($event, 'blur')"
                               (change)="onEvent($event, 'change')"
                               (focus)="onEvent($event, 'focus')"
                               (matEvent)="onEvent($event, 'custom')"></dynamic-material-form-control>
