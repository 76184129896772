export const SSA_LEVELS = [
	{
		id: 1,
		name: 'IC'
	},
	{
		id: 2,
		name: 'Recon'
	},
	{
		id: 3,
		name: 'HRG'
	},
	{
		id: 4,
		name: 'AC'
	},
	{
		id: 5,
		name: 'FDC'
	}
];
