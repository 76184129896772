import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {
	DynamicFormArrayModel,
	DynamicFormControlLayout,
	DynamicFormControlModel,
	DynamicFormLayout,
	DynamicFormService, DynamicInputModel
} from '@ng-dynamic-forms/core';
import {
	DATE_MANUAL,
	RADIO_USER_STATE,
	STRING_FIELD
} from "../../../../@util/dynamic-form/dynamic-controlls";
import {FormTamplate, FormValueBuilder} from "../../partial/forms/form-tamplate";
import {DynamicForm} from "../../../../@util/dynamic-form/dynamic-form";
import {FormClose} from "../../partial/forms/form-close";
import {Subscription} from "rxjs";
import {
	DynamicCheckboxModel, DynamicFormArrayModel as DynamicFormArray,
	DynamicFormGroupModel,
	DynamicTextAreaModel
} from "../../../../@util/ui-material/form-controls";
import {GlobalSaveService} from "../../../@core/global-save.service";
import {UserInfoService} from "../../../@core/user-info.service";
import {MEDICAL_TESTS_MODE} from "./partial-medical-tests.config";
import {DatepickerCustompropsModel} from "../../../../@util/ui-material/datepicker-customprops/datepicker-customprops-model";
import {DynamicInputAutocompleteModel} from "../../../../@util/ui-material/input/dynamic-input-autocomplete-model";
import { PERMISSIONS } from 'src/app/@config/permissions';

@Component({
	selector: 'app-partial-medical-tests',
	templateUrl: './partial-medical-tests.component.html',
	styleUrls: ['./partial-medical-tests.component.scss']
})
export class PartialMedicalTestsComponent extends DynamicForm implements FormTamplate, FormClose {
	@Input()
	mode;
	// @Input()
	// canEdit;
	// @Input()
	// medicalTest;
	@Input() isHospital = false;
	private _medicalTest;
	get medicalTest() {
		return this._medicalTest;
	}
	@Input()
	set medicalTest(medicalTest: any) {
		this._medicalTest = medicalTest;
		this.setMedicalTest(medicalTest);
	}

	@Output()
	backForm: EventEmitter<any> = new EventEmitter();
	@Output()
	nextForm: EventEmitter<any> = new EventEmitter();
	@Output()
	closeForm: EventEmitter<any> = new EventEmitter();
	@Output()
	saveForm: EventEmitter<any> = new EventEmitter();

	private _canEdit;
	get canEdit() {
		return this._canEdit;
	}
	@Input()
	set canEdit(canEdit: boolean) {
		this._canEdit = canEdit;
	}
	formGroup: FormGroup;
	formModel: DynamicFormControlModel[];
	arrayModel: DynamicFormArrayModel;
	arrayControl: FormArray;
	prescribedBy: any;
	title: string = 'Medical Tests';
	MEDICAL_TESTS_MODE = MEDICAL_TESTS_MODE;
	public PERMISSIONS = PERMISSIONS;
	formLayout: DynamicFormLayout = {
		'treatment': <DynamicFormControlLayout>{
			element: {
				host: 'material-form-group longer-centered',
				container: 'top-label form-element_hide-label',
			}
		},
		'showMedicalTests': <DynamicFormControlLayout>{
			element: {
				container: 'top-label'
			}
		},
		form: <DynamicFormControlLayout>{
			element: {
				label: 'form-label form-label_full-width form_mb',
				control: 'form-row is-hidden'
			},
			grid: {
				container: 'row-mb'
			}
		},
		col1rowLabelTest: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-col',
				control: 'sm-col',
				label: 'form-label form-label_full-width form_mb'
			},
		},
		col1rowLabelBodyPart: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-col',
				control: 'sm-col',
				label: 'form-label form-label_full-width form_mb'
			},
		},
		col1rowLabelDate: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-col',
				control: 'sm-col',
				label: 'form-label form-label_full-width form_mb'
			},
		},
		col1: <DynamicFormControlLayout>{
			element: {
				host: 'form-element',
				control: '',
				children: 'col-children col1-children',
			},
		},
		col2rowLabelTest: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-col',
				control: 'sm-col',
				label: 'form-label form-label_full-width form_mb'
			},
		},
		col2rowLabelDate: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-col',
				control: 'sm-col',
				label: 'form-label form-label_full-width form_mb'
			},
		},
		col2: <DynamicFormControlLayout>{
			element: {
				host: 'form-element',
				control: '',
				children: 'col-children col2-children',
			}
		},
		col1Test: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-col',
				control: 'sm-col',
        label: 'form-label form-label_full-width form_mb'
			}
		},
		col1Body: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-col',
				control: 'sm-col',
        label: 'form-label form-label_full-width form_mb'
			}
		},
		col1Date: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-col',
				control: 'sm-col',
        label: 'form-label form-label_full-width form_mb'
			}
		},
		col2Test: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-col',
				control: 'md-col',
        label: 'form-label form-label_full-width form_mb'
			}
		},
		col2Body: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-col',
				control: 'md-col',
        label: 'form-label form-label_full-width form_mb'
			}
		},
		col2Date: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-col',
				control: 'md-col',
        label: 'form-label form-label_full-width form_mb'
			}
		},
		biopsyDescription: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_hide-label'
			}
		},
		mriDescription: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_hide-label'
			}
		},
		xRayDescription: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_hide-label'
			}
		},
		otherDescription: <DynamicFormControlLayout>{
			element: {
				host: 'form-element form-element_hide-label'
			}
		},
	};

	medicalTestsWithBodyPart: any = [
		{
			name: 'X Ray',
			shortName: 'xRay',
		},
		{
			name: 'MRI',
			shortName: 'mri',
		},
		{
			name: 'CT Scan',
			shortName: 'ctScan',
		},
		{
			name: 'Ultra Sound',
			shortName: 'ultraSound',
		},
		{
			name: 'Biopsy',
			shortName: 'biopsy',
		},
		{
			name: 'Vascular',
			shortName: 'vascular',
		},
		// {
		// 	name: 'Other',
		// 	shortName: 'other',
		// },
	];

	medicalTestsWithOUTBodyPart: any = [
		{
			name: 'Sleep Study',
			shortName: 'sleepStudy',
		},
		{
			name: 'Breathing',
			shortName: 'breathing',
		},
		{
			name: 'EKG (Heart Test)',
			shortName: 'ekg',
		},
		{
			name: 'ECG (Brain Wave)',
			shortName: 'ecg',
		},
		{
			name: 'Treadmill',
			shortName: 'treadmill',
		},
		{
			name: 'Vision',
			shortName: 'vision',
		},
		{
			name: 'BLOOD',
			shortName: 'blood',
		},
		{
			name: 'HIV',
			shortName: 'hiv',
		},
		{
			name: 'Endoscopy',
			shortName: 'endoscopy',
		},
		{
			name: 'Neuropsych Exam',
			shortName: 'neuropsychExam',
		},
		{
			name: 'Neurocognitive Exam',
			shortName: 'neurocognitiveExam',
		},
	];

	form = {
		treatment: DynamicTextAreaModel({id: 'treatment', maxLength: 150, hidden: true, editableFor: PERMISSIONS.LOWER_EXEC}),
		showMedicalTests: RADIO_USER_STATE({id: 'showMedicalTests', options: [{label: 'YES', value: true}, {label: 'NO', value: false}], editableFor: PERMISSIONS.LOWER_EXEC})
	};



	// form = {
	// 	treatment: DynamicTextAreaModel({id: 'treatment', maxLength: 150}),
	// 	showMedicalTests: RADIO_USER_STATE({id: 'showMedicalTests', options: [{label: 'YES', value: true}, {label: 'NO', value: false}]}),
	// 	xRay: DynamicCheckboxModel({id: 'xRay', label: 'X-RAY',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	xRayBodyPart: STRING_FIELD({id: 'xRayBodyPart', maxLength: 20, editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	xRayDate: DATE_MANUAL({id: 'xRayDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	//
	// 	mri: DynamicCheckboxModel({id: 'mri', label: 'MRI',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	mriBodyPart: STRING_FIELD({id: 'mriBodyPart', maxLength: 20, editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	mriDate: DATE_MANUAL({id: 'mriDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	//
	// 	ctScan: DynamicCheckboxModel({id: 'ctScan', label: 'CT Scan',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	ctScanBodyPart: STRING_FIELD({id: 'ctScanBodyPart', maxLength: 20, editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	ctScanDate: DATE_MANUAL({id: 'ctScanDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	//
	// 	ultraSound: DynamicCheckboxModel({id: 'ultraSound', label: 'Ultra Sound',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	ultraSoundBodyPart: STRING_FIELD({id: 'ultraSoundBodyPart', maxLength: 20, editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	ultraSoundDate: DATE_MANUAL({id: 'ultraSoundDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	//
	// 	biopsy: DynamicCheckboxModel(
	// 		{
	// 			id: 'biopsy',
	// 			label: 'BIOPSY',
	// 			editableFor: PERMISSIONS.LOWER_EXEC
	// 		}),
	// 	biopsyBodyPart: STRING_FIELD({id: 'biopsyBodyPart', maxLength: 20, editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	biopsyDate: DATE_MANUAL({id: 'biopsyDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	//
	// 	vascular: DynamicCheckboxModel({id: 'vascular', label: 'Vascular',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	vascularBodyPart: STRING_FIELD({id: 'vascularBodyPart', maxLength: 20, editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	vascularDate: DATE_MANUAL({id: 'vascularDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	//
	// 	other: DynamicCheckboxModel({id: 'Other', label: 'Other',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	otherBodyPart: STRING_FIELD({id: 'otherBodyPart', maxLength: 20, editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	otherDate: DATE_MANUAL({id: 'otherDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	//
	// 	sleepStudy: DynamicCheckboxModel({id: 'sleepStudy', label: 'Sleep Study',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	sleepStudyDate: DATE_MANUAL({id: 'sleepStudyDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	breathing: DynamicCheckboxModel({id: 'breathing', label: 'Breathing',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	breathingDate: DATE_MANUAL({id: 'breathingDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	ecg: DynamicCheckboxModel({id: 'ecg', label: 'ECG (BRAIN WAVE TEST)',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	ecgDate: DATE_MANUAL({id: 'ecgDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	ekg: DynamicCheckboxModel({id: 'ekg', label: 'EKG (HEART TEST',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	ekgDate: DATE_MANUAL({id: 'ekgDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	treadmill: DynamicCheckboxModel({id: 'treadmill', label: 'Treadmill',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	treadmillDate: DATE_MANUAL({id: 'treadmillDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	vision: DynamicCheckboxModel({id: 'vision', label: 'Vision',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	visionDate: DATE_MANUAL({id: 'visionDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	blood: DynamicCheckboxModel({id: 'blood', label: 'BLOOD',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	bloodDate: DATE_MANUAL({id: 'bloodDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	hiv: DynamicCheckboxModel({id: 'hiv', label: 'HIV',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	hivDate: DATE_MANUAL({id: 'hivDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	endoscopy: DynamicCheckboxModel({id: 'endoscopy', label: 'Endoscopy',editableFor: PERMISSIONS.LOWER_EXEC,}),
	// 	endoscopyDate: DATE_MANUAL({id: 'endoscopyDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	neurocognitiveExam: DynamicCheckboxModel({id: 'neurocognitiveExam', label: 'Neurocognitive Exam',editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	neurocognitiveExamDate: DATE_MANUAL({id: 'neurocognitiveExamDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	neuropsychExam: DynamicCheckboxModel({id: 'neuropsychExam', label: 'Neurocognitive Exam',editableFor: PERMISSIONS.LOWER_EXEC}),
	// 	neuropsychExamDate: DATE_MANUAL({id: 'neuropsychExamDate', editableFor: PERMISSIONS.LOWER_EXEC}),
	// };
	formTemplate;

	isTestsVisible: boolean = false;

	constructor(private formService: DynamicFormService,
				public globalSaveService: GlobalSaveService,
				private userInfoService: UserInfoService) {
		super();

		this.medicalTestsWithBodyPart.forEach(el => {
			this.form[el.shortName] = DynamicCheckboxModel({id: el.shortName, label: el.name, editableFor: PERMISSIONS.LOWER_EXEC,});
			this.form[el.shortName + 'BodyPart'] = STRING_FIELD({id: el.shortName + 'BodyPart', placeholder: 'Body Part', disabled: true, maxLength: 20, editableFor: PERMISSIONS.LOWER_EXEC});
			this.form[el.shortName + 'Date'] = DATE_MANUAL({id: el.shortName + 'Date', disabled: true,  editableFor: PERMISSIONS.LOWER_EXEC});

			// xRay: DynamicCheckboxModel({id: 'xRay', label: 'X-RAY',editableFor: PERMISSIONS.LOWER_EXEC,}),
			// 	xRayBodyPart: STRING_FIELD({id: 'xRayBodyPart', maxLength: 20, editableFor: PERMISSIONS.LOWER_EXEC}),
			// 	xRayDate: DATE_MANUAL({id: 'xRayDate', editableFor: PERMISSIONS.LOWER_EXEC}),
		});

		this.medicalTestsWithOUTBodyPart.forEach(el => {
			this.form[el.shortName] = DynamicCheckboxModel({id: el.shortName, label: el.name, editableFor: PERMISSIONS.LOWER_EXEC,});
			this.form[el.shortName + 'Date'] = DATE_MANUAL({id: el.shortName + 'Date', disabled: true, editableFor: PERMISSIONS.LOWER_EXEC});
		});



		// this.formTemplate = {
		// 	treatment: this.form.treatment,
		// 	showMedicalTests: this.form.showMedicalTests,
		// 	form: DynamicFormGroupModel({
		// 		id: 'form',
		// 		group: [
		// 			DynamicFormGroupModel({
		// 				id: 'col1',
		// 				group: [
		// 					DynamicFormGroupModel({
		// 						label: 'Test',
		// 						id: 'col1Test',
		// 						group: [], editableFor: PERMISSIONS.LOWER_EXEC,
		// 					}),
		// 					DynamicFormGroupModel({
		// 						label: 'Body Part',
		// 						id: 'col1Body', group: [], editableFor: PERMISSIONS.LOWER_EXEC,
		// 					}),
		// 					DynamicFormGroupModel({
		// 						label: 'Date',
		// 						id: 'col1Date', group: [], editableFor: PERMISSIONS.LOWER_EXEC,
		// 					}),
		// 				], editableFor: PERMISSIONS.LOWER_EXEC,
		// 			}),
		// 			DynamicFormGroupModel({
		// 				id: 'col2',
		// 				group: [
		// 					DynamicFormGroupModel({
		// 						label: 'Test',
		// 						id: 'col2Test',
		// 						group: [], editableFor: PERMISSIONS.LOWER_EXEC,
		// 					}),
		// 					DynamicFormGroupModel({
		// 						label: 'Date',
		// 						id: 'col2Date', group: [], editableFor: PERMISSIONS.LOWER_EXEC,
		// 					}),
		// 				], editableFor: PERMISSIONS.LOWER_EXEC,
		// 			}),
		// 		], editableFor: PERMISSIONS.LOWER_EXEC,
		// 	})
		// };

		this.formTemplate = {
			treatment: this.form.treatment,
			showMedicalTests: this.form.showMedicalTests,
			form: DynamicFormGroupModel({
				id: 'form',
				group: [
					DynamicFormGroupModel({
						id: 'col1',
						group: [
						], editableFor: PERMISSIONS.LOWER_EXEC,
					}),
					DynamicFormGroupModel({
						id: 'col2',
						group: [
						], editableFor: PERMISSIONS.LOWER_EXEC,
					}),
				], editableFor: PERMISSIONS.LOWER_EXEC,
			}),
		};

		this.formTemplate.form.group[0].group[0] = 	DynamicFormGroupModel({
			// label: 'Test',
			id: `col1rowLabels`,
			group: [
				DynamicFormGroupModel({
					label: 'Test',
					id: `col1rowLabelTest`
				}),
				DynamicFormGroupModel({
					label: 'Body Part',
					id: `col1rowLabelBodyPart`,
				}),
				DynamicFormGroupModel({
					label: 'Date <span>(mm/dd/yyyy)</span>',
					id: `col1rowLabelDate`,
				}),
			],
		});

		this.medicalTestsWithBodyPart.forEach((el, index) => {
			this.formTemplate.form.group[0].group[index+1] = 	DynamicFormGroupModel({
				id: `col1row${index}`,
				group: [
					this.form[el.shortName],
					this.form[el.shortName + 'BodyPart'],
					this.form[el.shortName + 'Date']
				], editableFor: PERMISSIONS.LOWER_EXEC,
			});
			el.formId = `col1row${index}`;

			// this.formTemplate.form.group[0].group[0].group.push(this.form[el.shortName]);
			// this.formTemplate.form.group[0].group[1].group.push(this.form[el.shortName + 'BodyPart']);
			// this.formTemplate.form.group[0].group[2].group.push(this.form[el.shortName + 'Date']);
		});
		this.formTemplate.form.group[0].group.push(DynamicFormArray({
			id: 'others',
			initialCount: 1,
			groupFactory: () => {
				return [
					DynamicCheckboxModel({id: 'other', label: 'Other', editableFor: PERMISSIONS.LOWER_EXEC}),
					STRING_FIELD({id: 'otherBodyPart', placeholder: 'Body Part', disabled: true, maxLength: 20, editableFor: PERMISSIONS.LOWER_EXEC}),
					DATE_MANUAL({id: 'otherDate', disabled: true,  editableFor: PERMISSIONS.LOWER_EXEC}),
				];
			},editableFor: PERMISSIONS.LOWER_EXEC,
		}));



		// this.formTemplate.form.group[0].group[this.formTemplate.form.group[0].group.length + 1] = DynamicFormArray({
		// 	id: 'others',
		// 	initialCount: 1,
		// 	groupFactory: () => {
		// 		return [
		// 			DynamicCheckboxModel({id: 'other', label: 'Other', editableFor: PERMISSIONS.LOWER_EXEC}),
		// 			STRING_FIELD({id: 'otherBodyPart', placeholder: 'Body Part', disabled: true, maxLength: 20, editableFor: PERMISSIONS.LOWER_EXEC}),
		// 			DATE_MANUAL({id: 'otherDate', disabled: true,  editableFor: PERMISSIONS.LOWER_EXEC}),
		// 		];
		// 	},editableFor: PERMISSIONS.LOWER_EXEC,
		// });

		this.formTemplate.form.group[1].group[0] = 	DynamicFormGroupModel({
			// label: 'Test',
			id: `col2rowLabels`,
			group: [
				DynamicFormGroupModel({
					label: 'Test',
					id: `col2rowLabelTest`
				}),
				DynamicFormGroupModel({
					label: 'Date <span>(mm/dd/yyyy)</span>',
					id: `col2rowLabelDate`,
				}),
			],
		});
		this.medicalTestsWithOUTBodyPart.forEach((el, index) => {
			this.formTemplate.form.group[1].group[index+1] = 	DynamicFormGroupModel({
				id: `col2row${index}`,
				group: [
					this.form[el.shortName],
					this.form[el.shortName + 'Date']
				], editableFor: PERMISSIONS.LOWER_EXEC,
			});
			el.formId = `col2row${index}`;

			// this.formTemplate.form.group[1].group[0].group.push(this.form[el.shortName]);
			// this.formTemplate.form.group[1].group[1].group.push(this.form[el.shortName + 'Date']);
		});

		this.formModel = FormValueBuilder.buildFormModel(this.formTemplate);
		this.formGroup = formService.createFormGroup(this.formModel);
		this.arrayControl = this.formGroup.controls.form['controls'].col1.controls['others'] as FormArray;
		this.arrayModel = this.formService.findById('others', this.formModel) as DynamicFormArrayModel;

	}

	ngOnInit() {
		FormValueBuilder.setData(this.medicalTest, this.form);
		FormValueBuilder.resetToDefault(this.form);
		this.formGroup.valueChanges.subscribe(j => {
			this.formStateChanged.emit({key: this.formKey, value: FormValueBuilder.isDirty(this.form)});
		});

		this.form.showMedicalTests.valueUpdates.subscribe(j => {
			if (j === false) {
				this.nextMedicalTest();
			}

			this.isTestsVisible = !!j;
			this.isTestsVisible ? document.querySelector('#form').classList.remove('is-hidden') : document.querySelector('#form').classList.add('is-hidden');
		});

		if (this.isHospital) {
			this.formTemplate.treatment.hidden = true;
		}

		// setTimeout(() => {
		// 	this.formGroup.controls.showMedicalTests.setValue(false);
		// });
	}

	ngOnChanges(changes) {
		if (this.isHospital) {
			this.formTemplate.treatment.hidden = true;
		} else {
			this.formTemplate.treatment.hidden = false;
		}
	}

	onFocus($event) {
	}

	onBlur($event) {
	}

	onChange($event) {
		if ($event.model.id === 'other') {
			if ($event.model.value) {
				setTimeout(() => {
					($event.context.group[1] as DynamicInputAutocompleteModel).disabledUpdates.next(false);
					($event.context.group[2] as DynamicInputAutocompleteModel).disabledUpdates.next(false);
				});
			} else {
				setTimeout(() => {
					($event.context.group[1] as DynamicInputAutocompleteModel).disabledUpdates.next(true);
					($event.context.group[2] as DynamicInputAutocompleteModel).disabledUpdates.next(true);
				});
			}

		}

		this.medicalTestsWithBodyPart.forEach(el => {
			if (this.form[el.shortName].id === $event.model.id) {
				if (this.form[el.shortName].value) {
					this.form[el.shortName + 'BodyPart'].disabled = false;
					this.form[el.shortName + 'Date'].disabled = false;
					this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'BodyPart'].enable();
					this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'Date'].enable();
				} else {
					this.form[el.shortName + 'BodyPart'].disabled = true;
					this.form[el.shortName + 'Date'].disabled = true;
					this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'BodyPart'].disable();
					this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'Date'].disable();
					if (!this.isEditingMode()) {
						this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'Date'].setValue(null);
						this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'BodyPart'].setValue(null);
					}
				}
			}

		});

		this.medicalTestsWithOUTBodyPart.forEach(el => {
			if (this.form[el.shortName].id === $event.model.id) {
				if (this.form[el.shortName].value) {
					this.form[el.shortName + 'Date'].disabled = false;
					this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName + 'Date'].enable();
				} else {
					this.form[el.shortName + 'Date'].disabled = true;
					this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName + 'Date'].disable();
					if (!this.isEditingMode()) {
						this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName + 'Date'].setValue(null);
					}
				}
			}
		});
	}

	setMedicalTest(medicalTest) {
		const treatment = medicalTest && medicalTest.medicalTestInfo ? medicalTest.medicalTestInfo.treatment : null;
		const showTests = medicalTest && medicalTest.medicalTestInfo ? medicalTest.medicalTestInfo.tests : false;
		const medicalTestsList = medicalTest && medicalTest.medicalTestsList ? medicalTest.medicalTestsList : [];
		const medicalTestsListOthers = medicalTestsList.filter(el => el.shortName.includes('other'));

		console.log(medicalTestsList, medicalTestsListOthers);
		medicalTestsListOthers.forEach((el, index) => {
			if (index) {
				this.insertOther();
			}
			setTimeout(() => {
				this.formGroup.controls.form['controls'].col1.controls['others'].controls[index].enable();
				this.formGroup.controls.form['controls'].col1.controls['others'].controls[index].controls['other'].setValue(true);
				this.formGroup.controls.form['controls'].col1.controls['others'].controls[index].controls['otherBodyPart'].setValue(el.description);
				this.formGroup.controls.form['controls'].col1.controls['others'].controls[index].controls['otherDate'].setValue(this.getTestDate(el.date));
			});
		})

		// this.formTemplate.form.group[0].group.push(DynamicFormArray({
		// 	id: 'others',
		// 	initialCount: 1,
		// 	groupFactory: () => {
		// 		return [
		// 			DynamicCheckboxModel({id: 'other', label: 'Other', editableFor: PERMISSIONS.LOWER_EXEC}),
		// 			STRING_FIELD({id: 'otherBodyPart', placeholder: 'Body Part', disabled: true, maxLength: 20, editableFor: PERMISSIONS.LOWER_EXEC}),
		// 			DATE_MANUAL({id: 'otherDate', disabled: true,  editableFor: PERMISSIONS.LOWER_EXEC}),
		// 		];
		// 	},editableFor: PERMISSIONS.LOWER_EXEC,
		// }));

		this.formGroup.controls.treatment.setValue(treatment);
		this.formGroup.controls.showMedicalTests.setValue(showTests);

		if (!this.canEdit) {
			this.formGroup.disable();
		} else {
			this.formGroup.controls.treatment.enable();
			this.formGroup.controls.showMedicalTests.enable();
		}

		medicalTestsList.forEach(test => {
			this.medicalTestsWithBodyPart.forEach(el => {
				if (this.canEdit) {
					this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName].enable();
				}
				if (el.shortName === test.shortName) {
					this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName].setValue(true);
					this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'BodyPart'].setValue(test.description);
					this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'Date'].setValue(this.getTestDate(test.date));
					if (this.canEdit) {
						this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName].enable();
						this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'BodyPart'].enable();
						this.formGroup.controls.form['controls'].col1.controls[el.formId].controls[el.shortName + 'Date'].enable();
					}
				}
			});
			this.medicalTestsWithOUTBodyPart.forEach(el => {
				if (this.canEdit) {
					this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName].enable();
				}
				if (el.shortName === test.shortName) {
					this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName].setValue(true);
					this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName + 'Date'].setValue(this.getTestDate(test.date));
					if (this.canEdit) {
						this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName].enable();
						this.formGroup.controls.form['controls'].col2.controls[el.formId].controls[el.shortName + 'Date'].enable();
					}
				}
			});
		});
	}

	getTestDate(date) {
		// const dateObject = new Date(date);
		// 		// let isDate = String(dateObject.getDate());
		// 		// if (isDate.length === 1) {
		// 		// 	isDate = '0' + isDate;
		// 		// }
		// 		// let isMonth = String(dateObject.getMonth() + 1);
		// 		// if (isMonth.length === 1) {
		// 		// 	isMonth = '0' + isMonth;
		// 		// }
		// 		// const isYear = String(dateObject.getFullYear());
		const isDate = date.slice(8, 10);
		const isMonth = date.slice(5, 7);
		const isYear = date.slice(0, 4);

		return isDate + isMonth + isYear;
	}

	saveMedicalTest() {
		FormValueBuilder.setControlsToched(this.formGroup);
		if (!this.formGroup.valid) {
			return;
		}
		const sendFormData = this.getSendForm();
		setTimeout(() => {
			this.saveForm.emit(sendFormData);
		});
		// FormValueBuilder.resetToDefault(this.form);
	}

	getSendForm() {
		const formData = FormValueBuilder.getData(this.form);
		const sendFormData: any = {};

		let others = (this.formGroup.controls.form['controls'].col1.controls['others'] as FormArray).value ? (this.formGroup.controls.form['controls'].col1.controls['others'] as FormArray).value : [];
		others = (others as any).filter(el => el.other && (el.other !== 'false'));
		let newFormGroupOthers = (others as any).map((j, index) => {
			if (j.other) {
				return {
					shortName: `other${index+1}`,
					description: j.otherBodyPart,
					date: this.formatDate(j.otherDate),
				}
			}
		});

		sendFormData.treatment = formData.treatment;
		sendFormData.showMedicalTests = formData.showMedicalTests;
		sendFormData.medicalTestsList = [];
		[...this.medicalTestsWithBodyPart, ...this.medicalTestsWithOUTBodyPart].forEach(el => {
			if (el.shortName && formData[el.shortName]) {
				sendFormData.medicalTestsList.push(
					{
						shortName: el.shortName,
						description: formData[el.shortName + 'BodyPart'],
						date:  this.formatDate(formData[el.shortName + 'Date']),
					}
				);
			}
		});
		sendFormData.medicalTestsList = [...sendFormData.medicalTestsList, ...newFormGroupOthers]
		return sendFormData;
	}

	formatDate(date) {
		if (date) {
			try {
				const dayNum = date.slice(2, 4);
				const month = date.slice(0, 2);
				const year = date.slice(4).length === 2 ? `20${date.slice(4)}` : date.slice(4);
				return `${year}-${month}-${dayNum} 00:00:00`;
			} catch (e) {
				console.log('ERROR date incorrect');
				return null;
			}
		}
		return null;
	}

	backMedicalTest() {
		this.backForm.emit();
	}

	nextMedicalTest() {
		FormValueBuilder.setControlsToched(this.formGroup);
		if (!this.formGroup.valid) {
			return;
		}

		const sendFormData = this.getSendForm();
		this.nextForm.emit(sendFormData);
	}

	closeMedicalTest() {
		this.closeForm.emit();
	}

	ngOnDestroy(): void {
		this.formStateChanged.next({value: false, key: this.formKey});
	}

	isClaimant() {
		if (this.userInfoService.getCurrentUserRole() == 'CLIENT') {
			return true;
		}

		return false;
	}

	isEditingMode() {
		return this.mode === this.MEDICAL_TESTS_MODE.edit;
	}

	isCreationMode() {
		return this.mode === this.MEDICAL_TESTS_MODE.create;
	}

	isCreationVisitMode() {
		return this.mode === this.MEDICAL_TESTS_MODE.createVisit;
	}

	insertOther() {
		this.formService.addFormArrayGroup(this.arrayControl, this.arrayModel);
		setTimeout(() => {
			(this.arrayModel.groups[this.arrayModel.groups.length - 1].group[5] as DynamicInputModel).valueUpdates.next(this.prescribedBy);
			FormValueBuilder.resetToDefault(this.arrayModel.groups[this.arrayModel.groups.length - 1].group);
		});
	}

	isRemoveHidden() {
		return this.arrayModel.groups.length < 2;
	}

	canRemove() {
		return this.arrayModel.groups.length > 1;
	}

	removeOther(index) {
		if (this.canRemove()) {
			this.formService.removeFormArrayGroup(index, this.arrayControl, this.arrayModel);
		}
	}

	getButtonType() {
		return this.canRemove() ? 'warning' : 'disabled';
	}

	private setPrescribedValue() {
		this.arrayModel.groups.forEach(item => {
			(item.group[5] as DynamicInputModel).valueUpdates.next(this.prescribedBy);
			FormValueBuilder.resetToDefault(item.group[5]);
		});
	}
}
