/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notifications-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../inner-container/inner-container.component.ngfactory";
import * as i3 from "../inner-container/inner-container.component";
import * as i4 from "./notifications-container.component";
var styles_NotificationsContainerComponent = [i0.styles];
var RenderType_NotificationsContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationsContainerComponent, data: {} });
export { RenderType_NotificationsContainerComponent as RenderType_NotificationsContainerComponent };
export function View_NotificationsContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inner-container", [], null, null, null, i2.View_InnerContainerComponent_0, i2.RenderType_InnerContainerComponent)), i1.ɵdid(1, 1163264, null, 0, i3.InnerContainerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_NotificationsContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-notifications-container", [], null, null, null, View_NotificationsContainerComponent_0, RenderType_NotificationsContainerComponent)), i1.ɵdid(1, 114688, null, 0, i4.NotificationsContainerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationsContainerComponentNgFactory = i1.ɵccf("app-notifications-container", i4.NotificationsContainerComponent, View_NotificationsContainerComponent_Host_0, {}, {}, []);
export { NotificationsContainerComponentNgFactory as NotificationsContainerComponentNgFactory };
