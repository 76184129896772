import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {TextMaskModule} from 'angular2-text-mask';
import {
	DynamicFormsCoreModule, DynamicFormValueControlModel
} from '@ng-dynamic-forms/core';
import {DynamicMaterialFormControlContainerComponent} from './dynamic-material-form-control-container.component';
import {DynamicMaterialFormComponent} from './dynamic-material-form.component';
import {DynamicMaterialCheckboxComponent} from './checkbox/dynamic-material-checkbox.component';
import {DynamicMaterialChipsComponent} from './chips/dynamic-material-chips.component';
import {DynamicMaterialDatePickerComponent} from './datepicker/dynamic-material-datepicker.component';
import {DynamicMaterialFormArrayComponent} from './form-array/dynamic-material-form-array.component';
import {DynamicMaterialFormGroupComponent} from './form-group/dynamic-material-form-group.component';
import {DynamicMaterialInputComponent} from './input/dynamic-material-input.component';
import {DynamicMaterialRadioGroupComponent} from './radio-group/dynamic-material-radio-group.component';
import {DynamicMaterialSelectComponent} from './select/dynamic-material-select.component';
import {DynamicMaterialSlideToggleComponent} from './slide-toggle/dynamic-material-slide-toggle.component';
import {DynamicMaterialSliderComponent} from './slider/dynamic-material-slider.component';
import {DynamicMaterialTextAreaComponent} from './textarea/dynamic-material-textarea.component';
import {MaskModule} from "../mask/mask.module";
import {DynamicMoneyComponent} from './money/dynamic-money.component';
import {DatepickerCustompropsComponent} from './datepicker-customprops/datepicker-customprops.component';
import {DatepickerCustomComponent} from './datepicker-custom/datepicker-custom.component';
import {MaterialModule} from "../../app/material/material.module";
import {DatepickerCheckboxComponent} from "./datepicker-checkbox/datepicker-checkbox.component";
import {NgDatepickerModule} from "../new-datepicker/module/ng-datepicker.module";
import {DynamicMaterialTextEditorComponent} from "./text-editor/dynamic-material-text-editor.component";
import {QuillModule} from "ngx-quill";

import './dynamic-form-value-control-model.extension';
import {CanAccessModule} from "../../app/auth/permission-guard/can-access.module";

@NgModule({
	imports: [
		CommonModule,
		MaterialModule,
		ReactiveFormsModule,
		TextMaskModule,
		MaskModule,
		DynamicFormsCoreModule,
		NgDatepickerModule,
		QuillModule,
		CanAccessModule,
	],
	declarations: [
		DynamicMaterialCheckboxComponent,
		DynamicMaterialChipsComponent,
		DynamicMaterialDatePickerComponent,
		DynamicMaterialFormArrayComponent,
		DynamicMaterialFormComponent,
		DynamicMaterialFormControlContainerComponent,
		DynamicMaterialFormGroupComponent,
		DynamicMaterialInputComponent,
		DynamicMaterialRadioGroupComponent,
		DynamicMaterialSelectComponent,
		DynamicMaterialSlideToggleComponent,
		DynamicMaterialSliderComponent,
		DynamicMaterialTextAreaComponent,
		DynamicMaterialTextEditorComponent,
		DynamicMoneyComponent,
		DatepickerCheckboxComponent,
		DatepickerCustompropsComponent,
		DatepickerCustomComponent
	],
	entryComponents: [
		DynamicMaterialCheckboxComponent,
		DynamicMaterialChipsComponent,
		DynamicMaterialDatePickerComponent,
		DynamicMaterialFormArrayComponent,
		DynamicMaterialFormGroupComponent,
		DynamicMaterialInputComponent,
		DynamicMaterialRadioGroupComponent,
		DynamicMaterialSelectComponent,
		DynamicMaterialSlideToggleComponent,
		DynamicMaterialSliderComponent,
		DynamicMaterialTextAreaComponent,
		DynamicMoneyComponent,
		DatepickerCustompropsComponent,
		DatepickerCheckboxComponent,
		DynamicMaterialTextEditorComponent,
		DatepickerCustomComponent
	],
	exports: [
		DynamicFormsCoreModule,
		DynamicMaterialCheckboxComponent,
		DynamicMaterialChipsComponent,
		DynamicMaterialDatePickerComponent,
		DynamicMaterialFormArrayComponent,
		DynamicMaterialFormComponent,
		DynamicMaterialFormControlContainerComponent,
		DynamicMaterialFormGroupComponent,
		DynamicMaterialInputComponent,
		DynamicMaterialRadioGroupComponent,
		DynamicMaterialSelectComponent,
		DynamicMaterialSlideToggleComponent,
		DynamicMaterialSliderComponent,
		DynamicMaterialTextAreaComponent,
		DynamicMoneyComponent,
		DatepickerCustompropsComponent,
		DatepickerCustomComponent,
		DatepickerCheckboxComponent,
		DynamicMaterialTextEditorComponent
	],
	providers: []
})
export class DynamicFormsMaterialUIModule {
}
