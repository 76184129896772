import {
	Component,
	ComponentFactoryResolver,
	ContentChildren,
	EventEmitter,
	Input, OnDestroy, OnInit,
	Output,
	QueryList,
	Type,
	ViewChild,
	ViewContainerRef
} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {
	DYNAMIC_FORM_CONTROL_TYPE_ARRAY,
	DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX,
	DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP,
	DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER,
	DYNAMIC_FORM_CONTROL_TYPE_GROUP,
	DYNAMIC_FORM_CONTROL_TYPE_INPUT,
	DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP,
	DYNAMIC_FORM_CONTROL_TYPE_SELECT,
	DYNAMIC_FORM_CONTROL_TYPE_SLIDER,
	DYNAMIC_FORM_CONTROL_TYPE_SWITCH,
	DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA,
	DynamicFormArrayGroupModel, DynamicFormComponentService,
	DynamicFormControl,
	DynamicFormControlContainerComponent,
	DynamicFormControlEvent, DynamicFormControlLayoutContext, DynamicFormControlLayoutPlace,
	DynamicFormControlModel,
	DynamicFormLayout,
	DynamicFormLayoutService, DynamicFormRelationService,
	DynamicFormValidationService, DynamicFormValueControlModel,
	DynamicInputModel,
	DynamicTemplateDirective,
} from "@ng-dynamic-forms/core";
import {DynamicMaterialDatePickerComponent} from "./datepicker/dynamic-material-datepicker.component";
import {DynamicMaterialInputComponent} from "./input/dynamic-material-input.component";
import {DynamicMaterialTextAreaComponent} from "./textarea/dynamic-material-textarea.component";
import {DynamicMaterialSlideToggleComponent} from "./slide-toggle/dynamic-material-slide-toggle.component";
import {DynamicMaterialCheckboxComponent} from "./checkbox/dynamic-material-checkbox.component";
import {DynamicMaterialSliderComponent} from "./slider/dynamic-material-slider.component";
import {DynamicMaterialRadioGroupComponent} from "./radio-group/dynamic-material-radio-group.component";
import {DynamicMaterialChipsComponent} from "./chips/dynamic-material-chips.component";
import {DynamicMaterialSelectComponent} from "./select/dynamic-material-select.component";
import {DynamicMaterialFormArrayComponent} from "./form-array/dynamic-material-form-array.component";
import {DynamicMaterialFormGroupComponent} from "./form-group/dynamic-material-form-group.component";
import {DynamicMoneyComponent} from "./money/dynamic-money.component";
import {DatepickerCustompropsComponent} from "./datepicker-customprops/datepicker-customprops.component";
import {DatepickerCustomComponent} from "./datepicker-custom/datepicker-custom.component";
import {DatepickerCheckboxComponent} from "./datepicker-checkbox/datepicker-checkbox.component";
import {DynamicMaterialTextEditorComponent} from "./text-editor/dynamic-material-text-editor.component";
import {AuthenticationService} from "../../app/auth/_services/authentication.service";
import {Subscription} from "rxjs";
import {DatepickerCustompropsModel} from "./datepicker-customprops/datepicker-customprops-model";

@Component({
	selector: "dynamic-material-form-control",
	templateUrl: "./dynamic-material-form-control-container.component.html"
})
export class DynamicMaterialFormControlContainerComponent extends DynamicFormControlContainerComponent implements OnInit {

	@ContentChildren(DynamicTemplateDirective) contentTemplateList: QueryList<DynamicTemplateDirective>;
	@Input("templates") inputTemplateList: QueryList<DynamicTemplateDirective>;

	@Input() context: DynamicFormArrayGroupModel | null = null;
	@Input() group: FormGroup;
	@Input() layout: DynamicFormLayout;
	@Input() model: DynamicFormControlModel;

	@Output() blur: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();
	@Output() change: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();
	@Output() focus: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();
	@Output("matEvent") customEvent: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>();

	@ViewChild("componentViewContainer", {
		read: ViewContainerRef,
		static: true
	}) componentViewContainerRef: ViewContainerRef;
	private rolesSubscription: Subscription;

	constructor(protected componentFactoryResolver: ComponentFactoryResolver,
				protected layoutService: DynamicFormLayoutService,
				protected validationService: DynamicFormValidationService,
				protected componentService: DynamicFormComponentService,
				protected relationService: DynamicFormRelationService, private authService: AuthenticationService) {

		super(componentFactoryResolver, layoutService, validationService, componentService, relationService);

	}

	get componentType(): Type<DynamicFormControl> | null {
		return this.componentService.getCustomComponentType(this.model) || materialUIFormControlMapFn(this.model);
	}

	get hasMatFormField(): boolean {

		let matFormFieldTypes = [DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER, DYNAMIC_FORM_CONTROL_TYPE_INPUT,
			DYNAMIC_FORM_CONTROL_TYPE_SELECT, DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA, "MONEY", 'customDatePicker', 'customDatePickerV1', "DATE_CHECK_BOX"];

		return matFormFieldTypes.some(type => this.model.type === type) || (
			this.model instanceof DynamicFormValueControlModel &&
			this.model.getAdditional("isFormFieldControl")
		);
	}

	isDirty(): boolean {
		if ((this.model as any).isDirty != undefined)
			return (this.model as any).isDirty();
	}

	ngOnInit(): void {
		if ((this.model as DynamicFormValueControlModel<any>).valueUpdates !== undefined) {
			(this.model as DynamicFormValueControlModel<any>).valueUpdates.subscribe(value => {
				if (value == null)
					return;
				if (typeof value === 'string' && !value.trim().length) {
					(this.model as DynamicFormValueControlModel<any>).valueUpdates.next(null);
				}
			});
		}
		if (!this.model["_config"])
			return;
		if (this.model["_config"].editableFor) {
			this.setReadOnly();
		}

		if (this.model["_config"].visibleFor) {
			this.setHidden();
		}
	}

	getClass(context: DynamicFormControlLayoutContext, place: DynamicFormControlLayoutPlace, model?: DynamicFormControlModel): string {
		let classList = super.getClass(context, place, model);
		if (this.model["_config"] && !this.hasAccess(this.model["_config"].visibleFor)) {
			classList += " visually-hidden"
		}
		return classList;
	}

	private setReadOnly() {
		let makeReadOnly = () =>{
			if (!this.hasAccess(this.model["_config"].editableFor)) {

				this.model.disabledUpdates.next(true);
				this.model.disabled = true;
				this.model._disabled = true;

				if (((<any>this.model) as DatepickerCustompropsModel).hiddenDatePicker != undefined) {
					((<any>this.model) as DatepickerCustompropsModel).hiddenDatePicker = true;
					((<any>this.model) as DatepickerCustompropsModel).hiddenClearIco = true;
				}
				this.model.disabledUpdates.subscribe(val => {
					if (!val) {
						this.model.disabledUpdates.next(true);
					}
				});
			}
		}

		if (this.authService.roles)
			makeReadOnly();
		this.componentSubscriptions.push(this.authService.rolesObservable.subscribe(() => {
			makeReadOnly();
		}));
	}

	private setHidden() {
		let removeControls = () => {
			if (!this.hasAccess(this.model["_config"].visibleFor)) {
				this.componentViewContainerRef.clear();
			}
		}
		if (this.authService.roles)
			removeControls();
		this.componentSubscriptions.push(this.authService.rolesObservable.subscribe(() => {
			removeControls();
		}));
	}

	private hasAccess(roles: string[]) {
		return !roles ||
			(this.authService.roles && this.authService.roles.find(role => roles.indexOf(role) != -1));
	}
}

export function materialUIFormControlMapFn(model: DynamicFormControlModel): Type<DynamicFormControl> | null {
	switch (model.type) {

		case DYNAMIC_FORM_CONTROL_TYPE_ARRAY:
			return DynamicMaterialFormArrayComponent;

		case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX:
			return DynamicMaterialCheckboxComponent;

		case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
			return DynamicMaterialFormGroupComponent;

		case DYNAMIC_FORM_CONTROL_TYPE_DATEPICKER:
			return DynamicMaterialDatePickerComponent;

		case DYNAMIC_FORM_CONTROL_TYPE_GROUP:
			return DynamicMaterialFormGroupComponent;

		case DYNAMIC_FORM_CONTROL_TYPE_INPUT:
			let inputModel = model as DynamicInputModel;

			return inputModel.multiple ? DynamicMaterialChipsComponent : DynamicMaterialInputComponent;

		case DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP:
			return DynamicMaterialRadioGroupComponent;

		case DYNAMIC_FORM_CONTROL_TYPE_SELECT:
			return DynamicMaterialSelectComponent;

		case DYNAMIC_FORM_CONTROL_TYPE_SLIDER:
			return DynamicMaterialSliderComponent;

		case DYNAMIC_FORM_CONTROL_TYPE_SWITCH:
			return DynamicMaterialSlideToggleComponent;

		case DYNAMIC_FORM_CONTROL_TYPE_TEXTAREA:
			return DynamicMaterialTextAreaComponent;
		case 'MONEY':
			return DynamicMoneyComponent;
		case 'customDatePicker':
			return DatepickerCustompropsComponent;
		case 'customDatePickerV1':
			return DatepickerCustomComponent;
		case 'DATE_CHECK_BOX':
			return DatepickerCheckboxComponent;
		case 'TEXT_EDITOR':
			return DynamicMaterialTextEditorComponent;
		default:
			return null;
	}
}
