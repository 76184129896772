/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-loader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./page-loader.component";
import * as i3 from "../../../@core/page-loader.service";
var styles_PageLoaderComponent = [i0.styles];
var RenderType_PageLoaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageLoaderComponent, data: {} });
export { RenderType_PageLoaderComponent as RenderType_PageLoaderComponent };
export function View_PageLoaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "page-loader"]], [[2, "page-loader--loading", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "page-loader__progress"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n\n\n\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.show; _ck(_v, 0, 0, currVal_0); }); }
export function View_PageLoaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-loader", [], null, null, null, View_PageLoaderComponent_0, RenderType_PageLoaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.PageLoaderComponent, [i3.PageLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageLoaderComponentNgFactory = i1.ɵccf("app-page-loader", i2.PageLoaderComponent, View_PageLoaderComponent_Host_0, {}, {}, []);
export { PageLoaderComponentNgFactory as PageLoaderComponentNgFactory };
