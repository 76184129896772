import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MEDICAL_TREATING_REPORT_TYPE } from "../../pages/claimants/medical-treating-sources/medical-treating-reports/medical-treating-reports.config";
export class MedicalTreatingReportHeaderComponent {
    constructor() {
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
        });
    }
    isMedicationReport() {
        return this.data.type === MEDICAL_TREATING_REPORT_TYPE.medications;
    }
    isTreatingSourceReport() {
        return this.data.type === MEDICAL_TREATING_REPORT_TYPE.mts;
    }
}
