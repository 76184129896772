<app-frame [overlay]="false"
		   [titleHTML]="title"
		   [showCloseButton]="true"
		   [class.no-padding-form-content]="isVisitStep() || isVisitNotesStep() || isAddAnotherVisitStepTwo() || isAddAnotherVisitStepOne() || isMedicalTestsStep() || isMedicationsStep()"
		   (neadClose)="cancel()" autocomplete="off">
	<div class="adding-info-block adding-info-block--finish" *ngIf="isFinishedStep()">
		<div class="adding-info-block__row adding-info-block__row--wrap">
			<form-button (clickOnButton)="addAHospitalVisit()"
						 buttonType="add"
						 class="adding-info-block__btn"
						 title="Continue">
				Add another visit to THIS Hospital
			</form-button>
			<form-button (clickOnButton)="addNewHospital()"
						 buttonType="add"
						 class="adding-info-block__btn"
						 title="Continue">
				Add a New Different Hospital
			</form-button>
			<form-button (clickOnButton)="addHospitalization()"
						 buttonType="add"
						 class="adding-info-block__btn"
						 title="Continue">
				Add a Doctor
			</form-button>
			<form-button (clickOnButton)="addOtherTreatingSource()"
						 buttonType="add"
						 class="adding-info-block__btn"
						 title="Continue">
				Add a OTHER Treating Source
			</form-button>
			<form-button (clickOnButton)="returnToMyProfile()"
						 buttonType="add"
						 class="adding-info-block__btn"
						 title="Continue">
				Return to my Profile
			</form-button>
		</div>
	</div>

    <ng-container *ngIf="!isFinishedStep()">
        <div [style.display]="isInitialStep() ? 'block' : 'none'"
             id="block-tab-info" class="blocks-tab">
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>
                <mat-form-field *ngIf="showName()" class="name_input" [ngClass]="{'dirty': isInputDirty()}">
                    <input required matInput placeholder="Name" aria-label="Name" [matAutocomplete]="auto"
                           #trigger="matAutocompleteTrigger"
                           [formControl]="nameCtrl">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option [class.grey]="!name.label" *ngFor="let name of filteredNames | async" [value]="name"
                                    [matTooltip]="name.label ? name.label : null">
                            <span [class.grey]="!name.label">{{name.name}}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <dynamic-material-form [group]="formGroup"
                                       [layout]="formLayout"
                                       [model]="formModel"
                                       (blur)="onBlur($event)"
                                       (change)="onChange($event); onStateChanges()"
                                       (focus)="onFocus($event)">


                </dynamic-material-form>
            </form>

            <app-form-actions-content *ngIf="canAccess()">
                <form-button
                        (clickOnButton)="cancel()"
                        buttonType="warning"
                        title="Cancel">
                    Cancel
                </form-button>
                <!--			<form-button-->
                <!--				*ngIf="showName()"-->
                <!--				title="Add Visit"-->
                <!--				(clickOnButton)="addVisit()">-->
                <!--				Add Visit-->
                <!--			</form-button>-->
                <!--			<form-button *ngIf="showName()"-->
                <!--						 (clickOnButton)="saveDoctor('new')"-->
                <!--						 buttonType="add"-->
                <!--						 title="Add a New Medical Source">-->
                <!--				Next-->
                <!--			</form-button>-->
                <form-button *ngIf="showName()"
                             (clickOnButton)="changeTypeAskingStep(creationSteps.visit)"
							 [disabled]="!this.nameCtrl.value"
                             buttonType="add"
                             title="Add a New Medical Source">
                    Next
                </form-button>
            </app-form-actions-content>
        </div>
    </ng-container>


	<ng-container *ngIf="(this.nameCtrl.value && !resetVisits) || (isAddAnotherVisitStepOne() && createdHospitalName && !resetVisits)">
		<div [style.display]="isVisitStep() || (isAddAnotherVisitStepOne() && createdHospitalName) ? 'block' : 'none'" >
			<div class="adding-info-block" [style.display]="isVisitStep() ? 'block' : 'none'">
				<div class="adding-info-block__head">
					<div class="adding-info-block__text" style="color: #0867ff; font-size: 1.2em">
						If your were treated at this Hospital more than once, please add your most recent visit first.
						<br>
						When you've finished, select: Add another visit to this Hospital
					</div>
				</div>
			</div>

			<app-hospitals-visit (closeForm)="closeForm('Visit')"
								 (saveForm)="saveVisit($event)"
								 (backForm)="backVisit()"
								 (nextForm)="nextVisit($event)"
								 [sslgId]="sslgId"
								 [isAnotherVisit]="isAddAnotherVisitStepOne()"
								 (formStateChanged)="onFormStateChanged($event)"
								 (allFormStateChanged)="onAllFormStateChanged($event)"
								 [formKey]="'Visit'"></app-hospitals-visit>
		</div>
	</ng-container>

	<ng-container *ngIf="(this.nameCtrl.value && !resetVisits) || (isAddAnotherVisitStepTwo() && createdHospitalName && !resetVisits)">
		<div [style.display]="isVisitNotesStep() || isAddAnotherVisitStepTwo() ? 'block' : 'none'">
			<app-hospitals-visit-notes
								   [sslgId]="sslgId"
								   (backForm)="backVisitNotes()"
								   (nextForm)="nextVisitNotes($event)"
								   [isAnotherVisit]="isAddAnotherVisitStepTwo()"
								   (formStateChanged)="onFormStateChanged($event)"
								   (allFormStateChanged)="onAllFormStateChanged($event)"
								   [formKey]="'visitSecondStep'"></app-hospitals-visit-notes>
		</div>
	</ng-container>

	<ng-container *ngIf="(this.nameCtrl.value && !resetVisits) || (isAddAnotherVisitStepTests() && createdHospitalName && !resetVisits)">
		<div [style.display]="isMedicalTestsStep() || isAddAnotherVisitStepTests() ? 'block' : 'none'">
<!--			<app-medical-treating-sources-medical-test [medicalTest]="null"-->
<!--													   (backForm)="backMedicalTest($event)"-->
<!--													   (formStateChanged)="onFormStateChanged($event)"-->
<!--													   (allFormStateChanged)="onAllFormStateChanged($event)"-->
<!--													   [formKey]="'Medical Tests'"-->
<!--													   (nextForm)="nextMedicalTest($event)"-->
<!--													   (saveForm)="saveMedicalTest($event)"></app-medical-treating-sources-medical-test>-->
			<app-partial-medical-tests (backForm)="backMedicalTest($event)"
									   [isHospital]="true"
									   (formStateChanged)="onFormStateChanged($event)"
									   (allFormStateChanged)="onAllFormStateChanged($event)"
									   [formKey]="'Medical Tests'"
									   [mode]="MEDICAL_TESTS_MODE.create"
									   (nextForm)="nextMedicalTest($event)"
									   (saveForm)="saveMedicalTest($event)"></app-partial-medical-tests>
		</div>
	</ng-container>

	<ng-container *ngIf="(this.nameCtrl.value && !resetVisits) || (isAddAnotherVisitStepMedications() && createdHospitalName && !resetVisits)">
		<div [style.display]="isMedicationsStep() || isAddAnotherVisitStepMedications() ? 'block' : 'none'">
			<app-medical-treating-sources-medications
					(closeForm)="closeForm('Medications')"
					(backForm)="backMedication($event)"
					[sslgId]="sslgId"
					(formStateChanged)="onFormStateChanged($event)"
					(saveForm)="saveMedication($event)"
					(allFormStateChanged)="onAllFormStateChanged($event)"
					[formKey]="'Medications'">
			</app-medical-treating-sources-medications>
		</div>
	</ng-container>
</app-frame>
