import {
    ApplicationRef,
    ComponentFactoryResolver, ComponentRef,
    EmbeddedViewRef,
    Injectable,
    Injector,
    Optional
} from '@angular/core';
import {
    Observable,
    Subject,
    Subscription,
    merge,
    fromEvent,
    from,
    interval,
    timer,
    of
} from 'rxjs';
import {
    bufferTime,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    scan,
    switchMap,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';
import {UserIdleConfig} from './user-idle-config';
import {UserIdleComponent} from './user-idle/user-idle.component';

/**
 * User's idle service.
 */
@Injectable({
    providedIn: 'root'
})
export class UserIdleService {
    ping$: Observable<any>;

    /**
     * Events that can interrupts user's inactivity timer.
     */
    protected activityEvents$: Observable<any>;

    protected timerStart$ = new Subject<boolean>();
    protected timeout$ = new Subject<boolean>();
    protected idle$: Observable<any>;
    protected timer$: Observable<any>;
    /**
     * Idle value in seconds.
     * Default equals to 10 minutes.
     */
    protected idle = 600;
    /**
     * Timeout value in seconds.
     * Default equals to 5 minutes.
     */
    protected timeout = 300;
    /**
     * Ping value in seconds.
     * * Default equals to 2 minutes.
     */
    protected ping = 120;
    /**
     *  if idle can show dialog
     */
    protected showIdleDialog = false;
    /**
     * Timeout status.
     */
    protected isTimeout: boolean;
    /**
     * Timer of user's inactivity is in progress.
     */
    protected isInactivityTimer: boolean;

    protected idleSubscription: Subscription;

    protected comp: ComponentRef<UserIdleComponent>;

    constructor(@Optional() private config: UserIdleConfig, private componentFactoryResolver: ComponentFactoryResolver,
                private appRef: ApplicationRef, private injector: Injector) {
        if (config) {
            //console.log(this.config);
            this.idle = config.idle;
            this.timeout = config.timeout;
            this.ping = config.ping;
            this.showIdleDialog = config.showIdleDialog;
        }
        this.activityEvents$ = merge(
            fromEvent(window, 'mousemove'),
            fromEvent(window, 'resize'),
            fromEvent(document, 'keydown'),
            fromEvent(window, 'click'),
            fromEvent(window, 'blur')
        );

        this.idle$ = from(this.activityEvents$);
    }

    /**
     * Start watching for user idle and setup timer and ping.
     */
    startWatching() {
        if (this.idle === 0) {
            return;
        }

        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }

        // If any of user events is not enabled for idle-seconds when start timer.
        this.idleSubscription = this.idle$
            .pipe(
                bufferTime(2000), // Starting point of detecting of user's inactivity
                filter(arr => {

                    if (!!arr.length && !this.isInactivityTimer) {
                        this.resetTimer();
                    }

                    return !arr.length && !this.isInactivityTimer;
                }),
                tap(() => this.isInactivityTimer = true),
                switchMap(() => interval(1000).pipe(
                    takeUntil(
                        merge(
                            this.activityEvents$,
                            timer(this.idle * 1000).pipe(
                                tap(() => this.timerStart$.next(true))
                            )
                        )
                    ),
                    finalize(() => {
                        this.isInactivityTimer = false;
                        ////console.log(this.isInactivityTimer);
                    })
                ))).subscribe();

        this.setupTimer(this.timeout);
        this.setupPing(this.ping);
    }

    stopWatching() {
        if (this.idle === 0) {
            return;
        }
        this.stopTimer();
        if (this.idleSubscription) {
            this.idleSubscription.unsubscribe();
        }
    }

    stopTimer() {
        if (this.idle === 0) {
            return;
        }
        this.timerStart$.next(false);
        if (this.comp && this.comp != null) {
            this.destroy(this.comp.hostView);
        }
    }

    resetTimer() {
        if (this.idle === 0) {
            return;
        }
        this.stopTimer();
        this.isTimeout = false;

    }

    /**
     * Return observable for timer's countdown number that emits after idle.
     */
    onTimerStart(): Observable<number> {
        return this.timerStart$.pipe(
            distinctUntilChanged(),
            switchMap(start => (start ? this.timer$ : of(null))),
            tap(j => {
                if (!!j && this.showIdleDialog) {
                    if (!this.comp || this.comp == null) {
                        this.showDialog();
                    }
                    this.comp.instance.timeToEnd = this.timeout - j;
                }
            })
        );
    }

    /**
     * Return observable for timeOut is fired.
     */
    onTimeout(): Observable<boolean> {
        return this.timeout$.pipe(
            filter(timeout => !!timeout),
            tap(() => {
                this.isTimeout = true;
            }),
            map(() => {

                return true;

            })
        );
    }

    getConfigValue(): UserIdleConfig {
        return {
            idle: this.idle,
            timeout: this.timeout,
            ping: this.ping,
            showIdleDialog: this.showIdleDialog
        };
    }

    /**
     * Set config values.
     * @param config
     */
    setConfigValues(config: UserIdleConfig) {
        if (this.idleSubscription && !this.idleSubscription.closed) {
            console.error('Call stopWatching() before formset config values');
            return;
        }

        if (config.idle) {
            this.idle = config.idle;
        }
        if (config.ping) {
            this.ping = config.ping;
        }
        if (config.timeout) {
            this.timeout = config.timeout;
        }
        if (config.showIdleDialog) {
            this.showIdleDialog = config.showIdleDialog;
        }
    }

    /**
     * Setup timer.
     *
     * Counts every seconds and return n+1 and fire timeOut for last count.
     * @param timeout Timeout in seconds.
     */
    protected setupTimer(timeout: number) {
        this.timer$ = interval(1000).pipe(
            take(timeout),
            map(() => 1),
            scan((acc, n) => acc + n),
            tap(count => {
                if (count === timeout) {
                    this.timeout$.next(true);

                }
            })
        );
    }

    /**
     * Setup ping.
     *
     * Pings every ping-seconds only if is not timeOut.
     * @param ping
     */
    protected setupPing(ping: number) {
        this.ping$ = interval(ping * 1000).pipe(
            filter(() => !this.isTimeout));
    }

    protected destroy(comp) {
        this.appRef.detachView(comp);
        comp.destroy();
        this.comp = null;

    }

    protected showDialog() {
        this.comp = this.componentFactoryResolver
            .resolveComponentFactory(UserIdleComponent)
            .create(this.injector);

        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(this.comp.hostView);
        this.comp.instance.onCloseDialog.subscribe(j => {
            this.destroy(this.comp.hostView);
        });
        // Get DOM element from component
        const domElem = (this.comp.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        // Append DOM element to the body
        document.body.appendChild(domElem);
    }

}
