import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomIrisNotificationComponent} from './custom-iris-notification-component/custom-iris-notification.component';
import {CustomIrisNotificationService} from "./custom-iris-notification.service";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NotificationsContainerComponent} from './notifications-container/notifications-container/notifications-container.component';
import {InnerContainerComponent} from './notifications-container/inner-container/inner-container.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@NgModule({
    declarations: [CustomIrisNotificationComponent, NotificationsContainerComponent, InnerContainerComponent],
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
    ],
    entryComponents: [NotificationsContainerComponent]
})
export class CustomIrisNotificationModule {
    public static forRoot(): ModuleWithProviders {
        return {
            ngModule: CustomIrisNotificationModule,
            providers: [CustomIrisNotificationService]
        }
    }
}
