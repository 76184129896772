/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-frame.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/drag-drop";
import * as i3 from "@angular/common";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "./form-frame.component";
var styles_FormFrameComponent = [i0.styles];
var RenderType_FormFrameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormFrameComponent, data: {} });
export { RenderType_FormFrameComponent as RenderType_FormFrameComponent };
function View_FormFrameComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, ":svg:svg", [["class", "form-legend__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("../../../../assets/img/sprite.svg#" + _co.icon); _ck(_v, 2, 0, currVal_0); }); }
function View_FormFrameComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 9, "legend", [["cdkDrag", ""], ["cdkDragRootElement", ".cdk-overlay-pane"], ["class", "form-legend cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i1.ɵprd(6144, null, i2.ɵb, null, [i2.CdkDrag]), i1.ɵdid(2, 4866048, null, 3, i2.CdkDrag, [i1.ElementRef, [3, i2.CDK_DROP_LIST], i3.DOCUMENT, i1.NgZone, i1.ViewContainerRef, i2.CDK_DRAG_CONFIG, [2, i4.Directionality], i2.DragDrop, i1.ChangeDetectorRef], { rootElementSelector: [0, "rootElementSelector"], disabled: [1, "disabled"] }, null), i1.ɵqud(603979776, 1, { _handles: 1 }), i1.ɵqud(603979776, 2, { _previewTemplate: 0 }), i1.ɵqud(603979776, 3, { _placeholderTemplate: 0 }), (_l()(), i1.ɵted(6, null, ["\n    ", "\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFrameComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = ".cdk-overlay-pane"; var currVal_3 = _co.dragAndDropDisabled; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_5 = _co.icon; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 2).disabled; var currVal_1 = i1.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _co.title; _ck(_v, 6, 0, currVal_4); }); }
function View_FormFrameComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 6, "legend", [["cdkDrag", ""], ["cdkDragRootElement", ".cdk-overlay-pane"], ["class", "form-legend cdk-drag"]], [[8, "innerHTML", 1], [2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i1.ɵprd(6144, null, i2.ɵb, null, [i2.CdkDrag]), i1.ɵdid(2, 4866048, null, 3, i2.CdkDrag, [i1.ElementRef, [3, i2.CDK_DROP_LIST], i3.DOCUMENT, i1.NgZone, i1.ViewContainerRef, i2.CDK_DRAG_CONFIG, [2, i4.Directionality], i2.DragDrop, i1.ChangeDetectorRef], { rootElementSelector: [0, "rootElementSelector"], disabled: [1, "disabled"] }, null), i1.ɵqud(603979776, 4, { _handles: 1 }), i1.ɵqud(603979776, 5, { _previewTemplate: 0 }), i1.ɵqud(603979776, 6, { _placeholderTemplate: 0 }), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = ".cdk-overlay-pane"; var currVal_4 = _co.dragAndDropDisabled; _ck(_v, 2, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleHTML; var currVal_1 = i1.ɵnov(_v, 2).disabled; var currVal_2 = i1.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_FormFrameComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "close-modal"]], [[2, "is-ios", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 3, ":svg:svg", [["class", "close-modal__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/img/sprite.svg#close"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isIOS; _ck(_v, 0, 0, currVal_0); }); }
export function View_FormFrameComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "fieldset", [["class", "form-fieldset"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFrameComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFrameComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "form-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormFrameComponent_4)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.showLegend && !_co.titleHTML); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.showLegend && _co.titleHTML); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.showCloseButton; _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 0, 0, currVal_0); }); }
export function View_FormFrameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-frame", [], null, null, null, View_FormFrameComponent_0, RenderType_FormFrameComponent)), i1.ɵdid(1, 114688, null, 0, i5.FormFrameComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormFrameComponentNgFactory = i1.ɵccf("app-frame", i5.FormFrameComponent, View_FormFrameComponent_Host_0, { title: "title", titleHTML: "titleHTML", icon: "icon", autocomplete: "autocomplete", overlay: "overlay", disabled: "disabled", showCloseButton: "showCloseButton", showLegend: "showLegend", dragAndDropDisabled: "dragAndDropDisabled" }, { neadClose: "neadClose" }, ["*"]);
export { FormFrameComponentNgFactory as FormFrameComponentNgFactory };
