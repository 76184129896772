import * as tslib_1 from "tslib";
import { NavigationService } from "./@core/navigation.service";
import { ReferralService } from "./@core/referral.service";
import { map } from "rxjs/operators";
export class TitleRouterResolver {
    constructor(navService, referralService) {
        this.navService = navService;
        this.referralService = referralService;
    }
    resolve(route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let sslgId = this.getSslgId(route);
            if (route.data.title) {
                if (route.data.title.indexOf("${referral}") > -1) {
                    if (!this.referralService.current) {
                        if (!sslgId) {
                            Error("SSLG Id Not Found");
                            return false;
                        }
                        return this.referralService.getClaimantLevelDetails(sslgId).pipe(map(current => {
                            this.referralService.setCurrent(current);
                            this.navService
                                .setTitle(route.data.title.replace('${referral}', this.referralService.current.firstName + ' ' + this.referralService.current.lastName));
                        })).subscribe();
                    }
                    else {
                        this.navService
                            .setTitle(route.data.title.replace('${referral}', this.referralService.current.firstName + ' ' + this.referralService.current.lastName));
                    }
                }
                else {
                    this.navService.setTitle(route.data.title);
                }
            }
            else if (sslgId) {
                return this.referralService.getClaimantLevelDetails(sslgId).pipe(map(current => {
                    this.referralService.setCurrent(current);
                })).subscribe();
            }
            return true;
        });
    }
    getSslgId(route) {
        let sslgId = route.paramMap.get('sslgId');
        if (!sslgId && route.parent)
            return this.getSslgId(route.parent);
        return sslgId;
    }
}
