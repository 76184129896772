<ng-template #form_button_text>
  <ng-content>
  </ng-content>
</ng-template>

<!--class="form-action form-action_accent"-->
<button [disabled]="disabled" (click)="clickOnButton.emit()" [ngClass]="['form-action', button.buttonClass]">
  <svg *ngIf="button.showIcon" class="save-action__icon">
    <use [attr.xlink:href]="'../../../../assets/img/sprite.svg#' + button.iconId"></use>
  </svg>
  <ng-container *ngTemplateOutlet="form_button_text"></ng-container>
</button>




