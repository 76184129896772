<app-frame [overlay]="false"
		   [title]="title"
		   [showCloseButton]="true"
		   [class.is-medication]="isMedicationStep()"
		   (neadClose)="closeMedicationForm({})"
		   autocomplete="off">
	<div class="adding-info-block adding-info-block--finish" *ngIf="isInitialStep()">
		<div class="adding-info-block__row adding-info-block__row--wrap">
			<!--			<app-form-actions-content>-->
			<form-button (clickOnButton)="addMedication()"
						 buttonType="add"
						 class="adding-info-block__btn"
						 title="Continue">
				Add a Medication
			</form-button>
			<form-button (clickOnButton)="addVisit()"
						 buttonType="add"
						 class="adding-info-block__btn"
						 title="Continue">
				Add as a part of a visit
			</form-button>
			<!--			</app-form-actions-content>-->
		</div>
	</div>

	<div *ngIf="isMedicationStep()">
		<form class="form-horizontal form-fieldset" [formGroup]="formGroupTemplate" novalidate>
			<dynamic-material-form [group]="formGroupTemplate"
								   class="row"
								   (change)="onChange($event)"
								   [layout]="formLayout"
								   [model]="formModelTemplate">
			</dynamic-material-form>
		</form>

		<app-form-actions-content>
			<form-button (clickOnButton)="saveMedication()">Save</form-button>
		</app-form-actions-content>
	</div>

</app-frame>

