import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {AuthenticationService} from "../_services/authentication.service";
import {Subscription} from "rxjs";
import {UserInfoService} from "../../@core/user-info.service";
import {map} from "rxjs/operators";

@Injectable()
export class ClaimGuard implements CanActivate {

	private authSubscription: Subscription;

	constructor(private router: Router, private userInfoService: UserInfoService) {

	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		this.userInfoService.loadCurent();
		return this.userInfoService.userInfo.pipe(map(j => {
			if (j.role == 'CLIENT') {
				this.router.navigate([`/claimants/record/${j.username}`]);
				return false;
			} else {
				return true;
			}
		}));
	}
}
