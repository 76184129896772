import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import { GlobalSaveService } from "../../../@core/global-save.service";
import {HttpClient} from "@angular/common/http";
import {UserInfoService} from "../../../@core/user-info.service";
import {ActivatedRoute, Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";

@Component({
	selector: 'app-global-save-cancel',
	templateUrl: './global-save-cancel.component.html',
	styleUrls: ['../global-save-msg-form.component.scss']
})
export class GlobalSaveCancelComponent implements OnInit {
	title = 'StSr Message';

	globalSaveService;


	constructor(
		private http: HttpClient,
		private userInfoService: UserInfoService,
		public dialog: MatDialog,
		private route: ActivatedRoute, private router: Router,
		private dialogRef: MatDialogRef<GlobalSaveCancelComponent>,
		@Inject(MAT_DIALOG_DATA) private data: string[]) {

	}

	ngOnInit() {
		this.globalSaveService = new GlobalSaveService(this.http,this.userInfoService,this.dialog);
	}

	closeVisitForm() {
		this.dialogRef.close();
	}

	confirm() {
		this.globalSaveService.confirmOff().subscribe(data => {
			if(data.status == "true") {
				this.globalSaveService.setStatus(this.globalSaveService.statusNotActive);
			}

			this.dialogRef.close();
			document.location.reload();


			// if (!this.globalSaveService.goToPage) {
			// 	document.location.reload();
			// } else {
			// 	this.globalSaveService.goToPage.next('');
			// 	this.dialogRef.close();
			// 	this.router.navigate([this.route.snapshot.queryParams['returnUrl']],
			// 		{queryParams: {page: this.route.snapshot.queryParams['page']}});
			//
			// }

		});
		//this.dialogRef.close((<any>this.formGroup).controls['medicalCondition'].value.map(j => j.value).filter(j => j));
	}
}
