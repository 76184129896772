import {
	DynamicFormValueControlModel
} from "@ng-dynamic-forms/core";

(<any>DynamicFormValueControlModel).prototype.defaultValue = null;

(<any>DynamicFormValueControlModel).prototype.setValue = function (value: string): void {
	this.defaultValue = value;
	this.valueUpdates.next(value);
};

(<any>DynamicFormValueControlModel).prototype.isDirty = function (): boolean {

	let isHidden = (model) => {
		if (model.hidden) {
			return model.hidden;
		} else if (model.parent) {
			return isHidden(model.parent);
		} else {
			return model.hidden;
		}
	};
	if (!this.disabled && (!isHidden(this))) {
		// console.log(this);
		return (this.defaultValue || this.value) && this.defaultValue !== this.value;
	}

};

(<any>DynamicFormValueControlModel).prototype.resetToDefault = function (): void {
	this.defaultValue = this.value;
};
export {};
