import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AuthenticationService} from "../auth/_services/authentication.service";
import {UserInfoService} from "../@core/user-info.service";
import {CodeDictionaryService} from "./code-dictionary.service";
import {LoadingService} from "../../@util/loading/loading.service";
import {DialogService} from "../../@util/dialog/dialog.service";
import {DevicesService} from "./devices.service";

declare global {
    interface Window {
        myPhone: any;
        webkit?: any;
        androidFunction?: any;
    }
}

declare var window: Window;
declare var AndroidFunction;

@Injectable({
    providedIn: 'root'
})
export class PhoneService {
    token;

    public avatarChanged = new BehaviorSubject(null);
	public showPreloader = new BehaviorSubject(null);
	public hidePreloader = new BehaviorSubject(null);
	//public deviceToken = new BehaviorSubject(null);

	private loader;

    constructor(private dialog: DialogService,
				private ngZone: NgZone,
                private authenticationService:AuthenticationService,
				private userInfoService:UserInfoService,
				private devicesService:DevicesService,
				private loading: LoadingService) {

    }

    init() {
        window.myPhone = window.myPhone || {};
        window.myPhone.namespace = window.myPhone.namespace || {};
        window.myPhone.namespace.publicFunc = this.publicFunc.bind(this);

    }

    destroy(blockId: number) {
        window.myPhone.namespace.publicFunc = null;
    }

    publicFunc(json) {
		//this.dialog.show(json, ['OK']);
    	if (typeof json === 'string'){
            try {
                json = JSON.parse(json);
            }catch (e) {

            }
        }

        if(json.status == "true"){
			// this.loading.loaded(this.loader);
			this.loading.loaded();

            let command = json.command;
            if (command == 'changeLogo') {
                this.avatarChanged.next(Math.round(+new Date()/1000)+Math.random());
            }

			if (command == 'uploadDocumentMedicalRecords') {
				this.dialog.show(json.message, ['OK']);
			}

			if (command == 'showPreloader') {
				this.loader = this.loading.loading();
			}

			if (command == 'hidePreloader') {
				//this.dialog.show('showPreloader', ['OK']);

				//this.loading.loaded(this.loader);
			}

			if (command == 'firebaseToken') {
				let token = json.token;

				//this.dialog.show(command, ['OK']);

				//this.dialog.show(token, ['OK']);

				this.devicesService.setFirebaseToken(token);
			}
        }
    }

	setNotificationToken(){
		let claimantName = this.userInfoService.getCurrentUserName();
		let token = this.userInfoService.getToken();
		let json = '{"command":"notificationToken","token":"'+token+'","claimantName":"'+claimantName+'"}';

		this.sendCommand(json);
	}

    setChangeAvatar(claimantName:string){
        let token = this.userInfoService.getToken();
        let json = '{"command":"changeLogo","token":"'+token+'","claimantName":"'+claimantName+'"}';

        this.sendCommand(json);
    }

	sendMedicalRecords(){
		let token = this.userInfoService.getToken();
		let json = '{"command":"sendMedicalRecords","token":"'+token+'"}';

		this.sendCommand(json);
	}

	uploadDocumentMedicalRecords(claimantName:string,title:string){
		let token = this.userInfoService.getToken();
		let json = '{"command":"uploadDocumentMedicalRecords","token":"'+token+'","claimantName":"'+claimantName+'","data":"'+title+'"}';

		this.sendCommand(json);
	}

	setChangeAvatarInsurance(id:string){
		let token = this.userInfoService.getToken();
		let json = '{"command":"changeAvatarInsurance","token":"'+token+'","id":"'+id+'"}';
		this.sendCommand(json);
	}

    setMakeCall(phone:string){
        let json = '{"command":"makePhone","phone":"'+phone+'"}';
		this.sendCommand(json);
	}

	setMakeMailTo(email:string){
		let json = '{"command":"makeMailTo","email":"'+email+'"}';
		this.sendCommand(json);
	}

	sendCommand(json){
		if(window.webkit){
			window.webkit.messageHandlers.phoneListener.postMessage(json);
		}

		try {
			AndroidFunction.getCommand(json);
		}catch (e) {

		}
	}
}
