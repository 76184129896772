import {EventEmitter, Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {UserIdleService} from '../user-idle/user-idle.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthConfig} from './auth-config';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {UserIdleConfig} from '../user-idle/user-idle-config';
import {AppStorage} from "../../storage";
import {GlobalSaveService} from "../../@core/global-save.service";
import {UserTempService} from "../../@core/userTemp.service";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private timerStartSubscription: Subscription;
    private timeoutSubscription: Subscription;
    private pingSubscription: Subscription;
    isTimer: boolean;
    timeIsUp: boolean;
    timerCount: number;
    lastPing: string;
    private storage = sessionStorage;
    roles: string[];
    rolesObservable = new EventEmitter<any>();

    constructor(
    	@Inject(AuthConfig) private authConfig: AuthConfig,
		private http: HttpClient,
		private userIdle: UserIdleService,
		private router: Router,
		private route: ActivatedRoute,
    private userTempService: UserTempService,
		private globalSaveService: GlobalSaveService) {
    }

    // get userName() {
    //   return AppStorage.getStorage().getItem('user');
    // }

    sendUserName(username: string) {
      return this.http.post<any>(this.authConfig.authUserNameUrl, {username: username});
    }

    login(username: string, password: string, pin: string, ignore: boolean) {
        return this.http.post<any>(this.authConfig.authUrl, {username: username, password: password, pin: pin, ignore: ignore})
            .pipe(map((res: any) => {
                // login successful if there's a jwt token in the response

                if (res && res.token) {
                    this.onStartWatching();
                    this.storage = sessionStorage;

                    // store username and jwt token in local storage to keep user logged in between page refreshes
                    this.storage.setItem('currentUser', JSON.stringify({token: res.token}));
					this.storage.setItem('currentUserName', username);
					this.storage.setItem('currentUserRole', res.role);

					this.userTempService.setUserTempState(res);
					//this.storage.setItem('currentUserName', username);

					this.storage.setItem('audioNotifiication', "0");

                    this.roles = [res.role];
                    this.rolesObservable.next(this.roles);

					this.globalSaveService.setLastClaimantData(res.globalSave,res.globalSaveLastClaimant,
						res.globalSaveLastClaimentFullName);

                    if (UserIdleConfig.REMEMBER_ME) {
                        AppStorage.getStorage().setItem('user', username);
                    } else {
                        AppStorage.getStorage().removeItem('user');
                    }
                }
            }));
    }

	checkUser(email: string, hash: string) {
		return this.http.post(this.authConfig.checkUser, {email: email, hash: hash});
	}

	restore(email: string, hash: string, password: string) {
		return this.http.post(this.authConfig.restoreUrl, {email: email, hash: hash, password:password});
	}

    async logout() {
        if (this.router.url.indexOf(this.authConfig.loginRoute) === -1) {

            await this.router.navigate([this.authConfig.loginRoute]);
            location.reload();
            return;
        }
        this.storage.removeItem('currentUser');
        if (this.timerStartSubscription && !this.timerStartSubscription.closed) {
            this.onStopWatching();
            // window.location.reload();
        }

    }

    refreshToken() {
        if (!this.storage.getItem('currentUser')) {
            return;
        }
        return this.http.post<any>(this.authConfig.refreshTokenUrl, {}).subscribe(res => {
            this.storage.removeItem('currentUser');
            if (res && res.token) {
                this.storage.setItem('currentUser', JSON.stringify({token: res.token}));
            }
        }, error => {
            this.storage.removeItem('currentUser');
        });

    }

    async checkSession() {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                this.onStopWatching();
            } catch (e) {
                ////console.log(e);
            }
            this.refreshToken();
            this.onStartWatching();
        } else {
        	let pathname = location.pathname;
        	let dir = pathname.split('/',);

        	if(this.authConfig.restoreRoute !== '/'+dir[1]){
				this.router.navigate([this.authConfig.loginRoute],
					{
						queryParams: this.authConfig.loginRoute !== location.pathname &&
						this.authConfig.restoreRoute !== location.pathname ?
							{
								returnUrl:
								location.pathname
							} : null
					});
			}

        }
    }

    onStartWatching() {
        if (this.userIdle.getConfigValue().idle === 0) {
            return;
        }
        // Start watching for user inactivity.
        this.userIdle.startWatching();

        // Start watching when user idle is starting.
        this.timerStartSubscription = this.userIdle.onTimerStart()
            .pipe(tap(() => this.isTimer = true))
            .subscribe(count => {
                if (count == null && !this.timeIsUp) {
                    this.isTimer = false;
                } else if (count == null) {
                    this.isTimer = false;
                }
            });

        // Start watch when time is followup.
        this.timeoutSubscription = this.userIdle.onTimeout()
            .subscribe(j => {
                this.timeIsUp = true;
                this.logout();
                ////console.log('time is followup.... LOGOUT', j);
            });

        this.pingSubscription = this.userIdle.ping$
            .subscribe(value => {
                if (!this.isTimer && !this.timeIsUp) {
                    ////console.log('ping');
                    this.refreshToken();
                }
            });
    }

    onStopWatching() {
        if (this.userIdle.getConfigValue().idle === 0) {
            return;
        }
        this.userIdle.resetTimer();
        this.userIdle.stopWatching();
        this.isTimer = false;
        this.timeIsUp = false;
        this.lastPing = null;
        if (!this.timerStartSubscription) {
            return;
        }
        this.timerStartSubscription.unsubscribe();
        this.timeoutSubscription.unsubscribe();
        this.pingSubscription.unsubscribe();
    }

    isAuthorized(){
		if (this.storage.getItem('currentUser')) {
			return true;
		}

		return false;
	}
}
