<mat-form-field
	[color]="model.getAdditional('color', 'primary')"
	[formGroup]="group"
	[hideRequiredMarker]="model.getAdditional('hideRequiredMarker', false)"
	[ngClass]="getClass('grid', 'control')"
	[floatLabel]="model.getAdditional('floatLabel', LABEL_OPTIONS && LABEL_OPTIONS['float'] || 'auto')">

	<ng-container *ngIf="model.label" ngProjectAs="mat-label">
		<mat-label>{{ model.label }}</mat-label>
	</ng-container>

	<span *ngIf="model.prefix" matPrefix [innerHTML]="model.prefix"></span>

	<input
		matInput
		[autofocus]="model.autoFocus"
		[formControlName]="model.id"
		[id]="elementId"
		[name]="model.name"
		[ngClass]="getClass('element', 'control')"
		[placeholder]="model.placeholder"
		[readonly]="model.readOnly"
		[required]="model.required"
		(blur)="onBlur($event)"
		[tabIndex]="model.tabIndex"
		(dateChange)="onChange($event)"
		(dateInput)="onCustomEvent($event, 'dateInput')"
		(focus)="onFocus($event)"
		[disabled]=""
		[min]="minDate"
		[matDatepicker]="picker"
		[formControl]="date">

	<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
	<mat-datepicker #picker></mat-datepicker>

	<ng-container *ngFor="let message of errorMessages" ngProjectAs="mat-error">

		<mat-error *ngIf="showErrorMessages">{{ message }}</mat-error>

	</ng-container>

</mat-form-field>

<div class="ngx-datepicker-container mat-calendar">

	<div class="ngx-datepicker-input-container" #ngxInputContainer>
		<div class="input-container">
			<ng-content>

			</ng-content>
		</div>
		<span class="icon-clear material-icon material-icons" (click)="clearInput()">highlight_off</span>
	</div>

</div>
