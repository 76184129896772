/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./visit-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../partial/forms/form-frame/form-frame.component.ngfactory";
import * as i4 from "../../../../partial/forms/form-frame/form-frame.component";
import * as i5 from "../../../../partial/form-actions-content/form-actions-content.component.ngfactory";
import * as i6 from "../../../../partial/form-actions-content/form-actions-content.component";
import * as i7 from "../../../../partial/form-button/form-button.component.ngfactory";
import * as i8 from "../../../../partial/form-button/form-button.component";
import * as i9 from "./visit-dialog.component";
import * as i10 from "@angular/material/dialog";
var styles_VisitDialogComponent = [i0.styles];
var RenderType_VisitDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VisitDialogComponent, data: {} });
export { RenderType_VisitDialogComponent as RenderType_VisitDialogComponent };
export function View_VisitDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LowerCasePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 18, "app-frame", [["autocomplete", "off"], ["class", "align-title"]], null, [[null, "neadClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("neadClose" === en)) {
        var pd_0 = (_co.closeVisitForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FormFrameComponent_0, i3.RenderType_FormFrameComponent)), i1.ɵdid(2, 114688, null, 0, i4.FormFrameComponent, [], { title: [0, "title"], autocomplete: [1, "autocomplete"], overlay: [2, "overlay"] }, { neadClose: "neadClose" }), (_l()(), i1.ɵted(-1, 0, ["\n\t"])), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "div", [["class", "visit-dialog-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["\n\t\tWould you like to enter more visits to a ", "?\n\t"])), i1.ɵppd(6, 1), (_l()(), i1.ɵted(-1, 0, ["\n\t"])), (_l()(), i1.ɵeld(8, 0, null, 0, 10, "app-form-actions-content", [], null, null, null, i5.View_FormActionsContentComponent_0, i5.RenderType_FormActionsContentComponent)), i1.ɵdid(9, 114688, null, 0, i6.FormActionsContentComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i1.ɵeld(11, 0, null, 0, 2, "form-button", [], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.btnClick("yes") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_FormButtonComponent_0, i7.RenderType_FormButtonComponent)), i1.ɵdid(12, 638976, null, 0, i8.FormButtonComponent, [], null, { clickOnButton: "clickOnButton" }), (_l()(), i1.ɵted(-1, 0, ["Yes"])), (_l()(), i1.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i1.ɵeld(15, 0, null, 0, 2, "form-button", [["buttonType", "warning"]], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.btnClick("no") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_FormButtonComponent_0, i7.RenderType_FormButtonComponent)), i1.ɵdid(16, 638976, null, 0, i8.FormButtonComponent, [], { buttonType: [0, "buttonType"] }, { clickOnButton: "clickOnButton" }), (_l()(), i1.ɵted(-1, 0, ["No"])), (_l()(), i1.ɵted(-1, 0, ["\n\t"])), (_l()(), i1.ɵted(-1, 0, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = "off"; var currVal_2 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 9, 0); _ck(_v, 12, 0); var currVal_4 = "warning"; _ck(_v, 16, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), _co.data.type)); _ck(_v, 5, 0, currVal_3); }); }
export function View_VisitDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-visit-dialog", [], null, null, null, View_VisitDialogComponent_0, RenderType_VisitDialogComponent)), i1.ɵdid(1, 49152, null, 0, i9.VisitDialogComponent, [i10.MAT_DIALOG_DATA, i10.MatDialogRef], null, null)], null, null); }
var VisitDialogComponentNgFactory = i1.ɵccf("app-visit-dialog", i9.VisitDialogComponent, View_VisitDialogComponent_Host_0, {}, {}, []);
export { VisitDialogComponentNgFactory as VisitDialogComponentNgFactory };
