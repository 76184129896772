import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ReportItem} from "../report-item";
import {ClientsService} from "../../../@core/clients.service";
import {User} from "../../../@models/system-managment/user";
import {ReferralService} from "../../../@core/referral.service";

@Component({
	selector: 'app-report-header',
	templateUrl: './report-header.component.html',
	styleUrls: ['./report-header.component.scss']
})
export class ReportHeaderComponent implements OnInit, ReportItem {
	@ViewChild('element', { static: true })
	public element;

	@Input()
	sslgId;
	currentUser;

	constructor(private referralService: ReferralService) {
	}

	async ngOnInit() {
		this.currentUser = await <any>this.referralService.getClaimantPdfInfo(this.sslgId).toPromise();
		console.log(this.currentUser);
	}


	getUserPhone() {
		if (this.currentUser) {
			return this.currentUser.phone ? this.currentUser.phone : 'Not specified'
		} else {
			return ''
		}

	}

	getUserEmail() {
		if (this.currentUser) {
			return this.currentUser.email ? this.currentUser.email : 'Not specified'
		} else {
			return '';
		}

	}

	getIcdId(j: any, index) {
		if (this.currentUser.icdId.length == index+1) {
			return j.code
		} else {
			return j.code+','
		}
	}
}
