import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import * as Stomp from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { NOTIFICATIONS_CONFIG } from "../@config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class NotificationService {
    constructor(http) {
        this.http = http;
        this.notifiRecived = new EventEmitter();
        this.alertsRecived = new EventEmitter();
        this.alertsQtyChanged = new BehaviorSubject(null);
        this.config = NOTIFICATIONS_CONFIG;
    }
    connect() {
        // this.disconnect();
        // this.connection = setInterval(() => {
        // }, 3000);
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        const url = `${window.location.protocol}//${window.location.hostname}${window.location.port ? environment.production ? `:${window.location.port}` : ':8191' : ''}`;
        const socket = new SockJS(url + '/ws?token=' + currentUser.token);
        const stompClient = Stomp.over(socket);
        // Subscribe the '/notify' channell
        stompClient.connect({}, (frame) => {
            stompClient.subscribe('/user/notifications', (notification) => {
                this.notifiRecived.emit(JSON.parse(notification.body));
            });
            stompClient.subscribe('/user/notifications/alerts', (notification) => {
                // Call the notify function when receive a notification
                this.alertsRecived.emit(JSON.parse(notification.body));
            });
        });
        setTimeout(() => {
            this.http.get('/api/notifications').subscribe(j => {
                this.notifiRecived.emit(j);
            });
            this.http.get('/api/notifications/alerts').subscribe(j => {
                this.alertsRecived.emit(j);
            });
        }, 300);
    }
    disconnect() {
        clearInterval(this.connection);
        this.connection = undefined;
    }
    getNotifications() {
        return this.notifiRecived;
    }
    getAlerts() {
        return this.alertsRecived;
    }
    getNotificationsQuantity(sslgId) {
        return this.http.get(this.config.quantity(sslgId));
    }
    alertNotificationsWatched(id) {
        return this.http.post(this.config.setWatched, { id: id });
    }
    getNotification(sslgId, notificationId) {
        /*return this.http.get<Alert>('/api/audit-log/' + sslgId + '/read/' + notificationId).pipe(map(j => {
            if (j.content) {
                Object.keys(j.content).forEach(i => {
                    if (j.content[i]) {
                        j.content[i] = JSON.parse(j.content[i]);
                    }
                });
            }
            return j;
        }));*/
        return this.http.get('/api/claimant/' + sslgId + '/audit-log/read/' + notificationId).pipe(map(j => {
            if (j.content) {
                Object.keys(j.content).forEach(i => {
                    if (j.content[i]) {
                        j.content[i] = JSON.parse(j.content[i]);
                    }
                });
            }
            return j;
        }));
    }
    setAlertsQtyChanged() {
        this.alertsQtyChanged.next(Math.round(+new Date() / 1000) + Math.random());
    }
}
NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.HttpClient)); }, token: NotificationService, providedIn: "root" });
