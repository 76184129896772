import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// import { NgSlimScrollModule } from 'ngx-slimscroll';
import { NgDatepickerComponent } from '../component/ng-datepicker.component';
import {PickerComponent} from "../picker/picker.component";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [ NgDatepickerComponent,PickerComponent ],
  imports: [ CommonModule, FormsModule, MatDialogModule ],
  exports: [ NgDatepickerComponent, CommonModule, FormsModule ],
  entryComponents: [
    PickerComponent
  ],
})
export class NgDatepickerModule { }
