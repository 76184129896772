import {Component, forwardRef, Host, Inject, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {AbstractControl,FormArray, FormControl, FormGroup, Validators,FormBuilder} from '@angular/forms';
import {DynamicFormService, DynamicSelectModel,} from '@ng-dynamic-forms/core';

import {ActivatedRoute, Router} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {DateService} from "../../../../../../@core/functions/date.service";
import {UserInfoService} from "../../../../../../@core/user-info.service";
import {NotificationConfirmationsComponent} from "../../../../../notification/confirmations/notification-confirmations.component";
import {MatDialog} from "@angular/material";
import {State} from "../../../../../../test-new-stepper-form/test-form/on-changes";
import {DialogService} from "../../../../../../../@util/dialog/dialog.service";
import {MedicalSourcesService} from "../../../../../../@core/medical-sources.service";
import {PhoneService} from "../../../../../../@core/functions/phone.service";
import {GlobalSaveService} from "../../../../../../@core/global-save.service";

export interface RequestTableData {
	from: Date;
	to: Date;
	dateRequested: Date;
	ssaLavel: any;
	fu: Date;
	qsRfc1: String;
	qsRfc2: String;
	qsRfc3: String;
	fu2: Date;
	fu3: Date;
	nextFu: Date;
	nextRcvd: Date;
	submittedSsa: Date;
	confirmedSsa: Date;
	ssaEreFile: Date;
	name: String;
	phone: String;
	press: String;
	ext: String;
}

@Component({
	selector: 'app-medical-requests-form',
	templateUrl: './medical-requests-form.component.html',
	styleUrls: ['./medical-requests-form.component.scss']
})
export class MedicalRequestsFormComponent implements OnInit, OnDestroy {
	@Input() pageType;
	@Input() contacts;

	@Output() backToRequestsList = new EventEmitter<boolean>();

	requestData: RequestTableData[] = [ {
		from: null,
		to: null,
		dateRequested: null,
		ssaLavel: null,
		fu: null,
		qsRfc1: null,
		qsRfc2: null,
		qsRfc3: null,
		fu2: null,
		fu3: null,
		nextFu: null,
		nextRcvd: null,
		submittedSsa: null,
		confirmedSsa: null,
		ssaEreFile: null,
		name: null,
		phone: null,
		press: null,
		ext: null
	} ];


	dataSource = new BehaviorSubject<AbstractControl[]>([]);
	requestsFields: FormArray = this.fb.array([]);
	requestsForm: FormGroup = this.fb.group({
		requests:this.requestsFields
	});
	ssaLevels = [
		{id:1,name:"IC"},
		{id:2,name:"Recon"},
		{id:3,name:"HRG"},
		{id:4,name:"AC"},
		{id:5,name:"FDC"},
	];

	requestId = '';

	constructor(private formService: DynamicFormService,
				private fb: FormBuilder,
				private route: ActivatedRoute,
				private dateService: DateService,
				private dialog: DialogService,
				private matDialog: MatDialog,
				public globalSaveService: GlobalSaveService,
				private medicalSourcesService: MedicalSourcesService,
				public userInfoService: UserInfoService,
				public phoneService: PhoneService,
				) {

	}

	ngOnDestroy(): void {

	}

	ngOnInit() {
		// this.medicalSourcesService.treatingSourcesRequestsList(this.getSslgId(),this.getSourceId()).subscribe(data => {
		// 	console.log("hhhh")
		// 	console.log(data)
		// });
	}

	onChange($event) {

	}

	addById(id){
		this.requestsFields.clear();

		if(id){
			this.medicalSourcesService.treatingSourcesFindRequest(id).subscribe(data => {
				this.requestId = id;

				this.addRequest(data, false);
			});
		}else{
			this.requestId = '0';
			this.medicalSourcesService.treatingSourcesLastUserToRecords(this.getSourceId()).subscribe(data => {
				try {
					let result:any = {
						name:data.name,
						ext:data.ext,
						phone:data.phone,
						press:data.press
					};
					this.addRequest(result, false);
				}catch (e) {
					this.addRequest(null, false);
				}
			});
		}

	}

	getBtnName(){
		if(this.requestId == '0'){
			return 'Add';
		}
		return 'Update';
	}

	init(requests) {
		if (requests == null) {
			this.requestData.forEach((d: RequestTableData) => this.addRequest(d, false));
		} else {
			requests.forEach((d: RequestTableData) => this.addRequest(d, false));
		}
	}

	back(){
		this.backToRequestsList.emit(true);
	}

	save(){
		let requestsPayments = this.requestsForm.value;

		for(var i=0;i<requestsPayments.requests.length;i++){
			requestsPayments.requests[i].from = this.dateService.getDateWithoutHours(requestsPayments.requests[i].from);
			requestsPayments.requests[i].to = this.dateService.getDateWithoutHours(requestsPayments.requests[i].to);
			requestsPayments.requests[i].dateRequested = this.dateService.getDateWithoutHours(requestsPayments.requests[i].dateRequested);
			requestsPayments.requests[i].fu = this.dateService.getDateWithoutHours(requestsPayments.requests[i].fu);
			requestsPayments.requests[i].fu2 = this.dateService.getDateWithoutHours(requestsPayments.requests[i].fu2);
			requestsPayments.requests[i].fu3 = this.dateService.getDateWithoutHours(requestsPayments.requests[i].fu3);
			requestsPayments.requests[i].nextFu = this.dateService.getDateWithoutHours(requestsPayments.requests[i].nextFu);
			requestsPayments.requests[i].nextRcvd = this.dateService.getDateWithoutHours(requestsPayments.requests[i].nextRcvd);
			requestsPayments.requests[i].submittedSsa = this.dateService.getDateWithoutHours(requestsPayments.requests[i].submittedSsa);
			requestsPayments.requests[i].confirmedSsa = this.dateService.getDateWithoutHours(requestsPayments.requests[i].confirmedSsa);
			requestsPayments.requests[i].ssaEreFile = this.dateService.getDateWithoutHours(requestsPayments.requests[i].ssaEreFile);
		}

		// if (requestsPayments.requests[0].from === null) {
		// 	this.dialog.show("For saving information, please fill 'From' and 'To' fields at least!", ["ok"]);
		// 	return;
		// }
		//
		// if (requestsPayments.requests[0].to === null) {
		// 	this.dialog.show("Please, fill 'To' field!", ["ok"]);
		// 	return;
		// } else {
			this.medicalSourcesService.saveTreatingSourcesRequest(this.getSslgId(),this.requestId,this.getSourceId(), requestsPayments.requests[0]).subscribe(data => {
				this.dialog.show("Information was saved successfully!", ["ok"]);

				this.back();
			});
		// }



		// this.medicalSourcesService.saveTreatingSourcesRequests(this.getSslgId(),this.getSourceId(), requestsPayments.requests).subscribe(data => {
		// 	this.dialog.show("Information was saved successfully!", ["ok"]);
		// });
	}

	getSourceId(){
		return this.route.snapshot.paramMap.get('id');
	}

	getSslgId(){
		return this.route.snapshot.parent.params['sslgId'];
	}

	getData(){
		let requestsPayments = this.requestsForm.value;

		for(var i=0;i<requestsPayments.requests.length;i++){
			requestsPayments.requests[i].from = this.dateService.getDateWithoutHours(requestsPayments.requests[i].from);
			requestsPayments.requests[i].to = this.dateService.getDateWithoutHours(requestsPayments.requests[i].to);
			requestsPayments.requests[i].dateRequested = this.dateService.getDateWithoutHours(requestsPayments.requests[i].dateRequested);
			requestsPayments.requests[i].fu = this.dateService.getDateWithoutHours(requestsPayments.requests[i].fu);
			requestsPayments.requests[i].fu2 = this.dateService.getDateWithoutHours(requestsPayments.requests[i].fu2);
			requestsPayments.requests[i].fu3 = this.dateService.getDateWithoutHours(requestsPayments.requests[i].fu3);
			requestsPayments.requests[i].nextFu = this.dateService.getDateWithoutHours(requestsPayments.requests[i].nextFu);
			requestsPayments.requests[i].nextRcvd = this.dateService.getDateWithoutHours(requestsPayments.requests[i].nextRcvd);
			requestsPayments.requests[i].submittedSsa = this.dateService.getDateWithoutHours(requestsPayments.requests[i].submittedSsa);
			requestsPayments.requests[i].confirmedSsa = this.dateService.getDateWithoutHours(requestsPayments.requests[i].confirmedSsa);
			requestsPayments.requests[i].ssaEreFile = this.dateService.getDateWithoutHours(requestsPayments.requests[i].ssaEreFile);
		}

		return requestsPayments.requests;
	}

	addRequest(d?: RequestTableData, noUpdate?: boolean) {
		const row = this.fb.group({
			'from':[d && d.from ? new Date(d.from) : null, []],
			'to':[d && d.to   ? new Date(d.to)   : null, []],
			'dateRequested':[d && d.dateRequested   ? new Date(d.dateRequested)   : null, []],
			'ssaLavel':[d && d.ssaLavel   ? parseInt(d.ssaLavel)   : null, []],
			'fu':[d && d.fu   ? new Date(d.fu)   : null, []],
			'qsRfc1':[d && d.qsRfc1   ? d.qsRfc1   : null, []],
			'qsRfc2':[d && d.qsRfc2   ? d.qsRfc2   : null, []],
			'qsRfc3':[d && d.qsRfc3   ? d.qsRfc3   : null, []],
			'fu2':[d && d.fu2   ? new Date(d.fu2)   : null, []],
			'fu3':[d && d.fu3   ? new Date(d.fu3)   : null, []],
			'nextFu':[d && d.nextFu   ? new Date(d.nextFu)   : null, []],
			'nextRcvd':[d && d.nextRcvd   ? new Date(d.nextRcvd)   : null, []],
			'submittedSsa':[d && d.submittedSsa   ? new Date(d.submittedSsa)   : null, []],
			'confirmedSsa':[d && d.confirmedSsa   ? new Date(d.confirmedSsa)   : null, []],
			'ssaEreFile':[d && d.ssaEreFile   ? new Date(d.ssaEreFile)   : null, []],
			'name':[d && d.name   ? d.name   : null, []],
			'phone':[d && d.phone   ? d.phone   : null, []],
			'press':[d && d.press   ? d.press   : null, []],
			'ext':[d && d.ext   ? d.ext   : null, []]
		});
		this.requestsFields.push(row);
	}

	deleteRequest(index){
		let dialog = this.matDialog.open(NotificationConfirmationsComponent,{
			data:{
				message: "Are you sure you want to delete the Request?"
			}
		});
		dialog.afterClosed().subscribe(j => {
			try {
				if (j.status) {
					this.requestsFields.removeAt(index);
				}
			} catch (e) {

			}
		});

	}

	requestsShowDeleteBtn(){
		let count = this.requestsFields.length;
		if(count == 1){
			return false;
		}

		return true;
	}

	clearDate(form,field:any,index){
		if (this.isViewing()) {
			return;
		}
		this.requestsFields.controls[index].value[field] = null;
		this.requestsFields.controls[index].get(field).reset();
	}

	onBlur($event) {
	}

	onFocus($event) {
	}

	getContactName(contacts){
		try {
			return contacts.name;
		}catch (e) {
			return '';
		}
	}
	getContactFax(contacts){
		try {
			return this.phoneService.format(contacts.fax);
		}catch (e) {
			return '';
		}
	}

	isViewing() {
		if (this.globalSaveService.getStatusTrue()) {
			return false;
		}
		return true;
	}
}
