/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./google-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../partial/forms/form-frame/form-frame.component.ngfactory";
import * as i3 from "../partial/forms/form-frame/form-frame.component";
import * as i4 from "./google-map.component";
import * as i5 from "@angular/material/dialog";
import * as i6 from "../../../@util/dialog/dialog.service";
var styles_GoogleMapComponent = [i0.styles];
var RenderType_GoogleMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GoogleMapComponent, data: {} });
export { RenderType_GoogleMapComponent as RenderType_GoogleMapComponent };
export function View_GoogleMapComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { gmap: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "app-frame", [["autocomplete", "off"], ["class", "summaries-frame"]], null, [[null, "neadClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("neadClose" === en)) {
        var pd_0 = (_co.closeForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormFrameComponent_0, i2.RenderType_FormFrameComponent)), i1.ɵdid(2, 114688, null, 0, i3.FormFrameComponent, [], { title: [0, "title"], autocomplete: [1, "autocomplete"], overlay: [2, "overlay"] }, { neadClose: "neadClose" }), (_l()(), i1.ɵted(-1, 0, ["\n\n\t"])), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["mapContainer", 1]], 0, 0, "div", [["id", "map"], ["style", "width: 60vw; height: 80vh;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, 0, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var currVal_0 = "Map"; var currVal_1 = "off"; var currVal_2 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_GoogleMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-google-map", [], null, null, null, View_GoogleMapComponent_0, RenderType_GoogleMapComponent)), i1.ɵdid(1, 4308992, null, 0, i4.GoogleMapComponent, [i5.MatDialogRef, i6.DialogService, i5.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GoogleMapComponentNgFactory = i1.ɵccf("app-google-map", i4.GoogleMapComponent, View_GoogleMapComponent_Host_0, {}, {}, []);
export { GoogleMapComponentNgFactory as GoogleMapComponentNgFactory };
