import {Injectable,ViewChild} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DIARIES_CONFIG} from "../@config/api";
import {UserInfoService} from "./user-info.service";


@Injectable({
	providedIn: 'root'
})
export class DiariesService {
	private config = DIARIES_CONFIG;

	constructor(
		private http: HttpClient,
		private userInfoService: UserInfoService,
		) {
	}

	getList(type, user?){
		let userName = user?user:this.getUserName();

		return this.http.post<any>(this.config.getList,{
			userName:userName,
			type:type
		});
	}

	getUserName(){
		return this.userInfoService.getCurrentUserName();
	}
}

