import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DiariesService } from "../../../../../../@core/diaries.service";
import { CompleteFollowUpComponent } from '../../../../../pages/claimants/follow-ups/pending/complet-follow-up/complete-follow-up.component';
import { ReferralService } from "../../../../../../@core/referral.service";
import { DateService } from "../../../../../../@core/date.service";
import { GlobalSaveService } from '../../../../../../@core/global-save.service';
import { DATE_FORMAT } from "../../../../../../@config/dateFormat";
import { MatTableDataSource } from '@angular/material/table';
import { PERMISSIONS } from 'src/app/@config/permissions';
export class DiariesComponent {
    constructor(router, route, diariesService, dialog, referralService, dateService, globalSaveService) {
        this.router = router;
        this.route = route;
        this.diariesService = diariesService;
        this.dialog = dialog;
        this.referralService = referralService;
        this.dateService = dateService;
        this.globalSaveService = globalSaveService;
        this.DATE_FORMAT = DATE_FORMAT;
        this.title = "Diaries";
        this.diaries = [];
        this.overDueDiaries = [];
        this.followUpResult = [];
        this.canScrollLeft = false;
        this.canScrollRight = true;
        this.PERMISSIONS = PERMISSIONS;
        this.dataSource = new MatTableDataSource([]);
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.dataSource.paginator = this.paginator;
            this.loadDiaries();
        });
    }
    loadDiaries() {
        this.route.paramMap.subscribe((j) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let typeRes = 'pending';
            this.diariesService.getList(typeRes, this.username).subscribe(data => {
                this.diaries = JSON.parse(data.message);
                this.sortItemsArrayByZIndex();
                this.dataSource.data = this.diaries;
                this.data = this.dataSource.connect();
                if (this.route.snapshot.queryParamMap.get('page')) {
                    this.setPageData(+this.route.snapshot.queryParamMap.get('page'));
                }
            });
        }));
    }
    edit(item) {
        const url = this.getUrl();
        this.router.navigate([`claimants/${item.sslgId}/follow-ups/pending/${item.id}/edit`], { queryParams: { returnUrl: url, page: this.paginator.pageIndex } });
    }
    toClaimantRecord(item) {
        const url = this.getUrl();
        this.router.navigate([`claimants/record/${item.sslgId}`], { queryParams: { returnUrl: url, page: this.paginator.pageIndex } });
    }
    setComplete(item, $event) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const dialogRef = this.dialog.open(CompleteFollowUpComponent, {
                position: {
                // top: $event.pageY + 'px',
                // left: 'calc(' + $event.pageX + 'px - 35%)'
                },
                data: item,
                backdropClass: 'overlay',
                panelClass: 'mobile-popup',
                minWidth: '35%',
                minHeight: '25%'
            });
            const dialogRez = yield dialogRef.afterClosed().toPromise();
            if (dialogRez) {
                item.completedDate = this.dateService.convertDateWithTimezone(dialogRez.completeDate);
                item.completedBy = dialogRez.completedBy;
                item.createDate = this.dateService.convertDateWithTimezone(item.createDate);
                item.dueDate = this.dateService.convertDateWithTimezone(item.dueDate);
                item.editedDate = this.dateService.convertDateWithTimezone(item.editedDate);
                yield this.referralService.setFollowUpCompleted(item).toPromise();
                this.loadDiaries();
            }
        });
    }
    getUrl() {
        return this.router.url.indexOf('?') !== -1 ?
            this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
    }
    setPageData(pageNum) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.paginator.pageIndex = pageNum;
            this.data.next(this.dataSource._pageData(this.diaries));
        });
    }
    getClaimentName(data) {
        return data.claiment.firstName + ' ' + data.claiment.lastName;
    }
    getFollowUp(data) {
        return data.followUpResult;
    }
    getFollowUpDays(data) {
        let daysBetweenCompleted = data.daysBetweenCompleted;
        let daysBetweenCurrent = data.daysBetweenCurrent;
        let completedBy = data.completedBy;
        let days = daysBetweenCurrent;
        if (completedBy != null) {
            days = daysBetweenCompleted;
        }
        if (days < 0) {
            days = days * -1;
        }
        return days;
    }
    getStatusName(data) {
        return data.claiment.primaryStatus;
    }
    getStatus2Name(data) {
        return data.claiment.secondaryStatus;
    }
    sortItemsArrayByZIndex() {
        this.overDueDiaries = [...this.diaries];
        for (var i = 0; i < this.overDueDiaries.length; i++) {
            let followUp = this.overDueDiaries[i].followUp;
            let pendingDayMin = 0;
            let comletedDayMin = 0;
            var pending = [];
            var comleted = [];
            for (let p = 0; p < followUp.length; p++) {
                let completedBy = null;
                try {
                    completedBy = followUp[p].completedBy;
                }
                catch (e) { }
                let daysBetweenCurrent = 0;
                try {
                    daysBetweenCurrent = parseInt(followUp[p].daysBetweenCurrent);
                }
                catch (e) { }
                let daysBetweenCompleted = parseInt(followUp[p].daysBetweenCompleted);
                if (completedBy == null) {
                    pending.push(followUp[p]);
                    if (daysBetweenCurrent < pendingDayMin) {
                        pendingDayMin = daysBetweenCurrent;
                    }
                }
                else {
                    comleted.push(followUp[p]);
                    if (daysBetweenCompleted < comletedDayMin) {
                        comletedDayMin = daysBetweenCompleted;
                    }
                }
            }
            pending.sort((i1, i2) => {
                return parseInt(i2.daysBetweenCurrent) - parseInt(i1.daysBetweenCurrent);
            });
            pending.reverse();
            comleted.sort((i1, i2) => {
                return parseInt(i1.daysBetweenCompleted) - parseInt(i2.daysBetweenCompleted);
            });
            this.overDueDiaries[i].pendingDayMin = pendingDayMin;
            this.overDueDiaries[i].comletedDayMin = comletedDayMin;
            let merge = [...pending, ...comleted];
            const filtered = this.filterOverDueDiaries(merge);
            if (filtered.length) {
                this.overDueDiaries[i].followUpResult = filtered;
            }
            else {
                this.overDueDiaries.splice(i, 1);
                i = i - 1;
            }
        }
        this.overDueDiaries.sort((i1, i2) => {
            return parseInt(i1.pendingDayMin) - parseInt(i2.pendingDayMin);
        });
        this.diaries = this.overDueDiaries;
    }
    getIndicatorColor(data) {
        let daysBetweenCompleted = data.daysBetweenCompleted;
        let daysBetweenCurrent = data.daysBetweenCurrent;
        let completedBy = data.completedBy;
        let days = daysBetweenCurrent;
        if (completedBy != null) {
            days = daysBetweenCompleted;
        }
        let indicatorColor;
        if (days < 0) {
            indicatorColor = 'red';
        }
        else if (days >= 0 && days <= 4) {
            indicatorColor = 'yellow';
        }
        else {
            indicatorColor = 'green';
        }
        return indicatorColor;
    }
    tableScrolled($event) {
        const scrollLeft = $event.target.scrollLeft;
        const scrollWidth = $event.target.scrollWidth;
        const offsetWidth = $event.target.offsetWidth;
        if (scrollLeft > 0) {
            this.canScrollLeft = true;
        }
        else {
            this.canScrollLeft = false;
        }
        if (scrollWidth - scrollLeft > offsetWidth) {
            this.canScrollRight = true;
        }
        else {
            this.canScrollRight = false;
        }
    }
    getDate(date) {
        this.dateService.convertDateWithTimezone(date);
    }
    filterOverDueDiaries(data) {
        return data.filter((item) => this.getIndicatorColor(item) == 'red');
    }
}
