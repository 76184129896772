<div class="report-wrapper" #element>
	<div class="report-title hospitals">
		Visit Summaries From SSLG
	</div>

	<table class="report-table">
		<tr>
			<td>Visit From</td>
			<td>{{tableData.visit.visitDate ? (tableData.visit.visitDate | date:'MM/dd/yyyy') : '--/--/----'}}</td>
		</tr>
		<tr>
			<td>Source Name</td>
			<td>{{tableData.catalogItem.name}}</td>
		</tr>
		<tr>
			<td>ER visit</td>
			<td>{{tableData.visit.eRVisit ? 'YES' : 'NO'}} <span *ngIf="tableData.visit.eRVisit">{{',' +  tableData.visit.eRVisitDate ? (tableData.visit.eRVisitDate | date:'MM/dd/yyyy'): '--/--/----'}}</span></td>
		</tr>
		<tr>
			<td>Outpatient:</td>
			<td>{{tableData.visit.outPatient ? 'YES' : 'NO'}} <span *ngIf="tableData.visit.outPatient">{{',' +  tableData.visit.outPatientDate ? (tableData.visit.outPatientDate | date:'MM/dd/yyyy'): '--/--/----'}}</span></td>
		</tr>
		<tr>
			<td class="d-flex align-items-center">Inpatient:</td>
			<td class="d-flex align-items-center">
				{{tableData.visit.inPatient ? 'YES' : 'NO'}}
				<div style="margin-left: 15px" *ngIf="tableData.visit.inPatient">
					<p>
						Admission
						<span
							*ngIf="tableData.visit.inPatient">{{tableData.visit.inPatientAdmission ? (tableData.visit.inPatientAdmission | date:'MM/dd/yyyy') : '--/--/----'}}</span>
					</p>
					<p>
						Discharge
						<span
							*ngIf="tableData.visit.inPatient">{{tableData.visit.inPatientDischarge ? (tableData.visit.inPatientDischarge | date:'MM/dd/yyyy') : '--/--/----'}}</span>
					</p>
				</div>
			</td>
		</tr>
		<tr>
			<td>
				Reason for Hospitalization
			</td>
			<td>
				{{tableData.visit.hospitalizationReason}}
			</td>
		</tr>
		<tr>
			<td>Medical Conditions or Diagnoses</td>
			<td>{{tableData.visit.medicalConditions}}</td>
		</tr>
		<tr>
			<td>Details of Treatment</td>
			<td>{{tableData.visit.treatmentDetails}}</td>
		</tr>
		<tr>
			<td>Doctors Treated By</td>
			<td>{{tableData.visit.treatedDoctor}}</td>
		</tr>

		<tr *ngIf="tableData.visit.medicalTest">
			<td rowspan="2">
				<app-pdf-view-blocks-medical-test [tableData]="tableData.visit.medicalTest"></app-pdf-view-blocks-medical-test>
			</td>
		</tr>

		<tr *ngIf="tableData.medications.length > 0">
			<td colspan="2">
				<app-pdf-view-blocks-medications [tableData]="tableData.medications"></app-pdf-view-blocks-medications>
			</td>
		</tr>
	</table>
</div>

