import {Component, forwardRef, Host, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl,FormArray, FormControl, FormGroup, Validators,FormBuilder} from '@angular/forms';
import {
	DynamicFormArrayModel,
	DynamicFormControlLayout,
	DynamicFormControlModel,
	DynamicFormService, DynamicSelectModel,
} from '@ng-dynamic-forms/core';

import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs';
import {Summary} from "../../../../../@models/referral/summary";
import {DateService} from "../../../../../@core/functions/date.service";
import {UserInfoService} from "../../../../../@core/user-info.service";
import {NotificationConfirmationsComponent} from "../../../../notification/confirmations/notification-confirmations.component";
import {MatDialog, MatPaginator, MatTableDataSource} from "@angular/material";
import {MedicalPaymentsFormComponent} from "./medical-payments-form/medical-payments-form.component";
import {SourcesPayments} from "../../../../../@models/medical/sources-payments";
import {DialogService} from "../../../../../../@util/dialog/dialog.service";
import {GlobalSaveService} from "../../../../../@core/global-save.service";
import {MedicalSourcesService} from "../../../../../@core/medical-sources.service";

@Component({
	selector: 'app-medical-payments',
	templateUrl: './medical-payments.component.html',
	styleUrls: ['./medical-payments.component.scss']
})
export class MedicalPaymentsComponent implements OnInit, OnDestroy {
	@ViewChild(MedicalPaymentsFormComponent, {static: false}) medicalPaymentsFormComponent: MedicalPaymentsFormComponent;

	@ViewChild(MatPaginator, {static: true})
	paginator: MatPaginator;
	dataSource: MatTableDataSource<SourcesPayments>;

	canScrollLeft = false;
	canScrollRight = true;

	data: Subject<SourcesPayments[]>;
	payments: SourcesPayments[];

	constructor(private formService: DynamicFormService,
				private fb: FormBuilder,
				private route: ActivatedRoute,
				private dateService: DateService,
				private dialog: DialogService,
				private matDialog: MatDialog,
				private router: Router,
				public globalSaveService: GlobalSaveService,
				private medicalSourcesService: MedicalSourcesService,
				public userInfoService: UserInfoService,
	) {

		this.dataSource = new MatTableDataSource([]);
	}

	ngOnDestroy(): void {

	}

	ngOnInit() {
		this.dataSource.paginator = this.paginator;

		this.loadPayments();
	}

	loadPayments(){
		this.medicalSourcesService.treatingSourcesPaymentsList(this.getSslgId(),this.getSourceId()).subscribe((data:SourcesPayments[]) => {
			this.payments = data;

			this.loadData();
		});
	}

	loadData(){
		this.dataSource.data = this.payments;
		this.data = this.dataSource.connect();
		if (this.route.snapshot.queryParamMap.get('page')) {
			this.paginator.pageIndex = +this.route.snapshot.queryParamMap.get('page');
			this.data.next(this.dataSource._pageData(this.payments));
		}
	}

	addPayment(){
		this.hideBlocks();

		document.getElementById('block-tab-requests-payment').style.display = '';

		this.medicalPaymentsFormComponent.addById(null);
	}

	edit(data){
		this.hideBlocks();

		document.getElementById('block-tab-requests-payment').style.display = '';

		this.medicalPaymentsFormComponent.addById(data.id);
	}

	view(data) {
		this.hideBlocks();

		document.getElementById('block-tab-requests-payment').style.display = '';

		this.medicalPaymentsFormComponent.addById(data.id);
	}

	backToPaymentsList(event){
		this.hideBlocks();

		document.getElementById('block-tab-payments-list').style.display = '';

		this.loadPayments();
	}

	hideBlocks(){
		document.querySelectorAll('.blocks-tab-payments').forEach((el:any) => {
			el.style.display = 'none';
		});
	}

	delete(item,index) {
		let dialog = this.matDialog.open(NotificationConfirmationsComponent, {
			data: {
				message: "Are you sure you want to delete the payment?"
			}
		});
		dialog.afterClosed().subscribe(j => {
			try {
				if (j.status) {
					this.medicalSourcesService.deleteSourcePayment(item.id).subscribe(() => {
						let itemIndex = index + this.paginator.pageSize * this.paginator.pageIndex;
						this.payments.splice(itemIndex, 1);
						this.loadData();
					});
				}
			} catch (e) {

			}
		});
	}

	onChange($event) {

	}

	tableScrolled($event) {
		const scrollLeft = $event.target.scrollLeft;
		const scrollWidth = $event.target.scrollWidth;
		const offsetWidth = $event.target.offsetWidth;

		if (scrollLeft > 0) {
			this.canScrollLeft = true;
		} else {
			this.canScrollLeft = false;
		}

		if (scrollWidth - scrollLeft > offsetWidth) {
			this.canScrollRight = true;
		} else {
			this.canScrollRight = false;
		}
	}

	getSourceId(){
		return this.route.snapshot.paramMap.get('id');
	}

	getSslgId(){
		return this.route.snapshot.parent.params['sslgId'];
	}

	isClaimant() {
		if(this.userInfoService.getCurrentUserRole() == 'CLIENT'){
			return true;
		}
		return false;
	}

	isSystem(){
		return this.userInfoService.getCurrentUserNameIsSystem();
	}

	getUrl() {
		return this.router.url.indexOf('?') !== -1 ?
			this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
	}

	getTableHeadClass() {
		if (this.isSystem() || this.isClaimant()) {
			return 'i-btn-2';
		} else {
			return 'i-btn-0';
		}
	}

	isShowBtn(){
		if(this.globalSaveService.getStatusTrue()){
			return true;
		}
		return false;
	}
}
