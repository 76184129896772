import {Injectable} from '@angular/core';
import {FileUploadingService} from '../../@util/file-uploading-progress/file-uploading.service';
import {Observable} from 'rxjs';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {MedicalRequest} from "../@models/request";
import {MEDICAL_RECORDS_CONFIG} from "../@config/api";

@Injectable({
	providedIn: 'root'
})
export class MedicalRecordService {
	config = MEDICAL_RECORDS_CONFIG;
	constructor(private fileUploadin: FileUploadingService, private http: HttpClient) {
	}

	// pushFileToStorage(data: { file: File, fileInfo: { title: string, category: string, description: string, sslgId: string } }) {
	//   this.fileUploadin.uploadFile('/api/claimant/medical-record', data);
	// }
	// uploadEnded(): Observable<any> {
	//   return this.fileUploadin.uploadEnded;
	// }
	//
	// getDocuments(sslgId: any, title: string) {
	//   return this.fileUploadin.getDocuments('/api/claimant/medical-record/' + sslgId, title);
	// }
	//
	// isExist(sslgId: string, name: string) {
	//   return this.fileUploadin.isExist('/api/claimant/medical-record/' + sslgId + '/exist/' + name);
	// }
	//
	//
	// getTitlesForClaim(sslgId: string) {
	//   return this.fileUploadin.getTitlesForClaim('/api/claimant/medical-record/' + sslgId + '/titles');
	// }

	getVisitsInformation(sslgId: any, catalogItemId) {
		return this.http.get(this.config.getVisitsInformation(sslgId, catalogItemId));
	}

	getRequestsByType(sslgId: any, requestType: string) {
		if (requestType === 'SSA') {
			return null;
		}
		if (requestType !== 'ALL') {
			return this.http.get<MedicalRequest[]>(this.config.getRequestsByTypeOne(sslgId, requestType));
		} else {
			return this.http.get<MedicalRequest[]>(this.config.getRequestsByTypeAll(sslgId));
		}
	}

	createRequest(sslgId: string, $event: any) {
		return this.http.post<any>(this.config.createRequest(sslgId), $event);
	}

	downloadDocument(sslgId, documentId: number) {
		return this.fileUploadin.downloadDocument(this.config.downloadDocument(sslgId, documentId));
	}

	getRequestById(sslgId, requestId: number) {
		return this.http.get<any>(this.config.getRequestById(sslgId, requestId));
	}

	getUploadedDocument(sslgId, documentId) {
		return this.http.get<any>(this.config.getUploadedDocument(sslgId, documentId));
	}

	sendFile(sslgId, requestId: any, file: {
		file: File,
		type: string,
		uploadedBy: string
	}) {
		const formData: FormData = new FormData();
		if (file === undefined || file === null) {
			return;
		}
		formData.append('file', file.file);
		formData.append('fileName', file.file.name);
		formData.append('type', file.type);
		formData.append('uploadedBy', file.uploadedBy);

		return this.http.post<any>(this.config.sendDocument(sslgId, requestId),formData);
	}

	uploadFile(sslgId, requestId: any, file: {
		file: File,
		type: string,
		uploadedBy: string
	}) {
		const formData: FormData = new FormData();
		if (file === undefined || file === null) {
			return;
		}
		formData.append('file', file.file);
		formData.append('type', file.type);
		formData.append('uploadedBy', file.uploadedBy);
		const req = new HttpRequest('POST', this.config.uploadFile(sslgId, requestId), formData, {
			reportProgress: true,
			responseType: 'text'
		});
		this.fileUploadin.show(0, file.file.name);
		return this.http.request(req);
	}

	getDocTypes(sslgId) {
		return this.http.get<any>(this.config.getDocTypes(sslgId));
	}

	getDocumentById(sslgId, docId) {
		return this.http.get<any>(this.config.getDocumentById(sslgId, docId));
	}

	deleteDocument(sslgId, docId) {
		return this.http.post<any>(this.config.deleteDocument(sslgId, docId),{});
	}

	updateDocument(sslgId, docId, value) {
		return this.http.post<any>(this.config.updateDocument(sslgId, docId), value);
	}

	changeDocumentStatus(sslgId, id, status) {
		return this.http.post<any>(this.config.changeDocumentStatus(sslgId, id), status);
	}



	getSSADocument(sslgId: any) {
		return this.http.get<any[]>(this.config.getSSADocument(sslgId));
	}

	changeSSADocumentStatus(sslgId, id, status) {
		return this.http.post<any>(this.config.changeSSADocumentStatus(sslgId, id), status);
	}

	getDocSSATypes(sslgId) {
		return this.http.get<any>(this.config.getDocSSATypes(sslgId));
	}

	downloadSSADocument(sslgId, id: number) {
		return this.fileUploadin.downloadDocument(this.config.downloadSSADocument(sslgId, id));
	}

	uploadSSAFile(sslgId: any, file: {
		file: File,
		type: string,
		uploadedBy: string
	}) {
		const formData: FormData = new FormData();
		if (file === undefined || file === null) {
			return;
		}
		formData.append('file', file.file);
		formData.append('type', file.type);
		formData.append('uploadedBy', file.uploadedBy);
		const req = new HttpRequest('POST', this.config.uploadSSAFile(sslgId), formData, {
			reportProgress: true,
			responseType: 'text'
		});
		this.fileUploadin.show(0, file.file.name);
		return this.http.request(req);
	}

	updateDocumentSsa(sslgId, docId, value) {
		return this.http.post<any>(this.config.updateDocumentSsa(sslgId, docId), value);
	}

	getDocumentSsaById(sslgId, docId) {
		return this.http.get<any>(this.config.getDocumentSsaById(sslgId, docId));
	}
}
