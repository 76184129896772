import {Contact} from '../contact';


export class DoctorItem {
  id: number;
  sslgId: string;
  name: string;
  practiceName: string;
  treats: string;
  speciality: string;
  type: DoctorType;
  contact: Contact;
  callerName: string;
  callerPhone: string;
  callerPress: string;
  callerExt: string;
  medicalConditions: string;
 visits:{
	 firstSeen:Date,
	 lastSeen:Date,
	 nextVisit:Date,
 }
}

export enum DoctorType {
  PCP = 'PCP',
  SPECIALIST = 'SPECIALIST'
}
