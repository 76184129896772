<div class="overlay">
  <div class="mask">
    <div class="tnfr-modal">

		<app-frame [showCloseButton]="buttons?.length===1" (neadClose)="this.onCloseDialog.emit()">
			<div class="tnfr-modal__content">
				<div class="head" *ngFor="let item of title">
					{{getTitle(item)}}
				</div>
				<button *ngFor="let btn of buttons" class="btn-inp" [ngClass]="{'btn-green': btn === 'OK' }" #enroll (click)="click(btn)">{{btn}}</button>
			</div>
		</app-frame>

    </div>
  </div>
</div>
