import { Component, OnInit} from '@angular/core';
import { LoadingService } from 'src/@util/loading/loading.service';
import {ReferralService} from '../../@core/referral.service';
import {ActivatedRoute} from "@angular/router";
import {Claimant} from "../../@models/system-managment/claimant";
import {MatDialog} from "@angular/material";

@Component({
  selector: 'app-consolidator-report-list',
  templateUrl: './consolidator-report-list.component.html',
  styleUrls: ['./consolidator-report-list.component.scss']
})
export class ConsolidatorReportListComponent implements OnInit {

	claiments: Claimant[];
	displayedColumns: string[] = ['Last Name', 'First Name', 'Status', 'Phone', 'IC Examiner', 'Case ID', 'Direct Dial'];
  	dataSource;
	constructor(private referralService: ReferralService,
        private loading: LoadingService,
				private route: ActivatedRoute,
				public dialog: MatDialog,) {

		this.referralService.setCurrent(null);
	}

	navLinks: ({ path: string; label: string })[];
	ngOnInit() {
		this.route.paramMap.subscribe(async j => {
			this.initDataSource();
		});

	}

	initDataSource() {
		this.referralService.getConsolidator().subscribe(data => {
			this.claiments = data.sort((a, b) => {
				if (a.lastName < b.lastName) {
					return -1;
				}
				if (a.lastName > b.lastName) {
					return 1;
				}
				return 0;
			});
			this.dataSource = this.claiments;
      this.loading.loaded();
		});
	}

	print(){
		window.print();
	}
}
