import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from "../auth/_services/authentication.service";
import { UserInfoService } from "../@core/user-info.service";
import { LoadingService } from "../../@util/loading/loading.service";
import { DialogService } from "../../@util/dialog/dialog.service";
import { DevicesService } from "./devices.service";
import * as i0 from "@angular/core";
import * as i1 from "../../@util/dialog/dialog.service";
import * as i2 from "../auth/_services/authentication.service";
import * as i3 from "./user-info.service";
import * as i4 from "./devices.service";
import * as i5 from "../../@util/loading/loading.service";
export class PhoneService {
    constructor(dialog, ngZone, authenticationService, userInfoService, devicesService, loading) {
        this.dialog = dialog;
        this.ngZone = ngZone;
        this.authenticationService = authenticationService;
        this.userInfoService = userInfoService;
        this.devicesService = devicesService;
        this.loading = loading;
        this.avatarChanged = new BehaviorSubject(null);
        this.showPreloader = new BehaviorSubject(null);
        this.hidePreloader = new BehaviorSubject(null);
    }
    init() {
        window.myPhone = window.myPhone || {};
        window.myPhone.namespace = window.myPhone.namespace || {};
        window.myPhone.namespace.publicFunc = this.publicFunc.bind(this);
    }
    destroy(blockId) {
        window.myPhone.namespace.publicFunc = null;
    }
    publicFunc(json) {
        //this.dialog.show(json, ['OK']);
        if (typeof json === 'string') {
            try {
                json = JSON.parse(json);
            }
            catch (e) {
            }
        }
        if (json.status == "true") {
            // this.loading.loaded(this.loader);
            this.loading.loaded();
            let command = json.command;
            if (command == 'changeLogo') {
                this.avatarChanged.next(Math.round(+new Date() / 1000) + Math.random());
            }
            if (command == 'uploadDocumentMedicalRecords') {
                this.dialog.show(json.message, ['OK']);
            }
            if (command == 'showPreloader') {
                this.loader = this.loading.loading();
            }
            if (command == 'hidePreloader') {
                //this.dialog.show('showPreloader', ['OK']);
                //this.loading.loaded(this.loader);
            }
            if (command == 'firebaseToken') {
                let token = json.token;
                //this.dialog.show(command, ['OK']);
                //this.dialog.show(token, ['OK']);
                this.devicesService.setFirebaseToken(token);
            }
        }
    }
    setNotificationToken() {
        let claimantName = this.userInfoService.getCurrentUserName();
        let token = this.userInfoService.getToken();
        let json = '{"command":"notificationToken","token":"' + token + '","claimantName":"' + claimantName + '"}';
        this.sendCommand(json);
    }
    setChangeAvatar(claimantName) {
        let token = this.userInfoService.getToken();
        let json = '{"command":"changeLogo","token":"' + token + '","claimantName":"' + claimantName + '"}';
        this.sendCommand(json);
    }
    sendMedicalRecords() {
        let token = this.userInfoService.getToken();
        let json = '{"command":"sendMedicalRecords","token":"' + token + '"}';
        this.sendCommand(json);
    }
    uploadDocumentMedicalRecords(claimantName, title) {
        let token = this.userInfoService.getToken();
        let json = '{"command":"uploadDocumentMedicalRecords","token":"' + token + '","claimantName":"' + claimantName + '","data":"' + title + '"}';
        this.sendCommand(json);
    }
    setChangeAvatarInsurance(id) {
        let token = this.userInfoService.getToken();
        let json = '{"command":"changeAvatarInsurance","token":"' + token + '","id":"' + id + '"}';
        this.sendCommand(json);
    }
    setMakeCall(phone) {
        let json = '{"command":"makePhone","phone":"' + phone + '"}';
        this.sendCommand(json);
    }
    setMakeMailTo(email) {
        let json = '{"command":"makeMailTo","email":"' + email + '"}';
        this.sendCommand(json);
    }
    sendCommand(json) {
        if (window.webkit) {
            window.webkit.messageHandlers.phoneListener.postMessage(json);
        }
        try {
            AndroidFunction.getCommand(json);
        }
        catch (e) {
        }
    }
}
PhoneService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PhoneService_Factory() { return new PhoneService(i0.ɵɵinject(i1.DialogService), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.UserInfoService), i0.ɵɵinject(i4.DevicesService), i0.ɵɵinject(i5.LoadingService)); }, token: PhoneService, providedIn: "root" });
