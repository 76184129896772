import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {PromotionsService} from "./promotions.service";

@Injectable({
  providedIn: 'root'
})
export class IrisTableTabsService {
  content = new BehaviorSubject(null);

  constructor(private promotionsService: PromotionsService) {}

  updateContent(data) {
    this.content.next(data);
  }

  requestContent(userId, categoryId) {
    this.updateContent('reset');
    this.promotionsService.getPromotionsCategory(userId, categoryId).subscribe(val => {
      this.updateContent(val);
    });
  }
}
