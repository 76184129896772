/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./claimant-medications-new.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../partial/form-button/form-button.component.ngfactory";
import * as i3 from "../../../../../partial/form-button/form-button.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../../../@util/ui-material/dynamic-material-form.component.ngfactory";
import * as i6 from "../../../../../../../@util/ui-material/dynamic-material-form.component";
import * as i7 from "@ng-dynamic-forms/core";
import * as i8 from "../../../../../partial/form-actions-content/form-actions-content.component.ngfactory";
import * as i9 from "../../../../../partial/form-actions-content/form-actions-content.component";
import * as i10 from "../../../../../partial/forms/form-frame/form-frame.component.ngfactory";
import * as i11 from "../../../../../partial/forms/form-frame/form-frame.component";
import * as i12 from "@angular/common";
import * as i13 from "./claimant-medications-new.component";
import * as i14 from "@angular/router";
import * as i15 from "@angular/material/dialog";
import * as i16 from "../../../../../../@core/medical.service";
var styles_ClaimantMedicationsNewComponent = [i0.styles];
var RenderType_ClaimantMedicationsNewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClaimantMedicationsNewComponent, data: {} });
export { RenderType_ClaimantMedicationsNewComponent as RenderType_ClaimantMedicationsNewComponent };
function View_ClaimantMedicationsNewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "adding-info-block adding-info-block--finish"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "adding-info-block__row adding-info-block__row--wrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "form-button", [["buttonType", "add"], ["class", "adding-info-block__btn"], ["title", "Continue"]], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.addMedication() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormButtonComponent_0, i2.RenderType_FormButtonComponent)), i1.ɵdid(6, 638976, null, 0, i3.FormButtonComponent, [], { buttonType: [0, "buttonType"] }, { clickOnButton: "clickOnButton" }), (_l()(), i1.ɵted(-1, 0, ["\n\t\t\t\tAdd a Medication\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "form-button", [["buttonType", "add"], ["class", "adding-info-block__btn"], ["title", "Continue"]], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.addVisit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormButtonComponent_0, i2.RenderType_FormButtonComponent)), i1.ɵdid(10, 638976, null, 0, i3.FormButtonComponent, [], { buttonType: [0, "buttonType"] }, { clickOnButton: "clickOnButton" }), (_l()(), i1.ɵted(-1, 0, ["\n\t\t\t\tAdd as a part of a visit\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_0 = "add"; _ck(_v, 6, 0, currVal_0); var currVal_1 = "add"; _ck(_v, 10, 0, currVal_1); }, null); }
function View_ClaimantMedicationsNewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 10, "form", [["class", "form-horizontal form-fieldset"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i4.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(4, 540672, null, 0, i4.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i4.ControlContainer, null, [i4.FormGroupDirective]), i1.ɵdid(6, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "dynamic-material-form", [["class", "row"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_DynamicMaterialFormComponent_0, i5.RenderType_DynamicMaterialFormComponent)), i1.ɵdid(9, 49152, null, 1, i6.DynamicMaterialFormComponent, [i7.DynamicFormService, i7.DynamicFormLayoutService], { formGroup: [0, "formGroup"], formModel: [1, "formModel"], formLayout: [2, "formLayout"] }, { change: "change" }), i1.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i1.ɵeld(14, 0, null, null, 6, "app-form-actions-content", [], null, null, null, i8.View_FormActionsContentComponent_0, i8.RenderType_FormActionsContentComponent)), i1.ɵdid(15, 114688, null, 0, i9.FormActionsContentComponent, [], null, null), (_l()(), i1.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i1.ɵeld(17, 0, null, 0, 2, "form-button", [], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.saveMedication() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormButtonComponent_0, i2.RenderType_FormButtonComponent)), i1.ɵdid(18, 638976, null, 0, i3.FormButtonComponent, [], null, { clickOnButton: "clickOnButton" }), (_l()(), i1.ɵted(-1, 0, ["Save"])), (_l()(), i1.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.formGroupTemplate; _ck(_v, 4, 0, currVal_7); var currVal_8 = _co.formGroupTemplate; var currVal_9 = _co.formModelTemplate; var currVal_10 = _co.formLayout; _ck(_v, 9, 0, currVal_8, currVal_9, currVal_10); _ck(_v, 15, 0); _ck(_v, 18, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ClaimantMedicationsNewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "app-frame", [["autocomplete", "off"]], [[2, "is-medication", null]], [[null, "neadClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("neadClose" === en)) {
        var pd_0 = (_co.closeMedicationForm({}) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_FormFrameComponent_0, i10.RenderType_FormFrameComponent)), i1.ɵdid(1, 114688, null, 0, i11.FormFrameComponent, [], { title: [0, "title"], autocomplete: [1, "autocomplete"], overlay: [2, "overlay"], showCloseButton: [3, "showCloseButton"] }, { neadClose: "neadClose" }), (_l()(), i1.ɵted(-1, 0, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ClaimantMedicationsNewComponent_1)), i1.ɵdid(4, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n\n\t"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ClaimantMedicationsNewComponent_2)), i1.ɵdid(7, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; var currVal_2 = "off"; var currVal_3 = false; var currVal_4 = true; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.isInitialStep(); _ck(_v, 4, 0, currVal_5); var currVal_6 = _co.isMedicationStep(); _ck(_v, 7, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMedicationStep(); _ck(_v, 0, 0, currVal_0); }); }
export function View_ClaimantMedicationsNewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-claimant-medications-new", [], null, null, null, View_ClaimantMedicationsNewComponent_0, RenderType_ClaimantMedicationsNewComponent)), i1.ɵdid(1, 245760, null, 0, i13.ClaimantMedicationsNewComponent, [i7.DynamicFormService, i14.ActivatedRoute, i15.MatDialogRef, i15.MAT_DIALOG_DATA, i14.Router, i16.MedicalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClaimantMedicationsNewComponentNgFactory = i1.ɵccf("app-claimant-medications-new", i13.ClaimantMedicationsNewComponent, View_ClaimantMedicationsNewComponent_Host_0, { formKey: "formKey", selectedType: "selectedType", sslgId: "sslgId" }, { formStateChanged: "formStateChanged", allFormStateChanged: "allFormStateChanged", saveThirdStepData: "saveThirdStepData" }, []);
export { ClaimantMedicationsNewComponentNgFactory as ClaimantMedicationsNewComponentNgFactory };
