import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MEDICAL_TREATING_REPORT_TYPE} from "../../pages/claimants/medical-treating-sources/medical-treating-reports/medical-treating-reports.config";

@Component({
	selector: 'app-medical-treating-report-header',
	templateUrl: './medical-treating-report-header.component.html',
	styleUrls: ['./medical-treating-report-header.component.scss']
})
export class MedicalTreatingReportHeaderComponent implements OnInit {
	@ViewChild('element', { static: true })
	public element;

	@Input() sslgId;
	@Input() data;

	constructor() {
	}

	async ngOnInit() {

	}

	isMedicationReport() {
		return this.data.type === MEDICAL_TREATING_REPORT_TYPE.medications;
	}

	isTreatingSourceReport() {
		return this.data.type === MEDICAL_TREATING_REPORT_TYPE.mts;
	}
}
