<div class="view-wrapper">
	<div  class="view-wrapper view-wrapper--no-mobile-padding" #report>
		<div #body class="visit-card">
			<div class="print-button no-print" style="margin-bottom: 20px;">
				<div class="form-buttons">
					<form-button (clickOnButton)="print()">Print</form-button>
				</div>
			</div>
			<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
				<ng-container matColumnDef="Last Name">
				  <th mat-header-cell *matHeaderCellDef> Last Name </th>
				  <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
				</ng-container>

				<ng-container matColumnDef="First Name">
				  <th mat-header-cell *matHeaderCellDef> First Name </th>
				  <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
				</ng-container>

				<ng-container matColumnDef="Status">
				  <th mat-header-cell *matHeaderCellDef> Status </th>
				  <td mat-cell *matCellDef="let element"> {{element.primaryStatus}} </td>
				</ng-container>
			  
				<ng-container matColumnDef="Phone">
				  <th mat-header-cell *matHeaderCellDef> Phone </th>
				  <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
				</ng-container>

				<ng-container matColumnDef="IC Examiner">
					<th mat-header-cell *matHeaderCellDef> IC Examiner </th>
					<td mat-cell *matCellDef="let element"> {{element.icExaminer}} </td>
				</ng-container>

				<ng-container matColumnDef="Case ID">
					<th mat-header-cell *matHeaderCellDef> Case ID </th>
					<td mat-cell *matCellDef="let element"> {{element.icClaimNum}} </td>
				</ng-container>
			  
				<ng-container matColumnDef="Direct Dial">
					<th mat-header-cell *matHeaderCellDef> Direct Dial </th>
					<td mat-cell *matCellDef="let element"> {{element.icExaminerDirectDial}} </td>
				</ng-container>
			  
				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			  </table>
		</div>
	</div>
</div>
  
  