import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core';
import {BaseDialog} from './base-dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit, OnDestroy, BaseDialog {

  title: string;
  buttons: string[];
  onCloseDialog: EventEmitter<any> = new EventEmitter<any>();
  buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() {

  }


  ngOnInit() {

  }

  click(button) {
    this.buttonClicked.emit(button);
    this.onCloseDialog.emit();
  }

  ngOnDestroy(): void {
    this.onCloseDialog.unsubscribe();
  }


}
