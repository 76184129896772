<div class="ngx-datepicker-container mat-calendar">

    <div class="ngx-datepicker-input-container" #ngxInputContainer>
        <div class="input-container">
            <ng-content>

            </ng-content>
            <div class="ngx-datepicker-btns-wrapper">
            <span class="icon-toggle" (click)="toggle(ngxInputContainer)" *ngIf="!hideTrigger">
                <svg class="contain" focusable="false" height="24px" viewBox="0 0 24 24" width="24px"><path class="contain" d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"></path></svg>
            </span>
            </div>
        </div>
        <span class="icon-clear material-icon material-icons" *ngIf="!hideClear" (click)="clearInput()">highlight_off</span>
    </div>

    <!--<app-picker></app-picker>-->

    <!--<div class="ngx-datepicker-calendar-container ngx-datepicker-position-{{position}}"-->
         <!--*ngIf="isOpened" [@enterAnimation] [ngStyle]="{'left': calendarXposition+'px', 'top': calendarYposition+'px'}" #calendarWrapper>-->
        <!--<div class="topbar-wrapper">-->
            <!--<div class="topbar-container">-->
                <!--<div class="topbar-title" (click)="toggleView()">-->
                    <!--<span *ngIf="this.view == 'days'">{{ barTitle }}</span>-->
                    <!--<span *ngIf="this.view == 'years'">{{ minYear }} - {{maxYear}}</span>-->
                    <!--<span *ngIf="this.view == 'month'">{{ selectedYear }}</span>-->
                    <!--<div class="calendar-arrow" [ngClass]="{'toggled': this.view == 'years'}"></div>-->
                <!--</div>-->
                <!--<div class="spacer"></div>-->

                <!--<button class="prev" (click)="prev()">-->
                    <!--<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">-->
<!--<g>-->
	<!--<g>-->
		<!--<polygon points="207.093,30.187 176.907,0 48.907,128 176.907,256 207.093,225.813 109.28,128   "/>-->
	<!--</g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
<!--</svg>-->
                <!--</button>-->
                <!--<button class="next" (click)="next()">-->
                    <!--<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;" xml:space="preserve">-->
<!--<g>-->
	<!--<g>-->
		<!--<polygon points="207.093,30.187 176.907,0 48.907,128 176.907,256 207.093,225.813 109.28,128   "/>-->
	<!--</g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
                        <!--<g>-->
<!--</g>-->
<!--</svg>-->
                <!--</button>-->
            <!--</div>-->
        <!--</div>-->
        <!--<div class="main-calendar-container main-calendar-container_days" *ngIf="view === 'days'">-->
            <!--<div class="main-calendar-day-names">-->
                <!--<span class="day-name-unit" *ngFor="let name of dayNames">{{ name[0] }}</span>-->
            <!--</div>-->
            <!--<div class="main-calendar-days">-->
                <!--<span class="day-unit" *ngFor="let day of days; let i = index;"-->
                      <!--[ngClass]="{ 'is-prev-month': !day.inThisMonth, 'is-today': day.isToday, 'is-selected': day.isSelected, 'is-disabled': !day.isSelectable }"-->
                      <!--(click)="day.isSelectable && setDate(i)">-->
                  <!--<i>{{ day.day }}</i>-->
                <!--</span>-->
            <!--</div>-->
        <!--</div>-->
        <!--<div class="main-calendar-container main-calendar-container_years" *ngIf="view === 'years'">-->
            <!--<div class="main-calendar-years">-->
                    <!--<span class="year-unit contain" *ngFor="let year of years; let i = index;"-->
                          <!--[ngClass]="{ 'is-selected': year.isThisYear, 'current-year': year.isCurrentYear }" (click)="setYear(i)">-->
                        <!--<i class="contain">{{ year.year }}</i>-->
                    <!--</span>-->
            <!--</div>-->
        <!--</div>-->
        <!--<div class="main-calendar-container main-calendar-container_month" *ngIf="view === 'month'">-->
            <!--<div class="main-calendar-month">-->
                    <!--<span class="month-unit contain" *ngFor="let month of month; let i = index;"-->
                          <!--[ngClass]="{'current-month': month.isCurrentMonth }" (click)="setMonth(i)">-->
                        <!--<i class="contain">{{ month.month }}</i>-->
                    <!--</span>-->
            <!--</div>-->
        <!--</div>-->
    <!--</div>-->

</div>

<!--HOW TO USE-->
<!--<app-ng-datepicker [(ngModel)]="selectedDateInCalendar" [startPosition]="'end'" [options]="newDatepickerOptions"></app-ng-datepicker>-->


