import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import { GlobalSaveService } from "../../../@core/global-save.service";
import {HttpClient} from "@angular/common/http";
import {UserInfoService} from "../../../@core/user-info.service";
import {DialogService} from "../../../../@util/dialog/dialog.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
	selector: 'app-global-save-cancel',
	templateUrl: './global-save-follow-ups-past-dues.component.html',
	styleUrls: ['../global-save-msg-form.component.scss']
})
export class GlobalSaveFollowUpsPastDuesComponent implements OnInit {
	title = 'StSr Message';

	globalSaveService;

	claimantName = '';

	constructor(
		private http: HttpClient,
		private userInfoService: UserInfoService,
		public dialog: MatDialog,
		private dialogRef: MatDialogRef<GlobalSaveFollowUpsPastDuesComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private dialogService: DialogService,
		private route: ActivatedRoute,
		private router: Router) {

	}

	ngOnInit() {

	}

	closeVisitForm() {
		this.dialogRef.close();
	}

	goToFollowUp(){
		this.dialogRef.close({status:false});
		this.router.navigate(['/claimants/'+this.data.sslgId+'/follow-ups/pending']);
	}

	cancel() {
		this.dialogRef.close({status:true});
	}
}
