import { HttpClient } from '@angular/common/http';
import { INSURERS_CONFIG } from '../@config/api';
import { Social } from '../@models/system-managment/social';
import { Functions } from '../../@util/functions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OfficeService {
    constructor(http) {
        this.http = http;
        this.config = INSURERS_CONFIG.OFFICES;
    }
    getCurrentOffice(officeId) {
        return this.http.get(this.config.byId(officeId));
    }
    getAll() {
        return this.http.get(this.config.getAll);
    }
    addOffice(value) {
        return this.http.post(this.config.addOffice(value.insurenceId), value);
    }
    setOfficeContact(officeId, value) {
        value.press = Functions.checkPhone(value.press);
        value.fax = Functions.checkPhone(value.fax);
        value.phone = Functions.checkPhone(value.phone);
        return this.http.post(this.config.setContact(officeId), value);
    }
    setOfficeNote(officeId, value) {
        return this.http.post(this.config.setNote(officeId), value);
    }
    getInsuranceOffices(insuranceId) {
        return this.http.get(this.config.getAllByCompanyId(insuranceId));
    }
    setSocial(officeId, value) {
        Social.checkLinck(value);
        return this.http.post(this.config.setSocial(officeId), value);
    }
    setBasic(value) {
        return this.http.post(this.config.setBasic(value.id), value);
    }
    setWebAndLtd(id, value) {
        return this.http.post(this.config.setWebAndLtd(id), value);
    }
    getWebAndLtd(id) {
        return this.http.get(this.config.getWebAndLtd(id), { responseType: 'text' });
    }
}
OfficeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OfficeService_Factory() { return new OfficeService(i0.ɵɵinject(i1.HttpClient)); }, token: OfficeService, providedIn: "root" });
