import {Component, EventEmitter, forwardRef, Host, Inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {AbstractControl,FormArray, FormControl, FormGroup, Validators,FormBuilder} from '@angular/forms';
import {DynamicFormService} from '@ng-dynamic-forms/core';

import {ActivatedRoute, Router} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import {DateService} from "../../../../../../@core/functions/date.service";
import {UserInfoService} from "../../../../../../@core/user-info.service";
import {NotificationConfirmationsComponent} from "../../../../../notification/confirmations/notification-confirmations.component";
import {MatDialog} from "@angular/material";
import {MedicalSourcesService} from "../../../../../../@core/medical-sources.service";
import {DialogService} from "../../../../../../../@util/dialog/dialog.service";
import {GlobalSaveService} from "../../../../../../@core/global-save.service";


export interface PaymentsTableData {
	feeCharged: String;
	datePd: Date;
	checkNo: String;
	dateBilled: Date;
	amountBilled: String;
	datePaid: Date;
}

@Component({
	selector: 'app-medical-payments-form',
	templateUrl: './medical-payments-form.component.html',
	styleUrls: ['./medical-payments-form.component.scss']
})
export class MedicalPaymentsFormComponent implements OnInit, OnDestroy {
	@Output() backToPaymentsList = new EventEmitter<boolean>();

	paymentsData: PaymentsTableData[] = [ {
		feeCharged: null,
		datePd: null,
		checkNo: null,
		dateBilled: null,
		amountBilled: null,
		datePaid: null
	} ];

	dataSource = new BehaviorSubject<AbstractControl[]>([]);
	paymentsFields: FormArray = this.fb.array([]);
	requestsForm: FormGroup = this.fb.group({
		payments:this.paymentsFields
	});

	paymentId = '';

	constructor(private formService: DynamicFormService,
				private fb: FormBuilder,
				private route: ActivatedRoute,
				private dateService: DateService,
				private dialog: DialogService,
				private matDialog: MatDialog,
				public globalSaveService: GlobalSaveService,
				public userInfoService: UserInfoService,
				private medicalSourcesService: MedicalSourcesService,
				) {

	}

	ngOnDestroy(): void {

	}

	async ngOnInit() {

	}

	onChange($event) {

	}

	init(payments) {
		if (payments == null) {
			this.paymentsData.forEach((d: PaymentsTableData) => this.addPayment(d, false));
		} else {
			payments.forEach((d: PaymentsTableData) => this.addPayment(d, false));
		}
	}

	addById(id){
		this.paymentsFields.clear();

		if(id){
			this.medicalSourcesService.treatingSourcesFindPayment(id).subscribe(data => {
				this.paymentId = id;

				this.addPayment(data, false);
			});
		}else{
			this.paymentId = '0';

			this.addPayment(null, false);
		}

	}

	getBtnName(){
		if(this.paymentId == '0'){
			return 'Add';
		}
		return 'Update';
	}

	back(){
		this.backToPaymentsList.emit(true);
	}

	getData(){
		let requestsPayments = this.requestsForm.value;
		for(var i=0;i<requestsPayments.payments.length;i++){
			requestsPayments.payments[i].datePd = this.dateService.getDateWithoutHours(requestsPayments.payments[i].datePd);
			requestsPayments.payments[i].dateBilled = this.dateService.getDateWithoutHours(requestsPayments.payments[i].dateBilled);
			requestsPayments.payments[i].datePaid = this.dateService.getDateWithoutHours(requestsPayments.payments[i].datePaid);
		}


		return requestsPayments.payments;
	}

	save(){
		let requestsPayments = this.requestsForm.value;
		for(var i=0;i<requestsPayments.payments.length;i++){
			requestsPayments.payments[i].datePd = this.dateService.getDateWithoutHours(requestsPayments.payments[i].datePd);
			requestsPayments.payments[i].dateBilled = this.dateService.getDateWithoutHours(requestsPayments.payments[i].dateBilled);
			requestsPayments.payments[i].datePaid = this.dateService.getDateWithoutHours(requestsPayments.payments[i].datePaid);
		}

		// console.log(requestsPayments)
		// if (requestsPayments.payments[0].dateBilled === null) {
		// 	this.dialog.show("Please, fill 'Date Claimant Billed' field!", ["ok"]);
		// 	return;
		// } else {
		// 	this.medicalSourcesService.saveTreatingSourcesPayment(this.getSslgId(),this.paymentId,this.getSourceId(), requestsPayments.payments[0]).subscribe(data => {
		// 		this.dialog.show("Information was saved successfully!", ["ok"]);
		//
		// 		this.back();
		// 	});
		// }

		this.medicalSourcesService.saveTreatingSourcesPayment(this.getSslgId(),this.paymentId,this.getSourceId(), requestsPayments.payments[0]).subscribe(data => {
			this.dialog.show("Information was saved successfully!", ["ok"]);

			this.back();
		});
	}

	getSourceId(){
		return this.route.snapshot.paramMap.get('id');
	}

	getSslgId(){
		return this.route.snapshot.parent.params['sslgId'];
	}

	addPayment(d?: PaymentsTableData, noUpdate?: boolean) {
		const row = this.fb.group({
			'feeCharged':[d && d.feeCharged ? d.feeCharged : null, []],
			'datePd':[d && d.datePd   ? new Date(d.datePd)   : null, []],
			'checkNo':[d && d.checkNo   ? d.checkNo   : null, []],
			'dateBilled':[d && d.dateBilled   ? new Date(d.dateBilled)   : null, []],
			'amountBilled':[d && d.amountBilled   ? d.amountBilled   : null, []],
			'datePaid':[d && d.datePaid   ? new Date(d.datePaid)   : null, []],

		});
		this.paymentsFields.push(row);
	}

	deletePayment(index){
		let dialog = this.matDialog.open(NotificationConfirmationsComponent,{
			data:{
				message: "Are you sure you want to delete the payment?"
			}
		});
		dialog.afterClosed().subscribe(j => {
			try {
				if (j.status) {
					this.paymentsFields.removeAt(index);
				}
			} catch (e) {

			}
		});
	}

	paymentsShowDeleteBtn(){
		let count = this.paymentsFields.length;
		if(count == 1){
			return false;
		}

		return true;
	}


	clearDate(form,field:any,index){
		if (this.isViewing()) {
			return;
		}
		this.paymentsFields.controls[index].value[field] = null;
		this.paymentsFields.controls[index].get(field).reset();

	}

	onBlur($event) {
	}

	onFocus($event) {
	}

	isViewing() {
		if (this.globalSaveService.getStatusTrue()) {
			return false;
		}
		return true;
	}

}
