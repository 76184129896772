import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
	selector: 'app-visit-dialog',
	templateUrl: './visit-dialog.component.html',
	styleUrls: ['./visit-dialog.component.scss']
})
export class VisitDialogComponent {
	title: any;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<VisitDialogComponent>) {
		if (data.type == 'DOCTOR')
			this.title = 'Doctor Completion';
		else if(data.type == 'HOSPITAL')
			this.title = 'Hospital Completion';
		else if(data.type == 'OTHER')
			this.title = 'Other Completion'
	}

	closeVisitForm() {
		this.dialogRef.close('close');
	}

	btnClick(answer) {
		this.dialogRef.close(answer);
	}
}
