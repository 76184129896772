export class Functions {
	public static checkPhone(value: string | null | undefined): string {
		if (!value) {
			return value;
		}
		if (value.length > 14) {
			return value.slice(0, -1);
		}
		return value;
	}

	public static validateEmail(email: string): boolean {
		const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	public static validateICD_10(code: string): boolean {
		const re = /^[A-Z]\d{2}(\.\d{1,3})$/;
		return re.test(code);
	}

	public static getHashCode(value: string): number {
		let hash = 0, i, chr;
		if (!value || value.length === 0) {
			return hash;
		}
		for (i = 0; i < value.length; i++) {
			chr = value.charCodeAt(i);
			hash = ((hash << 5) - hash) + chr;
			hash |= 0; // Convert to 32bit integer
		}
		return hash;
	}

	public static equals(obj1, obj2) {
		return this.getHashCode(JSON.stringify(this.filterNullInFormData(obj1))) === this.getHashCode(JSON.stringify(this.filterNullInFormData(obj2)));
	}
	public static filterNullInFormData(obj) {
		Object.keys(obj).forEach(j => {
			if (typeof obj[j] === 'string' && obj[j].trim().length == 0) {
				obj[j] = null;
			} else if (obj[j] instanceof Object) {
				obj[j] = this.filterNullInFormData(obj[j]);
			}
		});
		return obj;
	}


	public static validateFileSize(file: File, maxSizeMB: number): boolean {
		return (file.size / 1024 / 1024 - maxSizeMB) <= 0;
	}

	public static groupBy<T>(source: T[], key: string): IGrouped<T>[] {

		const rez = source.reduce((rv, x) => {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
		const retVal: IGrouped<T>[] = [];
		Object.getOwnPropertyNames(rez).forEach(j => {
			retVal.push(<IGrouped<T>>{key: j, group: rez[j]});
		});
		return retVal;
	}

	public static groupByRetObj(source: any[], key: string) {
		return source.reduce((rv, x) => {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	}

	public static checkLinck(val) {
		if (val && val.indexOf('http') === -1)
			return 'http://' + val;
		return val;
	}
}

export interface IGrouped<T> {
	key: any;
	group: T[];
}
