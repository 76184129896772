import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from "@angular/core";
import { State } from "../../app/test-new-stepper-form/test-form/on-changes";
export class DynamicForm {
    constructor() {
        this.formStateChanged = new EventEmitter();
        this.allFormStateChanged = new EventEmitter();
    }
}
export class DynamicFormParent extends DynamicForm {
    constructor(matDialog) {
        super();
        this.matDialog = matDialog;
        this.formsState = new Map();
    }
    onFormStateChanged(state) {
        this.formsState.set(state.key, state.value);
    }
    onAllFormStateChanged(state) {
        this.allFormState = state;
    }
    get state() {
        let changed = State.before;
        this.formsState.forEach((value) => {
            if (value) {
                changed = State.after;
            }
        });
        return changed;
    }
    onStateChanges() {
        throw Error("Not Implemented");
    }
    canCloseForm(formName) {
        return !this.formsState.has(formName) || !this.formsState.get(formName);
    }
    showDataChangedDialog() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // const dialogRef = this.matDialog.open(FormChangesAttentionComponent, {
            // 	data: {
            // 		closeBtnText: 'Ignore changes',
            // 		infoText: 'You have unsaved data. Please confirm before proceeding.'
            // 	},
            // 	panelClass: 'attention-form-changes',
            // 	disableClose: true
            // });
            // return (await dialogRef.afterClosed().toPromise()) == 'leave';
            return true;
        });
    }
}
