import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {MedicalTreatingSourcesComponent} from "./medical-treating-sources.component";
import {DoctorsComponent} from "./doctors/doctors.component";
import {DoctorFormComponent} from "./doctors/doctor-form/doctor-form.component";
import {MedicalRequestsComponent} from "./medical-requests/medical-requests.component";
import {OthersComponent} from "./othe/others.component";
import {OtheFormComponent} from "./othe/othe-form/othe-form.component";
import {HospitalsComponent} from "./hospitals/hospitals.component";
import {HospitalFormComponent} from "./hospitals/hospital-form/hospital-form.component";
import {ClaimantMedicationsComponent} from "./claimant-medications/claimant-medications.component";
import {TitleRouterResolver} from "../../../../title-router-resolver";
import {CanDeactivateService} from "../../../../@core/can-deactivate.service";

const routes: Routes = [
	{
		path: '', component: MedicalTreatingSourcesComponent,
		data: {title: "${referral}: Medical Treating Sources"},
		resolve: [TitleRouterResolver],
		children: [
			{path: 'doctors', component: DoctorsComponent},
			{path: 'doctors/new', component: DoctorFormComponent, canDeactivate: [CanDeactivateService],},
			{path: 'doctors/:id/info', component: DoctorFormComponent},

			{path: 'others', component: OthersComponent},
			{path: 'others/new', component: OtheFormComponent, canDeactivate: [CanDeactivateService],},
			{path: 'others/:id/info', component: OtheFormComponent},

			{path: 'hospitals', component: HospitalsComponent},
			{path: 'hospitals/new', component: HospitalFormComponent, canDeactivate: [CanDeactivateService],},
			{path: 'hospitals/:id/info', component: HospitalFormComponent},

			{path: 'medications', component: ClaimantMedicationsComponent},
			{path: '', redirectTo: 'doctors', pathMatch: 'full'},
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})

export class MedicalTreatingSourcesRoutingModule {

}
