export class NotificationConfig {
    private _id?;
    get id() {
        return this._id;
    }

    set id(e) {

    }


    title?: string;
    message?: string;
    type?: string;
    imagePosition?: string;
    progressBar?: string;
    autoHide?: boolean;
    timeOut?: number;
    position?: string;

    genId() {
        this._id = btoa(Math.random() + '');
    }
}
