/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inner-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../custom-iris-notification-component/custom-iris-notification.component.ngfactory";
import * as i3 from "../../custom-iris-notification-component/custom-iris-notification.component";
import * as i4 from "@angular/common";
import * as i5 from "./inner-container.component";
var styles_InnerContainerComponent = [i0.styles];
var RenderType_InnerContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InnerContainerComponent, data: { "animation": [{ type: 7, name: "fadeIn", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: "0", transform: "translateY(110%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: "1", transform: "translateY(0)" }, offset: null }, timings: ".5s ease-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { opacity: "1", transform: "translateY(0)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: "0", transform: "translateX(110%)" }, offset: null }, timings: ".5s ease-out" }], options: null }], options: {} }] } });
export { RenderType_InnerContainerComponent as RenderType_InnerContainerComponent };
function View_InnerContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-custom-iris-notification", [], [[24, "@fadeIn", 0]], [[null, "onCloseDialog"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).close() !== false);
        ad = (pd_0 && ad);
    } if (("onCloseDialog" === en)) {
        var pd_1 = (_co.closeNotif(_v.context.$implicit.id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_CustomIrisNotificationComponent_0, i2.RenderType_CustomIrisNotificationComponent)), i1.ɵdid(1, 5488640, null, 0, i3.CustomIrisNotificationComponent, [], { config: [0, "config"] }, { onCloseDialog: "onCloseDialog" }), (_l()(), i1.ɵted(-1, null, ["\n\n    "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_InnerContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InnerContainerComponent_2)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.allNotificationsConf; var currVal_2 = _co.trackByFn; _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getClass(); _ck(_v, 0, 0, currVal_0); }); }
export function View_InnerContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InnerContainerComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.allNotificationsConf; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InnerContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inner-container", [], null, null, null, View_InnerContainerComponent_0, RenderType_InnerContainerComponent)), i1.ɵdid(1, 1163264, null, 0, i5.InnerContainerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InnerContainerComponentNgFactory = i1.ɵccf("app-inner-container", i5.InnerContainerComponent, View_InnerContainerComponent_Host_0, {}, {}, []);
export { InnerContainerComponentNgFactory as InnerContainerComponentNgFactory };
