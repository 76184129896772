import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LTD_INFO_CONFIG} from "../@config/api";

@Injectable({
  providedIn: 'root'
})
export class LtdInfoService {

  private config = LTD_INFO_CONFIG;

  constructor(private http: HttpClient) {
  }

  getLtdInfo() {
    return this.http.get(this.config.getLtd, {responseType: 'text'});
  }

  setLtdInfo(data) {
    return this.http.post(this.config.setLtd, data, {responseType: 'text'});
  }
}
