import { HttpClient } from "@angular/common/http";
import { CLAIMANT_HEARING_OFFICE_CONFIG } from "../@config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ClaimantHearingOfficeService {
    constructor(http) {
        this.http = http;
    }
    getHearingOffice(sslgId) {
        return this.http.get(CLAIMANT_HEARING_OFFICE_CONFIG.get(sslgId));
    }
    updateHearingOffice(sslgId, data) {
        return this.http.post(CLAIMANT_HEARING_OFFICE_CONFIG.update(sslgId), data);
    }
    getHearingOfficeData(sslgId, zip) {
        return this.http.post(CLAIMANT_HEARING_OFFICE_CONFIG.getData(sslgId), { zip: zip });
    }
}
ClaimantHearingOfficeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClaimantHearingOfficeService_Factory() { return new ClaimantHearingOfficeService(i0.ɵɵinject(i1.HttpClient)); }, token: ClaimantHearingOfficeService, providedIn: "root" });
