import {Component, ElementRef, Inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../_services/authentication.service';
import {ClientsService} from '../../@core/clients.service';
import {first, map} from 'rxjs/operators';
import {UserIdleConfig} from '../user-idle/user-idle-config';
import {DialogService} from "../../../@util/dialog/dialog.service";
import {AppStorage} from "../../storage";
import {BreakpointObserver} from "@angular/cdk/layout";
import {User} from "../../@models/system-managment/user";
import {AuthConfig} from "../_services/auth-config";
import {DevicesService} from "../../@core/devices.service";
import {PhoneService} from "../../@core/phone.service";
import {UserInfoService} from "../../@core/user-info.service";
import {PageLoaderService} from "../../@core/page-loader.service";
import {LoginService} from "../../@core/login.service";
import {UserTempService} from "../../@core/userTemp.service";

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {
    // rememberMe: boolean = REMEMBER_ME;
	showLoginForm = true;
	showForgotPasswordForm = false;
  showPasswordInput = false;
  showPinInput = false;
  showLoginStepTwo = false;

  loginForm: FormGroup;
  forgotPasswordForm: FormGroup;
  numOfDigits = 4;

    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

  @ViewChildren('inputs') inputs: QueryList<ElementRef>;
    // @ViewChild('loader') el: ElementRef;
    //
    constructor(
		@Inject(AuthConfig) private authConfig: AuthConfig,
		private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private breakpointObserver: BreakpointObserver,
        private authenticationService: AuthenticationService,
    private loginService: LoginService,
		private clientsService: ClientsService,
		private devicesService: DevicesService,
		private phoneService: PhoneService,
        private pageLoaderService: PageLoaderService,
		private userTempService: UserTempService,
		public userInfoService: UserInfoService,
		private dialog: DialogService) {
        // Image

      this.loginService.changePassword.subscribe(value => {
        if (value) {
          this.showLoginForm = false;
          this.showForgotPasswordForm = true;
          this.forgotPasswordForm.controls.username.setValue(this.loginForm.controls.username.value);
          this.loginService.changePassword.next(false);
        }
      });

      this.loginService.ignorePasswordChanging.subscribe(value => {
        if (value) {
          this.onSubmit(true);
        }
      });
    }

    ngOnInit() {

        // reset login status
        this.authenticationService.logout();

        this.loginForm = this.formBuilder.group({
            username: [null, Validators.required],
            password: [null, Validators.required],
            digits: this.formBuilder.array([]),
            rememberMe: [false]
        });

      for (let i = 0; i < this.numOfDigits; i++) {
        (this.loginForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required));
      }

		this.forgotPasswordForm = this.formBuilder.group({
			username: [null, Validators.required],
		});

        try {
            const userName = AppStorage.getStorage().getItem('user');
            if (userName) {
                this.loginForm.controls['username'].setValue(userName);
                this.loginForm.controls['rememberMe'].setValue(true);
            }
        } catch (e) {
            this.dialog.show(JSON.stringify(e), ['OK']);
        }

        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.pageLoaderService.hide();

    }

    //
    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    onSubmit(ignore = false) {
        this.submitted = true;
        let pin;
        let password = '';

        // stop here if form is invalid
        if (!this.canLoginWithPassword() && !this.canLoginWithPin()) {
            return;
        }

        if (this.canLoginWithPassword()) {
          password = this.loginForm.controls.password.value;
          pin = '';
        }

        if (this.canLoginWithPin()) {
          pin = this.loginForm.controls.digits.value;
          pin = pin.join('');
        }

        this.loading = true;
        this.pageLoaderService.show();
        UserIdleConfig.REMEMBER_ME = this.loginForm.controls.rememberMe.value;
        // this.dialog.show(window.navigator.userAgent, ['OK']);

        this.userTempService.resetUserTempState();
        this.authenticationService.login(this.loginForm.controls.username.value, password, pin, ignore)
            .pipe(first())
            .subscribe(
                data => {
                    if (window.navigator.userAgent === 'SSLG_Browser') { // TODO: !
                        if (this.authenticationService.roles.indexOf('CLIENT') === -1) {
                            this.dialog.show('Only for clients', ['OK']);
                            this.loading = false;
                            this.pageLoaderService.hide();
                            return;
                        } else {
                        	this.router.navigate([this.returnUrl]);
                        }

                    } else {
                      if(this.userInfoService.getDeviceUuid()){
                        if (this.authenticationService.roles.indexOf('CLIENT') === -1) {
                          this.dialog.show('Only for clients', ['OK']);
                          this.loading = false;
                                          this.pageLoaderService.hide();
                          return;
                        } else {
                          this.devicesService.add(this.userInfoService.getDeviceUuid(), this.userInfoService.getDeviceType()).subscribe(
                            data => {
                              this.phoneService.setNotificationToken();

                              this.router.navigate([this.returnUrl]);
                            });
                        }
                      }else {
                        this.router.navigate([this.returnUrl]);
                      }
                    }

                    this.loading = false;
                    this.pageLoaderService.hide();
                }, error1 => {
                    this.loading = false;
                    this.pageLoaderService.hide();
                    //console.log(error1);
                    ////console.log(error1);
                }
            );
    }

	onForgotPasswordSubmit(){
		this.submitted = true;

		// stop here if form is invalid
		if (this.forgotPasswordForm.invalid) {
			return;
		}
		this.loading = true;
        this.pageLoaderService.show();

		/*return this.clientsService.forgotPassword(this.forgotPasswordForm.controls.username.value)
			.pipe(map((data: User[]) => {
			return data.map(j => {
				let name = 'Fill Client Name';
				if (j.clientBasic && j.clientBasic.firstName && j.clientBasic.lastName) {
					name = j.clientBasic.firstName + ' ' + j.clientBasic.lastName;
				}
				return {
					value: j.username,
					label: name
				};
			})*/

		this.clientsService.forgotPassword(this.forgotPasswordForm.controls.username.value)
			.pipe(first())
			.subscribe(
				data => {
					//console.log(data)
					this.dialog.show(data.message, ['OK']);

					/*if (window.navigator.userAgent === 'SSLG_Browser') { // TODO: !
						if (this.authenticationService.roles.indexOf('CLIENT') === -1) {
							this.dialog.show('Only for clients', ['OK']);
							this.loading = false;
							return;
						} else {
							this.router.navigate([this.loginForm.controls.username.value]);
						}

					} else {
						this.router.navigate([this.returnUrl]);
					}*/

					this.loading = false;
                    this.pageLoaderService.hide();
				}, error1 => {
					this.loading = false;
                    this.pageLoaderService.hide();
					//console.log(error1);
					////console.log(error1);
				}
			);
	}

    checkValid(reg: string) {
        return this.loginForm.controls[reg].touched && this.loginForm.controls[reg].hasError('required');
    }

	showForgotPassword(){
		this.showLoginForm = false;
		this.showForgotPasswordForm = true;
	}

	showLogin(){
		this.showLoginForm = true;
		this.showForgotPasswordForm = false;
	}

	isPhone(){
    	return this.userInfoService.isApp();
	}

  sendUserName() {
    // this.showLoginStepTwo = true;
    // this.showPasswordInput = true;
    this.authenticationService.sendUserName(this.loginForm.controls.username.value).subscribe(data => {
    	//alert(data.code)
      if (data.status == 'true') {
        if (data.code === '0') {
          this.showPasswordInput = true;
        } else if (data.code === '1') {
          this.showPinInput = true;
          setTimeout(() => {
            this.inputs.toArray()[0].nativeElement.focus();
          });
        }
          this.showLoginStepTwo = true;
      } else {
        this.dialog.show(data.message, ['OK']);
      }
    });
  }

  goToFirstLoginStep() {
      this.showLoginStepTwo = false;
      this.showPasswordInput = false;
      this.showPinInput = false;
  }

  check(index, field, event) {
    if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
      event.preventDefault();
    }

    if (field.value && event.key !== 'Backspace') {
      if (index < this.inputs.toArray().length - 1) {
        this.inputs.toArray()[index + 1].nativeElement.focus();
      }
    } else if (event.key === 'Backspace') {
      if (index > 0) {
        field.setValue(null)
        this.inputs.toArray()[index - 1].nativeElement.focus();
      } else {
        console.log('first field');
      }
    }
  }

  canLoginWithPin() {
    return this.loginForm.controls.username.valid && this.showPinInput && this.loginForm.controls.digits.valid;
  }

  canLoginWithPassword() {
    return this.loginForm.controls.username.valid && this.showPasswordInput && this.loginForm.controls.password.valid;
  }

}
