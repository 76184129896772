<!--<app-frame [overlay]="false" [title]="title" (neadClose)="closeForm.emit()" autocomplete="off">-->
<section class="table-section">
	<div *ngIf="this.globalSaveService.getStatusTrue() || isClaimant() || isExecutive()" class="mobile-table-action">
		<button *ngIf="(this.globalSaveService.getStatusTrue() || isClaimant()) && (hospitals && hospitals.length)"
				(click)="deleteOthers()"
				style="color: #FF6565; text-transform: none"
				class="table-button table-button_add"
				title="Delete an Other">
			Delete an Other
			<svg class="table-button__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				 viewBox="0 0 490.383 490.383" style="visibility: hidden; enable-background:new 0 0 490.383 490.383;" xml:space="preserve">
				<g>
					<g>
						<g>
							<path d="M468.2,170.891H93c-9.7,0-17.9,6.2-21,15.2l-70.8,227.8c-4.3,14,6.2,28.4,21,28.4h375.2c9.7,0,17.9-6.2,21-15.2
								l71.2-227.5C493.4,185.291,482.9,170.891,468.2,170.891z M342.6,331.091h-73.1v73.1h-49v-73.1h-73.1v-49h73.1v-73.1h49v73.1h73.1
								V331.091z"/>
							<path d="M53.3,180.291c5.4-17.5,21.4-29.2,39.7-29.2h327.8v-26.1c0-12.1-9.7-21.8-21.8-21.8H220.5c-15.2,0-27.6-12.4-27.6-27.6
								s-12.5-27.5-27.6-27.5H21.8c-12,0-21.8,10.1-21.8,22.1v280L53.3,180.291z"/>
						</g>
					</g>
				</g>
			</svg>

		</button>
		<button (click)="addDoctor()" class="table-button table-button_add" *appCanAccess="PERMISSIONS.LOWER_EXEC">
			Add New Source
			<svg class="table-button__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
				 xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				 viewBox="0 0 490.383 490.383" style="enable-background:new 0 0 490.383 490.383;" xml:space="preserve">
<g>
	<g>
		<g>
			<path d="M468.2,170.891H93c-9.7,0-17.9,6.2-21,15.2l-70.8,227.8c-4.3,14,6.2,28.4,21,28.4h375.2c9.7,0,17.9-6.2,21-15.2
				l71.2-227.5C493.4,185.291,482.9,170.891,468.2,170.891z M342.6,331.091h-73.1v73.1h-49v-73.1h-73.1v-49h73.1v-73.1h49v73.1h73.1
				V331.091z"/>
			<path d="M53.3,180.291c5.4-17.5,21.4-29.2,39.7-29.2h327.8v-26.1c0-12.1-9.7-21.8-21.8-21.8H220.5c-15.2,0-27.6-12.4-27.6-27.6
				s-12.5-27.5-27.6-27.5H21.8c-12,0-21.8,10.1-21.8,22.1v280L53.3,180.291z"/>
		</g>
	</g>
</g>
				<g>
</g>
				<g>
</g>
				<g>
</g>
				<g>
</g>
				<g>
</g>
				<g>
</g>
				<g>
</g>
				<g>
</g>
				<g>
</g>
				<g>
</g>
				<g>
</g>
				<g>
</g>
				<g>
</g>
				<g>
</g>
				<g>
</g>
</svg>

		</button>
	</div>

	<div class="i-swipers" *ngIf="canScrollRight || canScrollLeft">
		<div class="i-swipers__left i-swipers__arrow" [class.is-visible]="canScrollLeft">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
					<path d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
			c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
			l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
			c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"/>
			  </svg>
		</div>
		<div class="i-swipers__right i-swipers__arrow" [class.is-visible]="canScrollRight">
			<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
					<path d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
			c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
			l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
			c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"/>
			  </svg>
		</div>
	</div>


	<app-iris-table #table (tableScrolled)="tableScrolled($event)" [inOneLine]="true">
		<div class="i-thead">
			<div class="i-tr">
				<div class="i-th w-size-4 i-th--checkbox" *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()"></div>
				<div class="i-th w-size-36 i-th--name">NAME</div>
				<div class="i-th i-th--phone">PHONE</div>
				<div class="i-th w-size-5 i-btn {{getTableHeadClass()}}">
					<div *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" class="i-btn-container">
						<button *ngIf="(this.globalSaveService.getStatusTrue() || isClaimant()) && (hospitals && hospitals.length)"
								(click)="deleteOthers()"
								style="right: 9em; color: #FF6565; text-transform: none"
								class="table-button table-button_add"
								title="Delete an ther">
							Delete an Other
							<svg class="table-button__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
								 viewBox="0 0 490.383 490.383" style="enable-background:new 0 0 490.383 490.383; visibility: hidden" xml:space="preserve">
<g>
	<g>
		<g>
			<path d="M468.2,170.891H93c-9.7,0-17.9,6.2-21,15.2l-70.8,227.8c-4.3,14,6.2,28.4,21,28.4h375.2c9.7,0,17.9-6.2,21-15.2
				l71.2-227.5C493.4,185.291,482.9,170.891,468.2,170.891z M342.6,331.091h-73.1v73.1h-49v-73.1h-73.1v-49h73.1v-73.1h49v73.1h73.1
				V331.091z"/>
			<path d="M53.3,180.291c5.4-17.5,21.4-29.2,39.7-29.2h327.8v-26.1c0-12.1-9.7-21.8-21.8-21.8H220.5c-15.2,0-27.6-12.4-27.6-27.6
				s-12.5-27.5-27.6-27.5H21.8c-12,0-21.8,10.1-21.8,22.1v280L53.3,180.291z"/>
		</g>
	</g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
</svg>

						</button>
						<button (click)="addDoctor()" class="table-button table-button_add" *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()">Add New Source
							<svg class="table-button__icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
								 viewBox="0 0 490.383 490.383" style="enable-background:new 0 0 490.383 490.383;" xml:space="preserve">
<g>
	<g>
		<g>
			<path d="M468.2,170.891H93c-9.7,0-17.9,6.2-21,15.2l-70.8,227.8c-4.3,14,6.2,28.4,21,28.4h375.2c9.7,0,17.9-6.2,21-15.2
				l71.2-227.5C493.4,185.291,482.9,170.891,468.2,170.891z M342.6,331.091h-73.1v73.1h-49v-73.1h-73.1v-49h73.1v-73.1h49v73.1h73.1
				V331.091z"/>
			<path d="M53.3,180.291c5.4-17.5,21.4-29.2,39.7-29.2h327.8v-26.1c0-12.1-9.7-21.8-21.8-21.8H220.5c-15.2,0-27.6-12.4-27.6-27.6
				s-12.5-27.5-27.6-27.5H21.8c-12,0-21.8,10.1-21.8,22.1v280L53.3,180.291z"/>
		</g>
	</g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
</svg>

						</button>
					</div>
				</div>
				<div class="i-th w-size-15"></div>
			</div>
		</div>
		<div class="i-tbody">
			<div class="i-tr" *ngFor="let doctor of data | async;let i = index">
				<div class="i-td w-size-4 i-th--checkbox"  *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" [title]="doctor.name" style="padding-left: 1%; padding-right: 1%">
					<mat-checkbox (change)="toggleDeleteOthersArray($event, doctor.id)"
								  class="mat-checkbox mat-accent ng-valid mat-checkbox-checked ng-dirty ng-touched" [id]="doctor.id">
						<label class="mat-checkbox-layout" [for]="doctor.id">
							<div class="mat-checkbox-inner-container">
								<input class="mat-checkbox-input cdk-visually-hidden"
									   [id]="doctor.id"
									   type="checkbox"
									   tabindex="0" aria-checked="true">
							</div>
						</label>
					</mat-checkbox>
				</div>
				<div class="i-td w-size-40 i-th--name" [title]="doctor.name">{{ doctor.name }}</div>
				<div class="i-td i-th--phone" [title]="doctor.phoneFormatted">{{ doctor.phoneFormatted }}</div>
<!--				<div class="i-td" [title]="doctor.phoneFormatted">{{ doctor.phoneFormatted }}</div>-->
<!--				<div class="i-td" [title]="doctor.phoneFormatted">{{ doctor.phoneFormatted }}</div>-->
<!--				<div class="i-td" [title]="doctor.phoneFormatted">{{ doctor.phoneFormatted }}</div>-->
				<div class="i-td w-size-5 i-btn">
					<div *ngIf="!this.globalSaveService.getStatusTrue() && !isClaimant()" class="i-btn-container">
						<button (click)="otherInfo(doctor)" class="table-button-complete">
							<svg  class="table-button__icon ng-star-inserted" style="fill: #43425D">
								<use xlink:href="../../../../assets/img/sprite.svg#eye-icon"></use>
							</svg>
						</button>
					</div>
					<div *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" class="i-btn-container">
						<button (click)="otherInfo(doctor)" class="table-button-complete">
							<svg class="table-button__icon"  version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
								 viewBox="0 0 494.907 494.907" style="enable-background:new 0 0 494.907 494.907;" xml:space="preserve">
<g>
	<path d="M70.571,459.196c-6.131,0-11.114-4.983-11.114-11.106V105.993c0-6.123,4.983-11.104,11.114-11.104H308.28
		c6.131,0,11.115,4.98,11.115,11.104v147.911c10.565-3.519,21.644-5.855,33.132-6.844V105.993c0-24.396-19.849-44.236-44.247-44.236
		H121.157V44.236c0-6.124,4.982-11.104,11.113-11.104h237.711c6.13,0,11.113,4.98,11.113,11.104V247.36
		c11.517,1.279,22.586,4.013,33.131,7.839V44.236C414.225,19.841,394.378,0,369.981,0H132.27c-24.397,0-44.245,19.841-44.245,44.236
		v17.521H70.571c-24.397,0-44.246,19.841-44.246,44.236V448.09c0,24.395,19.849,44.238,44.246,44.238h190.666
		c-9.543-9.811-17.714-20.943-24.203-33.132H70.571z"/>
	<path d="M126.913,190.86h95.61c9.158,0,16.565-7.418,16.565-16.565c0-9.149-7.407-16.566-16.565-16.566h-95.61
		c-9.153,0-16.561,7.418-16.561,16.566C110.352,183.442,117.759,190.86,126.913,190.86z"/>
	<path d="M268.514,247.846c0-9.148-7.407-16.566-16.566-16.566H126.913c-9.153,0-16.561,7.418-16.561,16.566
		c0,9.149,7.407,16.566,16.561,16.566h125.035C261.107,264.412,268.514,256.995,268.514,247.846z"/>
	<path d="M249.055,304.808H126.913c-9.153,0-16.561,7.417-16.561,16.565c0,9.148,7.407,16.566,16.561,16.566h103.521
		C235.172,326.022,241.483,314.926,249.055,304.808z"/>
	<path d="M126.913,378.342c-9.153,0-16.561,7.418-16.561,16.565c0,9.148,7.407,16.566,16.561,16.566h94.737
		c-0.907-6.584-1.552-13.267-1.552-20.103c0-4.4,0.274-8.728,0.664-13.029H126.913z"/>
	<path d="M365.047,357.148c-28.438,0-53.614,23.563-63.545,34.223c9.931,10.655,35.107,34.209,63.545,34.209
		c28.553,0,53.658-23.547,63.545-34.199C418.675,380.728,393.504,357.148,365.047,357.148z M365.047,416.22
		c-13.718,0-24.846-11.128-24.846-24.849c0-13.732,11.128-24.847,24.846-24.847s24.846,11.114,24.846,24.847
		C389.893,405.092,378.765,416.22,365.047,416.22z"/>
	<path d="M365.047,287.837c-57.186,0-103.536,46.349-103.536,103.534c0,57.173,46.35,103.536,103.536,103.536
		c57.186,0,103.535-46.363,103.535-103.536C468.582,334.185,422.233,287.837,365.047,287.837z M365.047,442.143
		c-44.681,0-79.594-43.791-81.064-45.652c-2.345-3.008-2.345-7.23,0-10.23c1.471-1.868,36.384-45.678,81.064-45.678
		c44.679,0,79.592,43.809,81.064,45.678c2.345,3,2.345,7.223,0,10.23C444.639,398.353,409.726,442.143,365.047,442.143z"/>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
								<g>
</g>
</svg>
						</button>
					</div>
				</div>
<!--				<div class="i-td w-size-8 i-btn"  [ngClass]="{'i-btn-no': !isSystem()}">-->
<!--					<div class="i-btn-container">-->
<!--						<button *ngIf="isSystem()" (click)="delete(doctor,i)" class="table-button-complete">-->
<!--							Delete-->
<!--						</button>-->
<!--					</div>-->
<!--				</div>-->
				<div class="i-td w-size-15 mobile--flex">
					<div style="width: 100%">
						<mat-form-field appearance="fill" class="no-label">
<!--							<mat-label>Choices:</mat-label>-->
							<mat-select  #medicalTreatingSourcesStatusSelect
										 [disabled]="!globalSaveService.getStatusTrue()"
										 (selectionChange)="onMedicalTreatingSourcesStatusChange($event, doctor, medicalTreatingSourcesStatusSelect)"
										 [value]="getMedicalTreatingSourcesStatus(doctor)">
<!--								<mat-option [value]="0">-->
<!--									Empty/Nothing/Blank-->
<!--								</mat-option>-->
								<mat-option *ngFor="let status of medicalTreatingSourcesStatuses" [value]="status.value">
									{{status.label}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
	</app-iris-table>
</section>

<mat-paginator #paginator
			   [length]="dataSource.data.length"
			   [pageIndex]="0"
			   [pageSize]="8"
			   [hidePageSize]="true">
	<!--[pageSizeOptions]="[6, 12, 25, 50]"-->
</mat-paginator>
<app-medical-treating-reports [isAbsolute]="true" [sslgId]="sslgId"></app-medical-treating-reports>
<!--</app-frame>-->
