import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import * as conf from '../@config/api';
import {Contact} from '../@models/contact';
import {Personal} from '../@models/system-managment/personal';
import {User} from '../@models/system-managment/user';
import {Social} from '../@models/system-managment/social';
import {Functions} from '../../@util/functions';
import {FileUploadingService} from "../../@util/file-uploading-progress/file-uploading.service";
import {FormMode} from "../@models/system-managment/form-mode";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class SystemUsersService {
    private config = conf.SYSTEM_USER_CONFIG;

    constructor(private http: HttpClient, private fileUploadingService: FileUploadingService) {

    }

    addUser(user: User) {
        return this.http.post(this.config.add, user);
    }

    getUsersList(role) {
        let httpParams = new HttpParams();
        if (role !== null)
            httpParams = httpParams.append('role', role);

        return this.http.get<User[]>(this.config.getAll, {params: httpParams})
           ;

    }

    mapUserStaff(data: User[]) {
        return data.map(j => {
            let thisLabel = '';
            if (!(j.personal && j.personal.firstName && j.personal.lastName)) {
                thisLabel = 'Fill Name';
            } else {
                thisLabel = j.personal.firstName + ' ' + j.personal.lastName;
            }
            if (j.personal && j.personal.title) {
                thisLabel = thisLabel + ', ' + j.personal.title;

            } else {
                thisLabel = thisLabel + ' ';
            }
            if (!j.contact || !j.contact.email) {
                thisLabel = thisLabel + '';
            } else {
                thisLabel = thisLabel + ' ~ ' + j.contact.email;
            }
            return {
                value: j.username,
                label: thisLabel
            };
        });
    }

    getCurrentUser(userId) {
        return this.http.get(this.config.getById(userId));
    }

    updatePasswordAndActive(value: User) {
        return this.http.post(this.config.updateLoginInfo(value.username), value);
    }

    setContact(userName: string, value: Contact, formMode: FormMode) {
        value.press = Functions.checkPhone(value.press);
        value.fax = Functions.checkPhone(value.fax);
        value.phone = Functions.checkPhone(value.phone);
        const url = formMode !== FormMode.SelfEdit ? this.config.setContact(userName) : this.config.selfEdit.setContact(userName);
        return this.http.post(this.config.setContact(userName), value);
    }

    setPersonal(userName: string, value: Personal, formMode: FormMode) {
        value.phone = Functions.checkPhone(value.phone);
        ////console.log(formMode);
        const url = formMode !== FormMode.SelfEdit ? this.config.setPersonal(userName) : this.config.selfEdit.setPersonal(userName);
        return this.http.post(url, value);
    }


    setSocial(userName: string, value: Social, formMode: FormMode) {
        Social.checkLinck(value);

        const url = formMode !== FormMode.SelfEdit ? this.config.setSocial(userName) : this.config.selfEdit.setSocial(userName);
        return this.http.post(this.config.setSocial(userName), value);
    }

    uploadAvatar(username: string, avatar: File) {
        return this.fileUploadingService.uploadFile('/api/user/avatar/' + username, {file: avatar, fileInfo: null});
    }
}
