import {
	AfterViewInit,
	Component,
	OnInit,
	QueryList,
	ViewChild,
	ViewChildren
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import html2canvas from 'html2canvas'
import * as jsPDF from 'jspdf'
import {Report} from "./report";
import {PdfData} from "./pdfData";
import {LoadingService} from "../../@util/loading/loading.service";
import {MedicalService} from "../@core/medical.service";
import {PdfDocument} from "../../@util/pdf-document/pdf-document";

@Component({
	selector: 'app-pdf-view',
	templateUrl: './pdf-view.component.html',
	styleUrls: ['./pdf-view.component.scss']
})
export class PdfViewComponent implements OnInit, AfterViewInit {
	@ViewChildren('body') body: QueryList<any>;
	@ViewChild('head', { static: true }) head;
	@ViewChild('report', { static: true }) report;
	@ViewChild('footer', { static: true }) footer;

	pdfSrc: Report;
	private scale = 0.95;
	pdfData: PdfData;
	showContent = true;

	constructor(private route: ActivatedRoute, private router: Router,
				private loading: LoadingService, private medicalService: MedicalService) {
	}

	ngOnInit() {
		let data = localStorage.getItem('reportData');
		if (!data) {
			this.router.navigate(['/']);
		}
		this.pdfSrc = JSON.parse(data);

		if(this.pdfSrc.type == "DOWNLOAD"){
			this.showContent = false;
		}

		var ids = this.getVisitsIds();

		this.medicalService.getVisitSummariesById(this.pdfSrc.sslgId, ids).subscribe((data:any) => {
			this.pdfData = data.data;
		});
	}

	getVisitsIds() {
		var ids = [];
		for (let i = 0; i < this.pdfSrc.data.length; i++) {
			ids.push(this.pdfSrc.data[i].id);
		}

		return ids;
	}

	async ngAfterViewInit() {
		// let loader = this.loading.loading();
		// setTimeout(()=>{
		// 	let doc = PdfDocument.createDocument({
		// 		format: "a4",
		// 		compressPdf: true,
		// 		nativeElements: null,
		// 		orientation: "portrait",
		// 		unit: "mm"
		// 	});
		// 	try {
		// 		if (!this.pdfSrc.isIndividual) {
		// 			doc.build(() => {
		// 				doc.setHeaders(() => {
		//
		// 				}, this.head.nativeElement, this.footer.nativeElement);
		// 			}, [this.report.nativeElement])
		// 		} else {
		// 			let elements = <any[]>(<any>this.body)._results.map(j => j.nativeElement);
		// 			doc.build(() => {
		// 				doc.setHeaders(() => {
		// 					this.saveAndSendEmail(doc, loader);
		// 				}, this.head.nativeElement, this.footer.nativeElement);
		// 			}, elements);
		// 		}
		// 	} catch (e) {
		// 		this.loading.loaded(loader);
		// 		console.error(e)
		// 	}
		// },100)
		//

		if (this.pdfSrc.type == "EMAIL") {
			this.sendEmail();
		} else if (this.pdfSrc.type == "DOWNLOAD") {
			this.save();
		} else {
			//this.save();
		}
	}

	saveAndSendEmail(doc: PdfDocument, loader) {
		// this.loading.loaded(loader);
		this.loading.loaded();
		if (this.pdfSrc.type == "EMAIL") {
			let data: String = doc.output();

			this.medicalService.sentVisitSummaries(this.pdfSrc.sslgId, {
				content: data,
				email: this.pdfSrc.email
			}).subscribe();
		}
		doc.save();
	}

	private sendEmail() {
		// let {data, report} =  <any>{...this.pdfSrc};
		/*let rez = {...this.pdfSrc};
		rez.data = rez.data.map(j=>j.id);
		(<any>rez).individual = rez.isIndividual;
		this.medicalService.sentVisitSummariesByEmail(rez).subscribe(rez=>{
			if (rez.type == 4)
				this.downloadFile(rez, "report.pdf");
		});*/

		var ids = this.getVisitsIds();
		this.medicalService.sendVisitSummariesByEmail(this.pdfSrc.sslgId,this.pdfSrc.email,this.pdfSrc.isIndividual, ids).subscribe(rez => {
			if (rez.type == 4)
			this.downloadFile(rez, "report.pdf");
		});
	}

	// private download(){
	// 	let rez = {...this.pdfSrc};
	// 	rez.data = rez.data.map(j=>j.id);
	// 	(<any>rez).individual = rez.isIndividual;
	// 	this.medicalService.saveVisitSummariesReport(rez).subscribe(rez=>{
	// 		if (rez.type == 4){
	// 			this.downloadFile(rez, "report.pdf");
	// 			window.close();
	// 		}
	// 	});
	// }

	private save() {
		// let {data, report} =  <any>{...this.pdfSrc};
		//console.log(this.pdfSrc);
		let rez = {...this.pdfSrc};
		rez.data = rez.data.map(j=>j.id);
		(<any>rez).individual = rez.isIndividual;
		this.medicalService.saveVisitSummariesReport(rez).subscribe(rez=>{
			if (rez.type == 4){
				this.downloadFile(rez, "report.pdf");
				window.close();
			}
		});
	}
	downloadFile(data, fileName) {
		const ieEDGE = navigator.userAgent.match(/Edge/g);
		const ie = navigator.userAgent.match(/.NET/g); // IE 11+
		const oldIE = navigator.userAgent.match(/MSIE/g);

		const blob = new Blob([<any>data.body], {type: 'application/pdf'});
		if (ie || oldIE || ieEDGE) {
			window.navigator.msSaveBlob(blob, atob(fileName));
		} else {
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = fileName;
			link.click();
		}
		return blob;
	}

}
