import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ReferralService } from "../../../@core/referral.service";
export class ReportHeaderComponent {
    constructor(referralService) {
        this.referralService = referralService;
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.currentUser = yield this.referralService.getClaimantPdfInfo(this.sslgId).toPromise();
            console.log(this.currentUser);
        });
    }
    getUserPhone() {
        if (this.currentUser) {
            return this.currentUser.phone ? this.currentUser.phone : 'Not specified';
        }
        else {
            return '';
        }
    }
    getUserEmail() {
        if (this.currentUser) {
            return this.currentUser.email ? this.currentUser.email : 'Not specified';
        }
        else {
            return '';
        }
    }
    getIcdId(j, index) {
        if (this.currentUser.icdId.length == index + 1) {
            return j.code;
        }
        else {
            return j.code + ',';
        }
    }
}
