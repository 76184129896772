<div class="wrapper-content">
  <section class="login">

    <div class="login__part login__bg-wrapper">
      <div class="login__bg" style="background-image: url('../../../assets/img/left-bg/left-bg.svg');"></div>

      <img class="login-inner-logo" src="../../../assets/img/login-new.svg" alt=""/>

      <ul class="city-list">
        <li class="city-list__item">Boston</li>
        <li class="city-list__item">Dallas</li>
        <li class="city-list__item">Denver</li>
        <li class="city-list__item">Atlanta</li>
        <li class="city-list__item">St. Louis</li>
      </ul>

      <div class="login-desc-text">
        <p>ATTORNEYS AT LAW</p>
        <p>Nationwide social security attorneys</p>
        <p>800-909-SSLG (7754)</p>
        <p>www.<span>SSLG</span>.com</p>
      </div>

      <!--<div class="login-bottom">-->
        <!--<p>ATTORNEYS AT LAW</p>-->
        <!--<span>1 (800) 909-SSLG</span>-->
        <!--<span>www.SSLG.com</span>-->
      <!--</div>-->

    </div>

    <div class="login__part login__content">
      <div class="login-block">
        <p class="logo login__logo">
          <!--<img src="../../../assets/img/logo_yellow.png" alt="Company logo" class="logo__img">-->
          <img src="../../../assets/img/logo_all_blue.svg" alt="Company logo" class="logo__img">
          <img src="../../../assets/img/text-logo.png" alt="Company logo" class="logo__text">
        </p>
        <p class="login__text">
			Restore Access
        </p>

        <form [formGroup]="restoreForm" (ngSubmit)="onSubmit()" class="from-login">
          <div class="field-group">
            <mat-form-field>
              <input formControlName="password" matInput placeholder="Password" [type]="'password'" class="form-input" autocomplete="off" required>

			</mat-form-field>

          </div>
			<div class="min-length">Min Length is 6</div>
          <div class="filed-group" >
            <mat-form-field>
              <input formControlName="confirmPassword" matInput placeholder="Confirm password" [type]="'password'" class="form-input" autocomplete="off" required>
            </mat-form-field>
          </div>
			<div class="min-length" style="margin-top: -1.2em;">Min Length is 6</div>

          <div class="form-btn-submit" >
            <button mat-raised-button class="form-send" [disabled]="!restoreForm.valid || loading ">Change</button>
          </div>
        </form>

        <div class="conditions__wrapper">
          <a href="javascript:void(0)" class="conditions">Term of use. Privacy policy</a>
        </div>
      </div>
    </div>


  </section>

</div>

