export const MEDICAL_TESTS_MODE = {
  create: 'CREATE',
  edit: 'EDIT',
  createVisit: 'CREATE_VISIT'
}

export const MEDICAL_TEST_LIST = [
      {
        name: 'X Ray',
        shortName: 'xRay',
      },
      {
        name: 'MRI',
        shortName: 'mri',
      },
      {
        name: 'CT Scan',
        shortName: 'ctScan',
      },
      {
        name: 'Ultra Sound',
        shortName: 'ultraSound',
      },
      {
        name: 'Biopsy',
        shortName: 'biopsy',
      },
      {
        name: 'Vascular',
        shortName: 'vascular',
      },
      {
        name: 'Other',
        shortName: 'other',
      },
    {
      name: 'Sleep Study',
      shortName: 'sleepStudy',
    },
    {
      name: 'Breathing',
      shortName: 'breathing',
    },
    {
      name: 'EKG (Heart Test)',
      shortName: 'ekg',
    },
    {
      name: 'ECG (Brain Wave)',
      shortName: 'ecg',
    },
    {
      name: 'Treadmill',
      shortName: 'treadmill',
    },
    {
      name: 'Vision',
      shortName: 'vision',
    },
    {
      name: 'BLOOD',
      shortName: 'blood',
    },
    {
      name: 'HIV',
      shortName: 'hiv',
    },
    {
      name: 'Endoscopy',
      shortName: 'endoscopy',
    },
    {
      name: 'Neuropsych Exam',
      shortName: 'neuropsychExam',
    },
    {
      name: 'Neurocognitive Exam',
      shortName: 'neurocognitiveExam',
    }
];
