import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class MenuService {


	constructor() {

	}

	getAuditLogConfig(){
		return {
			// width: '250px',
			panelClass: ['mobile-popup', 'with-shadow'],
			backdropClass: 'mobile-popup__overlay',
			position: {top: '8%', left: '18%'},
			disableClose: true
		};
	}
}
