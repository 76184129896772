import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../_services/authentication.service';
import {ClientsService} from '../../@core/clients.service';
import {DialogService} from "../../../@util/dialog/dialog.service";
import {BreakpointObserver} from "@angular/cdk/layout";
import {AuthConfig} from "../_services/auth-config";

@Component({
    selector: 'app-restore',
    templateUrl: 'restore.component.html',
    styleUrls: ['restore.component.scss']
})
export class RestoreComponent implements OnInit {

	restoreForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = '';

	email = '';
	hash = '';

    constructor(
		@Inject(AuthConfig) private authConfig: AuthConfig,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private breakpointObserver: BreakpointObserver,
        private authenticationService: AuthenticationService,
		private clientsService: ClientsService,
		private dialog: DialogService) {
    }

    ngOnInit() {
		this.route.params.subscribe(data => {
			if (data.email && data.hash) {
				this.email = data.email;
				this.hash = data.hash;
			}else{
				this.router.navigate([this.authConfig.loginRoute]);
			}
		});

        this.restoreForm = this.formBuilder.group({
			confirmPassword: [null, Validators.required],
            password: [null, Validators.required],
        });

		this.restoreForm.controls.password.setValidators([Validators.minLength(6), Validators.maxLength(16)]);
		this.restoreForm.controls.confirmPassword.setValidators([Validators.minLength(6), Validators.maxLength(16)]);


    }

    //
    // convenience getter for easy access to form fields
    get f() {
        return this.restoreForm.controls;
    }

    onSubmit() {
    	let thisEl = this;
		thisEl.submitted = true;

        // stop here if form is invalid
        if (thisEl.restoreForm.invalid) {
            return;
        }
        this.loading = true;

        let password = this.restoreForm.controls.password.value;
		let confirmPassword = this.restoreForm.controls.confirmPassword.value;
        if(password == confirmPassword){
			thisEl.authenticationService.restore(this.email,this.hash,password)
				.subscribe(
					(data:any) => {
						if(data.status && data.status == 'true'){
							let login = data.userId;

							thisEl.dialog.show('Successfully updated. Redirecting in five seconds.', ['OK']);

							setTimeout(function() {
								thisEl.goToLogin(login, password);
							},5000);
						} else {
							thisEl.dialog.show(data.message, ['OK']);
							thisEl.loading = false;
						}
					}, error1 => {
						thisEl.loading = false;
					}
				);
		} else {
			thisEl.dialog.show('Passwords do not match', ['OK']);
			thisEl.loading = false;
		}

    }

	goToLogin(login, password, ignore = false, pin = '') {
		this.authenticationService.login(login, password, pin, ignore)
			.subscribe(
				data => {
					this.router.navigate([`/`]);

					this.loading = false;
				}, error1 => {
					this.loading = false;
				}
			);
	}

}
