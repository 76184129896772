import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
	selector: 'app-frame',
	templateUrl: './form-frame.component.html',
	styleUrls: ['./form-frame.component.scss']
})
export class FormFrameComponent implements OnInit {
	@Input()
	public title: string;
	@Input()
	public titleHTML: string;
	@Input()
	public icon: string;
	@Input()
	public autocomplete;
	@Output()
	public neadClose: EventEmitter<any> = new EventEmitter<any>();
	@Input()
	public overlay: boolean;
	@Input()
	public disabled = false;
	@Input()
	showCloseButton: boolean = true;
	@Input()
	showLegend: boolean = true;
	isIOS = false;

	@Input()
	dragAndDropDisabled: boolean = false;

	constructor() {
		this.styleForIOS();

	}

	ngOnInit() {

	}

	showOverlay() {
		return this.overlay === undefined || this.overlay;
	}

	closeModal() {
		this.neadClose.emit();
	}

	styleForIOS() {
		this.isIOS = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
	}
}
