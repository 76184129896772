import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import { GlobalSaveService } from "../../../@core/global-save.service";
import {HttpClient} from "@angular/common/http";
import {UserInfoService} from "../../../@core/user-info.service";
import {DialogService} from "../../../../@util/dialog/dialog.service";
import {Router} from "@angular/router";

@Component({
	selector: 'app-global-save-cancel',
	templateUrl: './global-save-continue-claimant-update.component.html',
	styleUrls: ['../global-save-msg-form.component.scss', './global-save-continue-claimant-update.component.scss']
})
export class GlobalSaveContinueClaimantUpdateComponent implements OnInit {
	title = 'StSr Message';

	globalSaveService;

	claimantName = '';

	constructor(
		private http: HttpClient,
		private userInfoService: UserInfoService,
		public dialog: MatDialog,
		private dialogRef: MatDialogRef<GlobalSaveContinueClaimantUpdateComponent>,
		@Inject(MAT_DIALOG_DATA) private data: string[],
		private dialogService: DialogService,
		private router: Router) {

	}

	ngOnInit() {
		this.globalSaveService = new GlobalSaveService(this.http,this.userInfoService,this.dialog);

		this.claimantName = this.globalSaveService.getLastClaimanNameFullName();
	}

	clearVisitForm(){
		this.globalSaveService.removeLastClaimant().subscribe(data => {
			if(data.status == "true") {
				this.globalSaveService.confirmOff().subscribe(data => {
					if(data.status == "true"){
						this.globalSaveService.cleareLastClaiment();

						this.globalSaveService.setStatus(this.globalSaveService.statusNotActive);

						this.dialogRef.close();
					}
				});
			}else{
				this.dialogService.show("An error occurred, try again later", ['OK']);
			}
		});
	}

	closeVisitForm() {
		this.dialogRef.close();

	}

	confirm() {
		this.dialogRef.close();

		this.router.navigate([`/claimants/record/${this.globalSaveService.getLastClaimanName()}`]);
	}
}
