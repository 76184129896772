import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {POST_AWARD_CONFIG} from '../@config/api';

@Injectable({
  providedIn: 'root'
})
export class PostAwardService {
  private config = POST_AWARD_CONFIG;

  constructor(private http: HttpClient) {
  }

  getAwardDetails(sslgId) {
    return this.http.get<any>(this.config.detail.getAwardDetail(sslgId));
  }

  setAwardDetails(sslgId: string,details) {
    return this.http.post(this.config.detail.setAwardDetail(sslgId), details);
  }

  setCoraDetails(sslgId: string,data: any) {
    return this.http.post(this.config.detail.setCoraDetail(sslgId), data);
  }

  getCoraDetails(sslgId: any) {
    return this.http.get<any>(this.config.detail.getCoraDetail(sslgId));
  }
}
