import {Component, EventEmitter, Inject, Input, Optional, Output, ViewChild} from "@angular/core";
import {FormGroup} from "@angular/forms";
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocomplete, MatAutocompleteDefaultOptions, AUTOCOMPLETE_OPTION_HEIGHT } from "@angular/material/autocomplete";
import { ErrorStateMatcher, LabelOptions, MAT_LABEL_GLOBAL_OPTIONS, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from "@angular/material/core";
import { MatInput } from "@angular/material/input";
import {
    DynamicFormControlCustomEvent,
    DynamicFormLayout,
    DynamicFormLayoutService,
    DynamicFormValidationService,
} from "@ng-dynamic-forms/core";
import {DynamicMaterialFormInputControlComponent} from "../dynamic-material-form-input-control.component";
import {DynamicInputAutocompleteModel} from "./dynamic-input-autocomplete-model";

@Component({
    selector: "dynamic-material-input",
    templateUrl: "./dynamic-material-input.component.html",
    styleUrls: ['./dynamic-material-input.component.scss']
})
export class DynamicMaterialInputComponent extends DynamicMaterialFormInputControlComponent {


    @Input() group: FormGroup;
    @Input() layout: DynamicFormLayout;
    @Input() model: DynamicInputAutocompleteModel;

    @Output() blur: EventEmitter<any> = new EventEmitter();
    @Output() change: EventEmitter<any> = new EventEmitter();
    @Output() customEvent: EventEmitter<DynamicFormControlCustomEvent> = new EventEmitter();
    @Output() focus: EventEmitter<any> = new EventEmitter();

    @ViewChild("matAutocomplete", { static: true }) matAutocomplete: MatAutocomplete;
    @ViewChild(MatInput, { static: true }) matInput: MatInput;

    constructor(protected layoutService: DynamicFormLayoutService,
                protected validationService: DynamicFormValidationService,
                @Inject(ErrorStateMatcher) public errorStateMatcher: ErrorStateMatcher,
                @Inject(MAT_AUTOCOMPLETE_DEFAULT_OPTIONS) public AUTOCOMPLETE_OPTIONS: MatAutocompleteDefaultOptions,
                @Inject(MAT_LABEL_GLOBAL_OPTIONS) @Optional() public LABEL_OPTIONS: LabelOptions,
                @Inject(MAT_RIPPLE_GLOBAL_OPTIONS) @Optional() public RIPPLE_OPTIONS: RippleGlobalOptions) {

        super(layoutService, validationService);

    }

    isObject(option: any) {
        return (typeof option !== 'string')
    }

    setSelectedValue($event, param: any) {
        this.model.autocompleteSelectedValue = param;
		this.model.autocompleteSelectedValueChanged.next(param);
        super.onChange($event);
    }


}
