export class AuditLogNotification {
	type: NotificationType[];
	typeChanges: NotificationTypeChanges;
	recipientType: NotificationRecepientType[];
}

// export enum NotificationType {
// 	CHANGES = 0,
// 	DOCSTAR = 1,
// 	POST_AWARD = 2,
// }
export enum NotificationType {
	EMAIL = 'EMAIL',
	WEB = 'WEB'
}
export enum NotificationTypeChanges {
	CHANGES = 'CHANGES',
	DOCSTAR = 'DOCSTAR',
	POST_AWARD = 'POST_AWARD'
}
export enum NotificationRecepientType {
	CLAIMANT = 'CLAIMANT',
	ADJUSTER = 'ADJUSTER',
	STAFF = 'STAFF'
}

export class TimeSpent {
	hours: number;
	minutes: number;
}

