import {
    DynamicCheckboxModel,
    DynamicCheckboxModelConfig,
    DynamicDatePickerModel,
    DynamicFormControlLayout
} from "@ng-dynamic-forms/core";

export class DatepickerCheckboxModel extends DynamicDatePickerModel {
    readonly type = 'DATE_CHECK_BOX';

}
