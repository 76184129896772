import {Directive, Input, TemplateRef, Type} from "@angular/core";

export enum DYNAMIC_TEMPLATE_DIRECTIVE_ALIGNMENT { Start = "START", End = "END"}

@Directive({
	selector: "ng-template[modelId],ng-template[modelType]"
})
export class CellId {

	@Input() align: string = DYNAMIC_TEMPLATE_DIRECTIVE_ALIGNMENT.End;
	@Input() as: string | null = null;
	@Input() index: number | undefined;
	@Input() modelId: string;
	@Input() modelType: 'HeaderCell' | 'DataCell' | 'DataRow';

	constructor(public templateRef: TemplateRef<any>) {
	}
}
