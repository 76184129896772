<table class="report-table">
	<tr>
		<td rowspan="2" style="background: #ccc;">Medications</td>
	</tr>
	<tr *ngFor="let medication of tableData">
		<td colspan="2" style="padding: 0px;">
			<table class="report-table">
				<tr>
					<td>Name:</td>
					<td>
						{{medication.name}}
					</td>
				</tr>
				<tr>
					<td>Generic Form of what Medicine?</td>
					<td>
						{{medication.genericForm}}
					</td>
				</tr>
				<tr>
					<td>Reason for Taking:</td>
					<td>
						{{medication.reason}}
					</td>
				</tr>
				<tr>
					<td>Side Effects:</td>
					<td>
						{{medication.sideEffects}}
					</td>
				</tr>
				<tr>
					<td>Prescribed By:</td>
					<td>
						{{medication.prescribedBy}}
					</td>
				</tr>
				<tr>
					<td>Start Date:</td>
					<td>
						{{medication.period.startDate | date: 'MM/dd/yyyy'}}
					</td>
				</tr>
				<tr>
					<td>
						<mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="medication.regular">Prescription</mat-checkbox>
					</td>
					<td></td>
				</tr>
			</table>
		</td>
	</tr>
</table>
