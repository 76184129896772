import * as tslib_1 from "tslib";
import { AfterViewInit, OnInit, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "../../@util/loading/loading.service";
import { MedicalService } from "../@core/medical.service";
export class PdfViewComponent {
    constructor(route, router, loading, medicalService) {
        this.route = route;
        this.router = router;
        this.loading = loading;
        this.medicalService = medicalService;
        this.scale = 0.95;
        this.showContent = true;
    }
    ngOnInit() {
        let data = localStorage.getItem('reportData');
        if (!data) {
            this.router.navigate(['/']);
        }
        this.pdfSrc = JSON.parse(data);
        if (this.pdfSrc.type == "DOWNLOAD") {
            this.showContent = false;
        }
        var ids = this.getVisitsIds();
        this.medicalService.getVisitSummariesById(this.pdfSrc.sslgId, ids).subscribe((data) => {
            this.pdfData = data.data;
        });
    }
    getVisitsIds() {
        var ids = [];
        for (let i = 0; i < this.pdfSrc.data.length; i++) {
            ids.push(this.pdfSrc.data[i].id);
        }
        return ids;
    }
    ngAfterViewInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // let loader = this.loading.loading();
            // setTimeout(()=>{
            // 	let doc = PdfDocument.createDocument({
            // 		format: "a4",
            // 		compressPdf: true,
            // 		nativeElements: null,
            // 		orientation: "portrait",
            // 		unit: "mm"
            // 	});
            // 	try {
            // 		if (!this.pdfSrc.isIndividual) {
            // 			doc.build(() => {
            // 				doc.setHeaders(() => {
            //
            // 				}, this.head.nativeElement, this.footer.nativeElement);
            // 			}, [this.report.nativeElement])
            // 		} else {
            // 			let elements = <any[]>(<any>this.body)._results.map(j => j.nativeElement);
            // 			doc.build(() => {
            // 				doc.setHeaders(() => {
            // 					this.saveAndSendEmail(doc, loader);
            // 				}, this.head.nativeElement, this.footer.nativeElement);
            // 			}, elements);
            // 		}
            // 	} catch (e) {
            // 		this.loading.loaded(loader);
            // 		console.error(e)
            // 	}
            // },100)
            //
            if (this.pdfSrc.type == "EMAIL") {
                this.sendEmail();
            }
            else if (this.pdfSrc.type == "DOWNLOAD") {
                this.save();
            }
            else {
                //this.save();
            }
        });
    }
    saveAndSendEmail(doc, loader) {
        // this.loading.loaded(loader);
        this.loading.loaded();
        if (this.pdfSrc.type == "EMAIL") {
            let data = doc.output();
            this.medicalService.sentVisitSummaries(this.pdfSrc.sslgId, {
                content: data,
                email: this.pdfSrc.email
            }).subscribe();
        }
        doc.save();
    }
    sendEmail() {
        // let {data, report} =  <any>{...this.pdfSrc};
        /*let rez = {...this.pdfSrc};
        rez.data = rez.data.map(j=>j.id);
        (<any>rez).individual = rez.isIndividual;
        this.medicalService.sentVisitSummariesByEmail(rez).subscribe(rez=>{
            if (rez.type == 4)
                this.downloadFile(rez, "report.pdf");
        });*/
        var ids = this.getVisitsIds();
        this.medicalService.sendVisitSummariesByEmail(this.pdfSrc.sslgId, this.pdfSrc.email, this.pdfSrc.isIndividual, ids).subscribe(rez => {
            if (rez.type == 4)
                this.downloadFile(rez, "report.pdf");
        });
    }
    // private download(){
    // 	let rez = {...this.pdfSrc};
    // 	rez.data = rez.data.map(j=>j.id);
    // 	(<any>rez).individual = rez.isIndividual;
    // 	this.medicalService.saveVisitSummariesReport(rez).subscribe(rez=>{
    // 		if (rez.type == 4){
    // 			this.downloadFile(rez, "report.pdf");
    // 			window.close();
    // 		}
    // 	});
    // }
    save() {
        // let {data, report} =  <any>{...this.pdfSrc};
        //console.log(this.pdfSrc);
        let rez = Object.assign({}, this.pdfSrc);
        rez.data = rez.data.map(j => j.id);
        rez.individual = rez.isIndividual;
        this.medicalService.saveVisitSummariesReport(rez).subscribe(rez => {
            if (rez.type == 4) {
                this.downloadFile(rez, "report.pdf");
                window.close();
            }
        });
    }
    downloadFile(data, fileName) {
        const ieEDGE = navigator.userAgent.match(/Edge/g);
        const ie = navigator.userAgent.match(/.NET/g); // IE 11+
        const oldIE = navigator.userAgent.match(/MSIE/g);
        const blob = new Blob([data.body], { type: 'application/pdf' });
        if (ie || oldIE || ieEDGE) {
            window.navigator.msSaveBlob(blob, atob(fileName));
        }
        else {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        }
        return blob;
    }
}
