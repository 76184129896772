import { DynamicInputModel } from "@ng-dynamic-forms/core";
import { isObservable, of } from "rxjs";
import { EventEmitter } from "@angular/core";
import { tap } from "rxjs/operators";
export class DynamicInputAutocompleteModel extends DynamicInputModel {
    constructor() {
        super(...arguments);
        this.list$ = null;
        this.autocompleteSelectedValue = null;
        this.autocompleteSelectedValueChanged = new EventEmitter();
    }
    set list(list) {
        if (Array.isArray(list)) {
            this._list = list;
            this.list$ = of(this._list);
        }
        else if (isObservable(list)) {
            this.list$ = list.pipe(tap(list => this._list = list));
        }
        else {
            this._list = null;
            this.list$ = null;
        }
    }
}
