<ng-container [formGroup]="group">

    <div [formArrayName]="model.id" [class.group-dirty]="isDirty()" [id]="elementId" [ngClass]="getClass('element', 'control')">

        <div *ngFor="let groupModel of model.groups; let idx = index" role="group"
			 [class.group-dirty]="isDirty(groupModel)"
             [formGroupName]="idx" [ngClass]="[getClass('element', 'group'), getClass('grid', 'group')]">

            <ng-container *ngTemplateOutlet="startTemplate?.templateRef; context: groupModel"></ng-container>

            <dynamic-material-form-control *ngFor="let _model of groupModel.group"
                                           [context]="groupModel"
                                           [group]="getFormControl().at(idx)"
                                           [hidden]="_model.hidden"
                                           [layout]="layout"
                                           [model]="_model"
                                           [templates]="templates"

                                           [ngClass]="[getClass('element', 'host', _model), getClass('grid', 'host', _model),
                                                       getClass('element','children'), getClass('grid','children')]"
                                           (blur)="onBlur($event)"
                                           (change)="onChange($event)"
                                           (focus)="onFocus($event)"
                                           (matEvent)="onCustomEvent($event, null, true)"></dynamic-material-form-control>

            <ng-container *ngTemplateOutlet="endTemplate?.templateRef; context: groupModel"></ng-container>

        </div>

    </div>

</ng-container>
