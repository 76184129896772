import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {IrisTableComponent} from "./iris-table/iris-table.component";
import {CustomIrisTableService} from "./custom-iris-table.service";

@NgModule({
	declarations: [
		IrisTableComponent
	],
	imports: [
		CommonModule
	],
	exports: [
		IrisTableComponent
	],
	entryComponents: [
		IrisTableComponent
	],
	providers: [CustomIrisTableService]
})
export class CustomIrisTableModule {

}
