import { PERMISSIONS_CONFIG } from '../@config/api';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ClaimantsPermissionsService {
    constructor(http) {
        this.http = http;
        this.config = PERMISSIONS_CONFIG;
    }
    geUsers(type) {
        return this.http.get(this.config.users(type));
    }
    getPermissions(sslgId) {
        return this.http.get(this.config.claimant.permissions(sslgId));
    }
    addPermission(sslgId, userName) {
        return this.http.post(this.config.claimant.permit(sslgId, userName), {}, { responseType: 'text' });
    }
    removePermission(sslgId, userName) {
        return this.http.post(this.config.claimant.forbid(sslgId, userName), {}, { responseType: 'text' });
    }
    getClaimats() {
        return this.http.get(this.config.claimant.claimants);
    }
}
ClaimantsPermissionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClaimantsPermissionsService_Factory() { return new ClaimantsPermissionsService(i0.ɵɵinject(i1.HttpClient)); }, token: ClaimantsPermissionsService, providedIn: "root" });
