<app-frame [overlay]="false"
		   [title]="title"
		   [showCloseButton]="false"
		   [class.no-frame]="true"
		   (neadClose)="closeVisitForm()" autocomplete="off">
  <form class="form-horizontal" [formGroup]="formGroup" novalidate>
    <dynamic-material-form [group]="formGroup"
                           [layout]="formLayout"
                           [model]="formModel"
                           (blur)="onBlur($event)"
                           (change)="onChange($event)"
                           (focus)="onFocus($event)">
    </dynamic-material-form>
  </form>
	<ng-container *ngIf="!isAnotherVisit">
		<app-form-actions-content *appCanAccess="PERMISSIONS.LOWER_EXEC">
			<form-button style="margin-right: auto"
						 *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" (clickOnButton)="backHospitalsVisit()">Back</form-button>
			<form-button *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" (clickOnButton)="nextHospitalsVisit()">Next</form-button>
		</app-form-actions-content>
	</ng-container>
	<ng-container *ngIf="isAnotherVisit">
		<app-form-actions-content *appCanAccess="PERMISSIONS.LOWER_EXEC">
			<form-button *ngIf="this.globalSaveService.getStatusTrue() || isClaimant()" (clickOnButton)="nextHospitalsVisit()">Next</form-button>
		</app-form-actions-content>
	</ng-container>
</app-frame>
