import {Injectable} from '@angular/core';
import {POST_AWARD_CONFIG} from '../@config/api';
import {HttpClient} from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class CoraDetailsService {
	private config = POST_AWARD_CONFIG.cora_details;

	constructor(private http: HttpClient) {

	}

	getPrimary(sslgId) {
		return this.http.get(this.config.primary(sslgId));
	}

	setPrimary(sslgId, data) {
		return this.http.post(this.config.primary(sslgId), data);
	}

	getDependent(sslgId) {
		return this.http.get(this.config.dependent(sslgId));
	}

	setDependent(sslgId, data) {
		return this.http.post(this.config.dependent(sslgId), data);
	}

	getEnrolment(sslgId) {
		return this.http.get(this.config.enrolment(sslgId));
	}

	setEnrolment(sslgId, data) {
		return this.http.post(this.config.enrolment(sslgId), data);
	}

	getSummary(sslgId) {
		return this.http.get(this.config.summary(sslgId));
	}

	setSummary(sslgId, data) {
		return this.http.post(this.config.summary(sslgId), data);
	}

	getMeters(sslgId) {
		return this.http.get(this.config.meters(sslgId));
	}

	setMeters(sslgId, data) {
		return this.http.post(this.config.meters(sslgId), data);
	}
}
