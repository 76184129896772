import {Injectable} from '@angular/core';
import {DATE_FORMAT, DEFAULT_DATE} from "../../@config/dateFormat";
import {DatePipe} from "@angular/common";
//import * as moment from 'moment';
//import 'moment/locale/pt-br';

@Injectable({
	providedIn: 'root'
})
export class DateService {

	DEFAULT_DATE = DEFAULT_DATE;
	DATE_FORMAT = DATE_FORMAT;

	constructor() {

	}

	getUTCDate(date){
		let data = date.getUTCDate();
		if(data < 10){
			data = '0'+data;
		}

		return data;
	}
	getUTCMonth(date){
		let data = date.getUTCMonth();
		if(data < 10){
			data = '0'+data;
		}

		return data;
	}
	getUTCFullYear(date){
		return date.getUTCFullYear();
	}
	getUTCHours(date){
		let data = date.getUTCHours();
		if(data < 10){
			data = '0'+data;
		}

		return data;
	}
	getUTCMinutes(date){
		let data = date.getUTCMinutes();
		if(data < 10){
			data = '0'+data;
		}

		return data;
	}
	getUTCSeconds(date){
		let data = date.getUTCSeconds();
		if(data < 10){
			data = '0'+data;
		}

		return data;
	}

	getFullYear(date) {
		return date.getFullYear();
	}

	getMonth(date){
		let month = date.getMonth();
		month = month + 1;
		if(month < 10){
			month = '0'+month;
		}

		return month;
	}

	getDate(date){
		return date.getDate();
	}

	getHours(date) {
		return date.getHours();
	}

	getMinutes(date) {
		return date.getMinutes();
	}

	getSeconds(date) {
		return date.getSeconds();
	}

	getDateWithoutTimeZone(date){
		if (date === this.DEFAULT_DATE) {
			return null;
		}

		if(date && date != '' && date != null){
			date = this.toDate(date);

			return this.getFullYear(date)+'-'+this.getMonth(date)+'-'+this.getDate(date)+' '+this.getHours(date)+':'+this.getMinutes(date)+':'+this.getSeconds(date);

		}

		//return '0000-00-00 00:00:00';
		return null;
	}

	getDateSafari(date){
		if (date === this.DEFAULT_DATE) {
			return '';
		}

		if (date && date != '' && date != null) {
			date = this.toDate(date.replace(/-/g, "/"));

			return this.getFullYear(date)+'/'+this.getMonth(date)+'/'+this.getDate(date)+' '+this.getHours(date)+':'+this.getMinutes(date)+':'+this.getSeconds(date);
		}
		return '';
	}

	getDateWithoutHours(date){
		if (date === this.DEFAULT_DATE) {
			return null;
		}

		if(date && date != ''){
			date = this.toDate(date);

			return this.getFullYear(date)+'-'+this.getMonth(date)+'-'+this.getDate(date)+" 00:00:00";
		}

		//return '0000-00-00 00:00:00';
		return null;
	}

	isValidDate(dateString){
		// First check for the pattern
		if(!/^\d{1,4}-\d{1,2}-\d{2} \d{1,2}:\d{1,2}:\d{1,2}$/.test(dateString))
			return false;

		// Parse the date parts to integers
		var parts = dateString.split("/");
		var day = parseInt(parts[1], 10);
		var month = parseInt(parts[0], 10);
		var year = parseInt(parts[2], 10);

		// Check the ranges of month and year
		if(year < 1000 || year > 3000 || month == 0 || month > 12)
			return false;

		var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

		// Adjust for leap years
		if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
			monthLength[1] = 29;

		// Check the range of the day
		return day > 0 && day <= monthLength[month - 1];
	};

	toDate(date){
		//const date1 = moment();
		if (date === this.DEFAULT_DATE) {
			return '';
		}

		if(date && date != '0000-00-00 00:00:00'){
			let dateRes = new Date(date);

			let status = false;
			if(typeof date === "string") {
				let dateS = date.split(' ');
				if (dateS[0]) {
					let dateS1 = dateS[0].split('/');
					let dateS2 = dateS[0].split('-');

					if (dateS1[0]) {
						if (dateS1[0].length == 4) {
							status = true;
						}
					}

					if (dateS2[0]) {
						if (dateS2[0].length == 4) {
							status = true;
						}
					}

					if (dateS1[2]) {
						if (dateS1[2].length == 4) {
							status = true;
						}
					}

					if (dateS2[2]) {
						if (dateS2[2].length == 4) {
							status = true;
						}
					}
				}
			}


			if(status == true){
				return dateRes;
			}else if(dateRes.getFullYear() > 1900 && dateRes.getFullYear() < 2150){
				return dateRes;
			}else{
				return date;
			}


			//return this.toUTCString(new Date(date));

			//return date1.utc(date);

			//let dateRes = new Date(date);
			//let timezone = -dateRes.getTimezoneOffset();
			//timezone = -240;
			//var currentTimeZoneOffsetInHours = dateRes.getTimezoneOffset() / 60;
			//alert(dateRes.getTimezoneOffset()+"="+currentTimeZoneOffsetInHours)

			//return new Date(Date.UTC(dateRes.getFullYear(), dateRes.getMonth(), dateRes.getDate(), dateRes.getHours(), dateRes.getMinutes(), dateRes.getSeconds()));

			//return new Date(dateRes.valueOf() - timezone * 60000);

			// if(timezone > 0){
			// 	//alert(dateRes.valueOf()+"-"+timezone)
			// 	return new Date(dateRes.valueOf() - timezone * 60000);
			// }else{
			// 	return new Date(dateRes.valueOf() + (timezone * (-1)) * 60000);
			// }

			//new Date(date.valueOf() + date.getTimezoneOffset() * 60000);


			// if(typeof date === "string"){
			// 	var regex_date = /^\d{4}\-\d{1,2}\-\d{1,2}\ \d{1,2}\:\d{1,2}\:\d{1,2}$/;
			//
			// 	if(!regex_date.test(date))
			// 	{
			// 		//return new Date(date);
			// 	}
			//
			// 	// Parse the date parts to integers
			// 	var parts   = date.split("-");
			// 	var day     = parseInt(parts[2], 10);
			// 	var month   = parseInt(parts[1], 10);
			// 	var year    = parseInt(parts[0], 10);
			//
			// 	// Check the ranges of month and year
			// 	if(year < 1000 || year > 3000 || month == 0 || month > 12)
			// 	{
			// 		//return new Date(date);
			// 	}
			//
			// 	var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
			//
			// 	// Adjust for leap years
			// 	if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
			// 	{
			// 		monthLength[1] = 29;
			// 	}
			//
			// 	// Check the range of the day
			// 	//return day > 0 && day <= monthLength[month - 1];
			//
			// 	console.log("ll");
			// 	return new Date(year, month, day);
			// }else{
			// 	return new Date(date);
			// }
		}

		return null;
	}

	convertToDate(data){
		if(typeof data === "string"){
			if(!isNaN(Date.parse(data)) && !this.isInt(data) && !this.isFloat(data)) {
				data = this.toDate(data);
			}
		}else{
			for(var d in data){
				if(typeof data[d] === "object"){
					for(var p in data[d]){
						if(typeof data[d][p] === "object"){
							for(var t in data[d][p]){
								if(typeof data[d][p][t] === "object"){
									for(var k in data[d][p][t]){
										if(!isNaN(Date.parse(data[d][p][t][k])) && !this.isInt(data[d][p][t][k]) && !this.isFloat(data[d][p][t][k])){
											data[d][p][t][k] = this.toDate(data[d][p][t][k]);
										}
									}
								}else{
									if(!isNaN(Date.parse(data[d][p][t])) && !this.isInt(data[d][p][t]) && !this.isFloat(data[d][p][t])){
										data[d][p][t] = this.toDate(data[d][p][t]);
									}
								}
							}
						}else{
							if(!isNaN(Date.parse(data[d][p])) && !this.isInt(data[d][p]) && !this.isFloat(data[d][p])){
								data[d][p] = this.toDate(data[d][p]);
							}
						}
					}
				}else{
					if(!isNaN(Date.parse(data[d])) && !this.isInt(data[d]) && !this.isFloat(data[d])){
						data[d] = this.toDate(data[d]);
					}
				}
			}
		}


		return data;
	}

	isInt(value) {
		return !isNaN(value) && (function(x) { return (x | 0) === x; })(parseFloat(value))
	}

	isFloat(n){
		return Number(n) === n && n % 1 !== 0;
	}

	dateToFormat(date) {
		if (date === this.DEFAULT_DATE || !!date) {
			return '';
		}
		const datePipe = new DatePipe("en-US");
		if (date) {
			date = datePipe.transform(date.replace(/ /g,"T"), this.DATE_FORMAT.defaultPipe);
		}
		return date;
	}

	setDateFormat(date) {
		if (date && (new Date(date).toUTCString() !== new Date('1969-12-31 00:00:00').toUTCString())) {
			const newDate = new Date(date);
			return newDate;
		}
		return null;
	}
}
