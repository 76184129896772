import {Injectable} from '@angular/core';
import {SSA_LEVELS} from '../@config/ssa-levels';

@Injectable({
	providedIn: 'root'
})
export class SSALevelService {

	private config = SSA_LEVELS;

	constructor() {
	}

	getLevelName(id) {
		let name;
		this.config.find(el => {
			if (+el.id === +id) {
				name = el.name;
			}
		});
		return name;
	}
}
