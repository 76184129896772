import {Injectable} from '@angular/core';
import {ClosedCode, CodeItem, Impairment, PolicyHolderCode} from '../@models/follow-up/code-item';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {CODES_DICTIONARY_CONFIG} from '../@config/api';
import {FileUploadingService} from '../../@util/file-uploading-progress/file-uploading.service';
import { Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CodeDictionaryService {
    private config = CODES_DICTIONARY_CONFIG;

    constructor(private http: HttpClient, private fileService: FileUploadingService) {
    }

    getFollowUpsCodesByType(claimantLevelType) {
        return this.http.get<CodeItem[]>(this.config.FOLLOW_UPS.getCodesByType(claimantLevelType));
    }

    getFollowUpsCodes() {
        return this.http.get<CodeItem[]>(this.config.FOLLOW_UPS.getCodes);
    }

    addFollowUpsCode(data) {
        return this.http.post<CodeItem>(this.config.FOLLOW_UPS.addCode, data);
    }

    getFollowUpsCode(id: string) {
        return this.http.get<CodeItem>(this.config.FOLLOW_UPS.getCode(id));
    }

    getAvailableFollowUpCodes(type: string) {
        return this.http.get<number[]>(this.config.FOLLOW_UPS.getAvailable(type));
    }

    getProspectsCode(id: string) {
        return this.http.get<CodeItem>(this.config.PROSPECTS_TYPE.getCode(id));
    }

    getProspectsCodes() {
        return this.http.get<CodeItem[]>(this.config.PROSPECTS_TYPE.getCodes);
    }

    addProspectsCode(data) {
        return this.http.post<CodeItem>(this.config.PROSPECTS_TYPE.addCode, data);
    }

    getImpairmentsCodes() {
        return this.http.get<Impairment[]>(this.config.IMPAIRMENT.getCodes);
    }

    addImpairmentsCode(data: Impairment) {
        return this.http.post<Impairment>(this.config.IMPAIRMENT.addCode, data);
    }

    getImpairmentsCode(id: string) {
        return this.http.get<Impairment>(this.config.IMPAIRMENT.getCode(id));
    }

    importImpairmentsCodeFromFile(selectedFile: any, splitter: string) {
        const formData: FormData = new FormData();
        if (selectedFile === undefined || selectedFile === null) {
            return;
        }
        formData.append('file', selectedFile);

        formData.append('splitter', splitter);

        const req = new HttpRequest('POST', this.config.IMPAIRMENT.fromFile, formData, {
            reportProgress: true,
            responseType: 'text'
        });

        return this.http.request(req);
    }

    getReferralTypeCodes() {
        return this.http.get<CodeItem[]>(this.config.REFERRAL_TYPE.getCodes);
    }

    addReferralTypeCode(data) {
        return this.http.post<CodeItem>(this.config.REFERRAL_TYPE.addCode, data);
    }

    getReferralTypeCode(id: string) {
        return this.http.get<CodeItem>(this.config.REFERRAL_TYPE.getCode(id));
    }

    findImairamentCodeLike(value: string) {
        return this.http.get<CodeItem[]>(this.config.IMPAIRMENT.findLike(value));

    }
    findImairamentCodeByDescription(value: string) {
        return this.http.get<CodeItem[]>(this.config.IMPAIRMENT.findByDescription(value));

    }
    getPostAwardCodes() {
        return this.http.get<CodeItem[]>(this.config.POST_AWARD.getCodes);
    }

    getPostAwardCode(id: string) {
        return this.http.get<CodeItem>(this.config.POST_AWARD.getCode(id));
    }

    addPostAwardCode(value: any) {
        return this.http.post<CodeItem>(this.config.POST_AWARD.addCode, value);
    }

    getFormsSetCodes() {
        return this.http.get<CodeItem[]>(this.config.FORMS_SET.getCodes);
    }

    getFormsSetCode(id: string) {
        return this.http.get<CodeItem>(this.config.FORMS_SET.getCode(id));
    }

    addFormsSetCode(value: any) {
        return this.http.post<CodeItem>(this.config.FORMS_SET.addCode, value);
    }
    getPolicyholderCodes() {
        return this.http.get<PolicyHolderCode[]>(this.config.POLICYHOLDER.getCodes);
    }

    getPolicyholderCode(id: string) {
        return this.http.get<CodeItem>(this.config.POLICYHOLDER.getCode(id));
    }

    addPolicyholderCode(value: any) {
        return this.http.post<CodeItem>(this.config.POLICYHOLDER.addCode, value);
    }
    getClosedCodes() {
        return this.http.get<ClosedCode[]>(this.config.CLOSED.getCodes);
    }

    getClosedCode(id: string) {
        return this.http.get<CodeItem>(this.config.CLOSED.getCode(id));
    }

    getAvailableClosedCodes(type: string) {
        return this.http.get(this.config.CLOSED.getAvalibleCode(type), {responseType: 'text'});
    }

    getClosedCodeTypes() {
        return this.http.get<string[]>(this.config.CLOSED.getTypes);
    }

    addClosedCode(data) {
        return this.http.post<CodeItem>(this.config.CLOSED.addCode, data);
    }

    isClosedCodeExist(newCode: string) {
        return this.http.get(this.config.CLOSED.isExist(newCode), {responseType: 'text'});
    }

}
