/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./medical-treating-sources.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tabs";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/material/core";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i10 from "@angular/cdk/bidi";
import * as i11 from "@angular/cdk/scrolling";
import * as i12 from "./medical-treating-sources.component";
var styles_MedicalTreatingSourcesComponent = [i0.styles];
var RenderType_MedicalTreatingSourcesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MedicalTreatingSourcesComponent, data: {} });
export { RenderType_MedicalTreatingSourcesComponent as RenderType_MedicalTreatingSourcesComponent };
function View_MedicalTreatingSourcesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "a", [["class", "mat-tab-link"], ["mat-tab-link", ""], ["routerLinkActive", ""]], [[1, "aria-current", 0], [1, "aria-disabled", 0], [1, "tabIndex", 0], [2, "mat-tab-disabled", null], [2, "mat-tab-label-active", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, [[1, 4]], 0, i2.MatTabLink, [i2.MatTabNav, i1.ElementRef, i1.NgZone, i3.Platform, [2, i4.MAT_RIPPLE_GLOBAL_OPTIONS], [8, null], i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { active: [0, "active"] }, null), i1.ɵdid(2, 671744, [[3, 4]], 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(3, 1720320, [["rla", 4]], 2, i7.RouterLinkActive, [i7.Router, i1.ElementRef, i1.Renderer2, [2, i7.RouterLink], [2, i7.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 2, { links: 1 }), i1.ɵqud(603979776, 3, { linksWithHrefs: 1 }), i1.ɵpod(6, { exact: 0 }), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["\n      ", "\n    "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "]))], function (_ck, _v) { var currVal_7 = i1.ɵnov(_v, 3).isActive; _ck(_v, 1, 0, currVal_7); var currVal_8 = _v.context.$implicit.path; _ck(_v, 2, 0, currVal_8); var currVal_9 = _ck(_v, 6, 0, false); var currVal_10 = ""; _ck(_v, 3, 0, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).active; var currVal_1 = i1.ɵnov(_v, 1).disabled; var currVal_2 = i1.ɵnov(_v, 1).tabIndex; var currVal_3 = i1.ɵnov(_v, 1).disabled; var currVal_4 = i1.ɵnov(_v, 1).active; var currVal_5 = i1.ɵnov(_v, 2).target; var currVal_6 = i1.ɵnov(_v, 2).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_11 = _v.context.$implicit.label; _ck(_v, 9, 0, currVal_11); }); }
function View_MedicalTreatingSourcesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "nav", [["class", "mat-tab-nav-bar mat-tab-header"], ["mat-tab-nav-bar", ""]], [[2, "mat-tab-header-pagination-controls-enabled", null], [2, "mat-tab-header-rtl", null], [2, "mat-primary", null], [2, "mat-accent", null], [2, "mat-warn", null]], null, null, i9.View_MatTabNav_0, i9.RenderType_MatTabNav)), i1.ɵdid(1, 7520256, null, 1, i2.MatTabNav, [i1.ElementRef, [2, i10.Directionality], i1.NgZone, i1.ChangeDetectorRef, i11.ViewportRuler, [2, i3.Platform], [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵqud(603979776, 1, { _items: 1 }), (_l()(), i1.ɵted(-1, 0, ["\n  "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_MedicalTreatingSourcesComponent_2)), i1.ɵdid(5, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.navLinks; _ck(_v, 5, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._showPaginationControls; var currVal_1 = (i1.ɵnov(_v, 1)._getLayoutDirection() == "rtl"); var currVal_2 = ((i1.ɵnov(_v, 1).color !== "warn") && (i1.ɵnov(_v, 1).color !== "accent")); var currVal_3 = (i1.ɵnov(_v, 1).color === "accent"); var currVal_4 = (i1.ɵnov(_v, 1).color === "warn"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_MedicalTreatingSourcesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MedicalTreatingSourcesComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["style", "flex: 1; display: flex; flex-direction: column;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(6, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hideHeader; _ck(_v, 1, 0, currVal_0); _ck(_v, 6, 0); }, null); }
export function View_MedicalTreatingSourcesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-medical-treating-sources", [], null, null, null, View_MedicalTreatingSourcesComponent_0, RenderType_MedicalTreatingSourcesComponent)), i1.ɵdid(1, 114688, null, 0, i12.MedicalTreatingSourcesComponent, [i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MedicalTreatingSourcesComponentNgFactory = i1.ɵccf("app-medical-treating-sources", i12.MedicalTreatingSourcesComponent, View_MedicalTreatingSourcesComponent_Host_0, {}, {}, []);
export { MedicalTreatingSourcesComponentNgFactory as MedicalTreatingSourcesComponentNgFactory };
