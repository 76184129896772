<!--<div #footer style="height: 5px; width: 770px; background-color: white"></div>-->
<!--<div class="view-wrapper">-->

<!--	<div #head>-->
<!--		<app-report-header [sslgId]="pdfSrc.data[0]?.sslgId"></app-report-header>-->
<!--	</div>-->
<!--	<div  class="view-wrapper" #report>-->
<!--		<div #body class="visit-card" *ngFor="let visit of pdfSrc.data">-->
<!--			<app-doctors-report [tableData]="visit" *ngIf="visit.sourceType == 'DOCTOR'"></app-doctors-report>-->
<!--			<app-hospitals-report [tableData]="visit" *ngIf="visit.sourceType == 'HOSPITAL'"></app-hospitals-report>-->
<!--			<app-others-report [tableData]="visit" *ngIf="visit.sourceType == 'OTHER'"></app-others-report>-->
<!--		</div>-->
<!--	</div>-->

<!--</div>-->

<!--<div #footer style="height: 5px; background-color: white"></div>-->
<div *ngIf="showContent && data" class="view-wrapper">

	<div  class="view-wrapper view-wrapper--no-mobile-padding" #report>
		<div #body class="visit-card">
			<div class="print-button no-print">
				<div class="form-buttons">
					<form-button (clickOnButton)="print()">Print</form-button>
				</div>
			</div>

			<app-medical-treating-report-medications
					[headerInfo]="headerInfo"
					[tableData]="medicationsData"
					*ngIf="type == MEDICAL_TREATING_REPORT_TYPE.medications">
			</app-medical-treating-report-medications>

			<app-medical-treating-report-mts
					[headerInfo]="headerInfo"
					[tableData]="MTSData"
					*ngIf="type == MEDICAL_TREATING_REPORT_TYPE.mts || type === MEDICAL_TREATING_REPORT_TYPE.both">
			</app-medical-treating-report-mts>
		</div>
	</div>



</div>
