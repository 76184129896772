import { HttpClient } from "@angular/common/http";
import { POST_AWARD_CONFIG } from "../@config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PostAwardDetailsService {
    constructor(http) {
        this.http = http;
        this.config = POST_AWARD_CONFIG.award_details;
    }
    getOverview(sslgId) {
        return this.http.get(this.config.overview(sslgId));
    }
    setOverview(sslgId, data) {
        return this.http.post(this.config.overview(sslgId), data);
    }
    getDependent(sslgId) {
        return this.http.get(this.config.dependent(sslgId));
    }
    setDependent(sslgId, data) {
        return this.http.post(this.config.dependent(sslgId), data);
    }
    getPrimary(sslgId) {
        return this.http.get(this.config.primary(sslgId));
    }
    setPrimary(sslgId, data) {
        return this.http.post(this.config.primary(sslgId), data);
    }
}
PostAwardDetailsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PostAwardDetailsService_Factory() { return new PostAwardDetailsService(i0.ɵɵinject(i1.HttpClient)); }, token: PostAwardDetailsService, providedIn: "root" });
