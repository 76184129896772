import {DynamicInputModel} from "@ng-dynamic-forms/core";
import {isObservable, Observable, of} from "rxjs";
import {EventEmitter} from "@angular/core";
import {tap} from "rxjs/operators";

export class DynamicInputAutocompleteModel extends DynamicInputModel {
	list$: Observable<any[]> | null = null;
	autocompleteSelectedValue = null;
	autocompleteSelectedValueChanged = new EventEmitter();
	set list(list: any[] | Observable<any[]> | null) {

		if (Array.isArray(list)) {

			(<any>this)._list = list;
			this.list$ = of((<any>this)._list);

		} else if (isObservable(list)) {

			this.list$ = (list as Observable<any[]>).pipe(tap(list => (<any>this)._list = list));

		} else {

			(<any>this)._list = null;
			this.list$ = null;
		}
	}
}
