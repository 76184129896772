<app-frame [overlay]="false" [title]="title" [showCloseButton]="false" autocomplete="off">
	<div class="main-content__wrapper">
		<div class="controls">
			<div class="show-archived">
				<div>
					<nav mat-tab-nav-bar>
						<a mat-tab-link *ngFor="let link of navLinks; let i = index"
						   (click)="showBlock(i)"
						   [active]="blockTabActive(i)">
							<b>
								{{link.label}}
							</b>
						</a>
					</nav>
				</div>
			</div>
		</div>
	</div>

	<div id="block-tab-info" class="blocks-tab">
		<form *ngIf="this.globalSaveService.checkDisabledFields(this.form)" class="form-horizontal" [formGroup]="formGroup" novalidate>
			<mat-form-field *ngIf="showName()" class="name_input" [ngClass]="{'dirty': isInputDirty()}">
				<input required
					   matInput
					   placeholder="Name"
					   aria-label="Name"
					   [matAutocomplete]="auto"
					   [formControl]="nameCtrl">
				<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
			  <span *ngIf="!isApp()">
					<mat-option [class.grey]="name.id != -1" *ngFor="let name of filteredNames | async" [value]="name"
								[matTooltip]="name.label ? name.label : null">
						<span [class.grey]="!name.label">{{name.name}}</span>
					</mat-option>
			  </span>
					<span *ngIf="isApp()">
			  	<mat-option [class.grey]="name.id != -1" *ngFor="let name of filteredNames | async" [value]="name">
					<span [class.grey]="!name.label">{{name.name}}</span>
				</mat-option>
		 	</span>
				</mat-autocomplete>
			</mat-form-field>

			<dynamic-material-form [group]="formGroup"
								   [layout]="formLayout"
								   [model]="formModel"
								   (blur)="onBlur($event)"
								   (change)="onChange($event); onStateChanges()"
								   (focus)="onFocus($event)">
				<ng-template modelId="medicalConditions" align="START">
					<label class="form-label form-label_full-width form-label_mb">
						What medical conditions were treated?
					</label>
				</ng-template>
<!--				<ng-template modelId="callerName" align="START">-->
<!--					<label class="form-label form-label_full-width form-label_mb">-->
<!--						Who to call for Medical Record requests-->
<!--					</label>-->
<!--				</ng-template>-->
				<ng-template modelId="medicalCondition" let-context="context" let-index="index" align="END">
					<app-form-actions-content *ngIf="showRemoveBtn(context, index)" class="custom-btn col-1 auto-f-basis">

						<button (click)="remove(context, index)" class="close-btn">
							<span></span>
							<span></span>
						</button>
					</app-form-actions-content>
					<app-form-actions-content *ngIf="showAddBtn(context, index)" class="col-12">
						<form-button (clickOnButton)="insertMedicalCondition()">Add
						</form-button>
					</app-form-actions-content>
				</ng-template>
			</dynamic-material-form>

		</form>

		<app-form-actions-content *ngIf="canAccess()">
			<form-button *ngIf="showSave()"
						 (clickOnButton)="addVisit()"
						 buttonType="save"
						 title="Add Visit">
				Add Visit
			</form-button>
			<form-button *ngIf="showSave()"
						 (clickOnButton)="saveDoctor('new')"
						 buttonType="save"
						 title="{{getBtnTitle()}}">
				{{getBtnName()}}
			</form-button>
			<!--    <form-button *ngIf="!disabledBtn"-->
			<!--				 (clickOnButton)="saveDoctor()"-->
			<!--				 buttonType="save"-->
			<!--				 title="{{getBtnTitle()}}">-->
			<!--		{{getBtnName()}}-->
			<!--	</form-button>-->

			<!--	  <form-button *ngIf="disabledBtn"-->
			<!--				   buttonType="disabled"-->
			<!--				   title="{{getBtnTitle()}}">-->
			<!--		  {{getBtnName()}}-->
			<!--	  </form-button>-->
		</app-form-actions-content>
	</div>

	<div id="block-tab-requests" class="blocks-tab" style="display: none">
		<app-medical-requests
			[contacts]="doctorContacts"
		></app-medical-requests>
	</div>

	<div id="block-tab-payments" class="blocks-tab" style="display: none">
		<app-medical-payments></app-medical-payments>
	</div>

</app-frame>
