import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-table-swiper',
    templateUrl: './table-swiper.component.html',
    styleUrls: ['./table-swiper.component.scss']
})
export class TableSwiperComponent implements OnInit {

    @Input() directions;
    canScrollLeft = false;
    canScrollRight = true;

    constructor() {
    }

    ngOnInit() {
        //console.log(this.card);
    }

    ngOnChanges() {
        this.canScrollLeft = this.directions.canScrollLeft;
        this.canScrollRight = this.directions.canScrollRight;
        //console.log(this.directions)
    }

}
