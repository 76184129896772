<table class="report-table medical-test-table">
	<tr>
		<td rowspan="2" style="background: #ccc;">Medical Tests</td>
	</tr>
	<tr>
		<td rowspan="2">Were any medical tests done on this visit?</td>
	</tr>
	<tr>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.biopsy">BIOPSY</mat-checkbox></td>
		<td>{{tableData.biopsyDescription}}</td>
	</tr>
	<tr>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.mri">MRI</mat-checkbox></td>
		<td>{{tableData.mriDescription}}</td>
	</tr>
	<tr>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.blood">BLOOD TEST (NOT HIV)</mat-checkbox></td>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.breathing">BREATHING TEST</mat-checkbox></td>
	</tr>
	<tr>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.cardiac">CARDIAC CATHERIZATION</mat-checkbox></td>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.ecg">ECG (BRAIN WAVE TEST)</mat-checkbox></td>
	</tr>
	<tr>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.ekg">EKG (HEART TEST</mat-checkbox></td>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.hearing">HEARING TEST</mat-checkbox></td>
	</tr>
	<tr>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.iq">IQ TEST</mat-checkbox></td>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.speech">SPEECH/LANGUAGE</mat-checkbox></td>
	</tr>
	<tr>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.treadmill">TREADMILL TEST</mat-checkbox></td>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.vision">VISION TEST</mat-checkbox></td>
	</tr>
	<tr>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.hiv">HIV TEST</mat-checkbox></td>
		<td></td>
	</tr>
	<tr>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.xRay">X-RAY</mat-checkbox></td>
		<td>{{tableData.xRayDescription}}</td>
	</tr>
	<tr>
		<td><mat-checkbox class="mat-check-visits" [disabled]="true" [checked]="tableData.other">OTHER</mat-checkbox></td>
		<td>{{tableData.otherDescription}}</td>
	</tr>
</table>
