import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class PdfViewBlocksMedicationsComponent {
    constructor() {
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
        });
    }
}
