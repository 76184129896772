import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

import {} from 'googlemaps';
import {DialogService} from "../../../@util/dialog/dialog.service";

@Component({
	selector: 'app-google-map',
	templateUrl: './google-map.component.html',
	styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit {

	address;

	@ViewChild('mapContainer', { static: false }) gmap: ElementRef;
	map: google.maps.Map;

	constructor(private dialogRef: MatDialogRef<GoogleMapComponent>,
				private dialog: DialogService,
				@Inject(MAT_DIALOG_DATA) public data:any) {
	}

	ngOnInit() {

	}

	ngAfterViewInit() {
		this.mapInitializer();
	}

	closeForm() {
		this.dialogRef.close();
	}

	mapInitializer() {
		var $map = this.map;
		var $gmap = this.gmap;

		if(this.data.address != ''){
			var geocoder = new google.maps.Geocoder();
			geocoder.geocode( { 'address': this.data.address}, function(results, status) {
				if (status == 'OK') {
					var mapOptions: google.maps.MapOptions = {
						center: results[0].geometry.location,
						zoom: 16
					};

					var marker = new google.maps.Marker({
						position: results[0].geometry.location,
						map: $map,
					});

					$map = new google.maps.Map($gmap.nativeElement,mapOptions);

					marker.setMap($map);
				} else {
					this.dialog.show("Geo coordinates were not determined", ['OK']);
					//alert('Geocode was not successful for the following reason: ' + status);
				}
			});
		}else{
			this.dialogRef.close();
			this.dialog.show("Address was not defined", ['OK']);
		}

	}
}
