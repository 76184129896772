import {Component,Input, OnInit, ViewChild} from '@angular/core';
import {LoadingService} from "../../../../@util/loading/loading.service";

@Component({
	selector: 'app-medical-treating-report-medications',
	templateUrl: './medical-treating-report-medications.component.html',
	styleUrls: ['./medical-treating-report-medications.component.scss']
})
export class MedicalTreatingReportMedicationsComponent implements OnInit {

	@Input() tableData;
	@Input() headerInfo;

	constructor(private loadingService: LoadingService) {
	}
	@ViewChild('element', { static: true })
	public element;

	ngOnInit() {
		this.loadingService.loaded();
		//console.log(this.tableData);
	}
}
