import {Component, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {FIELD_TYPES} from "../../../app/@config/prospects/fieldTypes.config";
import {DatePipe} from "@angular/common";
import {
	addMonths,
	eachDay,
	endOfMonth,
	format,
	getDate, getDay, getMonth,
	getYear, isSameDay, isSameMonth, isSameYear, isToday,
	setDay, setMonth,
	setYear,
	startOfMonth, subDays,
	subMonths
} from "date-fns";
import {animate, state, style, transition, trigger} from "@angular/animations";

export interface DialogData {
	date: Date,
	position: string,
	minYear: number,
	maxYear: number,
	selectedYear: number,
	firstCalendarDay: any,
	startPosition: string,
	innerValue: Date,
	displayFormat: string,
	locale: object,
	displayValue: string;
	barTitle: string;
	barTitleFormat: string;
	barTitleIfEmpty: string;
	useEmptyBarTitle: boolean;
	dayNamesFormat: string;
	item: any;
	fieldType: any;
}

@Component({
	selector: 'app-picker',
	templateUrl: './picker.component.html',
	styleUrls: ['./picker.component.scss'],
	animations: [
		trigger('openCloseCalendar', [
			state('open', style({
				transform: 'scale(1, 1)',
				opacity: 1,
			})),
			state('closed', style({
				transform: 'scale(1, 0.8)',
				opacity: '0'
			})),
			transition('open => closed', [
				animate('250ms cubic-bezier(0,0,0.2,1)')
			]),
			transition('closed => open', [
				animate('250ms cubic-bezier(0,0,0.2,1)')
			]),
		]),
	],
})
export class PickerComponent implements OnInit, OnDestroy {

	@ViewChild('ngxPickerCalendar', { static: true }) ngxPickerCalendar;

	originalMonth;
	originalYears;

	toggleViewButtons: 'days' | 'years' | 'month' = 'days';
	days: {
		date: Date;
		day: number;
		month: number;
		year: number;
		inThisMonth: boolean;
		isToday: boolean;
		isSelected: boolean;
		isSelectable: boolean;
	}[];
	calculatedYearsArr: number;
	dayNames: string[];
	view: string;
	month: { month: string; isCurrentMonth: boolean }[];
	years: { year: number; isThisYear: boolean; isCurrentYear: boolean }[];
	monthNameList = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec',];
	calendarTopPos: number;
	calendarLeftPos: number;
	isOpened: boolean = false;

	constructor(public dialogRef: MatDialogRef<PickerComponent>,
				@Inject(MAT_DIALOG_DATA) public data: DialogData) {
	}

	ngOnInit() {
		this.view = FIELD_TYPES.yearDate===this.data.fieldType ? 'years' : 'days';
		//console.log(this.data);
		this.init();
		this.initDayNames();
		this.initMonth();
		this.changeYearsList();
		setTimeout(() => {
			this.setPickerPosition();
		}, 220);
		this.goToSelectedDate();
	}

	init(dirty?): void {
		// this.date may be null after .reset(); fall back to current date.
		// const actualDate = this.data.date || new Date();
		let actualDate;
		//console.log(this.data.date);
		if (dirty) {
			actualDate = this.data.date || new Date();
		} else {
			actualDate = this.data.innerValue || this.data.date || new Date();
		}
		//console.log(actualDate);
		const start = startOfMonth(actualDate);
		const end = endOfMonth(actualDate);

		this.days = eachDay(start, end).map(date => {

			let x =  {
				date: date,
				day: getDate(date),
				month: getMonth(date),
				year: getYear(date),
				inThisMonth: true,
				isToday: isToday(date),
				isSelected: isSameDay(date, this.data.innerValue) && isSameMonth(date, this.data.innerValue) && isSameYear(date, this.data.innerValue),
				isSelectable: true //this.isDateSelectable(date)
			};
			if (x.isSelected) {
				//console.log(x, date, this.data.innerValue)
			}return x;
		});

		const tmp = getDay(start) - this.data.firstCalendarDay;
		const prevDays = tmp < 0 ? 7 - this.data.firstCalendarDay : tmp;

		for (let i = 1; i <= prevDays; i++) {
			const date = subDays(start, i);
			this.days.unshift({
				date: date,
				day: getDate(date),
				month: getMonth(date),
				year: getYear(date),
				inThisMonth: false,
				isToday: isToday(date),
				isSelected: isSameDay(date, this.data.innerValue) && isSameMonth(date, this.data.innerValue) && isSameYear(date, this.data.innerValue),
				isSelectable: true //this.isDateSelectable(date)
			});
		}

		this.data.displayValue = format(this.data.innerValue, this.data.displayFormat, this.data.locale);
		this.data.barTitle = format(start, this.data.barTitleFormat, this.data.locale);
		// if (this.data.innerValue) {
		//
		// } else {
		//   this.data.displayValue = '';
		//   this.data.barTitle = this.data.useEmptyBarTitle ? this.data.barTitleIfEmpty : format(start, this.data.barTitleFormat, this.data.locale);
		// }
	}

	initDayNames(): void {
		this.dayNames = [];
		const start = this.data.firstCalendarDay;
		for (let i = start; i <= 6 + start; i++) {
			const date = setDay(new Date(), i);
			this.dayNames.push(format(date, this.data.dayNamesFormat, this.data.locale));
		}
	}

	initMonth(): void {
		let currMonth = new Date().getMonth();
		this.month = this.monthNameList.map((k, index) => {
			return {
				month: k,
				isCurrentMonth: currMonth == index,
			}
		});
	}

	private changeYearsList(which?) {
		let currentYear = new Date().getFullYear();

		if (!which) {
			if (this.data.startPosition === 'start') {
				this.data.minYear = currentYear - 1;
				this.data.maxYear = currentYear + 23;
			} else if (this.data.startPosition === 'center') {
				this.data.minYear = currentYear - 10;
				this.data.maxYear = currentYear + 14;
			} else if (this.data.startPosition == 'end') {
				this.data.minYear = currentYear - 22;
				this.data.maxYear = currentYear + 2;
			}
		} else {
			if (which == 'prev') {
				this.data.maxYear = this.data.minYear;
				this.data.minYear = this.data.minYear - 24;

			} else if (which == 'next') {
				this.data.minYear = this.data.maxYear;
				this.data.maxYear = this.data.maxYear + 24;

			}
		}
		this.calculatedYearsArr = this.data.maxYear - this.data.minYear;
		this.initYears();
	}

	initYears(): void {

		const range = this.calculatedYearsArr;
		this.years = Array.from(new Array(range), (x, i) => i + this.data.minYear).map(year => {
			return {
				year: year,
				isThisYear: year === getYear(this.data.date),
				isCurrentYear: year === new Date().getFullYear()
			};
		});

	}

	next(): void {
		if (this.toggleViewButtons === 'days') {
			// if (this.data.innerValue) {
			// 	this.data.date = this.data.innerValue;
			// }
			this.data.date = addMonths(this.data.date, 1);
			// if (this.data.innerValue) {
			// 	this.data.innerValue = addMonths(this.data.innerValue, 1);
			// }

		} else if (this.toggleViewButtons === 'years') {
			this.changeYearsList('next');
		} else {
			this.data.selectedYear = this.data.selectedYear + 1;
			this.data.date = setYear(this.data.date, this.data.selectedYear);
			this.initYears();
		}
		this.init(true);
	}

	prev(): void {
		if (this.toggleViewButtons === 'days') {
			// if (this.data.innerValue) {
			// 	this.data.date = this.data.innerValue;
			// }
			this.data.date = subMonths(this.data.date, 1);
			// if (this.data.innerValue) {
			// 	this.data.innerValue = subMonths(this.data.innerValue, 1);
			// }
		} else if (this.toggleViewButtons === 'years') {
			this.changeYearsList('prev');
		} else {
			this.data.selectedYear = this.data.selectedYear - 1;
			this.data.date = setYear(this.data.date, this.data.selectedYear);
			this.initYears();
		}
		this.init(true);
	}

	toggleView(): void {
		if (this.view === 'days') {
			this.view = 'years';
			this.toggleViewButtons = 'years'
		} else {
			this.view = 'days';
			this.toggleViewButtons = 'days'
		}
	}

	setDate(i: number): void {
		this.data.date = this.days[i].date;
		// this.data.innerValue = this.days[i].date;
		this.dialogRef.close(this.data.date);
		this.isOpened = false;
	}

	setYear(i: number): void {
		this.data.date = setYear(this.data.date, this.years[i].year);
		//console.log(this.data.date);
		this.initYears();
		this.view = 'month';
		this.toggleViewButtons = 'month';
		this.data.selectedYear = this.years[i].year;
		//console.log("YEEEEAR");
		//console.log(this.years[i].year);
	}

	setMonth(i: number) {
		this.data.date = setMonth(this.data.date, i);
		this.init(true);
		this.initMonth();
		if(FIELD_TYPES.yearDate===this.data.fieldType){
			this.dialogRef.close(this.data.date);
			this.isOpened = false;
		}
		this.view = 'days';
		this.toggleViewButtons = 'days';
	}

	private setPickerPosition() {
		let pickerContainer = this.ngxPickerCalendar.nativeElement.getClientRects()[0];
		let itemRect = this.data.item.getClientRects()[0];
		let windowWidth = window.innerWidth;
		let windowHeight = window.innerHeight;

		this.calendarTopPos = itemRect.top;
		this.calendarLeftPos = itemRect.left;


		if (pickerContainer.width + itemRect.left > windowWidth) {
			// this.calendarLeftPos = itemRect.left - ((itemRect.left + pickerContainer.width) - windowWidth) - 10;
			if (itemRect.left + itemRect.width - pickerContainer.width < 10) {
				this.calendarLeftPos = 16;
			} else {
				this.calendarLeftPos = itemRect.left + itemRect.width - pickerContainer.width;
			}
		}
		if (pickerContainer.height + itemRect.top + itemRect.height * 2 > windowHeight) {

			this.calendarTopPos = itemRect.top - ((itemRect.top + pickerContainer.height) - windowHeight) - itemRect.height * 2;
		}

		this.isOpened = true;
	}

	ngOnDestroy(): void {
		this.isOpened = false;
	}

	@HostListener('document:keypress', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent) {
		if (event.keyCode == 32) {
			this.spacePress();
		}
	}

	private spacePress() {

		if (this.view == 'days') {

		} else if (this.view == 'month') {

		} else if (this.view == 'years') {

		}

	}

	private goToSelectedDate() {
		if (this.data.innerValue) {

		}
	}
}
