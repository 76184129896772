import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "../material/material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DynamicFormsCoreModule} from "@ng-dynamic-forms/core";
import {DynamicFormsMaterialUIModule} from "../../@util/ui-material/dynamic-material-form-ui.module";
import {CustomIrisTableModule} from "../../@util/custom-iris-table/custom-iris-table.module";

@NgModule({
    declarations: [],
    imports: [
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        DynamicFormsCoreModule,
        DynamicFormsMaterialUIModule,
		CustomIrisTableModule
    ],
    exports: [
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        DynamicFormsCoreModule,
        DynamicFormsMaterialUIModule,
		CustomIrisTableModule
    ]
})
export class ExportModule {
}
