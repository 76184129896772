import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-file-uploading-progress',
  templateUrl: './file-uploading-progress.component.html',
  styleUrls: ['./file-uploading-progress.component.css']
})
export class FileUploadingProgressComponent {

}
