import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {RestoreComponent} from './restore/restore.component';
import {AuthGuard} from './_guards/auth.guard';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserIdleConfig} from './user-idle/user-idle-config';
import {AuthConfig} from './_services/auth-config';
import {UserIdleComponent} from './user-idle/user-idle/user-idle.component';
import {DialogModule} from '../../@util/dialog/dialog.module';
import {MaterialModule} from '../material/material.module';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {DateResponseInterceptor} from './_helpers/date-response.interceptor';
import {RolesConfig} from './permission-guard/roles-config';
import {CanAccessModule} from "./permission-guard/can-access.module";

export const idleConfigFactory = () => {
	if (window.navigator.userAgent === 'SSLG_Browser') {
		return {idle: 1800, timeout: 60, ping: 180, showIdleDialog: true};
	} else {
		return {idle: 1800, timeout: 60, ping: 180, showIdleDialog: true};
	}
};

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		DialogModule,
		MaterialModule,
		CanAccessModule
	],
	declarations: [
		LoginComponent,
		UserIdleComponent,
		RestoreComponent
	],
	entryComponents: [UserIdleComponent],
	providers: [
		AuthGuard
	],
	exports: [
		LoginComponent,
		RestoreComponent
	]
})
export class AuthModule {
	static forRoot(authConfig: AuthConfig, config: RolesConfig): ModuleWithProviders {

		return {
			ngModule: AuthModule,
			providers: [
				{provide: UserIdleConfig, useFactory: idleConfigFactory},
				{provide: RolesConfig, useValue: config},
				{provide: AuthConfig, useValue: authConfig},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: JwtInterceptor,
					multi: true
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: DateResponseInterceptor,
					multi: true
				}
			]
		};
	}
}
