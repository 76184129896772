<mat-form-field [appearance]="model.getAdditional('appearance', 'legacy')"
                [color]="model.getAdditional('color', 'primary')"
                [floatLabel]="model.getAdditional('floatLabel', LABEL_OPTIONS && LABEL_OPTIONS['float'] || 'auto')"
                [formGroup]="group"
                [hideRequiredMarker]="model.getAdditional('hideRequiredMarker', false)"
                [ngClass]="getClass('grid', 'control')">

    <ng-container *ngIf="model.label" ngProjectAs="mat-label">

        <mat-label>{{ model.label }}</mat-label>

    </ng-container>
	<input hidden matInput placeholder="Input">
	<quill-editor
		[attr.name]="model.name"
		[formControlName]="model.id"
		[id]="elementId"
		[ngClass]="getClass('element', 'control')"
		[placeholder]="model.placeholder"
		[required]="model.required"
		[spellcheck]="model.spellCheck"
		[tabindex]="model.tabIndex"
		(blur)="onBlur($event)"
		(change)="onChange($event)"
		(focus)="onFocus($event)">
<!--		[readonly]="model.readOnly"-->
	</quill-editor>
<!--    <textarea matInput-->
<!--              [cdkTextareaAutosize]="model.getAdditional('cdkTextareaAutosize', true)"-->
<!--              [cols]="model.cols"-->
<!--              [formControlName]="model.id"-->
<!--              [id]="elementId"-->
<!--              [maxlength]="model.maxLength"-->
<!--              [minlength]="model.minLength"-->
<!--              [name]="model.name"-->
<!--              [ngClass]="getClass('element', 'control')"-->
<!--              [placeholder]="model.placeholder"-->
<!--              [readonly]="model.readOnly"-->
<!--              [required]="model.required"-->
<!--              [rows]="model.rows"-->
<!--              [spellcheck]="model.spellCheck"-->
<!--              [tabindex]="model.tabIndex"-->
<!--              [wrap]="model.wrap"-->
<!--              (blur)="onBlur($event)"-->
<!--              (change)="onChange($event)"-->
<!--              (focus)="onFocus($event)"></textarea>-->

<!--    <mat-hint *ngIf="model.hint !== null" align="start" [innerHTML]="model.hint"></mat-hint>-->

<!--    <mat-hint *ngIf="showCharacterHint" align="end">{{ characterHint }}</mat-hint>-->

    <ng-container *ngFor="let message of errorMessages" ngProjectAs="mat-error">

        <mat-error *ngIf="showErrorMessages">{{ message }}</mat-error>

    </ng-container>

</mat-form-field>
