/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../global-save-msg-form.component.scss.shim.ngstyle";
import * as i1 from "./global-save-continue-claimant-update.component.scss.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "../../partial/forms/form-frame/form-frame.component.ngfactory";
import * as i4 from "../../partial/forms/form-frame/form-frame.component";
import * as i5 from "../../partial/form-actions-content/form-actions-content.component.ngfactory";
import * as i6 from "../../partial/form-actions-content/form-actions-content.component";
import * as i7 from "../../partial/form-button/form-button.component.ngfactory";
import * as i8 from "../../partial/form-button/form-button.component";
import * as i9 from "./global-save-continue-claimant-update.component";
import * as i10 from "@angular/common/http";
import * as i11 from "../../../@core/user-info.service";
import * as i12 from "@angular/material/dialog";
import * as i13 from "../../../../@util/dialog/dialog.service";
import * as i14 from "@angular/router";
var styles_GlobalSaveContinueClaimantUpdateComponent = [i0.styles, i1.styles];
var RenderType_GlobalSaveContinueClaimantUpdateComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_GlobalSaveContinueClaimantUpdateComponent, data: {} });
export { RenderType_GlobalSaveContinueClaimantUpdateComponent as RenderType_GlobalSaveContinueClaimantUpdateComponent };
export function View_GlobalSaveContinueClaimantUpdateComponent_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 17, "app-frame", [["autocomplete", "off"]], null, [[null, "neadClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("neadClose" === en)) {
        var pd_0 = (_co.closeVisitForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FormFrameComponent_0, i3.RenderType_FormFrameComponent)), i2.ɵdid(1, 114688, null, 0, i4.FormFrameComponent, [], { title: [0, "title"], autocomplete: [1, "autocomplete"], overlay: [2, "overlay"], showCloseButton: [3, "showCloseButton"] }, { neadClose: "neadClose" }), (_l()(), i2.ɵted(-1, 0, ["\n\t"])), (_l()(), i2.ɵeld(3, 0, null, 0, 1, "div", [], null, null, null, null, null)), (_l()(), i2.ɵted(4, null, ["\n\t\tYou did not save data for this person ", ".\n\t"])), (_l()(), i2.ɵted(-1, 0, ["\n\t"])), (_l()(), i2.ɵeld(6, 0, null, 0, 10, "app-form-actions-content", [], null, null, null, i5.View_FormActionsContentComponent_0, i5.RenderType_FormActionsContentComponent)), i2.ɵdid(7, 114688, null, 0, i6.FormActionsContentComponent, [], null, null), (_l()(), i2.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i2.ɵeld(9, 0, null, 0, 2, "form-button", [], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_FormButtonComponent_0, i7.RenderType_FormButtonComponent)), i2.ɵdid(10, 638976, null, 0, i8.FormButtonComponent, [], null, { clickOnButton: "clickOnButton" }), (_l()(), i2.ɵted(-1, 0, ["Go to Profile"])), (_l()(), i2.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i2.ɵeld(13, 0, null, 0, 2, "form-button", [["buttonType", "warning"]], null, [[null, "clickOnButton"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOnButton" === en)) {
        var pd_0 = (_co.clearVisitForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_FormButtonComponent_0, i7.RenderType_FormButtonComponent)), i2.ɵdid(14, 638976, null, 0, i8.FormButtonComponent, [], { buttonType: [0, "buttonType"] }, { clickOnButton: "clickOnButton" }), (_l()(), i2.ɵted(-1, 0, ["Ignore & Clear"])), (_l()(), i2.ɵted(-1, 0, ["\n\n\t"])), (_l()(), i2.ɵted(-1, 0, ["\n"])), (_l()(), i2.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = "off"; var currVal_2 = false; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); _ck(_v, 7, 0); _ck(_v, 10, 0); var currVal_5 = "warning"; _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.claimantName; _ck(_v, 4, 0, currVal_4); }); }
export function View_GlobalSaveContinueClaimantUpdateComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-global-save-cancel", [], null, null, null, View_GlobalSaveContinueClaimantUpdateComponent_0, RenderType_GlobalSaveContinueClaimantUpdateComponent)), i2.ɵdid(1, 114688, null, 0, i9.GlobalSaveContinueClaimantUpdateComponent, [i10.HttpClient, i11.UserInfoService, i12.MatDialog, i12.MatDialogRef, i12.MAT_DIALOG_DATA, i13.DialogService, i14.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GlobalSaveContinueClaimantUpdateComponentNgFactory = i2.ɵccf("app-global-save-cancel", i9.GlobalSaveContinueClaimantUpdateComponent, View_GlobalSaveContinueClaimantUpdateComponent_Host_0, {}, {}, []);
export { GlobalSaveContinueClaimantUpdateComponentNgFactory as GlobalSaveContinueClaimantUpdateComponentNgFactory };
