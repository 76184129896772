import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FileUploadingProgressComponent} from './file-uploading-progress/file-uploading-progress.component';
import {FileUploadingService} from './file-uploading.service';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [FileUploadingProgressComponent],
  entryComponents: [FileUploadingProgressComponent],
  providers: [FileUploadingService]
})
export class FileUploadingProgressModule {
}
