import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
export class PageLoaderService {
    constructor() {
        this.loaderShow = new BehaviorSubject(null);
    }
    show() {
        this.loaderShow.next(true);
    }
    hide() {
        this.loaderShow.next(false);
    }
}
PageLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PageLoaderService_Factory() { return new PageLoaderService(); }, token: PageLoaderService, providedIn: "root" });
