<div class="report-header" #element>
	<div class="report-header__item">
		<img alt="Original SSLG Logo" class="alert-mail__img" src="../../../../assets/img/logo_yellow.png">
	</div>
	<div class="visit-short">
		<div class="visit-short__item">
			<p>Claimant:</p>
			<p>{{currentUser?.firstName + ' ' + currentUser?.lastName}}</p>
		</div>
		<div class="visit-short__item">
			<p>Phone:</p>
			<p>{{getUserPhone()}}</p>
		</div>
		<div class="visit-short__item">
			<p>Email:</p>
			<p>{{getUserEmail()}}</p>
		</div>
		<div class="visit-short__item" *ngIf="currentUser">
			<p>ICD-10:</p>
			<p>
				<span *ngFor="let j of currentUser.icdId; let i = index;" [hidden]="i > 3">
					{{getIcdId(j,i)}}
				</span>
			</p>
		</div>
	</div>
</div>
