import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Personal} from '../@models/system-managment/personal';
import {Contact} from '../@models/contact';
import * as conf from '../@config/api';
import {User} from '../@models/system-managment/user';
import {Social} from '../@models/system-managment/social';
import {Functions} from '../../@util/functions';
import {Career} from "../@models/system-managment/career";
import {FileUploadingService} from "../../@util/file-uploading-progress/file-uploading.service";

@Injectable({
  providedIn: 'root'
})
export class AdvisorsService {
  private config = conf.MANAGER_USER_CONFIG;

  constructor(private http: HttpClient, private fileUploadingService: FileUploadingService) {

  }

  addUser(data) {
    return this.http.post(this.config.add, data);
  }

  getUsersList() {
    return this.http.get(this.config.getAll('ADVISOR'));
  }

  getCurrentUser(userId) {
    return this.http.get<any>(this.config.getById(userId));
  }

  updatePasswordAndActive(value: User) {
    return this.http.post<any>(this.config.updateLoginInfo(value.username), value);
  }

  setContact(userName: string, value: Contact) {
    value.press = Functions.checkPhone(value.press);
    value.fax = Functions.checkPhone(value.fax);
    value.phone = Functions.checkPhone(value.phone);
    return this.http.post(this.config.setContact(userName), value);
  }

  setPersonal(userName: string, value: Personal) {
    value.phone = Functions.checkPhone(value.phone);
    return this.http.post(this.config.setPersonal(userName), value);
  }

  setSocial(userName: string, value: Social) {
    Social.checkLinck(value);
    return this.http.post(this.config.setSocial(userName), value);
  }

  setCareer(username: string, $event: Career[]) {
    return this.http.post(this.config.setCareer(username), $event);
  }

  uploadAvatar(username: string, avatar: File) {
    return this.fileUploadingService.uploadFile('/api/user/avatar/' + username, {file: avatar, fileInfo: null});
  }
}
