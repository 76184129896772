import {Injectable} from '@angular/core';
import {FileUploadingService} from '../../@util/file-uploading-progress/file-uploading.service';
import {Observable, Subject} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Documents} from "../@models/documents/documents";

@Injectable({
	providedIn: 'root'
})
export class DocumentService {
	private utl = (sslgId) => `/api/claimant/${sslgId}/documents`;

	constructor(private fileUploadingService: FileUploadingService, private http: HttpClient) {
	}

	pushFileToStorage(data: { file: File, fileInfo: { title: string, category: string, description: string, sslgId: string, access: string } }) {
		this.fileUploadingService.uploadFile(this.utl(data.fileInfo.sslgId), data);
	}

	uploadEnded(): Observable<any> {
		return this.fileUploadingService.uploadEnded;
	}

	downloadDocument(sslgId: string, documentId: number) {
		return this.fileUploadingService.downloadDocument(`${this.utl(sslgId)}/${documentId}/download`);
	}

	getDocuments(sslgId: string, title: string) {
		let params = new HttpParams();
		if (title && title.trim().length > 0 && title !== 'null') {
			params = params.append('title', title);
		}
		return this.http.get<Documents[]>(this.utl(sslgId), {params: params});
	}

	getById(sslgId: string, documentId: number) {
		return this.http.get<Documents>(`${this.utl(sslgId)}/${documentId}`);
	}

	isExist(sslgId: string, name: string) {
		return this.http.get<boolean>(`${this.utl(sslgId)}/exist/${name}`);
	}


	getTitlesForClaim(sslgId: string) {
		return this.http.get<string[]>(`${this.utl(sslgId)}/titles`);
	}

	getTitles() {
		return this.http.get<string[]>('/api/claimant/documents/titles');
	}


	updateFileInfo(sslgId: string, documentId: number, formData: any) {
		return this.http.post(`${this.utl(sslgId)}/${documentId}/update`, formData);
	}
}
