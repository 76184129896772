<div class="report-wrapper" #element>
	<div class="report-title others">
		Visit Summaries From SSLG
	</div>

	<table class="report-table">
		<tr>
			<td>Visit From</td>
			<td>{{tableData.visit.visitDate ? (tableData.visit.visitDate | date:'MM/dd/yyyy') : '--/--/----'}}</td>
		</tr>
		<tr>
			<td>Source Name</td>
			<td>{{tableData.catalogItem.name}}</td>
		</tr>

		<tr>
			<td>1st Day:</td>
			<td> {{tableData.visit.firstDay ? "YES" : "NO"}}
				<span
					*ngIf="tableData.visit.firstDay"> {{tableData.visit.firstDayDate ? (" " + (tableData.visit.firstDayDate | date:'MM/dd/yyyy')) : '--/--/----'}}</span>
			</td>
		</tr>
		<tr>
			<td>Home Visit:</td>
			<td> {{tableData.visit.homeVisit ? "YES" : "NO"}}
				<span
					*ngIf="tableData.visit.homeVisit"> {{tableData.visit.homeVisitDate ? (" " + (tableData.visit.homeVisitDate | date:'MM/dd/yyyy')) : '--/--/----'}}</span>
			</td>
		</tr>
		<tr>
			<td class="d-flex align-items-center">Inpatient:</td>
			<td class="d-flex align-items-center">
				{{tableData.visit.inPatient ? 'YES' : 'NO'}}
				<div style="margin-left: 15px">
					<p>
						Admission
						<span
							*ngIf="tableData.visit.inPatient">{{tableData.visit.inPatientAdmission ? (tableData.visit.inPatientAdmission | date:'MM/dd/yyyy') : '--/--/----'}}</span>
					</p>
					<p>
						Discharge
						<span
							*ngIf="tableData.visit.inPatient">{{tableData.visit.inPatientDischarge ? (tableData.visit.inPatientDischarge | date:'MM/dd/yyyy') : '--/--/----'}}</span>
					</p>
				</div>
			</td>
		</tr>
		<tr>
			<td>Reason for Visit</td>
			<td>
				{{tableData.visit.visitReason}}
			</td>
		</tr>
		<tr>
			<td>Details of Treatment</td>
			<td>
				{{tableData.visit.treatmentDetails}}
			</td>
		</tr>
		<tr>
			<td>Next Visit</td>
			<td>
				{{tableData.visit.nextVisit ? (tableData.visit.nextVisit | date:'MM/dd/yyyy') : '--/--/----' }}
			</td>
		</tr>

		<tr *ngIf="tableData.visit.medicalTest">
			<td rowspan="2">
				<app-pdf-view-blocks-medical-test [tableData]="tableData.visit.medicalTest"></app-pdf-view-blocks-medical-test>
			</td>
		</tr>

		<tr *ngIf="tableData.medications.length > 0">
			<td colspan="2">
				<app-pdf-view-blocks-medications [tableData]="tableData.medications"></app-pdf-view-blocks-medications>
			</td>
		</tr>
	</table>
</div>

