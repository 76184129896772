import {
    ApplicationRef,
    ComponentFactoryResolver, ComponentRef, EmbeddedViewRef,
    EventEmitter, Inject,
    Injectable,
    Injector
} from '@angular/core';
import {NotificationConfig} from "./notification-config";
import {NotificationsContainerComponent} from "./notifications-container/notifications-container/notifications-container.component";

@Injectable()
export class CustomIrisNotificationService {

    public static allNotifications = [];

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {
        setTimeout(()=>this.addNotifContainerToDom());
    }

    show(parameters?: NotificationConfig) {
        let param = parameters;
        let foo = new NotificationConfig();

        foo.title = 'Hello world';
        foo.message = 'Test notification message';
        foo.type = 'info';
        foo.imagePosition = 'right';
        foo.progressBar = 'linear';
        foo.autoHide = false;
        foo.timeOut = 3000;
        foo.position = 'rb';

        Object.getOwnPropertyNames(foo).forEach(j => {
            if (param[j])
                foo[j] = param[j];
        });
        foo.genId();
        CustomIrisNotificationService.allNotifications.unshift(...[foo]);

        // if (param.autoHide) {
        //     setTimeout(() => {
        //         // componentRef.instance.isOpen = false;
        //         setTimeout(() => {
        //             this.destroy(this.getComponentRef(NotificationsContainerComponent));
        //         }, 500);
        //     }, param.timeOut);
        // }
        //
        // // Create a component reference from the component
        // const componentRef: any = this.getComponentRef(CustomIrisNotificationComponent);
        // // ////console.log(componentRef);
        //
        // componentRef.instance.title = param.title;
        // componentRef.instance.message = param.message;
        // componentRef.instance.type = param.type;
        // componentRef.instance.timeOut = param.timeOut;
        // componentRef.instance.autoHide = param.autoHide;
        // componentRef.instance.imagePosition = param.imagePosition;
        // componentRef.instance.progressBar = param.progressBar;
        // componentRef.instance.position = param.position;
        //
        // // this.addComponentInDOM(componentRef);
        // componentRef.instance.isOpen = true;
        // // Wait some time and remove it from the component tree and from the DOM
    }

    private getComponentRef(component) {

        const compRef: any = this.componentFactoryResolver
            .resolveComponentFactory(component)
            .create(this.injector);
        // compRef.instance.onCloseDialog.subscribe(j => {
        //     compRef.instance.isOpen = false;
        //     setTimeout(() => {
        //         this.destroy(compRef);
        //     }, 500);
        // });
        return compRef;
    }

    private destroy(componentRef) {
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
    }

    private addComponentInDOM<T>(componentRef: ComponentRef<T>): void {
        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(componentRef.hostView);

        // Get DOM element from component
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        // Append DOM element to the body
        document.body.appendChild(domElem);
    }

    private addNotifContainerToDom() {
        this.addComponentInDOM(this.getComponentRef(NotificationsContainerComponent));
    }

    static close(id: any) {
        let tmp = CustomIrisNotificationService.allNotifications.filter(j => j.id !== id);
        while (CustomIrisNotificationService.allNotifications.length !== 0) {
            CustomIrisNotificationService.allNotifications.pop()
        }
        CustomIrisNotificationService.allNotifications.push(...tmp);
    }
}
