import { HttpClient } from '@angular/common/http';
import { REFERRAL_CONFIG } from '../@config/api';
import { Subject, BehaviorSubject } from 'rxjs';
import { map } from "rxjs/operators";
import { UserInfoService } from "../@core/user-info.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user-info.service";
export class StatusService {
    constructor(http, userInfoService) {
        this.http = http;
        this.userInfoService = userInfoService;
        this.config = REFERRAL_CONFIG.STATUS;
        this.updateStatus = new Subject();
        //formsChanged = false;
        this.formsChanged = new BehaviorSubject(null);
        this.statusChanged = new BehaviorSubject(null);
    }
    checkStatus(sslgId) {
        this.getClaimantStatusDetails(sslgId).subscribe((j) => {
            if (j.status == "true") {
                try {
                    let status = JSON.parse(j.statusData);
                    this.setStatusChanged(status);
                }
                catch (e) { }
            }
        });
    }
    setStatusChanged(status) {
        this.statusChanged.next({ key: Math.round(+new Date() / 1000) + Math.random(), status: status });
    }
    setFormsUnchanged() {
        //this.formsChanged = false;
    }
    setFormsChanged() {
        //this.formsChanged = true;
        this.formsChanged.next({ key: Math.round(+new Date() / 1000) + Math.random() });
    }
    getFormsChanged() {
        return this.formsChanged;
    }
    get(sslgId) {
        return this.http.get(this.config.getById(sslgId));
    }
    /*setIntake(sslgId: string, $event: Intake): Observable<StatusModel> {
        return this.http.post<StatusModel>(this.config.setIntake(sslgId), $event)
            .pipe(map(x => {
                this.updateStatus.next();
                return x;
            }));
    }*/
    getClaimantStatusDetails(sslgId) {
        let userName = this.userInfoService.getCurrentUserName();
        return this.http.post(this.config.getClaimantStatusDetails(sslgId), { userName: userName });
    }
    saveIntake(sslgId, $event) {
        return this.http.post(this.config.setIntake(sslgId), $event);
    }
    setLevelDetails(sslgId, $event) {
        return this.http.post(this.config.setLevelDetails(sslgId), $event)
            .pipe(map(x => {
            this.updateStatus.next();
            return x;
        }));
    }
    setHearingDetails(sslgId, $event) {
        return this.http.post(this.config.setHearingDetails(sslgId), $event);
    }
    setHearingSchedule(sslgId, $event) {
        return this.http.post(this.config.setHearingSchedule(sslgId), $event);
    }
    setTerminated(sslgId, $event) {
        return this.http.post(this.config.setTerminated(sslgId), $event)
            .pipe(map(x => {
            this.updateStatus.next();
            return x;
        }));
    }
    getStatusDetails(sslgId) {
        //console.log(sslgId);
        return this.http.get(this.config.getStatusDetails(sslgId));
    }
    changeStatusEvent() {
        return this.updateStatus;
    }
    setRemanded(sslgId, value) {
        return this.http.post(this.config.setRemanded(sslgId, value.id), value.levelDetails);
    }
    setCeExam(sslgId, value) {
        return this.http.post(this.config.setCeExam(sslgId), value);
    }
    getCeExam(sslgId) {
        return this.http.get(this.config.getCeExam(sslgId));
    }
    getAvailableClosedCodes(sslgId) {
        return this.http.get(this.config.getClosedCodes(sslgId));
    }
    isCanTerminate(sslgId, rez) {
        return this.http.post(this.config.isCanTerminate(sslgId), rez);
    }
}
StatusService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StatusService_Factory() { return new StatusService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserInfoService)); }, token: StatusService, providedIn: "root" });
