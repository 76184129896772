export const Messages = {
  idleHeader: 'Are You Still Here?',
  idleMessage: 'You will be disconnect after seconds',
  sessionMessage: 'Session Ended or Invalid Authorization.',
  serviceTemporarilyUnavailable: 'Service Temporarily Unavailable',

  currencyIntervalValueInvalid: 'Write Correct Value. Minimal Interval is 60 seconds.',
  currencyPutRate: 'Write Correct Value.',
  employeesEnabled: 'Are You Sure?',
  rechargeConfirmation: 'Are You Sure?',
  rechargeIncorectAmount: 'Please Enter the Amount!',
};

