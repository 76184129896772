/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file-uploading-progress.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./file-uploading-progress.component";
var styles_FileUploadingProgressComponent = [i0.styles];
var RenderType_FileUploadingProgressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FileUploadingProgressComponent, data: {} });
export { RenderType_FileUploadingProgressComponent as RenderType_FileUploadingProgressComponent };
export function View_FileUploadingProgressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "example-pizza-party"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      Pizza party!!! \uD83C\uDF55\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_FileUploadingProgressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-file-uploading-progress", [], null, null, null, View_FileUploadingProgressComponent_0, RenderType_FileUploadingProgressComponent)), i1.ɵdid(1, 49152, null, 0, i2.FileUploadingProgressComponent, [], null, null)], null, null); }
var FileUploadingProgressComponentNgFactory = i1.ɵccf("app-file-uploading-progress", i2.FileUploadingProgressComponent, View_FileUploadingProgressComponent_Host_0, {}, {}, []);
export { FileUploadingProgressComponentNgFactory as FileUploadingProgressComponentNgFactory };
