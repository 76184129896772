import {Component,Input, OnInit, ViewChild} from '@angular/core';
import {MEDICAL_TEST_LIST} from "../../partial/partial-medical-tests/partial-medical-tests.config";
import {MEDICAL_TREATING_REPORT_TYPE} from "../../pages/claimants/medical-treating-sources/medical-treating-reports/medical-treating-reports.config";
import {LoadingService} from "../../../../@util/loading/loading.service";

@Component({
	selector: 'app-medical-treating-report-mts',
	templateUrl: './medical-treating-report-mts.component.html',
	styleUrls: ['./medical-treating-report-mts.component.scss']
})
export class MedicalTreatingReportMtsComponent implements OnInit {

	@Input() tableData;
	@Input() headerInfo;

	MEDICAL_TREATING_REPORT_TYPE = MEDICAL_TREATING_REPORT_TYPE;

	constructor(private loadingService: LoadingService) {
	}
	@ViewChild('element', { static: true })
	public element;

	ngOnInit() {
		this.loadingService.loaded();
		console.log(this.tableData);
	}


	getConditions(conditions) {
		if (conditions) {
			return (conditions.split('-||-'));
		}
		return '';
	}

	getTestName(shortName) {
		let title;
		MEDICAL_TEST_LIST.forEach(test => {
			if (shortName === test.shortName) {
				title = test.name;
			}
		});
		return title;
	}

	getTestDate(date) {
		if (date) {
			const isDate = date.slice(8, 10);
			const isMonth = date.slice(5, 7);
			const isYear = date.slice(0, 4);

			return isDate + '/' + isMonth + '/' + isYear;
		}
		return '';
	}

	sortVisits(visits) {
		const visitsSorted = visits.sort((a, b) => {
			// console.log(a, b);
			// outPatient
			// eRVisit
			if (a.inPatient && !b.inPatient) {
				return 1;
			}
		});
		console.log(visitsSorted);
		return visitsSorted;
	}

	getInpatientVisits(visits) {
		const visitsIn = [];
		visits.forEach(visit => {
			if ((visit.inPatient && !visit.outPatient && !visit.eRVisit) && (visit.method && (visit.method == 1))) {
				visitsIn.push(visit);
			}
		});
		visitsIn.sort((a, b) => {
			const aDate = Number(new Date(a.visitDate));
			const bDate = Number(new Date(b.visitDate));
			// console.log(aDate, a.visitDate, bDate, b.visitDate);
			return bDate - aDate;

		});
		// console.log(visitsIn)
		return visitsIn;
	}

	getOutpatientVisits(visits) {
		const visitsIn = [];
		visits.forEach(visit => {
			if ((visit.outPatient && !visit.eRVisit) && (visit.method && (visit.method == 1))) {
				visitsIn.push(visit);
			}
		});
		visitsIn.sort((a, b) => {
			const aDate = Number(new Date(a.visitDate));
			const bDate = Number(new Date(b.visitDate));
			// console.log(aDate, a.visitDate, bDate, b.visitDate);
			return bDate - aDate;

		});
		// console.log(visitsIn)
		return visitsIn;
	}

	getERVisits(visits) {
		const visitsIn = [];
		visits.forEach(visit => {
			if (visit.eRVisit && (visit.method && (visit.method == 1))) {
				visitsIn.push(visit);
			}
		});
		visitsIn.sort((a, b) => {
			const aDate = Number(new Date(a.visitDate));
			const bDate = Number(new Date(b.visitDate));
			// console.log(aDate, a.visitDate, bDate, b.visitDate);
			return bDate - aDate;

		});
		return visitsIn;
	}
}
