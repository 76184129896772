import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuditInfo} from '../@models/audit-info/audit-info';
import {NotifyUser} from '../@models/audit-info/notify-user';
import {Subject} from 'rxjs';
import {AUDIT_LOG_CONFIG} from '../@config/api';

@Injectable({
	providedIn: 'root'
})
export class AuditLogService {

	public $logSent = new Subject();
	private auditLogConfig  = AUDIT_LOG_CONFIG;

	constructor(private http: HttpClient) {
	}

	sendNotification(sslgId, data) {
		//return this.http.post(this.auditLogConfig.sendLog(sslgId), data);
		//http://192.168.56.1:4201/api/claimant/201980001-002/audit-log
		//http://192.168.56.1:4201/api/client/forgot-password

		//alert(this.auditLogConfig.sendLog(sslgId))

		return this.http.post(this.auditLogConfig.sendLog(sslgId), data);
	}

	reSendNotification(sslgId, auditClaimantId, data) {
		return this.http.post(this.auditLogConfig.reSendAuditLog(sslgId, auditClaimantId), data);
	}

	getAuditInfo(sslgId) {
		return this.http.get<any>(this.auditLogConfig.auditLogs(sslgId));
	}

	getAuditInfoLog(auditCalimantId, sslgId) {
		return this.http.get<NotifyUser[]>(this.auditLogConfig.auditLogInfo(sslgId, auditCalimantId));
	}
}
