/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../partial/forms/form-frame/form-frame.component.ngfactory";
import * as i2 from "../../../../../partial/forms/form-frame/form-frame.component";
import * as i3 from "./claimant-notifications-alert-details.component";
import * as i4 from "@angular/material/dialog";
import * as i5 from "../../../../../../../@util/dialog/dialog.service";
import * as i6 from "../../../../../../@core/global-save.service";
var styles_ClaimantNotificationsAlertDetailsComponent = [];
var RenderType_ClaimantNotificationsAlertDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ClaimantNotificationsAlertDetailsComponent, data: {} });
export { RenderType_ClaimantNotificationsAlertDetailsComponent as RenderType_ClaimantNotificationsAlertDetailsComponent };
export function View_ClaimantNotificationsAlertDetailsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "app-frame", [["autocomplete", "off"], ["class", "summaries-frame"]], null, [[null, "neadClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("neadClose" === en)) {
        var pd_0 = (_co.closeForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FormFrameComponent_0, i1.RenderType_FormFrameComponent)), i0.ɵdid(1, 114688, null, 0, i2.FormFrameComponent, [], { title: [0, "title"], autocomplete: [1, "autocomplete"], overlay: [2, "overlay"] }, { neadClose: "neadClose" }), (_l()(), i0.ɵted(-1, 0, ["\n\n\t"])), (_l()(), i0.ɵeld(3, 0, null, 0, 0, "iframe", [["id", "notificationAlert"], ["style", "width: 60vw; height: 80vh;"], ["target", "_parent"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, 0, ["\n\n"])), (_l()(), i0.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var currVal_0 = "Alert"; var currVal_1 = "off"; var currVal_2 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ClaimantNotificationsAlertDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-claimant-notifications-alert-details", [], null, null, null, View_ClaimantNotificationsAlertDetailsComponent_0, RenderType_ClaimantNotificationsAlertDetailsComponent)), i0.ɵdid(1, 114688, null, 0, i3.ClaimantNotificationsAlertDetailsComponent, [i4.MatDialogRef, i5.DialogService, i6.GlobalSaveService, i4.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClaimantNotificationsAlertDetailsComponentNgFactory = i0.ɵccf("app-claimant-notifications-alert-details", i3.ClaimantNotificationsAlertDetailsComponent, View_ClaimantNotificationsAlertDetailsComponent_Host_0, {}, {}, []);
export { ClaimantNotificationsAlertDetailsComponentNgFactory as ClaimantNotificationsAlertDetailsComponentNgFactory };
