import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpRequest} from '@angular/common/http';
import {VisitItem} from '../@models/medical/visit-item';
import {MEDICAL_CONFIG, REFERRAL_CONFIG, NOTIFICATIONS_CONFIG, MEDICAL_SOURCE_CONFIG} from '../@config/api';
import {DoctorItem} from '../@models/medical/doctor-item';
import {HospitalItem} from '../@models/medical/hospital-item';
import {OtherItem} from '../@models/medical/othe-item';
import {CatalogItemType, CatalogItemView} from '../@models/medical/catalog-item-view';
import {MedicalSourcesService} from './medical-sources.service';
import {Observable, Subject} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class MedicalService {
	private claimantUserName;

	private config = MEDICAL_CONFIG;
	private configNotification = NOTIFICATIONS_CONFIG;

	private medicalConditions: string[];

	constructor(private http: HttpClient, private medicalSourcesService: MedicalSourcesService) {

	}


	getClaimantUserName() {
		return this.claimantUserName;
	}

	setClaimantUserName(value: string) {
		this.claimantUserName = value;
	}

	getVisits(sslgId: any) {
		return this.http.get<VisitItem[]>(this.config.visit.getVisits(sslgId));
	}
	getVisit(sslgId, id: string) {
		return this.http.get<any>(this.config.visit.getVisit(sslgId, id));
	}
	saveVisit(sslgId, type: any, data: any) {
		let url;
		if (type === 'DOCTOR') {
			url = this.config.visit.saveDoctorVisit(sslgId);
		} else if (type === 'HOSPITAL') {
			url = this.config.visit.saveHospitalVisit(sslgId);
		} else {
			url = this.config.visit.saveOtherVisit(sslgId);
		}

		//console.log("jjjjj");
		//console.log(data);

		return this.http.post<any>(url, data);
	}

	getMedicalTest(sslgId, visitId) {
		return this.http.get(this.config.visit.getMedicalTests(sslgId, visitId));
	}
	setMedicalTest(sslgId, visitId, $event: any) {
		return this.http.post(this.config.visit.setMedicalTests(sslgId, visitId), $event);
	}
	getMedications(sslgId, visitId: any) {
		return this.http.get<any[]>(this.config.visit.getMedications(sslgId, visitId));
	}
	setMedication(sslgId, visitId: any, value: any) {
		return this.http.post(this.config.visit.setMedications(sslgId, visitId), value);
	}
	getAllMedicationForClaim(sslgId: any) {
		return this.http.get<any[]>(this.config.getAllMedicationForClaim(sslgId));
	}
	getVisitSummaries(sslgId: any, startDate, endDate, source?) {
		let param = new HttpParams();
		if (startDate) {
			param = param
				.append('startDate', startDate.getTime() + '');
		}
		if (endDate) {
			param = param
				.append('endDate', endDate.getTime() + '');
		}
		return this.http.get<VisitItem[]>(this.config.visit.getVisitsSummaries(sslgId), {params: param});
	}

	getVisitNotifications(sslgId: any) {
		return this.http.get<any>(this.config.visit.getVisitNotifications(sslgId));
	}

	getAlertNotifications(sslgId: any) {
		return this.http.get<any>(this.configNotification.getAlerts(sslgId));
	}

	setVisitReaded(sslgId: any){
		return this.http.post<any[]>(this.config.visit.setVisitReaded(sslgId), {});
	}

	getVisitSummariesById(sslgId: any, ids) {
		return this.http.post<any[]>('/api/claimant/'+sslgId+'/findByIds', {sslgId:sslgId,ids:ids});
	}

	saveStepTwo(sslgId, _visitId: any, rez: any) {
		return this.http.post<any[]>(this.config.visit.saveStepTwo(sslgId, _visitId), rez);
	}

	getCatalogItemInfo(sslgId, id: string) {
		return this.http.get<any>(this.config.catalog_items.getSourceInfo(sslgId, id));
	}

	getCatalogVisitItem(sslgId) {
		return this.http.get<any>(REFERRAL_CONFIG.CLAIMANT.getAllCatalogItems(sslgId));
	}

	getAllDoctors(sslgId) {
		return this.http.get<CatalogItemView[]>(this.config.catalog_items.getAllDoctors(sslgId));
	}

	getDoctorById(sslgId, doctorId) { // @Depr?
		return this.http.get<DoctorItem>(this.config.catalog_items.getDoctorById(sslgId, doctorId));
	}

	async setDoctor(sslgId, result: DoctorItem, current: DoctorItem) {
		//if (!current) {
			current = await this.medicalSourcesService.setDoctor(result).toPromise();
		//}
		var param = new HttpParams().append("name", result.name);
		return await this.http.post<DoctorItem>(this.config.catalog_items.setDoctor(sslgId), {
			sslgId: result.sslgId,
			catalogItemId: current.id,
			type: CatalogItemType.DOCTOR,
			phone: current.contact ? current.contact.phone : '',
			fax: current.contact ? current.contact.fax : '',
		}, {params: param}).toPromise();
	}


	async saveTreatingSourcesDoctor(sslgId, result, current: DoctorItem) {
		//if (!current) {
		current = await this.medicalSourcesService.saveTreatingSourcesDoctor(sslgId,result).toPromise();
		//}
		var param = new HttpParams().append("name", result.name);
		return await this.http.post<DoctorItem>(this.config.catalog_items.setDoctor(sslgId), {
			sslgId: result.sslgId,
			catalogItemId: current.id,
			type: CatalogItemType.DOCTOR,
			phone: current.contact ? current.contact.phone : '',
			fax: current.contact ? current.contact.fax : '',
		}, {params: param}).toPromise();
	}

	getHospital(sslgId, hospitalId: number) {//@Depr?
		return this.http.get<HospitalItem>(this.config.catalog_items.getHospitalById(sslgId, hospitalId));
	}

	getAllHospitals(sslgId) {
		return this.http.get<CatalogItemView[]>(this.config.catalog_items.getAllHospitals(sslgId));
	}

	async setHospital(sslgId, result: HospitalItem, current: HospitalItem) {
		if (!current) {
			current = await this.medicalSourcesService.setHospital(result).toPromise();
		}
		var param = new HttpParams().append("name", result.name);
		return this.http.post<HospitalItem>(this.config.catalog_items.setHospital(sslgId), {
			sslgId: result.sslgId,
			catalogItemId: current.id,
			type: CatalogItemType.HOSPITAL
		}, {params: param}).toPromise();
	}

	async saveTreatingSourcesHospital(sslgId, result, current: HospitalItem) {
		current = await this.medicalSourcesService.saveTreatingSourcesHospital(sslgId,result).toPromise();

		var param = new HttpParams().append("name", result.name);
		return this.http.post<HospitalItem>(this.config.catalog_items.setHospital(sslgId), {
			sslgId: result.sslgId,
			catalogItemId: current.id,
			type: CatalogItemType.HOSPITAL
		}, {params: param}).toPromise();
	}


	getOther(sslgId, id: number) {
		return this.http.get<OtherItem>(this.config.catalog_items.getOtherById(sslgId, id));
	}

	getAllOthers(sslgId) {
		return this.http.get<CatalogItemView[]>(this.config.catalog_items.getAllOthers(sslgId));
	}

	async setOther(sslgId, result: OtherItem, current: OtherItem) {
		//console.log(result);
		if (!current) {
			current = await this.medicalSourcesService.setOther(result).toPromise();
		}
		var param = new HttpParams().append("name", result.name);
		return this.http.post<OtherItem>(this.config.catalog_items.setOther(sslgId), {
			sslgId: result.sslgId,
			catalogItemId: current.id,
			type: CatalogItemType.OTHER
		}, {params: param}).toPromise();
	}

	async saveTreatingSourcesOther(sslgId, result, current: OtherItem) {
		//if (!current) {
			current = await this.medicalSourcesService.saveTreatingSourcesOther(sslgId,result).toPromise();
			//current = await this.medicalSourcesService.setOther(result).toPromise();
		//}
		var param = new HttpParams().append("name", result.name);
		return this.http.post<OtherItem>(this.config.catalog_items.setOther(sslgId), {
			sslgId: result.sslgId,
			catalogItemId: current.id,
			type: CatalogItemType.OTHER
		}, {params: param}).toPromise();
	}

	getCatalogItemBySourceIdAndSslgId(catalogItemId, sslgId) {
		return this.http.get<any>(this.config.catalog_items.getCatalogItemBySourceId(catalogItemId, sslgId));
	}
	getCatalogItemById(catalogItemId, sslgId) {
		return this.http.get<any>(this.config.catalog_items.getCatalogItemById(catalogItemId, sslgId));
	}

	getCatalog(type: CatalogItemType) {
		switch (type) {
			case CatalogItemType.DOCTOR:
				return this.medicalSourcesService.getClaimantDoctors(this.getClaimantUserName());
				//return this.medicalSourcesService.getAllDoctors();
				break;
			case CatalogItemType.HOSPITAL:
				return this.medicalSourcesService.getAllHospitals();
				break;
			case CatalogItemType.OTHER:
				return this.medicalSourcesService.getAllOthers();
				break;
			default:
				throw Error('Not Implemented');
		}
	}

	getCatalogItem(type: CatalogItemType, id: any): Observable<any> | Error | any {
		switch (type) {
			case CatalogItemType.DOCTOR:
				return this.medicalSourcesService.getDoctorById(id);
				break;
			case CatalogItemType.HOSPITAL:
				return this.medicalSourcesService.getHospital(id);
				break;
			case CatalogItemType.OTHER:
				return this.medicalSourcesService.getOther(id);
				break;
			default:
				throw Error("Type needed");
		}
	}

	getLastMedicalConditions(sslgId, id) {
		return this.http.get<any[]>(this.config.visit.getLastMedicalConditions(sslgId, id));
	}

	//@Deprecated
	// getSourceRecipients(id: any) {
	// 	return this.http.get<any[]>(this.config.catalog_items.getRecipients(id));
	// }

	// saveVisitSummaries(sslgId, data) {
	// 	return this.request(`/api/medical-visits/${sslgId}/visit-summaries/save`, data);
	// }

	sentVisitSummaries(sslgId, data) {
		return this.request(`/api/medical-visits/${sslgId}/visit-summaries/email`, data);
	}

	printVisitSummaries(sslgId, data) {
		return this.request(`/api/medical-visits/${sslgId}/visit-summaries/print`, data);
	}

	private request(url, data) {
		const req = new HttpRequest('POST', url, data, {responseType: 'arraybuffer'});
		return this.http.request(req);
	}

	setMedicalConditions(sslgId, _visitId: any, medicalConditions: any) {
		return this.http.post<any[]>(this.config.visit.setMedicalCondition(sslgId, _visitId), medicalConditions);
	}

	saveDoctorNextVisit(sslgId, visitId: any, data: any) {
		return this.http.post<any>(this.config.visit.saveDoctorNextVisit(sslgId, visitId), data);
	}

	setMedicalConditionsTemplate(conditions) {
		this.medicalConditions = conditions;
	}

	getMedicationsTemplate() {
		//console.log(this.medicalConditions);
		return this.medicalConditions;
	}

	saveVisits(type: string, visitSourceId: number, sslgId: string,
			   visits: {
				   selected: boolean; visit?: any; visitParameters?: {
					   medications: any, tests: any, notes: {
						   treatments: string,
						   discussion: string,
						   docResponse: string,
						   discusSSDI: string,
						   // hospital
						   hospitalizationReason: string,
						   medicalConditions: string,
						   // hospital/other
						   treatmentDetails: string,
						   // other
						   visitReason: string
					   }
				   }
			   }[], nextVisit) {
		let subscr = new Subject();

		visits.forEach((visit: any, index) => {
			if (visit.visitParameters && visit.visitParameters.notes) {
				if (type == 'DOCTOR') {
					visit.visit.treatments = visit.visitParameters.notes.treatments;
					visit.visit.discussion = visit.visitParameters.notes.discussion;
					visit.visit.docResponse = visit.visitParameters.notes.docResponse;
					visit.visit.discusSSDI = visit.visitParameters.notes.discusSSDI;
				} else {
					visit.visit.treatmentDetails = visit.visitParameters.notes.treatmentDetails;

					if (type == 'HOSPITAL') {
						visit.visit.hospitalizationReason = visit.visitParameters.notes.hospitalizationReason;
						visit.visit.medicalConditions = visit.visitParameters.notes.medicalConditions;
					}
					if (type == 'OTHER') {
						visit.visit.visitReason = visit.visitParameters.notes.visitReason;
					}
				}
			}


			if (nextVisit && index === visits.length - 1)
				visit.nextVisit = nextVisit;
			visit.visit.sslgId = sslgId;
			visit.visit.catalogItemId = visitSourceId;
			if (!visit.visit.visitReason && type == 'DOCTOR')
				visit.visit.visitReason = {};
			this.saveVisit(sslgId, type, visit.visit).subscribe(rez => {
				if (visit.visitParameters) {
					if (visit.visitParameters.tests)
						this.setMedicalTest(sslgId, rez.id, visit.visitParameters.tests).subscribe();
					if (visit.visitParameters.medications)
						this.setMedication(sslgId, rez.id, visit.visitParameters.medications).subscribe();
				}
				if (this.medicalConditions && this.medicalConditions.length)
					this.setMedicalConditions(sslgId, rez.id, this.medicalConditions).subscribe();

				if (index === visits.length - 1) {
					subscr.next(true);
					this.medicalConditions = [];
				}
			});
		});
		return subscr;

	}

	sentVisitSummariesByEmail(report) {
		return this.request(`/api/claimant/${report.sslgId}/visit-summaries/email-report`, report);
	}

	sendVisitSummariesByEmail(sslgId: any,email: any,individual:any, ids) {
		return this.request('/api/claimant/'+sslgId+'/visit-summaries/email-pdf-report', {sslgId:sslgId,email:email,individual:individual,ids:ids});
	}

	saveVisitSummariesReport(report) {
		return this.request(`/api/claimant/${report.sslgId}/visit-summaries/save-report`, report);
	}

	visitWatched(id,sslgId) {
		return this.request(`/api/claimant/${sslgId}/visit-watched`, {visitId:id});
	}

	async saveAllMedications(sslgId, data) {
		return await this.http.post<any>(this.config.changeMedicationStatus(sslgId), data).toPromise();
	}

	async addMedication(sslgId, data) {
		return await this.http.post<any>(this.config.addMedication(sslgId), data).toPromise();
	}

	async getMedicalTreatmentSourcesReport(sslgId, type) {
		return this.http.post<any>(this.config.catalog_items.getMedicalTreatmentSourcesReport(sslgId), {type:type}).toPromise();
	}
}
