<ng-container [formGroup]="group">

    <div role="group"
         [formGroupName]="model.id"
         [id]="elementId"
		 [class.group-dirty]="isDirty()"
         [ngClass]="[getClass('element', 'control'), getClass('grid', 'control')]">

        <dynamic-material-form-control *ngFor="let _model of model.group"
                                       [group]="control"
                                       [hidden]="_model.hidden"
                                       [layout]="layout"
                                       [model]="_model"
                                       [templates]="templates"
                                       [ngClass]="[getClass('element', 'host', _model), getClass('grid', 'host', _model),
                                                   getClass('element','children'), getClass('grid','children')]"
                                       (blur)="onBlur($event)"
                                       (change)="onChange($event)"
                                       (focus)="onFocus($event)"
                                       (matEvent)="onCustomEvent($event, null, true)"></dynamic-material-form-control>
    </div>

</ng-container>
