import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpParams, HttpRequest} from '@angular/common/http';
import {Documents} from '../../app/@models/documents/documents';
import {CustomIrisNotificationService} from "../custom-iris-notification/custom-iris-notification.service";
import {Subject} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class FileUploadingService {
	public uploadEnded = new EventEmitter();

	constructor(private http: HttpClient, private _notifications: CustomIrisNotificationService) {
	}

	uploadFile(url: string, data: {
		file: File,
		fileInfo: { title: string, category: string, description: string, sslgId: string, access: string }
	} | any) {
		const formData: FormData = new FormData();
		if (data.file === undefined || data.file === null)
			return;
		formData.append('file', data.file);
		if (data.fileInfo) {
			Object.keys(data.fileInfo).forEach(key => {
				if (data.fileInfo[key])
					formData.append(key, data.fileInfo[key]);
			});
		}

		const req = new HttpRequest('POST', url, formData, {
			reportProgress: true,
			responseType: 'text'
		});
		let rez = new Subject();
		this.http.request(req).subscribe(j => {
			if (j.type == 0)
				this.show(j.type, data.file.name);
			else if (j.type === 4) {
				this.uploadEnded.emit();
				rez.next();
			}
		});
		return rez;
	}

	getTitles(url: string) {
		return this.http.get<string[]>(url);
	}

	show(type, fileName: string) {
		let title = '';
		let actionType = 'info';
		switch (type) {
			case 0:
				title = 'Uploading Started!';
				break;
			case 1:
				title = 'Uploading to Server...';
				break;
			case 2:
			case 3:
				title = 'Sending to Storage!';
				break;
			case 4:
				title = 'Upload Successfully!';
				actionType = 'success';
				this.uploadEnded.emit();
				break;
		}
		this._notifications.show(<any>({
			title: title,
			message: '"' + fileName + '"',
			type: 'info', // info or alert or done
			imagePosition: 'right', // left or right
			progressBar: 'circle', //linear or circle
			autoHide: true,
			timeout: 3000,
			position: 'rb' //lb or rb
		}));

	}

	getDocuments(url: string, title: string) {
		////console.log(title);
		let params = new HttpParams();
		if (title && title.trim().length > 0 && title !== 'null') {
			params = params.append('title', title);
		}
		return this.http.get<Documents[]>(url, {params: params});
	}

	isExist(url: string) {
		return this.http.get<boolean>(url);
	}

	downloadDocument(url: string) {
		// this.show(-1, 'Downloading Started');
		const req = new HttpRequest('GET', url, null, {responseType: 'arraybuffer'});
		return this.http.request(req);    // return this.http.get('/api/claimant/documents/' + id + '/download');
	}
}
