import {DynamicFormControlModel, DynamicFormGroupModel} from '@ng-dynamic-forms/core';
import {FormGroup} from '@angular/forms';
import {FormClose} from './form-close';
import {DynamicMoneyInputModel} from "../../../../@util/ui-material/money/dynamic-money-input-model";
import {State} from "../../../test-new-stepper-form/test-form/on-changes";
import {of} from "rxjs";

export interface FormTamplate extends FormClose {
	formModel: DynamicFormControlModel[];
	formGroup: FormGroup;
	title: string;

	// form: any;
	onChange($event);

	onBlur($event);

	onFocus($event);


}

export class FormValueBuilder {

	public static async setData(data, formModel) {
		if (!data) {
			return;
		}
		return setTimeout(() => {
			Object.getOwnPropertyNames(data).forEach(prop => {
				if (formModel[prop] && formModel[prop].constructor.name !== 'Object' && formModel[prop].valueUpdates !== undefined) {

					formModel[prop].setValue(data[prop] === undefined ? null : data[prop]);
					// formModel[prop].valueUpdates.next(data[prop] === undefined ? null : data[prop]);

					// formModel[prop].foo();

				} else if (formModel[prop] && formModel[prop].constructor.name === 'Object') {
					FormValueBuilder.setData(data[prop], formModel[prop]);
				}
			});
		});
	}

	public static buildFormModel(model: any): DynamicFormControlModel[] {
		const rez: DynamicFormControlModel[] = [];
		Object.getOwnPropertyNames(model).forEach(j => rez.push(model[j]));
		FormValueBuilder.resetToDefault(model);
		return rez;
	}

	public static getData(formModel) {
		const rez = Object.create({});
		Object.getOwnPropertyNames(formModel).forEach(prop => {
			if (formModel[prop].constructor.name !== 'Object' && formModel[prop].valueUpdates !== undefined) {
				rez[prop] = formModel[prop].value === undefined ? null : formModel[prop].value;
			} else if (formModel[prop].constructor.name === 'Object') {
				rez[prop] = FormValueBuilder.getData(formModel[prop]);
			}
		});
		return rez;
	}


	public static focus(formModel) {
		return setTimeout(() => {
			if (formModel.constructor.name !== 'Object' && formModel.valueUpdates !== undefined) {
				if ((<DynamicMoneyInputModel>formModel).inputElement)
					(<DynamicMoneyInputModel>formModel).focusChange();
				return;
			}
			const names = Object.getOwnPropertyNames(formModel);
			let cnt = names.length;
			for (let i = 0; i < cnt; i++) {
				let prop = names[i];
				if (formModel[prop] && formModel[prop].constructor.name !== 'Object' && formModel[prop].valueUpdates !== undefined) {
					if ((<DynamicMoneyInputModel>formModel[prop]).inputElement) {

						(<DynamicMoneyInputModel>formModel[prop]).focusChange();
					}
				} else if (formModel[prop] && formModel[prop].constructor.name === 'Object') {
					FormValueBuilder.focus(formModel[prop]);
				}
			}
		});
	}

	public static setControlsToched(form) {
		for (const inner in form.controls) {
			form.get(inner).markAsTouched({onlySelf: true});
			form.get(inner).updateValueAndValidity();


			if (form.get(inner).controls && form.get(inner)) {
				FormValueBuilder.setControlsToched(form.controls[inner]);
			}
		}
	}

	public static resetToDefault(formModel): void {

		if (formModel.constructor.name !== 'Object' && formModel.valueUpdates !== undefined) {
			return formModel.resetToDefault();
		}
		Object.getOwnPropertyNames(formModel).forEach(prop => {
			if (formModel[prop] && formModel[prop].constructor.name !== 'Object') {
				if (formModel[prop].valueUpdates !== undefined)
					formModel[prop].resetToDefault();
				else if (formModel[prop].groups) {
					let contr = formModel[prop];
					FormValueBuilder.resetToDefault(contr.groups)
				} else if (formModel[prop].group) {
					FormValueBuilder.resetToDefault(formModel[prop].group)
				}
				// carAccident
			}
			if (formModel[prop] && formModel[prop].constructor.name === 'Object') {
				FormValueBuilder.resetToDefault(formModel[prop]);
			}
		});
	}

	public static isDirty(formModel): boolean {
		if (formModel.constructor.name !== 'Object' && formModel.valueUpdates !== undefined) {
			return formModel.isDirty();
		}
		let keys = Object.getOwnPropertyNames(formModel);
		let size = keys.length;
		let state = false;
		for (let i = 0; i < size; i++) {
			if (formModel[keys[i]] && formModel[keys[i]].constructor.name !== 'Object') {
				if (formModel[keys[i]].valueUpdates !== undefined) {
					if (formModel[keys[i]].isDirty()) {
						state = true;
					}
				} else if (formModel[keys[i]].groups) {
					let contr = formModel[keys[i]];
					state = FormValueBuilder.isDirty(contr.groups);
				} else if (formModel[keys[i]].group) {
					state = FormValueBuilder.isDirty(formModel[keys[i]].group);
				}
			}
			if (formModel[keys[i]] && formModel[keys[i]].constructor.name === 'Object') {
				state = FormValueBuilder.isDirty(formModel[keys[i]]);
			}
			if (state) {
				// console.log(state, formModel[keys[i]]);
				return state;

			}
		}
		return state;
	}
}
