import {EventEmitter, Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, Observable, of, Subject} from "rxjs";

@Injectable()
export class NavigationService {
	private title = new BehaviorSubject<String>('');
	_back = new EventEmitter();
	public animationValue: any;

	back() {
		return this._back;
	}

	constructor(
		private router: Router, private route: ActivatedRoute
	) {
		this.router
			.events
			.subscribe((event) => {
				if (event instanceof NavigationEnd) {
					this.animationValue = event.id;

				}
			});
	}

	setTitle(title: string) {
		this.title.next(title)
	}

	getTitle() {
		return this.title;
	}

	navigate(url: string[], urlMobile: string[]) {

		if (window.navigator.userAgent === 'SSLG_Browser') { // TODO: !
			this.router.navigate(urlMobile);
		} else {
			this.router.navigate(url);
		}
	}
}
