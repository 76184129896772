export const FIELD_TYPES = {
  text: 'INPUT_TEXT',
  textMessage: 'TEXT',
  textList: 'INPUT_TEXT_LIST',
  textListTwo: 'INPUT_TEXT_LIST_TWO',
  number: 'INPUT_NUMBER',
  phone: 'INPUT_PHONE',
  dollar: 'INPUT_DOLAR',
  select: 'SELECT',
  textarea: 'textarea',
  date: 'DATE',
  dateRange: 'DATE_RANGE',
  radio: 'RADIO_GROUP',
  checkbox: 'CHECK_GROUP',
  yearDate: 'YEAR_DATE'
}
