<!--<div #footer style="height: 5px; width: 770px; background-color: white"></div>-->
<!--<div class="view-wrapper">-->

<!--	<div #head>-->
<!--		<app-report-header [sslgId]="pdfSrc.data[0]?.sslgId"></app-report-header>-->
<!--	</div>-->
<!--	<div  class="view-wrapper" #report>-->
<!--		<div #body class="visit-card" *ngFor="let visit of pdfSrc.data">-->
<!--			<app-doctors-report [tableData]="visit" *ngIf="visit.sourceType == 'DOCTOR'"></app-doctors-report>-->
<!--			<app-hospitals-report [tableData]="visit" *ngIf="visit.sourceType == 'HOSPITAL'"></app-hospitals-report>-->
<!--			<app-others-report [tableData]="visit" *ngIf="visit.sourceType == 'OTHER'"></app-others-report>-->
<!--		</div>-->
<!--	</div>-->

<!--</div>-->

<!--<div #footer style="height: 5px; background-color: white"></div>-->
<div *ngIf="showContent" class="view-wrapper">

	<div #head style="page-break-inside: avoid; page-break-after: avoid;">
		<app-report-header [sslgId]="pdfSrc.data[0]?.sslgId"></app-report-header>
	</div>
	<div  class="view-wrapper" #report>
		<div #body class="visit-card" *ngFor="let data of pdfData">
			<app-pdf-view-blocks-doctors [tableData]="data" *ngIf="data.type == 'DOCTOR'"></app-pdf-view-blocks-doctors>
			<app-pdf-view-blocks-hospitals [tableData]="data" *ngIf="data.type == 'HOSPITAL'"></app-pdf-view-blocks-hospitals>
			<app-pdf-view-blocks-other [tableData]="data" *ngIf="data.type == 'OTHER'"></app-pdf-view-blocks-other>
		</div>
	</div>

</div>
