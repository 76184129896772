import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { AUDIT_LOG_CONFIG } from '../@config/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuditLogService {
    constructor(http) {
        this.http = http;
        this.$logSent = new Subject();
        this.auditLogConfig = AUDIT_LOG_CONFIG;
    }
    sendNotification(sslgId, data) {
        //return this.http.post(this.auditLogConfig.sendLog(sslgId), data);
        //http://192.168.56.1:4201/api/claimant/201980001-002/audit-log
        //http://192.168.56.1:4201/api/client/forgot-password
        //alert(this.auditLogConfig.sendLog(sslgId))
        return this.http.post(this.auditLogConfig.sendLog(sslgId), data);
    }
    reSendNotification(sslgId, auditClaimantId, data) {
        return this.http.post(this.auditLogConfig.reSendAuditLog(sslgId, auditClaimantId), data);
    }
    getAuditInfo(sslgId) {
        return this.http.get(this.auditLogConfig.auditLogs(sslgId));
    }
    getAuditInfoLog(auditCalimantId, sslgId) {
        return this.http.get(this.auditLogConfig.auditLogInfo(sslgId, auditCalimantId));
    }
}
AuditLogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuditLogService_Factory() { return new AuditLogService(i0.ɵɵinject(i1.HttpClient)); }, token: AuditLogService, providedIn: "root" });
