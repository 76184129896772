import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MedicalService } from '../../../../../@core/medical.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from "../../../../../@core/navigation.service";
import { CdkDragDrop, transferArrayItem } from "@angular/cdk/drag-drop";
import { FormGroup } from '@angular/forms';
import { UserInfoService } from "../../../../../@core/user-info.service";
import { GlobalSaveService } from "../../../../../@core/global-save.service";
import { DateService } from "../../../../../@core/functions/date.service";
import { ClaimantMedicationsNewComponent } from "./claimant-medications-new/claimant-medications-new.component";
import { MatDialog } from "@angular/material";
import { PERMISSIONS } from 'src/app/@config/permissions';
export class ClaimantMedicationsComponent {
    constructor(medicalService, userInfoService, route, dialog, dateService, globalSaveService, navService) {
        this.medicalService = medicalService;
        this.userInfoService = userInfoService;
        this.route = route;
        this.dialog = dialog;
        this.dateService = dateService;
        this.globalSaveService = globalSaveService;
        this.navService = navService;
        this.displayRow = {
            Doctor: true,
            Hospital: true,
            Other: true
        };
        this.isEditMode = false;
        this.PERMISSIONS = PERMISSIONS;
        this.sub = navService.back().subscribe(j => this.goBack());
        this.formGroup = new FormGroup({
        // firstName: new FormControl()
        });
    }
    ngOnDestroy() {
        this.sub.unsubscribe();
    }
    goBack() {
        this.navService.navigate(['/claimants'], [`${this.route.snapshot.paramMap.get('sslgId')}`]);
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.sslgId = this.route.snapshot.parent.paramMap.get('sslgId');
            yield this.getMedicationsData();
        });
    }
    getMedicationsData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.medicationsData = yield this.medicalService.getAllMedicationForClaim(this.sslgId).toPromise();
            this.medicationsDataFormatted = Object.assign({}, this.medicationsData);
            Object.keys(this.medicationsDataFormatted).forEach(type => {
                Object.keys(this.medicationsDataFormatted[type]).forEach(claim => {
                    const activeList = [];
                    const inactiveList = [];
                    this.medicationsDataFormatted[type][claim].medicationsList = [];
                    this.medicationsDataFormatted[type][claim].forEach((med) => {
                        med.activeList = [];
                        med.inactiveList = [];
                        if (med.status) {
                            med.activeList.push(med);
                        }
                        else {
                            med.inactiveList.push(med);
                        }
                        this.medicationsDataFormatted[type][claim].medicationsList.push(med);
                    });
                });
            });
        });
    }
    getTypes() {
        if (this.medicationsData) {
            return Object.getOwnPropertyNames(this.medicationsData).map(j => {
                return j.substr(0, 1) + j.toLocaleLowerCase().substr(1);
            }).reverse();
        }
        return [];
    }
    getMedications(type) {
        if (!type)
            return [];
        const values = this.medicationsData[type.toUpperCase()];
        return Object.values(values);
    }
    identify(index, item) {
        return item;
    }
    identify2(index, item) {
        return item.catalogItemId;
    }
    identify3(index, item) {
        return item.medicationName;
    }
    getDate(medication) {
        return medication.date ? new Date(medication.date).toLocaleDateString('en-Us') : null;
    }
    showRow(type) {
        this.displayRow[type] = !this.displayRow[type];
    }
    canShowRow(type) {
        return this.displayRow[type];
    }
    // compareMedicationsFn(a, b) {
    //   console.log(a, b);
    //   if (a < b) {
    //     return -1;
    //   } else if (a > b) {
    //     return 1;
    //   }
    //   // a must be equal to b
    //   return 0;
    // }
    dropSegments(event, el) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // const oldDate = el.date;
            // const oldStatus = el.status;
            //
            // const oldActiveList = [...el.activeList];
            // const oldInactiveList = [...el.inactiveList];
            let status;
            const date = new Date();
            if (event.distance.x < 0) {
                status = false;
            }
            else {
                status = true;
            }
            const data = {
                id: el.medicationId,
                status: status,
                date: this.dateService.getDateWithoutHours(date)
            };
            el.status = status;
            el.date = date;
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
            // await this.medicalService.changeMedicationStatus(this.sslgId, data).catch(data => {
            //   if (data.error) {
            //     el.status = oldStatus;
            //     el.date = oldDate;
            //     el.activeList = oldActiveList;
            //     el.inactiveList = oldInactiveList;
            //   }
            // });
            // visit.medicationsList.sort((a, b) => {
            //   console.log(a, b);
            //   if (a.period && a.period.endDate && b.period && b.period.endDate) {
            //     return b.period.endDate - a.period.endDate;
            //   }
            //   return 1;
            // });
        });
    }
    isDoctorType(type) {
        return type.toUpperCase() === 'DOCTOR';
    }
    isHospitalType(type) {
        return type.toUpperCase() === 'HOSPITAL';
    }
    isOtherType(type) {
        return type.toUpperCase() === 'OTHER';
    }
    isClaimant() {
        if (this.userInfoService.getCurrentUserRole() == 'CLIENT') {
            return true;
        }
        return false;
    }
    isExecutive() {
        if (PERMISSIONS.HIGHER_EXEC_LEVEL_4.includes(this.userInfoService.getCurrentUserRole())) {
            return true;
        }
        return false;
    }
    onEditMode() {
        this.isEditMode = true;
    }
    saveChanges() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const data = {
                medications: []
            };
            Object.keys(this.medicationsDataFormatted).forEach(type => {
                Object.keys(this.medicationsDataFormatted[type]).forEach(claim => {
                    this.medicationsDataFormatted[type][claim].forEach(med => {
                        // console.log(med);
                        data.medications.push({
                            id: med.medicationId,
                            status: med.status,
                            date: med.date ? this.dateService.getDateWithoutHours(med.date) : null
                        });
                    });
                });
            });
            yield this.medicalService.saveAllMedications(this.sslgId, data).catch(data => {
                if (data.error) {
                    this.getMedicationsData();
                }
            });
            this.isEditMode = false;
        });
    }
    changeActiveMedication(claimMedications, state, e) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.isEditMode) {
                return;
            }
            if (String(state) === String(claimMedications.status)) {
                return;
            }
            // const oldDate = claimMedications.date;
            // const oldStatus = claimMedications.status;
            //
            // const oldActiveList = [...claimMedications.activeList];
            // const oldInactiveList = [...claimMedications.inactiveList];
            if (state) {
                claimMedications.activeList = [...claimMedications.inactiveList];
                claimMedications.inactiveList = [];
            }
            else {
                claimMedications.inactiveList = [...claimMedications.activeList];
                claimMedications.activeList = [];
            }
            // e.target.value = !e.target.value;
            claimMedications.status = state;
            claimMedications.date = new Date();
            // const data = {
            //   id: claimMedications.medicationId,
            //   status: claimMedications.status,
            //   date: this.dateService.getDateWithoutHours(claimMedications.date)
            // };
            // await this.medicalService.changeMedicationStatus(this.sslgId, data).catch(data => {
            //   if (data.error) {
            //     claimMedications.status = oldStatus;
            //     claimMedications.date = oldDate;
            //     claimMedications.activeList = oldActiveList;
            //     claimMedications.inactiveList = oldInactiveList;
            //   }
            // });
        });
    }
    addMedication() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            document.querySelectorAll('.mat-drawer-content').forEach(el => {
                el.style.overflow = 'hidden';
            });
            const dialogRef = this.dialog.open(ClaimantMedicationsNewComponent, {
                data: {
                    sslgId: this.sslgId
                },
                panelClass: ['mobile-popup', 'with-shadow', 'popup-scrollable'],
                // panelClass: ['mobile-popup', 'with-shadow'],
                backdropClass: 'mobile-popup__overlay',
                disableClose: true
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    if (result.cmd) {
                    }
                    else if (result == 'new') {
                        this.getMedicationsData();
                    }
                }
            });
        });
    }
}
