<app-frame [overlay]="false" [title]="title" (neadClose)="closeVisitForm()" autocomplete="off">
	<app-iris-table [inOneLine]="true">
		<div class="i-thead">
			<div class="i-tr">
				<div class="i-th w-size-20">Folder</div>
				<div class="i-th w-size-20">Tab</div>
				<div class="i-th w-size-20">Form</div>
				<div class="i-th">Error</div>
				<div class="i-th w-size-5"></div>
			</div>
		</div>
		<div class="i-tbody">
			<div class="i-tr" *ngFor="let detail of data.errorList;">
				<div class="i-td w-size-20">{{getFolder(detail.formShortName)}}</div>
				<div class="i-td w-size-20">{{getTab(detail.formShortName)}}</div>
				<div class="i-td w-size-20">{{detail.formName}}</div>
				<div class="i-td">{{detail.message}}</div>
				<div class="i-td w-size-5">
					<div class="i-btn-container">
						<button (click)="link(detail)" class="table-button-edit">
						<svg class="table-button__icon" style="fill: #43425D">
							<use xlink:href="../../../../assets/img/sprite.svg#eye-icon"></use>
						</svg>
						</button>
					</div>
				</div>
			</div>
		</div>
	</app-iris-table>
	<app-form-actions-content>

		<form-button (clickOnButton)="closeVisitForm()" buttonType="warning">Cancel</form-button>
	</app-form-actions-content>
</app-frame>
