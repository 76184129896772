import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableSwiperComponent } from '../@view/partial/table-swiper/table-swiper.component';



@NgModule({
  declarations: [TableSwiperComponent],
  imports: [
    CommonModule
  ],
  exports: [
    TableSwiperComponent,
  ]
})
export class SharedModule { }
