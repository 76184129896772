import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
export class LoginService {
    constructor() {
        this.changePassword = new BehaviorSubject(false);
        this.ignorePasswordChanging = new BehaviorSubject(false);
    }
    showChangePassword() {
        this.changePassword.next(true);
    }
    loginWithIgnorePasswordChanging() {
        this.ignorePasswordChanging.next(true);
    }
}
LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(); }, token: LoginService, providedIn: "root" });
