<div class="ngx-datepicker-calendar-container ngx-datepicker-position-{{data.position}}" [@openCloseCalendar]="isOpened ? 'open' : 'closed'" [ngStyle]="{'top': calendarTopPos+'px', 'left': calendarLeftPos+'px'}" #ngxPickerCalendar>
    <div class="topbar-wrapper">
        <div class="topbar-container">
            <div class="topbar-title" (click)="toggleView()">
                <span *ngIf="this.view == 'days'">{{ data.barTitle }}</span>
                <span *ngIf="this.view == 'years'">{{ data.minYear }} - {{data.maxYear}}</span>
                <span *ngIf="this.view == 'month'">{{ data.selectedYear }}</span>
                <div class="calendar-arrow" [ngClass]="{'toggled': this.view == 'years'}"></div>
            </div>
            <div class="spacer"></div>

            <button class="prev" (click)="prev()">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="Capa_1" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;"
                     xml:space="preserve">
<g>
<g>
<polygon points="207.093,30.187 176.907,0 48.907,128 176.907,256 207.093,225.813 109.28,128   "/>
</g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
</svg>
            </button>
            <button class="next" (click)="next()">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="Capa_1" x="0px" y="0px" viewBox="0 0 256 256" style="enable-background:new 0 0 256 256;"
                     xml:space="preserve">
<g>
<g>
<polygon points="207.093,30.187 176.907,0 48.907,128 176.907,256 207.093,225.813 109.28,128   "/>
</g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
</svg>
            </button>
        </div>
    </div>
    <div class="main-calendar-container main-calendar-container_days" *ngIf="view === 'days'">
        <div class="main-calendar-day-names">
            <span class="day-name-unit" *ngFor="let name of dayNames">{{ name[0] }}</span>
        </div>
        <div class="main-calendar-days">
            <span class="day-unit" *ngFor="let day of days; let i = index;"
                  [ngClass]="{ 'is-prev-month': !day.inThisMonth, 'is-today': day.isToday, 'is-selected': day.isSelected, 'is-disabled': !day.isSelectable }"
                  (click)="day.isSelectable && setDate(i)">
            <i>{{ day.day }}</i>
            </span>
        </div>
    </div>
    <div class="main-calendar-container main-calendar-container_years" *ngIf="view === 'years'">
        <div class="main-calendar-years">
            <span class="year-unit contain" *ngFor="let year of years; let i = index;"
                  [ngClass]="{ 'is-selected': year.isThisYear, 'current-year': year.isCurrentYear }" (click)="setYear(i)">
            <i class="contain">{{ year.year }}</i>
            </span>
        </div>
    </div>
    <div class="main-calendar-container main-calendar-container_month" *ngIf="view === 'month'">
        <div class="main-calendar-month">
            <span class="month-unit contain" *ngFor="let month of month; let i = index;"
                  [ngClass]="{'current-month': month.isCurrentMonth }" (click)="setMonth(i)">
            <i class="contain">{{ month.month }}</i>
            </span>
        </div>
    </div>
</div>
