/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./form-button.component";
var styles_FormButtonComponent = [i0.styles];
var RenderType_FormButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormButtonComponent, data: {} });
export { RenderType_FormButtonComponent as RenderType_FormButtonComponent };
function View_FormButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n  "])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
function View_FormButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, ":svg:svg", [["class", "save-action__icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:use", [], [[1, ":xlink:href", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("../../../../assets/img/sprite.svg#" + _co.button.iconId); _ck(_v, 2, 0, currVal_0); }); }
function View_FormButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_FormButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, [["form_button_text", 2]], null, 0, null, View_FormButtonComponent_1)), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 10, "button", [], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickOnButton.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(6, 2), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormButtonComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormButtonComponent_3)), i1.ɵdid(12, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n\n\n\n\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 6, 0, "form-action", _co.button.buttonClass); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.button.showIcon; _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 0); _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 3, 0, currVal_0); }); }
export function View_FormButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "form-button", [], null, null, null, View_FormButtonComponent_0, RenderType_FormButtonComponent)), i1.ɵdid(1, 638976, null, 0, i3.FormButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormButtonComponentNgFactory = i1.ɵccf("form-button", i3.FormButtonComponent, View_FormButtonComponent_Host_0, { orientation: "orientation", buttonType: "buttonType", disabled: "disabled" }, { clickOnButton: "clickOnButton" }, ["*"]);
export { FormButtonComponentNgFactory as FormButtonComponentNgFactory };
