<ng-container [formGroup]="group">

    <mat-slide-toggle #matSlideToggle
                      [checked]="model.checked"
                      [color]="model.getAdditional('color', 'accent')"
                      [disableRipple]="model.getAdditional('disableRipple', RIPPLE_OPTIONS && RIPPLE_OPTIONS['disabled'] || false)"
                      [formControlName]="model.id"
                      [id]="elementId"
                      [labelPosition]="model.labelPosition || 'after'"
                      [name]="model.name"
                      [ngClass]="[getClass('element','control'), getClass('grid','control')]"
                      (change)="onChange($event)">

        <span *ngIf="!model.checked" [innerHTML]="model.offLabel"></span>
        <span *ngIf="model.checked" [innerHTML]="model.onLabel"></span>

    </mat-slide-toggle>

</ng-container>
