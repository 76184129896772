import {
	DynamicCheckboxGroupModel as DynamicCheckboxGroup,
	DynamicCheckboxModel as DynamicCheckbox,
	DynamicColorPickerModel as DynamicColorPicker,
	DynamicDatePickerModel as DynamicDatePicker,
	DynamicEditorModel as DynamicEditor,
	DynamicFileUploadModel as DynamicFileUpload,
	DynamicFormArrayModel as DynamicFormArray,
	DynamicFormControlLayout,
	DynamicFormGroupModel as DynamicFormGroup,
	DynamicInputModel as DynamicInput,
	DynamicRadioGroupModel as DynamicRadioGroup,
	DynamicRatingModel as DynamicRating,
	DynamicSelectModel as DynamicSelect,
	DynamicSliderModel as DynamicSlider,
	DynamicSwitchModel as DynamicSwitch,
	DynamicTextAreaModel as DynamicTextArea,
	DynamicTimePickerModel as DynamicTimePicker,
} from "@ng-dynamic-forms/core";
import "./extensions"
import {DynamicTextEditorModel as DynamicTextEditor} from "./text-editor/dynamic-text-editor-model";
import {DatepickerCheckboxModel as DatepickerCheckbox} from "./datepicker-checkbox/datepicker-checkbox-model";
import {
	DatepickerCustompropsModel as DatepickerCustomprops
} from "./datepicker-customprops/datepicker-customprops-model";
import {
	DatepickerCustomModel as DatepickerCustom
} from "./datepicker-custom/datepicker-custom-model";
import {DynamicInputAutocompleteModel as DynamicInputAutocomplete} from "./input/dynamic-input-autocomplete-model";
import {DynamicMoneyInputModel as DynamicMoneyInput} from "./money/dynamic-money-input-model";
import { PERMISSIONS } from "src/app/@config/permissions";

export function DynamicInputModel(config: DynamicInputModelConfig, layout?: DynamicFormControlLayout) {

	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicInput(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicTimePickerModel(config: DynamicTimePickerModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicTimePicker(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicTextEditorModel(config: DynamicTextAreaModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicTextEditor(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicTextAreaModel(config: DynamicTextAreaModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicTextArea(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicSwitchModel(config: DynamicSwitchModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicSwitch(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicSliderModel(config: DynamicSliderModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicSlider(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicSelectModel<T>(config: DynamicSelectModelConfig<T>, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicSelect<T>(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicRatingModel(config: DynamicRatingModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicRating(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicRadioGroupModel<T>(config: DynamicRadioGroupModelConfig<T>, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;

	let rez = new DynamicRadioGroup<T>(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicMoneyInputModel(config: DynamicMoneyModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicMoneyInput(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicInputAutocompleteModel(config: DynamicInputModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicInputAutocomplete(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicFormGroupModel(config: DynamicFormGroupModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;

	let rez = new DynamicFormGroup(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicFormArrayModel(config: DynamicFormArrayModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicFormArray(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicFileUploadModel(config: DynamicFileUploadModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicFileUpload(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicEditorModel(config: DynamicEditorModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicEditor(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicDatePickerModel(config: DynamicDatePickerModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicDatePicker(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicColorPickerModel(config: DynamicColorPickerModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicColorPicker(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicCheckboxModel(config: DynamicCheckboxModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicCheckbox(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DynamicCheckboxGroupModel(config: DynamicFormGroupModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DynamicCheckboxGroup(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DatepickerCustompropsModel(config: DatepickerCustompropsModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DatepickerCustomprops(config, layout);
	rez["_config"] = config;
	return rez;
}

export function DatepickerCustomModel(config: DatepickerCustomModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DatepickerCustom(config, layout);

	rez["_config"] = config;
	return rez;
}

export function DatepickerCheckboxModel(config: DynamicDatePickerModelConfig, layout?: DynamicFormControlLayout) {
	if (!config.editableFor || !config.editableFor.length)
		config.editableFor = PERMISSIONS.HIGHER_EXEC_LEVEL_4;
	let rez = new DatepickerCheckbox(config, layout);
	rez["_config"] = config;
	return rez;
}
