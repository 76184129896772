<app-frame [overlay]="false"
           [titleHTML]="title"
           [class.no-padding-form-content]="isOtherTreatmentStep()"
           [showCloseButton]="true"
           (neadClose)="cancel()" autocomplete="off">
    <div class="adding-info-block adding-info-block--finish" *ngIf="isFinishedStep()">
        <div class="adding-info-block__row adding-info-block__row--wrap">
            <form-button (clickOnButton)="addAnotherDoctor()"
                         buttonType="add"
                         class="adding-info-block__btn"
                         title="Continue">
                Add Another Doctor
            </form-button>
            <form-button (clickOnButton)="addHospitalization()"
                         buttonType="add"
                         class="adding-info-block__btn"
                         title="Continue">
                Add a Hospitalization
            </form-button>
            <form-button (clickOnButton)="addAVisitSummary()"
                         buttonType="add"
                         class="adding-info-block__btn"
                         title="Continue">
                Add a Visit Summary
            </form-button>
            <form-button (clickOnButton)="addOtherTreatingSource()"
                         buttonType="add"
                         class="adding-info-block__btn"
                         title="Continue">
                Add a OTHER Treating Source
            </form-button>
            <form-button (clickOnButton)="returnToMyProfile()"
                         buttonType="add"
                         class="adding-info-block__btn"
                         title="Continue">
                Return to my Profile
            </form-button>
        </div>
    </div>

    <ng-container *ngIf="!isFinishedStep()">
        <div  [style.display]="isInitialStep() ? 'block' : 'none'" id="block-tab-info" class="blocks-tab">
            <form class="form-horizontal" [formGroup]="formGroup" novalidate>
                <mat-form-field *ngIf="showName()" class="name_input" [ngClass]="{'dirty': isInputDirty()}">
                    <input required matInput placeholder="Name" aria-label="Name" [matAutocomplete]="auto"
                           #trigger="matAutocompleteTrigger"
                           [formControl]="nameCtrl">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                        <mat-option [class.grey]="!name.label" *ngFor="let name of filteredNames | async" [value]="name"
                                    [matTooltip]="name.label ? name.label : null">
                            <span [class.grey]="!name.label">{{name.name}}</span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <dynamic-material-form [group]="formGroup"
                                       [layout]="formLayout"
                                       [model]="formModel"
                                       (blur)="onBlur($event)"
                                       (change)="onChange($event); onStateChanges()"
                                       (focus)="onFocus($event)">
                    <ng-template modelId="approximateDates" align="START">
                        <label style="text-align: center" class="form-label form-label_full-width form-label_mb">
                            Approximate Dates of Treatment
                        </label>
                    </ng-template>
                </dynamic-material-form>
            </form>

            <app-form-actions-content *ngIf="canAccess()">
                <form-button
                        (clickOnButton)="cancel()"
                        buttonType="warning"
                        title="Cancel">
                    Cancel
                </form-button>
                <!--			<form-button-->
                <!--				*ngIf="showName()"-->
                <!--				title="Add Visit"-->
                <!--				buttonType="add"-->
                <!--				(clickOnButton)="addVisit()">-->
                <!--				Add Visit-->
                <!--			</form-button>-->
                <form-button *ngIf="showName()"
                             [disabled]="!this.nameCtrl.value"
                             (clickOnButton)="goToOtherTreatment()"
                             title="Add a New Medical Source">
                    Next
                </form-button>
            </app-form-actions-content>
        </div>
    </ng-container>

	<ng-container *ngIf="this.nameCtrl.value && !isFinishedStep()">
		<div [style.display]="isOtherTreatmentStep() ? 'block' : 'none'">
			<app-other-treatment
					[sslgId]="sslgId"
					(backForm)="backOtherTreatment()"
					(nextForm)="nextOtherTreatment($event)"
					(formStateChanged)="onFormStateChanged($event)"
					(allFormStateChanged)="onAllFormStateChanged($event)"
					[formKey]="'visitSecondStep'"></app-other-treatment>
		</div>
	</ng-container>

</app-frame>
