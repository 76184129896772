import {Component, forwardRef, Host, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl,FormArray, FormControl, FormGroup, Validators,FormBuilder} from '@angular/forms';
import {DynamicFormService, DynamicSelectModel,} from '@ng-dynamic-forms/core';

import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Subject} from 'rxjs';
import {DateService} from "../../../../../@core/functions/date.service";
import {UserInfoService} from "../../../../../@core/user-info.service";
import {NotificationConfirmationsComponent} from "../../../../notification/confirmations/notification-confirmations.component";
import {MatDialog, MatPaginator, MatTableDataSource} from "@angular/material";
import {State} from "../../../../../test-new-stepper-form/test-form/on-changes";
import {DialogService} from "../../../../../../@util/dialog/dialog.service";
import {MedicalSourcesService} from "../../../../../@core/medical-sources.service";
import {SourcesRequests} from "../../../../../@models/medical/sources-requests";
import {GlobalSaveService} from "../../../../../@core/global-save.service";
import {MedicalRequestsFormComponent} from "./medical-requests-form/medical-requests-form.component";
import {SSALevelService} from "../../../../../@core/ssa-level.service";

@Component({
	selector: 'app-medical-requests',
	templateUrl: './medical-requests.component.html',
	styleUrls: ['./medical-requests.component.scss']
})
export class MedicalRequestsComponent implements OnInit, OnDestroy {
	@Input() page;
	@Input() contacts;

	@ViewChild(MedicalRequestsFormComponent, {static: false}) medicalRequestsFormComponent: MedicalRequestsFormComponent;

	@ViewChild(MatPaginator, {static: true})
	paginator: MatPaginator;
	dataSource: MatTableDataSource<SourcesRequests>;

	canScrollLeft = false;
	canScrollRight = true;

	data: Subject<SourcesRequests[]>;
	requests: SourcesRequests[];

	pageType;



	constructor(private formService: DynamicFormService,
				private fb: FormBuilder,
				private route: ActivatedRoute,
				private dateService: DateService,
				private dialog: DialogService,
				private matDialog: MatDialog,
				private router: Router,
				public globalSaveService: GlobalSaveService,
				private medicalSourcesService: MedicalSourcesService,
				public userInfoService: UserInfoService,
				private ssaLevelService: SSALevelService
				) {

		this.dataSource = new MatTableDataSource([]);


	}

	ngOnDestroy(): void {

	}

	ngOnInit() {
		this.dataSource.paginator = this.paginator;

		this.loadRequests();

		switch (this.page) {
			case 'Hospital':
				this.pageType = 'Hospital';
				break;
			case 'Other':
				this.pageType = 'Other';
				break;
			default:
				this.pageType = 'Doctor';
				break;
		}
	}

	loadRequests(){
		this.medicalSourcesService.treatingSourcesRequestsList(this.getSslgId(),this.getSourceId()).subscribe((data:SourcesRequests[]) => {
			this.requests = data;

			this.loadData();
		});
	}

	loadData(){
		this.dataSource.data = this.requests;
		this.data = this.dataSource.connect();
		if (this.route.snapshot.queryParamMap.get('page')) {
			this.paginator.pageIndex = +this.route.snapshot.queryParamMap.get('page');
			this.data.next(this.dataSource._pageData(this.requests));
		}
	}

	addRequest(){
		this.hideBlocks();

		document.getElementById('block-tab-requests-request').style.display = '';

		this.medicalRequestsFormComponent.addById(null);
	}

	edit(data){
		this.hideBlocks();

		document.getElementById('block-tab-requests-request').style.display = '';

		this.medicalRequestsFormComponent.addById(data.id);
	}

	view(data) {
		this.hideBlocks();

		document.getElementById('block-tab-requests-request').style.display = '';

		this.medicalRequestsFormComponent.addById(data.id);
	}

	backToRequestsList(event){
		this.hideBlocks();

		document.getElementById('block-tab-requests-list').style.display = '';
		document.getElementById('block-tab-requests-request').classList.remove('is-viewing');


		this.loadRequests();
	}

	hideBlocks(){
		document.querySelectorAll('.blocks-tab-requests').forEach((el:any) => {
			el.style.display = 'none';
		});
	}

	delete(item,index) {
		let dialog = this.matDialog.open(NotificationConfirmationsComponent, {
			data: {
				message: "Are you sure you want to delete the request?"
			}
		});
		dialog.afterClosed().subscribe(j => {
			try {
				if (j.status) {
					this.medicalSourcesService.deleteSourceRequest(item.id).subscribe(() => {
						let itemIndex = index + this.paginator.pageSize * this.paginator.pageIndex;
						this.requests.splice(itemIndex, 1);
						this.loadData();
					});
				}
			} catch (e) {

			}
		});
	}

	onChange($event) {

	}

	tableScrolled($event) {
		const scrollLeft = $event.target.scrollLeft;
		const scrollWidth = $event.target.scrollWidth;
		const offsetWidth = $event.target.offsetWidth;

		if (scrollLeft > 0) {
			this.canScrollLeft = true;
		} else {
			this.canScrollLeft = false;
		}

		if (scrollWidth - scrollLeft > offsetWidth) {
			this.canScrollRight = true;
		} else {
			this.canScrollRight = false;
		}
	}

	getSourceId(){
		return this.route.snapshot.paramMap.get('id');
	}

	getSslgId(){
		return this.route.snapshot.parent.params['sslgId'];
	}

	isClaimant() {
		if(this.userInfoService.getCurrentUserRole() == 'CLIENT'){
			return true;
		}
		return false;
	}

	isSystem(){
		return this.userInfoService.getCurrentUserNameIsSystem();
	}

	getUrl() {
		return this.router.url.indexOf('?') !== -1 ?
			this.router.url.substring(0, this.router.url.indexOf('?')) : this.router.url;
	}

	getTableHeadClass() {
		if (this.isSystem() || this.isClaimant()) {
			return 'i-btn-2';
		} else {
			return 'i-btn-0';
		}
	}

	getSSALavelName(id) {
		return this.ssaLevelService.getLevelName(id);
	}

	isShowBtn(){
		if(this.globalSaveService.getStatusTrue()){
			return true;
		}

		return false;
	}
}
