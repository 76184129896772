import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-table-search',
    templateUrl: './table-search.component.html',
    styleUrls: ['./table-search.component.scss'],
})
export class TableSearchComponent implements OnInit {
    @Output()
    valueChanged = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    onValueChanged(value: any) {
        this.valueChanged.next(value);
    }
}
