import { HttpClient } from "@angular/common/http";
import { GLOBAL_SAVE_CONFIG } from "../@config/api";
import { UserInfoService } from "./user-info.service";
import { MatDialog } from "@angular/material";
import { GlobalSaveCancelComponent } from "../@view/global-save/global-save-cancel/global-save-cancel.component";
import { GlobalSaveContinueClaimantUpdateComponent } from "../@view/global-save/global-save-continue-claimant-update/global-save-continue-claimant-update.component";
import { BehaviorSubject } from "rxjs";
import { PERMISSIONS } from '../@config/permissions';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user-info.service";
import * as i3 from "@angular/material/dialog";
export class GlobalSaveService {
    constructor(http, userInfoService, dialog) {
        this.http = http;
        this.userInfoService = userInfoService;
        this.dialog = dialog;
        this.storage = sessionStorage;
        this.config = GLOBAL_SAVE_CONFIG;
        this.statusActive = "1";
        this.statusNotActive = "0";
        this.sslgId = '';
        this.goToPage = new BehaviorSubject('');
    }
    getSslgId() {
        return this.sslgId;
    }
    setSslgId(value) {
        this.sslgId = value;
    }
    setStatus(status) {
        this.storage.setItem('globalSave', status);
    }
    setLastClaimant(name) {
        this.storage.setItem('globalSaveLastClaimant', name);
    }
    getLastClaimanName() {
        return this.storage.getItem('globalSaveLastClaimant');
    }
    setLastClaimantFullName(name) {
        this.storage.setItem('globalSaveLastClaimantFullName', name);
    }
    getLastClaimanNameFullName() {
        return this.storage.getItem('globalSaveLastClaimantFullName');
    }
    getStatus() {
        return this.storage.getItem('globalSave');
    }
    getStatusFalse() {
        return this.getStatus() == this.statusNotActive;
    }
    getStatusTrue() {
        if (this.isStaff()) {
            return this.getStatus() == this.statusActive;
        }
        return false;
    }
    isStaff() {
        if (PERMISSIONS.STAFF_ROLES.includes(this.userInfoService.getCurrentUserRole())) {
            return true;
        }
        return false;
    }
    isExecutiveStaff() {
        if (PERMISSIONS.HIGHER_EXEC_LEVEL_4.includes(this.userInfoService.getCurrentUserRole())) {
            return true;
        }
        return false;
    }
    isClient() {
        if (this.userInfoService.getCurrentUserRole() == 'CLIENT') {
            return true;
        }
        return false;
    }
    isAdjuster() {
        if (this.userInfoService.getCurrentUserRole() == 'ADJUSTER') {
            return true;
        }
        return false;
    }
    on() {
        this.http.post(this.config.changeStatus, {
            userName: this.getUserName(),
            status: this.statusActive
        })
            .subscribe(data => {
            if (data.status == "true") {
                this.setStatus(this.statusActive);
                this.saveLastClaimant(this.getSslgId()).subscribe(data => {
                });
            }
        });
    }
    off() {
        if (this.getStatusTrue()) {
            this.dialog.open(GlobalSaveCancelComponent, {})
                .afterClosed().subscribe(j => {
                //close
            });
        }
    }
    confirmOff() {
        return this.http.post(this.config.changeStatus, {
            userName: this.getUserName(),
            status: this.statusNotActive
        });
    }
    confirmSave() {
        return this.http.post(this.config.save, {
            userName: this.getUserName(),
        });
    }
    removeLastClaimant() {
        return this.http.post(this.config.clearLastClaimant, {
            userName: this.getUserName(),
        });
    }
    continueClaimantUpdate() {
        if (this.getStatusTrue()) {
            this.http.post(this.config.getLastClaimantInfo, {
                userName: this.getUserName()
            })
                .subscribe(data => {
                if (data.status == "true") {
                    /*try {*/
                    if (data.message != "null" && data.message != null) {
                        let result = JSON.parse(data.message);
                        if (result.status == this.statusActive) {
                            let lastClaimentFullName = result.lastClaimentFullName;
                            let lastClaimentStatus = result.status;
                            let lastClaimentUserName = result.lastClaimentUserName;
                            if (lastClaimentFullName != "" && lastClaimentFullName != "undefined") {
                                this.setLastClaimantData(lastClaimentStatus, lastClaimentUserName, lastClaimentFullName);
                                this.dialog.open(GlobalSaveContinueClaimantUpdateComponent, {})
                                    .afterClosed().subscribe(j => {
                                    //close
                                });
                            }
                        }
                        else {
                        }
                    }
                    /*} catch (e) {

                    }*/
                }
                else {
                }
            });
        }
    }
    setLastClaimantData(status, lastClaimant, lastClaimentFullName) {
        this.setStatus(status);
        this.setLastClaimant(lastClaimant);
        this.setLastClaimantFullName(lastClaimentFullName);
    }
    cleareLastClaiment() {
        this.setLastClaimant("");
        this.setLastClaimantFullName("");
    }
    getUserName() {
        return this.userInfoService.getCurrentUserName();
    }
    findForm(formName, claimentName) {
        return this.http.post(this.config.getForm, {
            userName: this.getUserName(),
            formName: formName,
            claimentName: claimentName
        });
    }
    saveForm(formName, claimentName, data, formValid) {
        let json = JSON.stringify(data);
        let validation = 0;
        if (formValid) {
            validation = 1;
        }
        return this.http.post(this.config.saveFormData, {
            userName: this.getUserName(),
            formName: formName,
            claimentName: claimentName,
            data: json,
            validation: validation
        });
    }
    saveLastClaimant(claimentName) {
        return this.http.post(this.config.saveLastClaimant, {
            userName: this.getUserName(),
            claimentName: claimentName,
        });
    }
    getFormData(formShortName, formdata, formValid, claimantId, params, runMethod) {
        if (this.getStatusTrue()) {
            this.findForm(formShortName, claimantId).subscribe(data => {
                if (data.status == "true") {
                    let result = formdata;
                    try {
                        if (data.message != "null" && data.message != null) {
                            result = JSON.parse(data.message);
                        }
                    }
                    catch (e) {
                        this.saveForm(formShortName, claimantId, formdata, formValid).subscribe(data => {
                        });
                    }
                    runMethod(result, params);
                }
                else {
                    if (this.getStatusTrue()) {
                        this.saveForm(formShortName, claimantId, formdata, formValid).subscribe(data => {
                        });
                    }
                    runMethod(formdata, params);
                }
            });
        }
        else {
            runMethod(formdata, params);
        }
    }
    checkDisabledFields(form) {
        let gsStatus = this.getStatusTrue();
        for (let element in form) {
            if (form[element]) {
                // if(form[element].groups){
                // 	let groups = form[element].groups;
                // 	let groupFactory = form[element].groupPrototype
                //
                // 	for (let element10 in groupFactory) {
                // 		this.fieldDisabledUpdate(groupFactory[element10], gsStatus);
                // 	}
                //
                // 	for (let element1 in groups) {
                // 		if(groups[element1].group){
                // 			let group = groups[element1].group;
                // 			for (let element2 in group) {
                // 				this.fieldDisabledUpdate(group[element2], gsStatus);
                //
                // 				group[element2].disabled = 1;
                // 			}
                // 		}
                // 	}
                // }else{
                if (form[element]._config) {
                    if (form[element]._config.group) {
                        for (let group in form[element].group) {
                            if (form[element].group[group]._config) {
                                if (form[element].group[group]._config.disabled) {
                                }
                                else {
                                    this.fieldDisabledUpdate(form[element].group[group], gsStatus);
                                }
                            }
                            else {
                                this.fieldDisabledUpdate(form[element], gsStatus);
                            }
                        }
                    }
                    else {
                        this.fieldDisabledUpdate(form[element], gsStatus);
                    }
                }
                else {
                    this.fieldDisabledUpdate(form[element], gsStatus);
                }
                //}
            }
        }
        return true;
    }
    fieldDisabledUpdate(el, gsStatus) {
        if (gsStatus) {
            if (el.disabledUpdates && el._config.disabled != true) {
                el.disabledUpdates.next(false);
            }
        }
        else {
            if (el.disabledUpdates) {
                el.disabledUpdates.next(true);
            }
        }
    }
}
GlobalSaveService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalSaveService_Factory() { return new GlobalSaveService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserInfoService), i0.ɵɵinject(i3.MatDialog)); }, token: GlobalSaveService, providedIn: "root" });
