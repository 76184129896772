import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DynamicFormService, } from '@ng-dynamic-forms/core';
import { ADDRESS, CYTY, DATE_PICKER, EXT, PHONE, PRESS, RADIO, STATE_SELECT, STRING_FIELD, ZIP } from '../../../../../../../@util/dynamic-form/dynamic-controlls';
import { MedicalService } from '../../../../../../@core/medical.service';
import { FormValueBuilder } from '../../../../../partial/forms/form-tamplate';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { CatalogItemType } from '../../../../../../@models/medical/catalog-item-view';
import { NavigationService } from "../../../../../../@core/navigation.service";
import { AddressService } from "../../../../../../@core/address.service";
import { State } from "../../../../../../test-new-stepper-form/test-form/on-changes";
import { Functions } from "../../../../../../../@util/functions";
import { DynamicFormGroupModel } from "../../../../../../../@util/ui-material/form-controls";
import { MedicalRequestsPaymentsComponent } from "../../medical-requests-payments/medical-requests-payments.component";
import { UserInfoService } from "../../../../../../@core/user-info.service";
import { DialogService } from "../../../../../../../@util/dialog/dialog.service";
import { MatAutocompleteTrigger, MatDialogRef } from "@angular/material";
import { DateService } from "../../../../../../@core/functions/date.service";
import { MedicalSourcesService } from "../../../../../../@core/medical-sources.service";
import { PERMISSIONS } from 'src/app/@config/permissions';
const creationSteps = {
    'initial': 1,
    'otherTreatment': 2,
    'finished': 3
};
export class OtheFormNewComponent {
    constructor(medicalService, formService, addressService, userInfoService, dialog, dateService, medicalSourcesService, dialogRef, componentData, route, router, navService) {
        this.medicalService = medicalService;
        this.formService = formService;
        this.addressService = addressService;
        this.userInfoService = userInfoService;
        this.dialog = dialog;
        this.dateService = dateService;
        this.medicalSourcesService = medicalSourcesService;
        this.dialogRef = dialogRef;
        this.componentData = componentData;
        this.route = route;
        this.router = router;
        this.navService = navService;
        this.updateOthersList = new EventEmitter();
        this.title = 'New Other';
        this.formLayout = {
            treat: {
                element: {
                    control: 'form-row'
                }
            },
            treats: {
                element: {
                    host: 'form-element'
                }
            },
            speciality: {
                element: {
                    host: 'form-element'
                }
            },
            type: {
                element: {
                    label: 'form-label'
                }
            },
            phones: {
                element: {
                    container: 'row-mb',
                    control: 'form-row',
                }
            },
            approximateDatesRow: {
                element: {
                    container: 'row-mb',
                    control: 'form-row',
                }
            },
            approximateDates_start: {
                element: {
                    host: 'form-element'
                }
            },
            approximateDates_end: {
                element: {
                    host: 'form-element'
                }
            },
            approximateDates_ongoing: {
                element: {
                    label: 'form-label',
                    host: 'form-element'
                }
            },
            contact_phone: {
                element: {
                    host: 'form-element'
                }
            },
            contact_press: {
                element: {
                    host: 'form-element'
                }
            },
            contact_ext: {
                element: {
                    host: 'form-element'
                }
            },
            contact_fax: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_address2: {
                element: {
                    container: 'row-mb'
                }
            },
            contact_cyti: {
                element: {
                    container: 'row-mb'
                }
            },
            state_zip: {
                element: {
                    control: 'form-row',
                    container: 'row-mb'
                }
            },
            contact_state: {
                element: {
                    host: 'form-element'
                }
            },
            contact_zip: {
                element: {
                    host: 'form-element label-accent'
                }
            },
            callerPhones: {
                element: {
                    control: 'form-row',
                }
            },
            callerPhone: {
                element: {
                    host: 'form-element form-element_x2'
                }
            },
            callerPress: {
                element: {
                    host: 'form-element'
                }
            },
            callerExt: {
                element: {
                    host: 'form-element'
                }
            },
        };
        this.formTemplate = {
            name: STRING_FIELD({ id: 'name', label: 'Name', hidden: true }),
            recipient: {
                recipientName: STRING_FIELD({
                    id: 'recipientName',
                    label: 'Person To Contact',
                    maxLength: 128,
                    required: true,
                    validators: { required: null }
                }),
            },
            contact: {
                phone: PHONE({ id: 'contact_phone', label: 'Phone' }),
                press: PRESS({ id: 'contact_press', label: '"Press"' }),
                ext: EXT({ id: 'contact_ext', label: 'Ext.' }),
                fax: PHONE({ id: 'contact_fax', label: 'Business Fax' }),
                street: ADDRESS({ id: 'contact_address' }),
                street2: ADDRESS({ id: 'contact_address2', label: 'Address 2' }),
                city: CYTY({ id: 'contact_cyti' }),
                state: STATE_SELECT({ id: 'contact_state' }),
                zip: ZIP({ id: 'contact_zip' }),
            },
            approximateDates: {
                start: DATE_PICKER({ id: 'approximateDates_start', editableFor: PERMISSIONS.LOWER_EXEC, label: 'Start' }),
                end: DATE_PICKER({ id: 'approximateDates_end', editableFor: PERMISSIONS.LOWER_EXEC, label: 'End' }),
                ongoing: RADIO({ id: 'approximateDates_ongoing', editableFor: PERMISSIONS.LOWER_EXEC, label: 'Ongoing', value: false }),
            }
        };
        this.form = {
            name: this.formTemplate.name,
            recipientName: this.formTemplate.recipient.recipientName,
            contact: DynamicFormGroupModel({
                id: 'contact',
                group: [
                    DynamicFormGroupModel({
                        id: 'phones',
                        group: [
                            this.formTemplate.contact.phone,
                            this.formTemplate.contact.press,
                            this.formTemplate.contact.ext
                        ]
                    }),
                    this.formTemplate.contact.fax,
                    DynamicFormGroupModel({
                        id: 'state_zip',
                        group: [
                            this.formTemplate.contact.zip,
                            this.formTemplate.contact.state,
                        ]
                    }),
                    this.formTemplate.contact.city,
                    this.formTemplate.contact.street,
                    this.formTemplate.contact.street2,
                ]
            }),
            approximateDates: DynamicFormGroupModel({
                id: 'approximateDates',
                group: [
                    DynamicFormGroupModel({
                        id: 'approximateDatesRow',
                        group: [
                            this.formTemplate.approximateDates.start,
                            this.formTemplate.approximateDates.end,
                            this.formTemplate.approximateDates.ongoing,
                        ]
                    })
                ]
            }),
        };
        this.nameCtrl = new FormControl();
        this.names = [];
        this.subscribed = true;
        this.state = State.before;
        this.typeAskingStep = creationSteps.initial;
        this.formModel = FormValueBuilder.buildFormModel(this.form);
        this.formGroup = this.formService.createFormGroup(this.formModel);
        // this.formGroup.addControl('name', this.nameCtrl);
        this.nameCtrl.setValidators(Validators.required);
        this.sub = navService.back().subscribe(j => this.closeDoctorForm());
        router.events.subscribe((val) => {
            if (val) {
                document.querySelectorAll('.mat-drawer-content').forEach(el => {
                    el.style.overflow = 'scroll';
                });
                this.dialogRef.close({});
            }
        });
    }
    onMousewheel(event) {
        const input = document.querySelector('.mat-input-element');
        input.blur();
        this.trigger.closePanel();
    }
    ngOnDestroy() {
    }
    _filterNames(value) {
        ////console.log(value);
        const filterValue = typeof value === 'string' ? value.toLowerCase() : value.name ? value.name.toLowerCase() : '';
        ////console.log(filterValue);
        return this.names.filter(state => state.name.toLowerCase().indexOf(filterValue) === 0);
    }
    displayFn(item) {
        return item ? item.name : undefined;
    }
    showName() {
        return true;
    }
    ngAfterViewInit() {
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.sslgId = this.getSslgId();
            this.names = yield this.medicalService.getCatalog(CatalogItemType.OTHER).toPromise();
            this.filteredNames = this.nameCtrl.valueChanges
                .pipe(map(value => {
                this.other = null;
                this.formTemplate.name = this.nameCtrl.value;
                if (value.id) {
                    this.subscription.unsubscribe();
                    if (value.id != -1) {
                        this.formGroup.disable({ onlySelf: true });
                        this.formGroup.controls.approximateDates.enable();
                        setTimeout(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            this.other = yield this.medicalService.getCatalogItem(CatalogItemType.OTHER, value.id).toPromise();
                            yield this.setFormData();
                        }));
                    }
                    else {
                        this.addressService.getPlaceInfo(value.placeId).subscribe(j => {
                            this.formTemplate.name.autocompleteSelectedValue = null;
                            if (j.zip)
                                this.formTemplate.contact.zip.valueUpdates.next(j.zip);
                            if (j.state)
                                this.formTemplate.contact.state.valueUpdates.next(j.state);
                            if (j.city)
                                this.formTemplate.contact.city.valueUpdates.next(j.city);
                            if (j.locations)
                                this.formTemplate.contact.street.valueUpdates.next((j.streetNumber ? j.streetNumber + ' ' : '') + (j.locations ? j.locations : ''));
                        });
                    }
                }
                else {
                    if (!this.subscribed) {
                        this.medicalService.setMedicalConditionsTemplate([]);
                        this.subscribeZipCode();
                    }
                    this.formGroup.enable({ onlySelf: true });
                    setTimeout(() => {
                        this.formGroup.reset();
                    });
                }
                return value;
            }), startWith(''), map((state) => {
                this.onStateChanges();
                if (state) {
                    let names = this._filterNames(state);
                    // if (typeof state === 'string' && state.length > 3 || state.name) {
                    // 	let query = typeof state === 'string' ? state : state.name;
                    // 	this.addressService.findPlace(query, "OTHER").pipe(map((place: any) => {
                    // 		return place.map(j=>{
                    // 			return {
                    // 				id: -1,
                    // 				name: j.title,
                    // 				label: j.description,
                    // 				...j
                    // 			};
                    // 		})
                    // 	})).subscribe(j => {
                    // 		names.push(...j);
                    // 	});
                    // }
                    return names;
                }
                return this.names.slice();
            }));
            this.subscribeZipCode();
            this.oldFormData = FormValueBuilder.getData(this.form);
            this.oldSourceNameData = this.nameCtrl.value ? this.nameCtrl.value : '';
        });
    }
    getSslgId() {
        return this.componentData.sslgId;
    }
    subscribeZipCode() {
        this.subscription = this.formTemplate.contact.zip.valueUpdates.subscribe((j) => {
            if (j && j.length == 5) {
                this.addressService.findAddress(j).subscribe(addr => {
                    if (addr && addr.state) {
                        this.formTemplate.contact.state.valueUpdates.next(addr.state);
                        this.formTemplate.contact.city.valueUpdates.next(addr.city);
                    }
                });
            }
            this.subscribed = true;
        });
    }
    setFormData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.other !== null) {
                try {
                    let street = this.other.contact.street;
                    this.other.contact.street = street.replace(/,/g, '');
                }
                catch (e) {
                }
                FormValueBuilder.setData(this.other, this.formTemplate);
            }
        });
    }
    onBlur($event) {
    }
    onChange($event) {
    }
    onFocus($event) {
    }
    closeDoctorForm() {
        this.router.navigate([this.route.snapshot.queryParams['returnUrl']], { queryParams: { page: this.route.snapshot.queryParams['page'] } });
        document.querySelectorAll('.mat-drawer-content').forEach(el => {
            el.style.overflow = 'scroll';
        });
        // this.dialogRef.close();
    }
    saveDoctor(cmd) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            FormValueBuilder.setControlsToched(this.formGroup);
            if (this.nameCtrl.value) {
                this.formTemplate.name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
                const data = FormValueBuilder.getData(this.formTemplate);
                if (this.nameCtrl && this.nameCtrl.value && this.nameCtrl.value.id) {
                    data.id = this.nameCtrl.value.id;
                }
                data.name = this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value;
                data.sslgId = this.sslgId;
                const treatmentStart = data && data.approximateDates && data.approximateDates.start ? this.dateService.getDateWithoutHours(data.approximateDates.start) : null;
                const treatmentEnd = data && data.approximateDates && data.approximateDates.end ? this.dateService.getDateWithoutHours(data.approximateDates.end) : null;
                const treatmentOngoing = data && data.approximateDates && data.approximateDates.ongoing ? data.approximateDates.ongoing : null;
                delete data.approximateDates;
                const treatmentReason = this.otherTreatmentData ? this.otherTreatmentData.treatmentReason : null;
                const treatmentDetails = this.otherTreatmentData ? this.otherTreatmentData.treatmentDetails : null;
                const nextVisit = this.otherTreatmentData ? this.dateService.getDateWithoutHours(this.otherTreatmentData.nextVisit) : null;
                // this.otherNewVisitId = 70781;
                // this.dialog.show("THANK YOU! You have successfully added this treating source to status star", ["OK"]);
                // this.nameCtrl.setValue({});
                // this.other = null;
                // this.formGroup.reset();
                // setTimeout(() => {
                //   this.updateOthersList.next(Math.random());
                //   this.changeTypeAskingStep(creationSteps.finished);
                // });
                //const doc = await this.medicalService.saveTreatingSourcesOther(this.sslgId, data, this.other);
                // const doc = await this.medicalService.setOther(this.sslgId, data, this.other);
                const other = yield this.medicalSourcesService.addTreatingSourcesOther(this.sslgId, data, treatmentStart, treatmentEnd, treatmentOngoing, treatmentReason, treatmentDetails, nextVisit);
                this.otherNewVisitId = other.id;
                this.dialog.show("THANK YOU! You have successfully added this treating source to status star", ["ok"]);
                this.nameCtrl.setValue({});
                this.other = null;
                this.formGroup.reset();
                setTimeout(() => {
                    this.updateOthersList.next(Math.random());
                    this.changeTypeAskingStep(creationSteps.finished);
                });
                // if (doc) {
                // document.querySelectorAll('.mat-drawer-content').forEach(el => {
                // 	(<HTMLElement>el).style.overflow = 'scroll';
                // });
                // this.dialogRef.close({cmd:cmd,data:doc});
                // }
            }
        });
    }
    onStateChanges() {
        const dataNew = FormValueBuilder.getData(this.form);
        const sourceNew = this.nameCtrl.value ? this.nameCtrl.value : '';
        if (this.oldFormData != '' && this.oldSourceNameData != '') {
            const isChanges = Functions.equals(this.oldFormData, dataNew);
            const isChangesSource = Functions.equals(this.oldSourceNameData, sourceNew);
            if (!isChanges || !isChangesSource) {
                this.state = State.after;
            }
            else {
                this.state = State.before;
            }
        }
    }
    isInputDirty() {
        return this.state == State.after;
    }
    addVisit() {
        this.saveDoctor('addVisit');
    }
    isClaimant() {
        return this.userInfoService.isClaimant();
    }
    canAccess() {
        if (this.userInfoService.isClaimant() || this.userInfoService.isSslgStaff() || this.userInfoService.isStaff()) {
            return true;
        }
        return false;
    }
    cancel() {
        document.querySelectorAll('.mat-drawer-content').forEach(el => {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close(false);
    }
    changeTypeAskingStep(step) {
        this.typeAskingStep = step;
        const name = this.nameCtrl && this.nameCtrl.value && this.nameCtrl.value.name ? this.nameCtrl.value.name : this.nameCtrl.value ? this.nameCtrl.value : '';
        if (this.isInitialStep()) {
            this.title = `New Other`;
        }
        if (this.isOtherTreatmentStep()) {
            this.title = `Other Treatment <span>${name}</span>`;
        }
        if (this.isFinishedStep()) {
            this.title = `What would you like to do next?`;
        }
    }
    isInitialStep() {
        return this.typeAskingStep === creationSteps.initial;
    }
    isOtherTreatmentStep() {
        return this.typeAskingStep === creationSteps.otherTreatment;
    }
    isFinishedStep() {
        return this.typeAskingStep === creationSteps.finished;
    }
    goToOtherTreatment() {
        this.changeTypeAskingStep(creationSteps.otherTreatment);
    }
    backOtherTreatment() {
        this.changeTypeAskingStep(creationSteps.initial);
    }
    nextOtherTreatment($event) {
        this.otherTreatmentData = $event;
        this.saveDoctor('new');
    }
    onFormStateChanged($event) {
    }
    onAllFormStateChanged($event) {
    }
    addAnotherDoctor() {
        this.router.navigate(['/claimants/medical-treating-sources/' + this.sslgId + '/doctors'], {
            queryParams: {
                returnUrl: this.route.snapshot.queryParams['returnUrl'],
                popup: 'new',
                from: 'other'
            }
        });
    }
    addAVisitSummary() {
        this.router.navigate(['/claimants/medical/' + this.sslgId + '/visit-summaries/new'], {
            queryParams: {
                returnUrl: '/claimants/medical/' + this.sslgId + '/visit-summaries',
                catalogItemId: this.otherNewVisitId,
                type: 'OTHER',
            }
        });
    }
    addHospitalization() {
        this.router.navigate(['/claimants/medical-treating-sources/' + this.sslgId + '/hospitals'], {
            queryParams: {
                returnUrl: this.route.snapshot.queryParams['returnUrl'],
                popup: 'new'
            }
        });
    }
    addOtherTreatingSource() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.names = yield this.medicalService.getCatalog(CatalogItemType.OTHER).toPromise();
            this.nameCtrl.setValue({});
            this.formGroup.reset();
            this.changeTypeAskingStep(creationSteps.initial);
        });
    }
    returnToMyProfile() {
        document.querySelectorAll('.mat-drawer-content').forEach(el => {
            el.style.overflow = 'scroll';
        });
        this.dialogRef.close({});
    }
}
