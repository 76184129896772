import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class PageLoaderService {
	public loaderShow = new BehaviorSubject(null);

	constructor() {
	}

	show() {
		this.loaderShow.next(true);
	}

	hide() {
		this.loaderShow.next(false);
	}
}
