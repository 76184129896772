import {Component, Input, OnInit, ViewChild} from '@angular/core';

@Component({
	selector: 'app-pdf-view-blocks-medications',
	templateUrl: './pdf-view-blocks-medications.component.html',
	styleUrls: ['./pdf-view-blocks-medications.component.scss']
})
export class PdfViewBlocksMedicationsComponent implements OnInit {

	@Input()
	tableData;

	constructor() {
	}

	async ngOnInit() {

	}

}
