import {Directive, Input, OnInit, OnDestroy, Injector} from '@angular/core';
import {NgControl} from '@angular/forms';
import {unmaskValue, valueToFormat} from './mask';


@Directive({
  selector: 'input[mask]'
})
export class MaskDirective implements OnInit, OnDestroy {
  @Input() mask: string;

  private control: NgControl;
  private _lastMaskedValue = '';

  constructor(
    private injector: Injector,
  ) {

  }

  ngOnInit() {
    if (!this.mask)
      return;
    this.control = this.injector.get(NgControl);

    if (!this.control || !this.control.valueAccessor) {
      return;
    }

    const originalWriteVal = this.control.valueAccessor.writeValue.bind(this.control.valueAccessor);
    this.control.valueAccessor.writeValue = (val: any) => originalWriteVal(this._maskValue(val));

    let originalChange = (<any>this.control.valueAccessor)['onChange'];
    if (!originalChange)
      originalChange = (<any>this.control.valueAccessor)['_onChange']
    originalChange.bind(this.control.valueAccessor);
    this.control.valueAccessor.registerOnChange((val: any) => originalChange(this._unmaskValue(val)));

    this._setVal(this._maskValue(this.control.value));
  }

  private _maskValue(val: string): string {
    if (!this.mask || val === this._lastMaskedValue || val == null) {
      return val;
    }
    const maskedVal = this._lastMaskedValue = valueToFormat(
      val,
      this.mask, this._lastMaskedValue.length > ((val && val != null) ? val.length : 0),
      this._lastMaskedValue);

    return maskedVal;
  }

  private _unmaskValue(val: string): string {
    const maskedVal = this._maskValue(val);
    const unmaskedVal = unmaskValue(maskedVal);

    if (maskedVal !== val) {
      this._setVal(maskedVal);
    }

    return maskedVal ? unmaskedVal : '';
  }

  private _setVal(val: string) {
    if (this.control.control) {
      this.control.control.setValue(val, {emitEvent: false});
    }
  }

  ngOnDestroy(): void {
  }
}
