/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./dynamic-material-form-control-container.component.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "./dynamic-material-form-control-container.component";
import * as i4 from "@ng-dynamic-forms/core";
import * as i5 from "../../app/auth/_services/authentication.service";
import * as i6 from "./dynamic-material-form.component";
var styles_DynamicMaterialFormComponent = [];
var RenderType_DynamicMaterialFormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DynamicMaterialFormComponent, data: {} });
export { RenderType_DynamicMaterialFormComponent as RenderType_DynamicMaterialFormComponent };
function View_DynamicMaterialFormComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "dynamic-material-form-control", [], [[8, "hidden", 0]], [[null, "blur"], [null, "change"], [null, "focus"], [null, "matEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("blur" === en)) {
        var pd_0 = (_co.onEvent($event, "blur") !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.onEvent($event, "change") !== false);
        ad = (pd_1 && ad);
    } if (("focus" === en)) {
        var pd_2 = (_co.onEvent($event, "focus") !== false);
        ad = (pd_2 && ad);
    } if (("matEvent" === en)) {
        var pd_3 = (_co.onEvent($event, "custom") !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_DynamicMaterialFormControlContainerComponent_0, i1.RenderType_DynamicMaterialFormControlContainerComponent)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpad(3, 2), i0.ɵdid(4, 770048, [[1, 4]], 1, i3.DynamicMaterialFormControlContainerComponent, [i0.ComponentFactoryResolver, i4.DynamicFormLayoutService, i4.DynamicFormValidationService, i4.DynamicFormComponentService, i4.DynamicFormRelationService, i5.AuthenticationService], { inputTemplateList: [0, "inputTemplateList"], group: [1, "group"], layout: [2, "layout"], model: [3, "model"] }, { blur: "blur", change: "change", focus: "focus", customEvent: "matEvent" }), i0.ɵqud(603979776, 2, { contentTemplateList: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, _co.getClass(_v.context.$implicit, "element", "host"), _co.getClass(_v.context.$implicit, "grid", "host")); _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.templates; var currVal_3 = _co.formGroup; var currVal_4 = _co.formLayout; var currVal_5 = _v.context.$implicit; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.hidden; _ck(_v, 0, 0, currVal_0); }); }
export function View_DynamicMaterialFormComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { components: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DynamicMaterialFormComponent_1)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formModel; var currVal_1 = _co.trackByFn; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_DynamicMaterialFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "dynamic-material-form", [], null, null, null, View_DynamicMaterialFormComponent_0, RenderType_DynamicMaterialFormComponent)), i0.ɵdid(1, 49152, null, 1, i6.DynamicMaterialFormComponent, [i4.DynamicFormService, i4.DynamicFormLayoutService], null, null), i0.ɵqud(603979776, 1, { templates: 1 })], null, null); }
var DynamicMaterialFormComponentNgFactory = i0.ɵccf("dynamic-material-form", i6.DynamicMaterialFormComponent, View_DynamicMaterialFormComponent_Host_0, { formGroup: "group", formModel: "model", formLayout: "layout" }, { blur: "blur", change: "change", focus: "focus", customEvent: "matEvent" }, []);
export { DynamicMaterialFormComponentNgFactory as DynamicMaterialFormComponentNgFactory };
