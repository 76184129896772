import {DynamicFormControlLayout, DynamicInputModel, DynamicInputModelConfig} from "@ng-dynamic-forms/core";
import {DynamicMoneyComponent} from "./dynamic-money.component";

const PADDING = "000000";

export interface DynamicMoneyModelConfig extends DynamicInputModelConfig, PermissionConfig {
    inputMaxValue?: number;
    fractionDigit?: number;
    showFractionZero?: boolean;
}

export class DynamicMoneyInputModel extends DynamicInputModel {
    inputElement: DynamicMoneyComponent;
    readonly type = 'MONEY';
    readonly PREFIX: string;
    readonly DECIMAL_SEPARATOR: string;
    readonly THOUSANDS_SEPARATOR: string;
    readonly SUFFIX: string;

    inputMaxValue: number;
    fractionDigit: number;
    showFractionZero: boolean;

    constructor(config: DynamicMoneyModelConfig, layout?: DynamicFormControlLayout) {
        super(config, layout);
        this.inputMaxValue = config.inputMaxValue;
        this.fractionDigit = config.fractionDigit;
        this.showFractionZero = config.showFractionZero;

        this.type = 'MONEY';
        this.PREFIX = '$ ';
        this.DECIMAL_SEPARATOR = ".";
        this.THOUSANDS_SEPARATOR = ",";
        this.SUFFIX = '';

        this.valueUpdates.subscribe(val => {

            if (!val || val === '')
                return;
            let newValue = this.checkIsNaN(val, this.inputMaxValue, this.fractionDigit, this.showFractionZero);

            if (newValue == val)
                return;

            setTimeout(() => {
                this.valueUpdates.next(newValue);
            });
        });
    }

    focusChange() {
        this.inputElement.onFocus(null);
        this.inputElement.onBlur(null);

    }

    private checkIsNaN(val, maxValue: number, fractionDigit: number, showFractionZero: boolean) {
        let curValue = val;
        if (!isNaN(+val)) {
            if (maxValue && +val > maxValue) {
                curValue = maxValue;
            }
            if (curValue && curValue.indexOf && curValue.indexOf('.') > -1) {
                let dij = curValue.substr(curValue.indexOf('.'));
                if (fractionDigit && dij.length > fractionDigit) {
                    dij = dij.substr(0, fractionDigit + 1);
                    curValue = curValue.substr(0, curValue.indexOf('.')) + dij;
                }
                ////console.log(+dij);
                if (+dij <= 0 && fractionDigit && (dij.length == fractionDigit + 1) && !showFractionZero) {
                    curValue = curValue.substr(0, curValue.indexOf('.'));
                }

            }
        } else {
            if (val.length > 1) {
                return this.checkIsNaN(val.substr(0, val.length - 1), maxValue, fractionDigit, showFractionZero);
            } else if (val.length === 1) {
                curValue = '';
            } else {
                return Error('Value length = 0, not implemented');
            }
        }
        return curValue;
    }

    transform(value: string, fractionSize: number = 2): string {
        let [integer, fraction = ""] = (value || "").toString()
            .split(".");

        fraction = fractionSize > 0
            ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
            : "";

        integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, this.THOUSANDS_SEPARATOR);

        if (integer.length > 0)
            return this.PREFIX + integer + fraction + this.SUFFIX;
        else
            return null;
    }

    parse(value: string, fractionSize: number = 2): string {
        let [integer, fraction = ""] = (value || "").replace(this.PREFIX, "")
            .replace(this.SUFFIX, "")
            .split(this.DECIMAL_SEPARATOR);

        integer = integer.replace(new RegExp(this.THOUSANDS_SEPARATOR, "g"), "");

        fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
            ? this.DECIMAL_SEPARATOR + (fraction + PADDING).substring(0, fractionSize)
            : "";

        return integer + fraction;
    }

}
