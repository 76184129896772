<nav mat-tab-nav-bar *ngIf="!hideHeader">
  <a mat-tab-link *ngFor="let link of navLinks"
     [routerLink]="link.path" routerLinkActive [routerLinkActiveOptions]="{exact: false}" #rla="routerLinkActive"
     [active]="rla.isActive">
    <b>
      {{link.label}}
    </b>

  </a>
</nav>
<div style="flex: 1; display: flex; flex-direction: column;">
  <router-outlet></router-outlet>
</div>
