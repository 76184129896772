import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CanAccessDirective} from "./can-access.directive";


@NgModule({
	declarations: [CanAccessDirective],
	exports: [CanAccessDirective],
	imports: [
		CommonModule
	]
})
export class CanAccessModule {
}
