<app-frame [overlay]="false" [title]="title" (neadClose)="closeVisitForm()" autocomplete="off">
	<div *ngIf="!messageHTML">
		{{this.message}}
	</div>
	<div *ngIf="messageHTML" [innerHTML]="messageHTML">
	</div>
	<app-form-actions-content>
		<form-button (clickOnButton)="confirm()">Confirm</form-button>
		<form-button (clickOnButton)="closeVisitForm()" buttonType="warning">Cancel</form-button>
	</app-form-actions-content>
</app-frame>
