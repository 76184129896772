<div class="report-header" #element>
	<div class="report-header__item">
		<img alt="Original SSLG Logo" class="alert-mail__img" src="../../../../assets/img/logo_yellow-group.png">
<!--		<div class="logo-title">Social security law group</div>-->
	</div>
</div>
<div class="report-info"
	 [class.is-blue]="isMedicationReport()"
	 [class.is-orange]="!isMedicationReport()">
	<div class="report-info__col">
		Claimant: <span>{{data.claimant}}</span>
	</div>
	<div class="report-info__col">
		SSLG Number <span>{{data.sslgNumber}}</span>
	</div>
	<div class="report-info__col">
		Date: <span>{{data.date| date:'MM/dd/yyyy'}}</span>
	</div>
</div>
<h1 class="report-title" *ngIf="isMedicationReport()">Claimant's medications</h1>
<h1 class="report-title" *ngIf="!isMedicationReport()">Claimant's treating sources</h1>
