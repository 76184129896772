import { HttpClient } from "@angular/common/http";
import { DEVICES_CONFIG } from "../@config/api";
import { UserInfoService } from "./user-info.service";
import { DialogService } from "../../@util/dialog/dialog.service";
import * as i0 from "@angular/core";
import * as i1 from "../../@util/dialog/dialog.service";
import * as i2 from "@angular/common/http";
import * as i3 from "./user-info.service";
export class DevicesService {
    constructor(dialog, http, userInfoService) {
        this.dialog = dialog;
        this.http = http;
        this.userInfoService = userInfoService;
        this.config = DEVICES_CONFIG;
    }
    add(uuid, type) {
        let userName = this.getUserName();
        return this.http.post(this.config.add, {
            userName: userName,
            uuid: uuid,
            type: type,
        });
    }
    setLocalFirebaseToken() {
        let token = this.userInfoService.getCurrentFirebaseToken();
        this.saveFirebaseToken(token);
    }
    setFirebaseToken(token) {
        this.saveFirebaseToken(token);
    }
    saveFirebaseToken(token) {
        let userName = this.getUserName();
        let currentFirebaseToken = this.userInfoService.getCurrentFirebaseToken();
        let uuid = this.userInfoService.getDeviceUuid();
        if (currentFirebaseToken != token && userName != '' && uuid != '') {
            this.http.post(this.config.saveFirebaseToken, {
                userName: userName,
                token: token,
                uuid: uuid
            }).subscribe(data => {
                this.userInfoService.setCurrentFirebaseToken(token);
            });
        }
    }
    getUserName() {
        return this.userInfoService.getCurrentUserName();
    }
}
DevicesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DevicesService_Factory() { return new DevicesService(i0.ɵɵinject(i1.DialogService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.UserInfoService)); }, token: DevicesService, providedIn: "root" });
